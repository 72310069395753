/**
 * cercacasa_api
 * cercacasa_api
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Agency } from '../model/agency';
import { Agent } from '../model/agent';
import { ContactRequestResponse } from '../model/contactRequestResponse';
import { CountUniqueSent } from '../model/countUniqueSent';
import { GenericRequest } from '../model/genericRequest';
import { InlineResponse200 } from '../model/inlineResponse200';
import { InlineResponse2001 } from '../model/inlineResponse2001';
import { InlineResponse2002 } from '../model/inlineResponse2002';
import { Member } from '../model/member';
import { RequestForGenericRequest } from '../model/requestForGenericRequest';
import { ZonesForDistinct } from '../model/zonesForDistinct';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class GenericRequestService {

    protected basePath = 'https://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Count instances of the model matched by where from the data source.
     * 
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericRequestCount(where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public genericRequestCount(where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public genericRequestCount(where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public genericRequestCount(where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/generic-requests/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericRequestCountUniqueSent(observe?: 'body', reportProgress?: boolean): Observable<CountUniqueSent>;
    public genericRequestCountUniqueSent(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CountUniqueSent>>;
    public genericRequestCountUniqueSent(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CountUniqueSent>>;
    public genericRequestCountUniqueSent(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<CountUniqueSent>(`${this.basePath}/generic-requests/countUniqueSent`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new instance of the model and persist it into the data source.
     * 
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericRequestCreate(data?: GenericRequest, observe?: 'body', reportProgress?: boolean): Observable<GenericRequest>;
    public genericRequestCreate(data?: GenericRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericRequest>>;
    public genericRequestCreate(data?: GenericRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericRequest>>;
    public genericRequestCreate(data?: GenericRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericRequest>(`${this.basePath}/generic-requests`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a change stream.
     * 
     * @param options 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericRequestCreateChangeStreamGetGenericRequestsChangeStream(options?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public genericRequestCreateChangeStreamGetGenericRequestsChangeStream(options?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public genericRequestCreateChangeStreamGetGenericRequestsChangeStream(options?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public genericRequestCreateChangeStreamGetGenericRequestsChangeStream(options?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (options !== undefined && options !== null) {
            queryParameters = queryParameters.set('options', <any>options);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get(`${this.basePath}/generic-requests/change-stream`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a change stream.
     * 
     * @param options 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericRequestCreateChangeStreamPostGenericRequestsChangeStream(options?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public genericRequestCreateChangeStreamPostGenericRequestsChangeStream(options?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public genericRequestCreateChangeStreamPostGenericRequestsChangeStream(options?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public genericRequestCreateChangeStreamPostGenericRequestsChangeStream(options?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (options !== undefined) {
            formParams.append('options', <any>options);
        }

        return this.httpClient.post(`${this.basePath}/generic-requests/change-stream`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericRequestCreateMultipleGenericRequests(data?: RequestForGenericRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<GenericRequest>>;
    public genericRequestCreateMultipleGenericRequests(data?: RequestForGenericRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GenericRequest>>>;
    public genericRequestCreateMultipleGenericRequests(data?: RequestForGenericRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GenericRequest>>>;
    public genericRequestCreateMultipleGenericRequests(data?: RequestForGenericRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<GenericRequest>>(`${this.basePath}/generic-requests/createMultipleGenericRequests`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a model instance by {{id}} from the data source.
     * 
     * @param id Model id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericRequestDeleteById(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public genericRequestDeleteById(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public genericRequestDeleteById(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public genericRequestDeleteById(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling genericRequestDeleteById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/generic-requests/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check whether a model instance exists in the data source.
     * 
     * @param id Model id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericRequestExistsGetGenericRequestsidExists(id: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2001>;
    public genericRequestExistsGetGenericRequestsidExists(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2001>>;
    public genericRequestExistsGetGenericRequestsidExists(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2001>>;
    public genericRequestExistsGetGenericRequestsidExists(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling genericRequestExistsGetGenericRequestsidExists.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse2001>(`${this.basePath}/generic-requests/${encodeURIComponent(String(id))}/exists`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check whether a model instance exists in the data source.
     * 
     * @param id Model id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericRequestExistsHeadGenericRequestsid(id: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2001>;
    public genericRequestExistsHeadGenericRequestsid(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2001>>;
    public genericRequestExistsHeadGenericRequestsid(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2001>>;
    public genericRequestExistsHeadGenericRequestsid(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling genericRequestExistsHeadGenericRequestsid.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<InlineResponse2001>(`${this.basePath}/generic-requests/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find all instances of the model matched by filter from the data source.
     * 
     * @param filter Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (&#x60;{\&quot;where\&quot;:{\&quot;something\&quot;:\&quot;value\&quot;}}&#x60;).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericRequestFind(filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<GenericRequest>>;
    public genericRequestFind(filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GenericRequest>>>;
    public genericRequestFind(filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GenericRequest>>>;
    public genericRequestFind(filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<GenericRequest>>(`${this.basePath}/generic-requests`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a model instance by {{id}} from the data source.
     * 
     * @param id Model id
     * @param filter Filter defining fields and include - must be a JSON-encoded string ({\&quot;something\&quot;:\&quot;value\&quot;})
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericRequestFindById(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<GenericRequest>;
    public genericRequestFindById(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericRequest>>;
    public genericRequestFindById(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericRequest>>;
    public genericRequestFindById(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling genericRequestFindById.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<GenericRequest>(`${this.basePath}/generic-requests/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find first instance of the model matched by filter from the data source.
     * 
     * @param filter Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (&#x60;{\&quot;where\&quot;:{\&quot;something\&quot;:\&quot;value\&quot;}}&#x60;).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericRequestFindOne(filter?: string, observe?: 'body', reportProgress?: boolean): Observable<GenericRequest>;
    public genericRequestFindOne(filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericRequest>>;
    public genericRequestFindOne(filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericRequest>>;
    public genericRequestFindOne(filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<GenericRequest>(`${this.basePath}/generic-requests/findOne`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param regione 
     * @param provincia 
     * @param comune 
     * @param zona 
     * @param coords 
     * @param radius 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericRequestGetRegisteredAgenciesForQuery(regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Agency>>;
    public genericRequestGetRegisteredAgenciesForQuery(regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Agency>>>;
    public genericRequestGetRegisteredAgenciesForQuery(regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Agency>>>;
    public genericRequestGetRegisteredAgenciesForQuery(regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (regione !== undefined && regione !== null) {
            queryParameters = queryParameters.set('regione', <any>regione);
        }
        if (provincia !== undefined && provincia !== null) {
            queryParameters = queryParameters.set('provincia', <any>provincia);
        }
        if (comune !== undefined && comune !== null) {
            queryParameters = queryParameters.set('comune', <any>comune);
        }
        if (zona !== undefined && zona !== null) {
            queryParameters = queryParameters.set('zona', <any>zona);
        }
        if (coords !== undefined && coords !== null) {
            queryParameters = queryParameters.set('coords', <any>coords);
        }
        if (radius !== undefined && radius !== null) {
            queryParameters = queryParameters.set('radius', <any>radius);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Agency>>(`${this.basePath}/generic-requests/getRegisteredAgenciesForQuery`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patch an existing model instance or insert a new one into the data source.
     * 
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericRequestPatchOrCreate(data?: GenericRequest, observe?: 'body', reportProgress?: boolean): Observable<GenericRequest>;
    public genericRequestPatchOrCreate(data?: GenericRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericRequest>>;
    public genericRequestPatchOrCreate(data?: GenericRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericRequest>>;
    public genericRequestPatchOrCreate(data?: GenericRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<GenericRequest>(`${this.basePath}/generic-requests`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetches belongsTo relation agency.
     * 
     * @param id GenericRequest id
     * @param refresh 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericRequestPrototypeGetAgency(id: string, refresh?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Agency>;
    public genericRequestPrototypeGetAgency(id: string, refresh?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agency>>;
    public genericRequestPrototypeGetAgency(id: string, refresh?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agency>>;
    public genericRequestPrototypeGetAgency(id: string, refresh?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling genericRequestPrototypeGetAgency.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (refresh !== undefined && refresh !== null) {
            queryParameters = queryParameters.set('refresh', <any>refresh);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Agency>(`${this.basePath}/generic-requests/${encodeURIComponent(String(id))}/agency`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetches belongsTo relation handledBy.
     * 
     * @param id GenericRequest id
     * @param refresh 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericRequestPrototypeGetHandledBy(id: string, refresh?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Agent>;
    public genericRequestPrototypeGetHandledBy(id: string, refresh?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agent>>;
    public genericRequestPrototypeGetHandledBy(id: string, refresh?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agent>>;
    public genericRequestPrototypeGetHandledBy(id: string, refresh?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling genericRequestPrototypeGetHandledBy.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (refresh !== undefined && refresh !== null) {
            queryParameters = queryParameters.set('refresh', <any>refresh);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Agent>(`${this.basePath}/generic-requests/${encodeURIComponent(String(id))}/handledBy`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetches belongsTo relation member.
     * 
     * @param id GenericRequest id
     * @param refresh 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericRequestPrototypeGetMember(id: string, refresh?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Member>;
    public genericRequestPrototypeGetMember(id: string, refresh?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Member>>;
    public genericRequestPrototypeGetMember(id: string, refresh?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Member>>;
    public genericRequestPrototypeGetMember(id: string, refresh?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling genericRequestPrototypeGetMember.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (refresh !== undefined && refresh !== null) {
            queryParameters = queryParameters.set('refresh', <any>refresh);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Member>(`${this.basePath}/generic-requests/${encodeURIComponent(String(id))}/member`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patch attributes for a model instance and persist it into the data source.
     * 
     * @param id GenericRequest id
     * @param data An object of model property name/value pairs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericRequestPrototypePatchAttributes(id: string, data?: GenericRequest, observe?: 'body', reportProgress?: boolean): Observable<GenericRequest>;
    public genericRequestPrototypePatchAttributes(id: string, data?: GenericRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericRequest>>;
    public genericRequestPrototypePatchAttributes(id: string, data?: GenericRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericRequest>>;
    public genericRequestPrototypePatchAttributes(id: string, data?: GenericRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling genericRequestPrototypePatchAttributes.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<GenericRequest>(`${this.basePath}/generic-requests/${encodeURIComponent(String(id))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace attributes for a model instance and persist it into the data source.
     * 
     * @param id Model id
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericRequestReplaceByIdPostGenericRequestsidReplace(id: string, data?: GenericRequest, observe?: 'body', reportProgress?: boolean): Observable<GenericRequest>;
    public genericRequestReplaceByIdPostGenericRequestsidReplace(id: string, data?: GenericRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericRequest>>;
    public genericRequestReplaceByIdPostGenericRequestsidReplace(id: string, data?: GenericRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericRequest>>;
    public genericRequestReplaceByIdPostGenericRequestsidReplace(id: string, data?: GenericRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling genericRequestReplaceByIdPostGenericRequestsidReplace.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericRequest>(`${this.basePath}/generic-requests/${encodeURIComponent(String(id))}/replace`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace attributes for a model instance and persist it into the data source.
     * 
     * @param id Model id
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericRequestReplaceByIdPutGenericRequestsid(id: string, data?: GenericRequest, observe?: 'body', reportProgress?: boolean): Observable<GenericRequest>;
    public genericRequestReplaceByIdPutGenericRequestsid(id: string, data?: GenericRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericRequest>>;
    public genericRequestReplaceByIdPutGenericRequestsid(id: string, data?: GenericRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericRequest>>;
    public genericRequestReplaceByIdPutGenericRequestsid(id: string, data?: GenericRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling genericRequestReplaceByIdPutGenericRequestsid.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<GenericRequest>(`${this.basePath}/generic-requests/${encodeURIComponent(String(id))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace an existing model instance or insert a new one into the data source.
     * 
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericRequestReplaceOrCreatePostGenericRequestsReplaceOrCreate(data?: GenericRequest, observe?: 'body', reportProgress?: boolean): Observable<GenericRequest>;
    public genericRequestReplaceOrCreatePostGenericRequestsReplaceOrCreate(data?: GenericRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericRequest>>;
    public genericRequestReplaceOrCreatePostGenericRequestsReplaceOrCreate(data?: GenericRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericRequest>>;
    public genericRequestReplaceOrCreatePostGenericRequestsReplaceOrCreate(data?: GenericRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericRequest>(`${this.basePath}/generic-requests/replaceOrCreate`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace an existing model instance or insert a new one into the data source.
     * 
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericRequestReplaceOrCreatePutGenericRequests(data?: GenericRequest, observe?: 'body', reportProgress?: boolean): Observable<GenericRequest>;
    public genericRequestReplaceOrCreatePutGenericRequests(data?: GenericRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericRequest>>;
    public genericRequestReplaceOrCreatePutGenericRequests(data?: GenericRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericRequest>>;
    public genericRequestReplaceOrCreatePutGenericRequests(data?: GenericRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<GenericRequest>(`${this.basePath}/generic-requests`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param agencyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericRequestReturnAllZonesFromAgencyId(agencyId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ZonesForDistinct>>;
    public genericRequestReturnAllZonesFromAgencyId(agencyId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ZonesForDistinct>>>;
    public genericRequestReturnAllZonesFromAgencyId(agencyId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ZonesForDistinct>>>;
    public genericRequestReturnAllZonesFromAgencyId(agencyId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agencyId !== undefined && agencyId !== null) {
            queryParameters = queryParameters.set('agencyId', <any>agencyId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<ZonesForDistinct>>(`${this.basePath}/generic-requests/returnAllZonesFromAgencyId`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param where 
     * @param order 
     * @param query 
     * @param limit 
     * @param skip 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericRequestSearchContactRequestOnAllFields(where?: string, order?: string, query?: string, limit?: number, skip?: number, observe?: 'body', reportProgress?: boolean): Observable<ContactRequestResponse>;
    public genericRequestSearchContactRequestOnAllFields(where?: string, order?: string, query?: string, limit?: number, skip?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContactRequestResponse>>;
    public genericRequestSearchContactRequestOnAllFields(where?: string, order?: string, query?: string, limit?: number, skip?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContactRequestResponse>>;
    public genericRequestSearchContactRequestOnAllFields(where?: string, order?: string, query?: string, limit?: number, skip?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (skip !== undefined && skip !== null) {
            queryParameters = queryParameters.set('skip', <any>skip);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<ContactRequestResponse>(`${this.basePath}/generic-requests/searchContactRequestOnAllFields`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update instances of the model matched by {{where}} from the data source.
     * 
     * @param where Criteria to match model instances
     * @param data An object of model property name/value pairs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericRequestUpdateAll(where?: string, data?: GenericRequest, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2002>;
    public genericRequestUpdateAll(where?: string, data?: GenericRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2002>>;
    public genericRequestUpdateAll(where?: string, data?: GenericRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2002>>;
    public genericRequestUpdateAll(where?: string, data?: GenericRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse2002>(`${this.basePath}/generic-requests/update`,
            data,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an existing model instance or insert a new one into the data source based on the where criteria.
     * 
     * @param where Criteria to match model instances
     * @param data An object of model property name/value pairs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public genericRequestUpsertWithWhere(where?: string, data?: GenericRequest, observe?: 'body', reportProgress?: boolean): Observable<GenericRequest>;
    public genericRequestUpsertWithWhere(where?: string, data?: GenericRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericRequest>>;
    public genericRequestUpsertWithWhere(where?: string, data?: GenericRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericRequest>>;
    public genericRequestUpsertWithWhere(where?: string, data?: GenericRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericRequest>(`${this.basePath}/generic-requests/upsertWithWhere`,
            data,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // x prod
  serverPath: 'https://api.cercacasa.it',
  apiPath: 'https://api.cercacasa.it/api',
  // x test
  // serverPath: 'https://api.cercacasa.loudsrl.com',
  // apiPath: 'https://api.cercacasa.loudsrl.com/api',
  sitePath: 'http://localhost:4200',
  ssrSitePath: 'http://localhost:4001',
  mapAccessToken:
    'pk.eyJ1IjoiZ2lhbmdpY2VzIiwiYSI6ImNrY2V2Nm12ajBjOXYyeW5uZmU1OWxidm0ifQ.fsPZpYn_LkQt7thxsfH-oQ',
  dashboard: 'https://cercacasa-backoffice-test.web.app/',
  gtag: 'G-SZHQFFKHCE',
  oldImgUrl: 'https://cercacasa.s3.amazonaws',
  cdnImgUrl: 'https://cdn.cercacasa.it',
  showProfessionist: true,
  showNews: true,
  redisPass: '',
  gtagId: 'GTM-MHHMQ5S4'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

export interface Typology {
    text: string;
    id: string;
    value: any[];
}

export interface Company {
    text: string;
    id: string;
    value: any[];
}

export interface SearchQuery {
    tipologia?: string;
    categoria?: Typology;
    coordinate?: any;
    raggio?: number;
    comune?: string;
    provincia?: string;
    regione?: string;
    metodo?: number;
}

export default class Constants {
    public static LIST_OF_COMPANY: Company[] = [
        {
            text: 'AstePoint',
            id: 'astepoint',
            value: [
                {
                    text: 'AstePoint',
                    value: 'astepoint'
                }
            ]
        },
        {
            text: 'AZ Group',
            id: 'az-group',
            value: [
                {
                    text: 'AZ Group',
                    value: 'az-group'
                }
            ]
        },
        {
            text: 'Bonifazi',
            id: 'bonifazi',
            value: [
                {
                    text: 'Bonifazi',
                    value: 'bonifazi'
                }
            ]
        },
        {
            text: 'Book Immobiliare',
            id: 'book-immobiliare',
            value: [
                {
                    text: 'Book Immobiliare',
                    value: 'book-immobiliare'
                }
            ]
        },
        {
            text: 'Buildingcase',
            id: 'buildingcase',
            value: [
                {
                    text: 'Buildingcase',
                    value: 'buildingcase'
                }
            ]
        },
        {
            text: 'Capital House',
            id: 'capital-house',
            value: [
                {
                    text: 'Capital House',
                    value: 'capital-house'
                }
            ]
        },
        {
            text: 'Casapoint',
            id: 'casapoint',
            value: [
                {
                    text: 'Casapoint',
                    value: 'casapoint'
                }
            ]
        },
        {
            text: 'Centro Aste Giudiziarie',
            id: 'centro-aste-giudiziarie',
            value: [
                {
                    text: 'Centro Aste Giudiziarie',
                    value: 'centro-aste-giudiziarie'
                }
            ]
        },
        {
            text: 'CIA',
            id: 'cia',
            value: [
                {
                    text: 'CIA',
                    value: 'cia'
                }
            ]
        },
        {
            text: 'Coldwell Banker Italy',
            id: 'coldwell-banker-italy',
            value: [
                {
                    text: 'Coldwell Banker Italy',
                    value: 'coldwell-banker-italy'
                }
            ]
        },
        {
            text: 'Consimm',
            id: 'consimm',
            value: [
                {
                    text: 'Consimm',
                    value: 'consimm'
                }
            ]
        },
        {
            text: 'Edilproposte',
            id: 'edilproposte',
            value: [
                {
                    text: 'Edilproposte',
                    value: 'edilproposte'
                }
            ]
        },
        {
            text: 'Engel & Völkers',
            id: 'engel-volkers',
            value: [
                {
                    text: 'Engel & Völkers',
                    value: 'engel-volkers'
                }
            ]
        },
        {
            text: 'Fondocasa',
            id: 'fondocasa',
            value: [
                {
                    text: 'Fondocasa',
                    value: 'fondocasa'
                }
            ]
        },
        {
            text: 'Frimm Group',
            id: 'frimm-group',
            value: [
                {
                    text: 'Frimm Group',
                    value: 'frimm-group'
                }
            ]
        },
        {
            text: 'Gabetti',
            id: 'gabetti',
            value: [
                {
                    text: 'Gabetti',
                    value: 'gabetti'
                }
            ]
        },
        {
            text: 'Grimaldi',
            id: 'grimaldi',
            value: [
                {
                    text: 'Grimaldi',
                    value: 'grimaldi'
                }
            ]
        },
        {
            text: 'Gruppo del Monte Immobiliare',
            id: 'gruppo-del-monte-immobiliare',
            value: [
                {
                    text: 'Gruppo del Monte Immobiliare',
                    value: 'gruppo-del-monte-immobiliare'
                }
            ]
        },
        {
            text: 'Gruppo ProgettoCasa',
            id: 'gruppo-progetto-casa',
            value: [
                {
                    text: 'Gruppo ProgettoCasa',
                    value: 'gruppo-progetto-casa'
                }
            ]
        },
        {
            text: 'GRUPPO SIR',
            id: 'gruppo-sir',
            value: [
                {
                    text: 'GRUPPO SIR',
                    value: 'gruppo-sir'
                }
            ]
        },
        {
            text: 'iad Italia',
            id: 'iad-italia',
            value: [
                {
                    text: 'iad Italia',
                    value: 'iad-italia'
                }
            ]
        },
        {
            text: 'Iconacasa',
            id: 'iconacasa',
            value: [
                {
                    text: 'Iconacasa',
                    value: 'iconacasa'
                }
            ]
        },
        {
            text: 'IMI',
            id: 'imi',
            value: [
                {
                    text: 'IMI',
                    value: 'imi'
                }
            ]
        },
        {
            text: 'Intesa Sanpaolo Casa',
            id: 'intesa-sanpaolo-casa',
            value: [
                {
                    text: 'Intesa Sanpaolo Casa',
                    value: 'intesa-sanpaolo-casa'
                }
            ]
        },
        {
            text: 'IPI',
            id: 'ipi',
            value: [
                {
                    text: 'IPI',
                    value: 'ipi'
                }
            ]
        },
        {
            text: 'Istituti Vendite Giudiziarie',
            id: 'istituti-vendite-giudiziarie',
            value: [
                {
                    text: 'Istituti Vendite Giudiziarie',
                    value: 'istituti-vendite-giudiziarie'
                }
            ]
        },
        {
            text: 'ITALCASA',
            id: 'italcasa',
            value: [
                {
                    text: 'ITALCASA',
                    value: 'italcasa'
                }
            ]
        },
        {
            text: 'Italiana Immobiliare',
            id: 'italiana-immobiliare',
            value: [
                {
                    text: 'Italiana Immobiliare',
                    value: 'italiana-immobiliare'
                }
            ]
        },
        {
            text: 'Italiancase',
            id: 'italiancase',
            value: [
                {
                    text: 'Italiancase',
                    value: 'italiancase'
                }
            ]
        },
        {
            text: 'ITI Network',
            id: 'iti-network',
            value: [
                {
                    text: 'ITI Network',
                    value: 'iti-network'
                }
            ]
        },
        {
            text: 'L\'Arte di Abitare',
            id: 'arte-di-abitare',
            value: [
                {
                    text: 'L\'Arte di Abitare',
                    value: 'arte-di-abitare'
                }
            ]
        },
        {
            text: 'L\'Immobiliare.com',
            id: 'immobiliare-com',
            value: [
                {
                    text: 'L\'Immobiliare.com',
                    value: 'immobiliare-com'
                }
            ]
        },
        {
            text: 'La Casa',
            id: 'la-casa',
            value: [
                {
                    text: 'La Casa',
                    value: 'la-casa'
                }
            ]
        },
        {
            text: 'Mediocasa',
            id: 'mediocasa',
            value: [
                {
                    text: 'Mediocasa',
                    value: 'mediocasa'
                }
            ]
        },
        {
            text: 'MIDA Group',
            id: 'mida-group',
            value: [
                {
                    text: 'MIDA Group',
                    value: 'mida-group'
                }
            ]
        },
        {
            text: 'Mister House',
            id: 'mister-house',
            value: [
                {
                    text: 'Mister House',
                    value: 'mister-house'
                }
            ]
        },
        {
            text: 'Obiettivocasa',
            id: 'obiettivocasa',
            value: [
                {
                    text: 'Obiettivocasa',
                    value: 'obiettivocasa'
                }
            ]
        },
        {
            text: 'ParametroCasa',
            id: 'parametro-casa',
            value: [
                {
                    text: 'ParametroCasa',
                    value: 'parametro-casa'
                }
            ]
        },
        {
            text: 'Primacasa',
            id: 'primalux-casa',
            value: [
                {
                    text: 'Primacasa',
                    value: 'primalux-casa'
                }
            ]
        },
        {
            text: 'PRIMALUX',
            id: 'primalux',
            value: [
                {
                    text: 'PRIMALUX',
                    value: 'primalux'
                }
            ]
        },
        {
            text: 'Professione Casa',
            id: 'professione-casa',
            value: [
                {
                    text: 'Professione Casa',
                    value: 'professione-casa'
                }
            ]
        },
        {
            text: 'Puntocasa',
            id: 'puntocasa',
            value: [
                {
                    text: 'Puntocasa',
                    value: 'puntocasa'
                }
            ]
        },
        {
            text: 'RE Point',
            id: 'repoint',
            value: [
                {
                    text: 'RE Point',
                    value: 'repoint'
                }
            ]
        },
        {
            text: 'RE/MAX',
            id: 'remax',
            value: [
                {
                    text: 'RE/MAX',
                    value: 'remax'
                }
            ]
        },
        {
            text: 'Rete Toscana Casa',
            id: 'rete-toscana-casa',
            value: [
                {
                    text: 'Rete Toscana Casa',
                    value: 'rete-toscana-casa'
                }
            ]
        },
        {
            text: 'Retecasa',
            id: 'retecasa',
            value: [
                {
                    text: 'Retecasa',
                    value: 'retecasa'
                }
            ]
        },
        {
            text: 'Rubino Immopoint',
            id: 'rubino-immopoint',
            value: [
                {
                    text: 'Rubino Immopoint',
                    value: 'rubino-immopoint'
                }
            ]
        },
        {
            text: 'San Pietro',
            id: 'san-pietro',
            value: [
                {
                    text: 'San Pietro',
                    value: 'san-pietro'
                }
            ]
        },
        {
            text: 'Sarpi',
            id: 'sarpi',
            value: [
                {
                    text: 'Sarpi',
                    value: 'sarpi'
                }
            ]
        },
        {
            text: 'Solo Affitti',
            id: 'solo-affitti',
            value: [
                {
                    text: 'Solo Affitti',
                    value: 'solo-affitti'
                }
            ]
        },
        {
            text: 'Sotheby\'s',
            id: 'sotheby',
            value: [
                {
                    text: 'Sotheby\'s',
                    value: 'sotheby'
                }
            ]
        },
        {
            text: 'SP Immobiliare',
            id: 'sp-immobiliare',
            value: [
                {
                    text: 'SP Immobiliare',
                    value: 'sp-immobiliare'
                }
            ]
        },
        {
            text: 'Stimacasa',
            id: 'stimacasa',
            value: [
                {
                    text: 'Stimacasa',
                    value: 'stimacasa'
                }
            ]
        },
        {
            text: 'Studio Area',
            id: 'studio-area',
            value: [
                {
                    text: 'Studio Area',
                    value: 'studio-area'
                }
            ]
        },
        {
            text: 'Studiocasa',
            id: 'studiocasa',
            value: [
                {
                    text: 'Studiocasa',
                    value: 'studiocasa'
                }
            ]
        },
        {
            text: 'Tecnoaffari',
            id: 'tecnoaffari',
            value: [
                {
                    text: 'Tecnoaffari',
                    value: 'tecnoaffari'
                }
            ]
        },
        {
            text: 'Tecnocasa',
            id: 'tecno-casa',
            value: [
                {
                    text: 'Tecnocasa',
                    value: 'tecno-casa'
                }
            ]
        },
        {
            text: 'Tecnorete',
            id: 'tecnorete',
            value: [
                {
                    text: 'Tecnorete',
                    value: 'tecnorete'
                }
            ]
        },
        {
            text: 'Tempocasa',
            id: 'tempocasa',
            value: [
                {
                    text: 'Tempocasa',
                    value: 'tempocasa'
                }
            ]
        },
        {
            text: 'Toscano',
            id: 'toscano',
            value: [
                {
                    text: 'Toscano',
                    value: 'toscano'
                }
            ]
        },
        {
            text: 'Tree Real Estate',
            id: 'tree-real-estate',
            value: [
                {
                    text: 'Tree Real Estate',
                    value: 'tree-real-estate'
                }
            ]
        },
        {
            text: 'Unica',
            id: 'unica',
            value: [
                {
                    text: 'Unica',
                    value: 'unica'
                }
            ]
        },
        {
            text: 'UniCredit Subito Casa',
            id: 'unicredit-subito-casa',
            value: [
                {
                    text: 'UniCredit Subito Casa',
                    value: 'unicredit-subito-casa'
                }
            ]
        },
        {
            text: 'Unire',
            id: 'unire',
            value: [
                {
                    text: 'Unire',
                    value: 'unire'
                }
            ]
        },
        {
            text: 'Vendocasa',
            id: 'vendocasa',
            value: [
                {
                    text: 'Vendocasa',
                    value: 'vendocasa'
                }
            ]
        },
        {
            text: 'Volpes Case',
            id: 'volpes-case',
            value: [
                {
                    text: 'Volpes Case',
                    value: 'volpes-case'
                }
            ]
        }
    ];

    public static LIST_OF_ASSOCIATIONS: any = [
        {
            text: 'air',
            id: 'AIR - Agenti Immobiliari Riuniti'
        },
        {
            text: 'anama',
            id: 'ANAMA - Associazione Nazionale Agenti e Mediatori d\'Affari'
        },
        {
            text: 'ance',
            id: 'Associazione Nazionale Costruttori Edili'
        },
        {
            text: 'fiaip',
            id: 'FIAIP - Federazione Italiana Agenti Immobiliari Professionali'
        },
        {
            text: 'fimaa',
            id: 'FIMAA - Federazione Italiana Mediatori Agenti d\'Affari'
        },
        {
            text: 'oam',
            id: 'OAM Organismo Agenti e Mediatori'
        }
    ];

    public static LIST_OF_TYPOLOGY: Typology[] = [
        {
            text: 'Case',
            id: 'case',
            value: [
                {
                    text: 'Appartamenti',
                    value: 'appartamenti'
                },
                {
                    text: 'Attici',
                    value: 'attici'
                },
                {
                    text: 'Baite',
                    value: 'baite'
                },
                {
                    text: 'Bifamiliare',
                    value: 'bifamiliari'
                },
                {
                    text: 'Case Semi-indipendenti',
                    value: 'case-semi-indipendenti'
                },
                {
                    text: 'Case Indipendenti',
                    value: 'case-indipendenti'
                },
                {
                    text: 'Tenuta Complesso',
                    value: 'tenuta-complesso'
                },
                {
                    text: 'Terratetti',
                    value: 'terratetti'
                },
                {
                    text: 'Castelli',
                    value: 'castelli'
                },
                {
                    text: 'Case Coloniche',
                    value: 'case-coloniche'
                },
                {
                    text: 'Loft',
                    value: 'loft'
                },
                {
                    text: 'Mansarde',
                    value: 'mansarde'
                },
                {
                    text: 'Multiproprieta',
                    value: 'multiproprieta'
                },
                {
                    text: 'Palazzi',
                    value: 'palazzi'
                },
                {
                    text: 'Residence',
                    value: 'residence'
                },
                {
                    text: 'Rustici Casali',
                    value: 'rustici-casali'
                },
                {
                    text: 'Ville',
                    value: 'ville'
                },
                {
                    text: 'Ville a Schiera',
                    value: 'ville-a-schiera'
                },
                {
                    text: 'Masserie',
                    value: 'masserie'
                },
                {
                    text: 'Case Singole',
                    value: 'case-singole'
                },
                {
                    text: 'Porzione di casa',
                    value: 'porzione-di-casa'
                },
                {
                    text: 'Abitazione tipica',
                    value: 'abitazione-tipica'
                },
                {
                    text: 'Trulli',
                    value: 'trulli'
                },
                {
                    text: 'Vacanza Trulli',
                    value: 'vacanza-trulli'
                },
                {
                    text: 'Porzione di villa',
                    value: 'porzione-di-villa'
                },
            ]
        },
        {
            text: 'Nuove Costruzioni',
            id: 'nuove',
            value: [
                {
                    value: 'nuove-costruzioni',
                    text: 'Nuove Costruzioni'
                }
            ]
        },
        {
            text: 'Garage',
            id: 'garage',
            value: [
                {
                    value: 'box',
                    text: 'Box'
                },
                {
                    value: 'posto-auto',
                    text: 'Posto Auto'
                },
                {
                    value: 'garage',
                    text: 'Garage'
                }
            ]
        },
        {
            text: 'Attività commerciali',
            id: 'commerciali',
            value: [
                {
                    text: 'Albergo',
                    value: 'albergo'
                },
                {
                    text: 'Uffici',
                    value: 'uffici'
                },
                {
                    text: 'Hotel',
                    value: 'hotel'
                },
                {
                    text: 'Laboratori',
                    value: 'laboratori'
                },
                {
                    text: 'Locali Commerciali',
                    value: 'locali-commerciali'
                },
                {
                    text: 'Negozi',
                    value: 'negozi'
                },
                {
                    text: 'Pizzerie / Pub',
                    value: 'pizzerie-pub'
                },
                {
                    text: 'Ristorante / Pizzerie / Pub',
                    value: 'ristorante-pizzeria-pub'
                },
                {
                    text: 'Attività Commerciali',
                    value: 'attivita-commerciali'
                },
                {
                    text: 'Aziende Agricole',
                    value: 'aziende-agricole'
                },
                {
                    text: 'Bar',
                    value: 'bar'
                },
                {
                    text: 'Ristoranti',
                    value: 'ristoranti'
                },
                {
                    text: 'Stabili',
                    value: 'stabili'
                },
                {
                    text: 'Stabilimento Balneare',
                    value: 'stabilimento-balneare'
                },
                {
                    text: 'Agriturismi',
                    value: 'agriturismi'
                },
                {
                    text: 'Capannoni Industriali',
                    value: 'capannoni-industriali'
                },
                {
                    text: 'Forni',
                    value: 'forni'
                },
                {
                    text: 'Immobile Storico',
                    value: 'immobile-storico'
                },
                {
                    text: 'Tabaccheria',
                    value: 'tabaccheria'
                },
                {
                    text: 'Sala e Giochi Scommesse',
                    value: 'sala-giochi-scommesse'
                },
                {
                    text: 'Negozio di alimentari',
                    value: 'alimentari'
                },
                {
                    text: 'Negozio di abbigliamento',
                    value: 'abbigliamento'
                },
                {
                    text: 'Pizza al taglio',
                    value: 'pizza-al-taglio'
                },
                {
                    text: 'Parrucchiere',
                    value: 'parrucchiere'
                },
                {
                    text: 'Palestra',
                    value: 'palestra'
                },
                {
                    text: 'Lavanderia / Tintoria',
                    value: 'lavanderia-tintoria'
                },
                {
                    text: 'Gelateria',
                    value: 'gelateria'
                },
                {
                    text: 'Fabbricato Misto',
                    value: 'fabbricato-misto'
                },
                {
                    text: 'Edicola',
                    value: 'edicola'
                },
                {
                    text: 'Discoteca',
                    value: 'discoteca'
                },
                {
                    text: 'Centro Estetico',
                    value: 'centro-estetico'
                },
                {
                    text: 'Bed and Breakfast',
                    value: 'bed-breakfast'
                }

            ]
        },
        {
            text: 'Magazzini',
            id: 'magazzini',
            value: [
                {
                    value: 'magazzini',
                    text: 'Magazzini'
                }
            ]
        },
        {
            text: 'Terreni',
            id: 'terreni',
            value: [
                {
                    value: 'terreno-industriale',
                    text: 'Terreno Industriale'
                },
                {
                    value: 'annesso-agricolo',
                    text: 'Annesso Agricolo'
                },
                {
                    text: 'Terreni',
                    value: 'terreni'
                },
                {
                    text: 'Terreno Agricolo',
                    value: 'terreno-agricolo'
                },
                {
                    text: 'Terreno Edificabile',
                    value: 'terreno-edificabile'
                },
                {
                    text: 'Terreno Edificabile Commerciale',
                    value: 'terreno-edificabile-commerciale'
                },
                {
                    text: 'Terreno Edificabile Produttivo',
                    value: 'terreno-edificabile-produttivo'
                }
            ]
        },
        {
            text: 'Stanze',
            id: 'stanze',
            value: [
                {
                    value: 'stanza-camera',
                    text: 'Stanza Camera'
                },
                {
                    value: 'stanza-/-camera',
                    text: 'Stanza / Camera'
                }
            ]
        },
        {
            text: 'Altro',
            id: 'altro',
            value: [
                {
                    value: 'posto-barca',
                    text: 'Posto Barca'
                },
                {
                    text: 'Qualsiasi tipo',
                    value: 'qualsiasi-tipo'
                },
                {
                    text: 'Altro',
                    value: 'altro'
                },
                {
                    text: 'Bungalow Piazzola',
                    value: 'bungalow-piazzola'
                },
                {
                    text: 'Pertinenze',
                    value: 'pertinenze'
                },
                {
                    text: 'Non definito',
                    value: 'non-definito'
                }
            ]
        },
        {
            text: 'Vacanze',
            id: 'vacanze',
            value: [
                {
                    value: 'vacanza-stanza-camera',
                    text: 'Camera'
                },
                {
                    value: 'vacanza-casa-singola',
                    text: 'Casa Singola'
                },
                {
                    text: 'Vacanza Masseria',
                    value: 'vacanza-masseria'
                },
                {
                    text: 'Vacanza Villa',
                    value: 'vacanza-villa'
                },
                {
                    text: 'Vacanza Villa a schiera',
                    value: 'vacanza-villa-a-schiera'
                },
                {
                    text: 'Vacanza Villini',
                    value: 'vacanza-villino'
                },
                {
                    text: 'Vacanza Colonica',
                    value: 'vacanza-colonica'
                },
                {
                    text: 'Vacanza Loft',
                    value: 'vacanza-loft'
                },
                {
                    text: 'Vacanza Mansarda',
                    value: 'vacanza-mansarda'
                },
                {
                    text: 'Vacanza Pertinenze',
                    value: 'vacanza-pertinenze'
                },
                {
                    text: 'Vacanza Palazzo',
                    value: 'vacanza-palazzo'
                },
                {
                    text: 'Vacanza Residence',
                    value: 'vacanza-residence'
                },
                {
                    text: 'Vacanza Rustico Casale',
                    value: 'vacanza-rustico-casale'
                },
                {
                    text: 'Vacanza Appartamento',
                    value: 'vacanza-appartamento'
                },
                {
                    text: 'Vacanza Appartamenti Indirpendenti',
                    value: 'vacanza-appartamenti-indipendente'
                },
                {
                    text: 'Vacanza Attico',
                    value: 'vacanza-attico'
                },
                {
                    text: 'Vacanza Bifamiliare',
                    value: 'vacanza-bifamiliare'
                },
                {
                    text: 'Vacanza Casa Semi-indipendente',
                    value: 'vacanza-casa-semi-indipendente'
                },
                {
                    text: 'Vacanza Tenuta-Complesso',
                    value: 'vacanza-tenuta-complesso'
                },
                {
                    text: 'Vacanza Terratetto',
                    value: 'vacanza-terratetto'
                },
                {
                    text: 'Vacanza Albergo',
                    value: 'vacanza-albergo'
                },
                {
                    text: 'Vacanza Agriturismo',
                    value: 'vacanza-agriturismo'
                },
                {
                    text: 'Vacanza Hotel',
                    value: 'vacanza-hotel'
                }
            ]
        }
    ];

    public static LIST_OF_TYPOLOGY_FOR_TITLE: any = [
        {
            text: 'Villa',
            value: ['ville', 'vacanza-villa', 'villa-quadrifamiliare', 'porzione-di-villa', 'villa-bifamiliare', 'villa-trifamiliare', 'ville-a-schiera', 'vacanza-villa-a-schiera', 'villini', 'vacanza-villino'],
        },
        {
            text: 'Ufficio',
            value: ['uffici'],
        },
        {
            text: 'Trullo',
            value: ['vacanza-trulli', 'trulli'],
        },
        {
            text: 'Terreno',
            value: ['terreni', 'terreno-edificabile-commerciale', 'terreno-edificabile-produttivo', 'terreno-agricolo', 'terreno-edificabile', 'terreno-industriale', 'annesso-agricolo'],
        },
        {
            text: 'Terratetto',
            value: ['terratetti', 'vacanza-terratetto'],
        },
        {
            text: 'Tenuta-Complesso',
            value: ['tenuta-complesso', 'vacanza-tenuta-complesso'],
        },
        {
            text: 'Stanza / Camera',
            value: ['stanza-/-camera', 'vacanza-stanza-camera', 'stanza-camera'],
        },
        {
            text: 'Stabilimento balneare',
            value: ['stabilimento-balneare'],
        },
        {
            text: 'Stabile',
            value: ['stabili'],
        },
        {
            text: 'Rustico casale',
            value: ['vacanza-rustico-casale', 'rustici-casali'],
        },
        {
            text: 'Ristorante',
            value: ['ristoranti'],
        },
        {
            text: 'Residence',
            value: ['vacanza-residence', 'residence'],
        },
        {
            text: 'Posto barca',
            value: ['posto-barca'],
        },
        {
            text: 'Pizzeria / Pub',
            value: ['pizzerie-pub', 'ristorante-pizzeria-pub'],
        },
        {
            text: 'Palazzo',
            value: ['vacanza-palazzo', 'palazzi'],
        },
        {
            text: 'Nuova costruzione',
            value: ['nuove-costruzioni'],
        },
        {
            text: 'Negozio',
            value: ['negozi', 'gelateria'],
        },
        {
            text: 'Multiproprietà',
            value: ['multiproprieta'],
        },
        {
            text: 'Masseria',
            value: ['masserie', 'vacanza-masseria'],
        },
        {
            text: 'Mansarda',
            value: ['mansarde', 'vacanza-mansarda'],
        },
        {
            text: 'Magazzino',
            value: ['magazzini'],
        },
        {
            text: 'Loft',
            value: ['loft', 'vacanza-loft'],
        },
        {
            text: 'Laboratorio',
            value: ['laboratori'],
        },
        {
            text: 'Hotel',
            value: ['hotel', 'vacanza-hotel'],
        },
        {
            text: 'Forno',
            value: ['forni'],
        },
        {
            text: 'Castello',
            value: ['castelli'],
        },
        {
            text: 'Casa',
            value: ['case-singole', 'vacanza-casa-singola', 'case-coloniche', 'case-semi-indipendenti', 'case-indipendenti', 'vacanza-casa-semi-indipendente', 'casa-indipendente', 'porzione-di-casa'],
        },
        {
            text: 'Proprietà',
            value: ['qualsiasi-tipo', 'non-definito', 'pertinenze', 'altro'],
        },
        {
            text: 'Capannone industriale',
            value: ['capannoni-industriali'],
        },
        {
            text: 'Bungalow / Piazzola',
            value: ['bungalow-piazzola'],
        },
        {
            text: 'Box',
            value: ['box', 'posto-auto', 'garage'],
        },
        {
            text: 'Bifamiliare',
            value: ['bifamiliari', 'vacanza-bifamiliare'],
        },
        {
            text: 'Bar',
            value: ['bar'],
        },
        {
            text: 'Baita',
            value: ['baite'],
        },
        {
            text: 'Azienda agricola',
            value: ['aziende-agricole'],
        },
        {
            text: 'Tabaccheria',
            value: ['tabaccheria'],
        },
        {
            text: 'Edicola',
            value: ['edicola'],
        },
        {
            text: 'Discoteca',
            value: ['discoteca'],
        },
        {
            text: 'Palestra',
            value: ['palestra'],
        },
        {
            text: 'Parrucchiere',
            value: ['parrucchiere'],
        },
        {
            text: 'Centro Estetico',
            value: ['centro-estetico'],
        },
        {
            text: 'Attività commerciale',
            value: ['attivita-commerciali', 'abbigliamento', 'fabbricato-misto', 'lavanderia-tintoria', 'pizza-al-taglio', 'sala-giochi-scommesse', 'locali-commerciali'],
        },
        {
            text: 'Attico',
            value: ['attici', 'vacanza-attico', 'attico-mansarda'],
        },
        {
            text: 'Appartamento',
            value: ['appartamenti', 'appartamento', 'vacanza-appartamento', 'abitazione-tipica', 'immobile-storico', 'appartamenti-indipendenti', 'vacanza-appartamenti-indipendente'],
        },
        {
            text: 'Albergo/Hotel',
            value: ['vacanza-albergo', 'albergo', 'bed-breakfast', 'vacanza-pertinenze', 'vacanza-colonica'],
        },
        {
            text: 'Agriturismo',
            value: ['agriturismi', 'vacanza-agriturismo'],
        },
    ];

    // URL ICONE PREMIO
    public static ICONE_PREMIO: any = [
        'https://cdn.cercacasa.it/assets/premio.png',
        'https://cdn.cercacasa.it/assets/riconoscimento.png',
        'https://cdn.cercacasa.it/assets/evento.png',
        'https://cdn.cercacasa.it/assets/obiettivi.png',
        'https://cdn.cercacasa.it/assets/team.png',
        'https://cdn.cercacasa.it/assets/affari-conclusi.png',
    ];

    // CUCINA
    public static TYPE_CUCINA: any = [
        { text: 'Abitabile', value: 'abitabile' },
        { text: 'Abitabile', value: 'AB' },
        { text: 'Angolo Cottura', value: 'angolo-cottura' },
        { text: 'Abitabile', value: 'AC' },
        { text: 'A Vista', value: 'a-vista' },
        { text: 'Abitabile', value: 'AV' },
        { text: 'Cucinotto', value: 'cucinotto' },
        { text: 'Cucinotto', value: 'CU' },
        { text: 'Non disp.', value: 'non-disponibile' },
        { text: 'Non disp.', value: 'ND' },
        { text: 'Semi abitabile', value: 'semi-abitabile' },
        { text: 'Semi abitabile', value: 'SA' },
    ];

    // CONDIZIONI IMMOBILE
    public static CONDIZIONE: any = [
        { text: 'Abitabile', value: 'abitabile' },
        { text: 'Abitabile', value: 'AB' },
        { text: 'Buone condizioni', value: 'buone-condizioni' },
        { text: 'Buone condizioni', value: 'BN' },
        { text: 'Da ristrutturare', value: 'da-ristrutturare' },
        { text: 'Da ristrutturare', value: 'DR' },
        { text: 'Nuova Costruzione', value: 'nuova-costruzione' },
        { text: 'Nuova Costruzione', value: 'NC' },
        { text: 'Non definito', value: 'non-definito' },
        { text: 'Non definito', value: 'ND' },
        { text: 'Ottime condizioni', value: 'ottime-condizioni' },
        { text: 'Ottime condizioni', value: 'OC' },
        { text: 'Ristrutturato', value: 'ristrutturato' },
        { text: 'Ristrutturato', value: 'RI' },
        { text: 'Seminuovo', value: 'seminuovo' },
        { text: 'Seminuovo', value: 'SM' },
    ];

    public static TO_FIX_CONDITIONS: any = [
        { text: 'AB', value: 'abitabile' },
        { text: 'BC', value: 'buone-condizioni' },
        { text: 'DR', value: 'da-ristrutturare' },
        { text: 'NC', value: 'nuova-costruzione' },
        { text: 'ND', value: 'non-definito' },
        { text: 'OT', value: 'ottime-condizioni' },
        { text: 'RI', value: 'ristrutturato' },
        { text: 'SM', value: 'seminuovo' }
    ];

    public static FILTER_CONDITIONS: any = [
        { text: 'AB', value: 'abitabile', filterName: 'Abitabile' },
        { text: 'BC', value: 'buone-condizioni', filterName: 'Buone Condizioni' },
        { text: 'DR', value: 'da-ristrutturare', filterName: 'Da Ristrutturare' },
        { text: 'NC', value: 'nuova-costruzione', filterName: 'Nuova Costruzione' },
        { text: 'OT', value: 'ottime-condizioni', filterName: 'Ottime Condizioni' },
        { text: 'RI', value: 'ristrutturato', filterName: 'Ristrutturato' },
        { text: 'SM', value: 'seminuovo', filterName: 'Seminuovo' }
    ];

    public static TIPOLOGIA_CATEGORIA: any = [
        { text: 'Commerciale', value: 'commerciale', filterName: 'Commerciale' },
        { text: 'Residenziale', value: 'residenziale', filterName: 'Residenziale' },
        { text: 'Stagionale', value: 'stagionale', filterName: 'Stagionale' },
        { text: 'Agricolo', value: 'agricolo', filterName: 'Agricolo' },
        { text: 'Uffici', value: 'uffici', filterName: 'Uffici' },
    ];

    public static TYPE_KITCHEN_FILTER: any = [
        { text: 'Abitabile', value: 'abitabile', filterName: 'Abitabile' },
        { text: 'Angolo Cottura', value: 'angolo-cottura', filterName: 'Angolo Cottura' },
        { text: 'A Vista', value: 'a-vista', filterName: 'A Vista' },
        { text: 'Cucinotto', value: 'cucinotto', filterName: 'Cucinotto' },
        { text: 'Semi abitabile', value: 'semi-abitabile', filterName: 'Semi abitabile' },
    ];
    public static HEATING: any = [
        { text: 'A Pavimento', value: 'a-pavimento', filterName: 'A Pavimento' },
        { text: 'Autonomo', value: 'autonomo', filterName: 'Autonomo' },
        { text: 'Centralizzato', value: 'centralizzato', filterName: 'Centralizzato' },
        { text: 'Inesistente', value: 'inesistente', filterName: 'Inesistente' },
    ];
    public static CONTEXT: any = [
        { text: 'Ultra popolare', value: 'ultra-popolare', filterName: 'Ultra popolare' },
        { text: 'Popolare', value: 'popolare', filterName: 'Popolare' },
        { text: 'Economico', value: 'economico', filterName: 'Economico' },
        { text: 'Civile', value: 'civile', filterName: 'Civile' },
        { text: 'Medio Signorile', value: 'medio-signorile', filterName: 'Medio Signorile' },
        { text: 'Signorile', value: 'signorile', filterName: 'Signorile' },
    ];
    public static FILTER_FLOORS: any = [
        { text: 'Piano Interrato', filterName: 'Piano Interrato', valueQuery: -0.5, value: 'interrato' },
        { text: 'Piano Terra', filterName: 'Piano Terra', valueQuery: 0, value: 'terra' },
        { text: 'Piano Rialzato', filterName: 'Piano Rialzato', valueQuery: 0.5, value: 'rialzato' }
    ];
    public static FILTER_GARDEN: any = [
        { text: 'Giardino Privato', filterName: 'Giardino Privato', valueQuery: 'PR', value: 'privato' },
        { text: 'Giardino Comune', filterName: 'Giardino Comune', valueQuery: 'CM', value: 'comune' },
    ];
    public static FILTER_GARAGE: any = [
        { text: 'Garage Singolo', filterName: 'Garage singolo', valueQuery: 'singolo', value: 'singolo' },
        { text: 'Garage Privato', filterName: 'Garage doppio', valueQuery: 'doppio', value: 'doppio' },
    ];
    public static FILTER_EXTRA: any = [
        { filterName: 'Balcone', text: 'Balcone', valueQuery: 'balcony', value: 'balcone' },
        { filterName: 'Terrazzo', text: 'Terrazzo', valueQuery: 'terrace', value: 'terrazzo' },
        { filterName: 'Ascensore', text: 'Ascensore', valueQuery: 'elevator', value: 'ascensore' },
        { filterName: 'Arredamento', text: 'Arredamento', valueQuery: 'furnished', value: 'arredamento' },
        { filterName: 'Cantina', text: 'Cantina', valueQuery: 'basement', value: 'cantina' },
        { filterName: 'Taverna', text: 'Taverna', valueQuery: 'tavern', value: 'taverna' },
        { filterName: 'Garage', text: 'Garage', valueQuery: 'garage', value: 'garage' },
        { filterName: 'Giardino', text: 'Giardino', valueQuery: 'garden', value: 'garden' },
    ];

    public static FILTER_IS_AUCTION: any = [
        { filterName: 'Immobile all\'asta', text: 'Immobile all\'asta', valueQuery: 'asta', value: 'asta' },
    ];

    public static TYPE_ASSICURAZIONE: any = {
        assicurazione_mutuo: 'Assicurazione Mutuo',
        assicurazione_auto: 'Assicurazione Auto',
        assicurazione_moto_ciclomotore: 'Assicurazione Motociclo',
        assicurazione_autocarro: 'Assicurazione Autocarro',
        assicurazione_bicicletta: 'Assicurazione Bicicletta',
        assicurazione_viaggi: 'Assicurazione Viaggi',
        assicurazione_vita: 'Assicurazione Vita',
        assicurazione_infortuni: 'Assicurazione Infortuni',
        assicurazione_casa: 'Assicurazione Casa',
        assicurazione_smartphone: 'Assicurazione Smartphone',
        assicurazione_coronavirus: 'Assicurazione Coronavirus',
        assicurazione_cane_gatto: 'Assicurazione Cane e Gatto',
        assicurazione_professionisti: 'Assicurazione professionisti',
        assicurazione_piccole_imprese: 'Assicurazione Piccole Imprese',
        altre_rc_professionali: 'Altre RC professionali',
    };
    public static TYPE_FINANZIARIO: any = {
        mutuo_prima_casa: 'Mutuo Prima Casa',
        mutuo_ristrutturazione: 'Mutuo Ristrutturazione',
        mutuo_costruzione: 'Mutuo Costruzione',
        mutuo_liquidita: 'Mutuo Liquidità',
        mutuo_asta_giudiziaria: 'Mutuo Asta Giudiziaria',
        mutuo_biodelizia: 'Mutuo Bioedilizia',
        surroga_mutuo: 'Surroga Mutuo',
        prestito_personale: 'Prestito Personale',
        prestito_auto: 'Prestito Auto',
        prestito_arredamento: 'Prestito arredamento',
        consolidamento_debiti: 'Consolidamendo Debiti',
        prestiti_casa: 'Prestiti Casa',
        prestiti_imprese: 'Prestiti Imprese',
        microcredito: 'Microcredito',
        cessione_del_quinto: 'Cessione del Quinto',
        delega_di_pagamento: 'Delega di Pagamento',
        trattamento_di_fine_servizio: 'Trattamento di Fine Servizio',
    };
    // DURATA MASSIMA DEL FOOTER
    public static HOUR_OF_EXPIRATION_FOOTER = 167;
}

import { Component, OnDestroy } from '@angular/core';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';



@Component({
    selector: 'app-common',
    templateUrl: './common.component.html',
    styleUrls: ['./common.component.scss'],
    standalone: true
})
export class CommonComponent implements OnDestroy{

  destroy$: Subject<boolean> = new Subject<boolean>();
  currentUser: User;

  constructor(public authService: AuthenticationService) {
    this.authService.currentUser.pipe(takeUntil(this.destroy$)).subscribe(u => this.currentUser = u);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get isLogged(): boolean {
    return this.currentUser && Object.keys(this.currentUser).length !== 0;
  }

  get showProfessionist(): boolean {
    return environment.showProfessionist;
  }

  get showNews(): boolean {
    return environment.showNews;
  }
}

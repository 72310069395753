/**
 * cercacasa_api
 * cercacasa_api
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Agency } from '../model/agency';
import { AgencyZoneGenericRequest } from '../model/agencyZoneGenericRequest';
import { Agent } from '../model/agent';
import { AgentZone } from '../model/agentZone';
import { Article } from '../model/article';
import { ArticleZone } from '../model/articleZone';
import { GeocodeResult } from '../model/geocodeResult';
import { InlineResponse200 } from '../model/inlineResponse200';
import { InlineResponse2001 } from '../model/inlineResponse2001';
import { InlineResponse2002 } from '../model/inlineResponse2002';
import { Professionist } from '../model/professionist';
import { ProfessionistZone } from '../model/professionistZone';
import { Zone } from '../model/zone';
import { ZonePivot } from '../model/zonePivot';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ZoneService {

    protected basePath = 'https://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Count instances of the model matched by where from the data source.
     * 
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zoneCount(where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public zoneCount(where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public zoneCount(where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public zoneCount(where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/zones/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new instance of the model and persist it into the data source.
     * 
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zoneCreate(data?: Zone, observe?: 'body', reportProgress?: boolean): Observable<Zone>;
    public zoneCreate(data?: Zone, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Zone>>;
    public zoneCreate(data?: Zone, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Zone>>;
    public zoneCreate(data?: Zone, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Zone>(`${this.basePath}/zones`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a change stream.
     * 
     * @param options 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zoneCreateChangeStreamGetZonesChangeStream(options?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public zoneCreateChangeStreamGetZonesChangeStream(options?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public zoneCreateChangeStreamGetZonesChangeStream(options?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public zoneCreateChangeStreamGetZonesChangeStream(options?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (options !== undefined && options !== null) {
            queryParameters = queryParameters.set('options', <any>options);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get(`${this.basePath}/zones/change-stream`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a change stream.
     * 
     * @param options 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zoneCreateChangeStreamPostZonesChangeStream(options?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public zoneCreateChangeStreamPostZonesChangeStream(options?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public zoneCreateChangeStreamPostZonesChangeStream(options?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public zoneCreateChangeStreamPostZonesChangeStream(options?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (options !== undefined) {
            formParams.append('options', <any>options);
        }

        return this.httpClient.post(`${this.basePath}/zones/change-stream`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a model instance by {{id}} from the data source.
     * 
     * @param id Model id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zoneDeleteById(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public zoneDeleteById(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public zoneDeleteById(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public zoneDeleteById(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zoneDeleteById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/zones/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check whether a model instance exists in the data source.
     * 
     * @param id Model id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zoneExistsGetZonesidExists(id: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2001>;
    public zoneExistsGetZonesidExists(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2001>>;
    public zoneExistsGetZonesidExists(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2001>>;
    public zoneExistsGetZonesidExists(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zoneExistsGetZonesidExists.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse2001>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/exists`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check whether a model instance exists in the data source.
     * 
     * @param id Model id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zoneExistsHeadZonesid(id: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2001>;
    public zoneExistsHeadZonesid(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2001>>;
    public zoneExistsHeadZonesid(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2001>>;
    public zoneExistsHeadZonesid(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zoneExistsHeadZonesid.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<InlineResponse2001>(`${this.basePath}/zones/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find all instances of the model matched by filter from the data source.
     * 
     * @param filter Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (&#x60;{\&quot;where\&quot;:{\&quot;something\&quot;:\&quot;value\&quot;}}&#x60;).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zoneFind(filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Zone>>;
    public zoneFind(filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Zone>>>;
    public zoneFind(filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Zone>>>;
    public zoneFind(filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Zone>>(`${this.basePath}/zones`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a model instance by {{id}} from the data source.
     * 
     * @param id Model id
     * @param filter Filter defining fields and include - must be a JSON-encoded string ({\&quot;something\&quot;:\&quot;value\&quot;})
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zoneFindById(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Zone>;
    public zoneFindById(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Zone>>;
    public zoneFindById(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Zone>>;
    public zoneFindById(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zoneFindById.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Zone>(`${this.basePath}/zones/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find first instance of the model matched by filter from the data source.
     * 
     * @param filter Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (&#x60;{\&quot;where\&quot;:{\&quot;something\&quot;:\&quot;value\&quot;}}&#x60;).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zoneFindOne(filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Zone>;
    public zoneFindOne(filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Zone>>;
    public zoneFindOne(filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Zone>>;
    public zoneFindOne(filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Zone>(`${this.basePath}/zones/findOne`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param lat 
     * @param lng 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zoneGeocode(lat?: number, lng?: number, observe?: 'body', reportProgress?: boolean): Observable<GeocodeResult>;
    public zoneGeocode(lat?: number, lng?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeocodeResult>>;
    public zoneGeocode(lat?: number, lng?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeocodeResult>>;
    public zoneGeocode(lat?: number, lng?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (lat !== undefined && lat !== null) {
            queryParameters = queryParameters.set('lat', <any>lat);
        }
        if (lng !== undefined && lng !== null) {
            queryParameters = queryParameters.set('lng', <any>lng);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<GeocodeResult>(`${this.basePath}/zones/geocode`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param regione 
     * @param provincia 
     * @param comune 
     * @param zona 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zoneGetPathData(regione?: string, provincia?: string, comune?: string, zona?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public zoneGetPathData(regione?: string, provincia?: string, comune?: string, zona?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public zoneGetPathData(regione?: string, provincia?: string, comune?: string, zona?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public zoneGetPathData(regione?: string, provincia?: string, comune?: string, zona?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (regione !== undefined && regione !== null) {
            queryParameters = queryParameters.set('regione', <any>regione);
        }
        if (provincia !== undefined && provincia !== null) {
            queryParameters = queryParameters.set('provincia', <any>provincia);
        }
        if (comune !== undefined && comune !== null) {
            queryParameters = queryParameters.set('comune', <any>comune);
        }
        if (zona !== undefined && zona !== null) {
            queryParameters = queryParameters.set('zona', <any>zona);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<any>(`${this.basePath}/zones/getPathData`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patch an existing model instance or insert a new one into the data source.
     * 
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePatchOrCreate(data?: Zone, observe?: 'body', reportProgress?: boolean): Observable<Zone>;
    public zonePatchOrCreate(data?: Zone, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Zone>>;
    public zonePatchOrCreate(data?: Zone, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Zone>>;
    public zonePatchOrCreate(data?: Zone, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<Zone>(`${this.basePath}/zones`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts agencies_generic_requests of Zone.
     * 
     * @param id Zone id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeCountAgenciesGenericRequests(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public zonePrototypeCountAgenciesGenericRequests(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public zonePrototypeCountAgenciesGenericRequests(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public zonePrototypeCountAgenciesGenericRequests(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeCountAgenciesGenericRequests.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/agencies_generic_requests/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts agents_serving of Zone.
     * 
     * @param id Zone id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeCountAgentsServing(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public zonePrototypeCountAgentsServing(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public zonePrototypeCountAgentsServing(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public zonePrototypeCountAgentsServing(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeCountAgentsServing.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/agents_serving/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts articleInZones of Zone.
     * 
     * @param id Zone id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeCountArticleInZones(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public zonePrototypeCountArticleInZones(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public zonePrototypeCountArticleInZones(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public zonePrototypeCountArticleInZones(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeCountArticleInZones.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/articleInZones/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts articles of Zone.
     * 
     * @param id Zone id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeCountArticles(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public zonePrototypeCountArticles(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public zonePrototypeCountArticles(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public zonePrototypeCountArticles(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeCountArticles.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/articles/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts children of Zone.
     * 
     * @param id Zone id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeCountChildren(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public zonePrototypeCountChildren(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public zonePrototypeCountChildren(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public zonePrototypeCountChildren(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeCountChildren.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/children/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts parents of Zone.
     * 
     * @param id Zone id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeCountParents(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public zonePrototypeCountParents(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public zonePrototypeCountParents(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public zonePrototypeCountParents(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeCountParents.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/parents/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts professionsts_serving of Zone.
     * 
     * @param id Zone id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeCountProfessionstsServing(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public zonePrototypeCountProfessionstsServing(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public zonePrototypeCountProfessionstsServing(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public zonePrototypeCountProfessionstsServing(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeCountProfessionstsServing.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/professionsts_serving/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in agencies_generic_requests of this model.
     * 
     * @param id Zone id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeCreateAgenciesGenericRequests(id: string, data?: Agency, observe?: 'body', reportProgress?: boolean): Observable<Agency>;
    public zonePrototypeCreateAgenciesGenericRequests(id: string, data?: Agency, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agency>>;
    public zonePrototypeCreateAgenciesGenericRequests(id: string, data?: Agency, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agency>>;
    public zonePrototypeCreateAgenciesGenericRequests(id: string, data?: Agency, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeCreateAgenciesGenericRequests.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Agency>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/agencies_generic_requests`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in agents_serving of this model.
     * 
     * @param id Zone id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeCreateAgentsServing(id: string, data?: Agent, observe?: 'body', reportProgress?: boolean): Observable<Agent>;
    public zonePrototypeCreateAgentsServing(id: string, data?: Agent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agent>>;
    public zonePrototypeCreateAgentsServing(id: string, data?: Agent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agent>>;
    public zonePrototypeCreateAgentsServing(id: string, data?: Agent, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeCreateAgentsServing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Agent>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/agents_serving`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in articleInZones of this model.
     * 
     * @param id Zone id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeCreateArticleInZones(id: string, data?: Zone, observe?: 'body', reportProgress?: boolean): Observable<Zone>;
    public zonePrototypeCreateArticleInZones(id: string, data?: Zone, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Zone>>;
    public zonePrototypeCreateArticleInZones(id: string, data?: Zone, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Zone>>;
    public zonePrototypeCreateArticleInZones(id: string, data?: Zone, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeCreateArticleInZones.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Zone>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/articleInZones`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in articles of this model.
     * 
     * @param id Zone id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeCreateArticles(id: string, data?: Article, observe?: 'body', reportProgress?: boolean): Observable<Article>;
    public zonePrototypeCreateArticles(id: string, data?: Article, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Article>>;
    public zonePrototypeCreateArticles(id: string, data?: Article, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Article>>;
    public zonePrototypeCreateArticles(id: string, data?: Article, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeCreateArticles.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Article>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/articles`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in children of this model.
     * 
     * @param id Zone id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeCreateChildren(id: string, data?: Zone, observe?: 'body', reportProgress?: boolean): Observable<Zone>;
    public zonePrototypeCreateChildren(id: string, data?: Zone, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Zone>>;
    public zonePrototypeCreateChildren(id: string, data?: Zone, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Zone>>;
    public zonePrototypeCreateChildren(id: string, data?: Zone, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeCreateChildren.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Zone>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/children`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in parents of this model.
     * 
     * @param id Zone id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeCreateParents(id: string, data?: Zone, observe?: 'body', reportProgress?: boolean): Observable<Zone>;
    public zonePrototypeCreateParents(id: string, data?: Zone, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Zone>>;
    public zonePrototypeCreateParents(id: string, data?: Zone, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Zone>>;
    public zonePrototypeCreateParents(id: string, data?: Zone, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeCreateParents.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Zone>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/parents`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in professionsts_serving of this model.
     * 
     * @param id Zone id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeCreateProfessionstsServing(id: string, data?: Professionist, observe?: 'body', reportProgress?: boolean): Observable<Professionist>;
    public zonePrototypeCreateProfessionstsServing(id: string, data?: Professionist, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Professionist>>;
    public zonePrototypeCreateProfessionstsServing(id: string, data?: Professionist, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Professionist>>;
    public zonePrototypeCreateProfessionstsServing(id: string, data?: Professionist, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeCreateProfessionstsServing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Professionist>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/professionsts_serving`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all agencies_generic_requests of this model.
     * 
     * @param id Zone id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeDeleteAgenciesGenericRequests(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public zonePrototypeDeleteAgenciesGenericRequests(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public zonePrototypeDeleteAgenciesGenericRequests(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public zonePrototypeDeleteAgenciesGenericRequests(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeDeleteAgenciesGenericRequests.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/agencies_generic_requests`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all agents_serving of this model.
     * 
     * @param id Zone id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeDeleteAgentsServing(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public zonePrototypeDeleteAgentsServing(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public zonePrototypeDeleteAgentsServing(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public zonePrototypeDeleteAgentsServing(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeDeleteAgentsServing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/agents_serving`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all articleInZones of this model.
     * 
     * @param id Zone id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeDeleteArticleInZones(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public zonePrototypeDeleteArticleInZones(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public zonePrototypeDeleteArticleInZones(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public zonePrototypeDeleteArticleInZones(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeDeleteArticleInZones.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/articleInZones`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all articles of this model.
     * 
     * @param id Zone id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeDeleteArticles(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public zonePrototypeDeleteArticles(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public zonePrototypeDeleteArticles(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public zonePrototypeDeleteArticles(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeDeleteArticles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/articles`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all children of this model.
     * 
     * @param id Zone id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeDeleteChildren(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public zonePrototypeDeleteChildren(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public zonePrototypeDeleteChildren(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public zonePrototypeDeleteChildren(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeDeleteChildren.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/children`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all parents of this model.
     * 
     * @param id Zone id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeDeleteParents(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public zonePrototypeDeleteParents(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public zonePrototypeDeleteParents(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public zonePrototypeDeleteParents(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeDeleteParents.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/parents`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all professionsts_serving of this model.
     * 
     * @param id Zone id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeDeleteProfessionstsServing(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public zonePrototypeDeleteProfessionstsServing(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public zonePrototypeDeleteProfessionstsServing(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public zonePrototypeDeleteProfessionstsServing(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeDeleteProfessionstsServing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/professionsts_serving`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for agencies_generic_requests.
     * 
     * @param id Zone id
     * @param fk Foreign key for agencies_generic_requests
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeDestroyByIdAgenciesGenericRequests(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public zonePrototypeDestroyByIdAgenciesGenericRequests(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public zonePrototypeDestroyByIdAgenciesGenericRequests(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public zonePrototypeDestroyByIdAgenciesGenericRequests(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeDestroyByIdAgenciesGenericRequests.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeDestroyByIdAgenciesGenericRequests.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/agencies_generic_requests/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for agents_serving.
     * 
     * @param id Zone id
     * @param fk Foreign key for agents_serving
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeDestroyByIdAgentsServing(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public zonePrototypeDestroyByIdAgentsServing(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public zonePrototypeDestroyByIdAgentsServing(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public zonePrototypeDestroyByIdAgentsServing(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeDestroyByIdAgentsServing.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeDestroyByIdAgentsServing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/agents_serving/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for articleInZones.
     * 
     * @param id Zone id
     * @param fk Foreign key for articleInZones
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeDestroyByIdArticleInZones(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public zonePrototypeDestroyByIdArticleInZones(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public zonePrototypeDestroyByIdArticleInZones(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public zonePrototypeDestroyByIdArticleInZones(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeDestroyByIdArticleInZones.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeDestroyByIdArticleInZones.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/articleInZones/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for articles.
     * 
     * @param id Zone id
     * @param fk Foreign key for articles
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeDestroyByIdArticles(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public zonePrototypeDestroyByIdArticles(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public zonePrototypeDestroyByIdArticles(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public zonePrototypeDestroyByIdArticles(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeDestroyByIdArticles.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeDestroyByIdArticles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/articles/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for children.
     * 
     * @param id Zone id
     * @param fk Foreign key for children
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeDestroyByIdChildren(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public zonePrototypeDestroyByIdChildren(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public zonePrototypeDestroyByIdChildren(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public zonePrototypeDestroyByIdChildren(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeDestroyByIdChildren.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeDestroyByIdChildren.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/children/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for parents.
     * 
     * @param id Zone id
     * @param fk Foreign key for parents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeDestroyByIdParents(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public zonePrototypeDestroyByIdParents(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public zonePrototypeDestroyByIdParents(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public zonePrototypeDestroyByIdParents(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeDestroyByIdParents.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeDestroyByIdParents.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/parents/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for professionsts_serving.
     * 
     * @param id Zone id
     * @param fk Foreign key for professionsts_serving
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeDestroyByIdProfessionstsServing(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public zonePrototypeDestroyByIdProfessionstsServing(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public zonePrototypeDestroyByIdProfessionstsServing(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public zonePrototypeDestroyByIdProfessionstsServing(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeDestroyByIdProfessionstsServing.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeDestroyByIdProfessionstsServing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/professionsts_serving/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of agencies_generic_requests relation to an item by id.
     * 
     * @param id Zone id
     * @param fk Foreign key for agencies_generic_requests
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeExistsAgenciesGenericRequests(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public zonePrototypeExistsAgenciesGenericRequests(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public zonePrototypeExistsAgenciesGenericRequests(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public zonePrototypeExistsAgenciesGenericRequests(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeExistsAgenciesGenericRequests.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeExistsAgenciesGenericRequests.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/agencies_generic_requests/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of agents_serving relation to an item by id.
     * 
     * @param id Zone id
     * @param fk Foreign key for agents_serving
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeExistsAgentsServing(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public zonePrototypeExistsAgentsServing(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public zonePrototypeExistsAgentsServing(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public zonePrototypeExistsAgentsServing(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeExistsAgentsServing.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeExistsAgentsServing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/agents_serving/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of articleInZones relation to an item by id.
     * 
     * @param id Zone id
     * @param fk Foreign key for articleInZones
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeExistsArticleInZones(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public zonePrototypeExistsArticleInZones(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public zonePrototypeExistsArticleInZones(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public zonePrototypeExistsArticleInZones(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeExistsArticleInZones.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeExistsArticleInZones.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/articleInZones/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of children relation to an item by id.
     * 
     * @param id Zone id
     * @param fk Foreign key for children
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeExistsChildren(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public zonePrototypeExistsChildren(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public zonePrototypeExistsChildren(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public zonePrototypeExistsChildren(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeExistsChildren.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeExistsChildren.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/children/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of parents relation to an item by id.
     * 
     * @param id Zone id
     * @param fk Foreign key for parents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeExistsParents(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public zonePrototypeExistsParents(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public zonePrototypeExistsParents(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public zonePrototypeExistsParents(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeExistsParents.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeExistsParents.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/parents/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of professionsts_serving relation to an item by id.
     * 
     * @param id Zone id
     * @param fk Foreign key for professionsts_serving
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeExistsProfessionstsServing(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public zonePrototypeExistsProfessionstsServing(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public zonePrototypeExistsProfessionstsServing(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public zonePrototypeExistsProfessionstsServing(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeExistsProfessionstsServing.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeExistsProfessionstsServing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/professionsts_serving/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for agencies_generic_requests.
     * 
     * @param id Zone id
     * @param fk Foreign key for agencies_generic_requests
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeFindByIdAgenciesGenericRequests(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Agency>;
    public zonePrototypeFindByIdAgenciesGenericRequests(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agency>>;
    public zonePrototypeFindByIdAgenciesGenericRequests(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agency>>;
    public zonePrototypeFindByIdAgenciesGenericRequests(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeFindByIdAgenciesGenericRequests.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeFindByIdAgenciesGenericRequests.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Agency>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/agencies_generic_requests/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for agents_serving.
     * 
     * @param id Zone id
     * @param fk Foreign key for agents_serving
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeFindByIdAgentsServing(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Agent>;
    public zonePrototypeFindByIdAgentsServing(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agent>>;
    public zonePrototypeFindByIdAgentsServing(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agent>>;
    public zonePrototypeFindByIdAgentsServing(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeFindByIdAgentsServing.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeFindByIdAgentsServing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Agent>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/agents_serving/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for articleInZones.
     * 
     * @param id Zone id
     * @param fk Foreign key for articleInZones
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeFindByIdArticleInZones(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Zone>;
    public zonePrototypeFindByIdArticleInZones(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Zone>>;
    public zonePrototypeFindByIdArticleInZones(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Zone>>;
    public zonePrototypeFindByIdArticleInZones(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeFindByIdArticleInZones.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeFindByIdArticleInZones.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Zone>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/articleInZones/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for articles.
     * 
     * @param id Zone id
     * @param fk Foreign key for articles
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeFindByIdArticles(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Article>;
    public zonePrototypeFindByIdArticles(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Article>>;
    public zonePrototypeFindByIdArticles(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Article>>;
    public zonePrototypeFindByIdArticles(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeFindByIdArticles.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeFindByIdArticles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Article>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/articles/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for children.
     * 
     * @param id Zone id
     * @param fk Foreign key for children
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeFindByIdChildren(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Zone>;
    public zonePrototypeFindByIdChildren(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Zone>>;
    public zonePrototypeFindByIdChildren(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Zone>>;
    public zonePrototypeFindByIdChildren(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeFindByIdChildren.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeFindByIdChildren.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Zone>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/children/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for parents.
     * 
     * @param id Zone id
     * @param fk Foreign key for parents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeFindByIdParents(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Zone>;
    public zonePrototypeFindByIdParents(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Zone>>;
    public zonePrototypeFindByIdParents(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Zone>>;
    public zonePrototypeFindByIdParents(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeFindByIdParents.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeFindByIdParents.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Zone>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/parents/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for professionsts_serving.
     * 
     * @param id Zone id
     * @param fk Foreign key for professionsts_serving
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeFindByIdProfessionstsServing(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Professionist>;
    public zonePrototypeFindByIdProfessionstsServing(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Professionist>>;
    public zonePrototypeFindByIdProfessionstsServing(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Professionist>>;
    public zonePrototypeFindByIdProfessionstsServing(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeFindByIdProfessionstsServing.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeFindByIdProfessionstsServing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Professionist>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/professionsts_serving/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries agencies_generic_requests of Zone.
     * 
     * @param id Zone id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeGetAgenciesGenericRequests(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Agency>>;
    public zonePrototypeGetAgenciesGenericRequests(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Agency>>>;
    public zonePrototypeGetAgenciesGenericRequests(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Agency>>>;
    public zonePrototypeGetAgenciesGenericRequests(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeGetAgenciesGenericRequests.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Agency>>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/agencies_generic_requests`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries agents_serving of Zone.
     * 
     * @param id Zone id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeGetAgentsServing(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Agent>>;
    public zonePrototypeGetAgentsServing(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Agent>>>;
    public zonePrototypeGetAgentsServing(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Agent>>>;
    public zonePrototypeGetAgentsServing(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeGetAgentsServing.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Agent>>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/agents_serving`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries articleInZones of Zone.
     * 
     * @param id Zone id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeGetArticleInZones(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Zone>>;
    public zonePrototypeGetArticleInZones(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Zone>>>;
    public zonePrototypeGetArticleInZones(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Zone>>>;
    public zonePrototypeGetArticleInZones(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeGetArticleInZones.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Zone>>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/articleInZones`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries articles of Zone.
     * 
     * @param id Zone id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeGetArticles(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Article>>;
    public zonePrototypeGetArticles(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Article>>>;
    public zonePrototypeGetArticles(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Article>>>;
    public zonePrototypeGetArticles(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeGetArticles.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Article>>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/articles`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries children of Zone.
     * 
     * @param id Zone id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeGetChildren(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Zone>>;
    public zonePrototypeGetChildren(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Zone>>>;
    public zonePrototypeGetChildren(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Zone>>>;
    public zonePrototypeGetChildren(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeGetChildren.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Zone>>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/children`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries parents of Zone.
     * 
     * @param id Zone id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeGetParents(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Zone>>;
    public zonePrototypeGetParents(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Zone>>>;
    public zonePrototypeGetParents(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Zone>>>;
    public zonePrototypeGetParents(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeGetParents.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Zone>>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/parents`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries professionsts_serving of Zone.
     * 
     * @param id Zone id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeGetProfessionstsServing(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Professionist>>;
    public zonePrototypeGetProfessionstsServing(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Professionist>>>;
    public zonePrototypeGetProfessionstsServing(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Professionist>>>;
    public zonePrototypeGetProfessionstsServing(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeGetProfessionstsServing.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Professionist>>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/professionsts_serving`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for agencies_generic_requests.
     * 
     * @param id Zone id
     * @param fk Foreign key for agencies_generic_requests
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeLinkAgenciesGenericRequests(id: string, fk: string, data?: AgencyZoneGenericRequest, observe?: 'body', reportProgress?: boolean): Observable<AgencyZoneGenericRequest>;
    public zonePrototypeLinkAgenciesGenericRequests(id: string, fk: string, data?: AgencyZoneGenericRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgencyZoneGenericRequest>>;
    public zonePrototypeLinkAgenciesGenericRequests(id: string, fk: string, data?: AgencyZoneGenericRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgencyZoneGenericRequest>>;
    public zonePrototypeLinkAgenciesGenericRequests(id: string, fk: string, data?: AgencyZoneGenericRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeLinkAgenciesGenericRequests.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeLinkAgenciesGenericRequests.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<AgencyZoneGenericRequest>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/agencies_generic_requests/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for agents_serving.
     * 
     * @param id Zone id
     * @param fk Foreign key for agents_serving
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeLinkAgentsServing(id: string, fk: string, data?: AgentZone, observe?: 'body', reportProgress?: boolean): Observable<AgentZone>;
    public zonePrototypeLinkAgentsServing(id: string, fk: string, data?: AgentZone, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgentZone>>;
    public zonePrototypeLinkAgentsServing(id: string, fk: string, data?: AgentZone, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgentZone>>;
    public zonePrototypeLinkAgentsServing(id: string, fk: string, data?: AgentZone, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeLinkAgentsServing.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeLinkAgentsServing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<AgentZone>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/agents_serving/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for articleInZones.
     * 
     * @param id Zone id
     * @param fk Foreign key for articleInZones
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeLinkArticleInZones(id: string, fk: string, data?: ArticleZone, observe?: 'body', reportProgress?: boolean): Observable<ArticleZone>;
    public zonePrototypeLinkArticleInZones(id: string, fk: string, data?: ArticleZone, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ArticleZone>>;
    public zonePrototypeLinkArticleInZones(id: string, fk: string, data?: ArticleZone, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ArticleZone>>;
    public zonePrototypeLinkArticleInZones(id: string, fk: string, data?: ArticleZone, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeLinkArticleInZones.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeLinkArticleInZones.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ArticleZone>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/articleInZones/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for children.
     * 
     * @param id Zone id
     * @param fk Foreign key for children
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeLinkChildren(id: string, fk: string, data?: ZonePivot, observe?: 'body', reportProgress?: boolean): Observable<ZonePivot>;
    public zonePrototypeLinkChildren(id: string, fk: string, data?: ZonePivot, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ZonePivot>>;
    public zonePrototypeLinkChildren(id: string, fk: string, data?: ZonePivot, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ZonePivot>>;
    public zonePrototypeLinkChildren(id: string, fk: string, data?: ZonePivot, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeLinkChildren.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeLinkChildren.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ZonePivot>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/children/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for parents.
     * 
     * @param id Zone id
     * @param fk Foreign key for parents
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeLinkParents(id: string, fk: string, data?: ZonePivot, observe?: 'body', reportProgress?: boolean): Observable<ZonePivot>;
    public zonePrototypeLinkParents(id: string, fk: string, data?: ZonePivot, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ZonePivot>>;
    public zonePrototypeLinkParents(id: string, fk: string, data?: ZonePivot, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ZonePivot>>;
    public zonePrototypeLinkParents(id: string, fk: string, data?: ZonePivot, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeLinkParents.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeLinkParents.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ZonePivot>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/parents/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for professionsts_serving.
     * 
     * @param id Zone id
     * @param fk Foreign key for professionsts_serving
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeLinkProfessionstsServing(id: string, fk: string, data?: ProfessionistZone, observe?: 'body', reportProgress?: boolean): Observable<ProfessionistZone>;
    public zonePrototypeLinkProfessionstsServing(id: string, fk: string, data?: ProfessionistZone, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProfessionistZone>>;
    public zonePrototypeLinkProfessionstsServing(id: string, fk: string, data?: ProfessionistZone, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProfessionistZone>>;
    public zonePrototypeLinkProfessionstsServing(id: string, fk: string, data?: ProfessionistZone, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeLinkProfessionstsServing.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeLinkProfessionstsServing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ProfessionistZone>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/professionsts_serving/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patch attributes for a model instance and persist it into the data source.
     * 
     * @param id Zone id
     * @param data An object of model property name/value pairs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypePatchAttributes(id: string, data?: Zone, observe?: 'body', reportProgress?: boolean): Observable<Zone>;
    public zonePrototypePatchAttributes(id: string, data?: Zone, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Zone>>;
    public zonePrototypePatchAttributes(id: string, data?: Zone, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Zone>>;
    public zonePrototypePatchAttributes(id: string, data?: Zone, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypePatchAttributes.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<Zone>(`${this.basePath}/zones/${encodeURIComponent(String(id))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the agencies_generic_requests relation to an item by id.
     * 
     * @param id Zone id
     * @param fk Foreign key for agencies_generic_requests
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeUnlinkAgenciesGenericRequests(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public zonePrototypeUnlinkAgenciesGenericRequests(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public zonePrototypeUnlinkAgenciesGenericRequests(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public zonePrototypeUnlinkAgenciesGenericRequests(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeUnlinkAgenciesGenericRequests.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeUnlinkAgenciesGenericRequests.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/agencies_generic_requests/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the agents_serving relation to an item by id.
     * 
     * @param id Zone id
     * @param fk Foreign key for agents_serving
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeUnlinkAgentsServing(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public zonePrototypeUnlinkAgentsServing(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public zonePrototypeUnlinkAgentsServing(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public zonePrototypeUnlinkAgentsServing(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeUnlinkAgentsServing.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeUnlinkAgentsServing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/agents_serving/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the articleInZones relation to an item by id.
     * 
     * @param id Zone id
     * @param fk Foreign key for articleInZones
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeUnlinkArticleInZones(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public zonePrototypeUnlinkArticleInZones(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public zonePrototypeUnlinkArticleInZones(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public zonePrototypeUnlinkArticleInZones(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeUnlinkArticleInZones.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeUnlinkArticleInZones.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/articleInZones/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the children relation to an item by id.
     * 
     * @param id Zone id
     * @param fk Foreign key for children
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeUnlinkChildren(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public zonePrototypeUnlinkChildren(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public zonePrototypeUnlinkChildren(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public zonePrototypeUnlinkChildren(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeUnlinkChildren.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeUnlinkChildren.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/children/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the parents relation to an item by id.
     * 
     * @param id Zone id
     * @param fk Foreign key for parents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeUnlinkParents(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public zonePrototypeUnlinkParents(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public zonePrototypeUnlinkParents(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public zonePrototypeUnlinkParents(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeUnlinkParents.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeUnlinkParents.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/parents/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the professionsts_serving relation to an item by id.
     * 
     * @param id Zone id
     * @param fk Foreign key for professionsts_serving
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeUnlinkProfessionstsServing(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public zonePrototypeUnlinkProfessionstsServing(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public zonePrototypeUnlinkProfessionstsServing(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public zonePrototypeUnlinkProfessionstsServing(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeUnlinkProfessionstsServing.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeUnlinkProfessionstsServing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/professionsts_serving/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for agencies_generic_requests.
     * 
     * @param id Zone id
     * @param fk Foreign key for agencies_generic_requests
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeUpdateByIdAgenciesGenericRequests(id: string, fk: string, data?: Agency, observe?: 'body', reportProgress?: boolean): Observable<Agency>;
    public zonePrototypeUpdateByIdAgenciesGenericRequests(id: string, fk: string, data?: Agency, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agency>>;
    public zonePrototypeUpdateByIdAgenciesGenericRequests(id: string, fk: string, data?: Agency, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agency>>;
    public zonePrototypeUpdateByIdAgenciesGenericRequests(id: string, fk: string, data?: Agency, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeUpdateByIdAgenciesGenericRequests.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeUpdateByIdAgenciesGenericRequests.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Agency>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/agencies_generic_requests/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for agents_serving.
     * 
     * @param id Zone id
     * @param fk Foreign key for agents_serving
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeUpdateByIdAgentsServing(id: string, fk: string, data?: Agent, observe?: 'body', reportProgress?: boolean): Observable<Agent>;
    public zonePrototypeUpdateByIdAgentsServing(id: string, fk: string, data?: Agent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agent>>;
    public zonePrototypeUpdateByIdAgentsServing(id: string, fk: string, data?: Agent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agent>>;
    public zonePrototypeUpdateByIdAgentsServing(id: string, fk: string, data?: Agent, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeUpdateByIdAgentsServing.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeUpdateByIdAgentsServing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Agent>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/agents_serving/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for articleInZones.
     * 
     * @param id Zone id
     * @param fk Foreign key for articleInZones
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeUpdateByIdArticleInZones(id: string, fk: string, data?: Zone, observe?: 'body', reportProgress?: boolean): Observable<Zone>;
    public zonePrototypeUpdateByIdArticleInZones(id: string, fk: string, data?: Zone, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Zone>>;
    public zonePrototypeUpdateByIdArticleInZones(id: string, fk: string, data?: Zone, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Zone>>;
    public zonePrototypeUpdateByIdArticleInZones(id: string, fk: string, data?: Zone, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeUpdateByIdArticleInZones.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeUpdateByIdArticleInZones.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Zone>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/articleInZones/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for articles.
     * 
     * @param id Zone id
     * @param fk Foreign key for articles
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeUpdateByIdArticles(id: string, fk: string, data?: Article, observe?: 'body', reportProgress?: boolean): Observable<Article>;
    public zonePrototypeUpdateByIdArticles(id: string, fk: string, data?: Article, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Article>>;
    public zonePrototypeUpdateByIdArticles(id: string, fk: string, data?: Article, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Article>>;
    public zonePrototypeUpdateByIdArticles(id: string, fk: string, data?: Article, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeUpdateByIdArticles.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeUpdateByIdArticles.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Article>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/articles/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for children.
     * 
     * @param id Zone id
     * @param fk Foreign key for children
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeUpdateByIdChildren(id: string, fk: string, data?: Zone, observe?: 'body', reportProgress?: boolean): Observable<Zone>;
    public zonePrototypeUpdateByIdChildren(id: string, fk: string, data?: Zone, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Zone>>;
    public zonePrototypeUpdateByIdChildren(id: string, fk: string, data?: Zone, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Zone>>;
    public zonePrototypeUpdateByIdChildren(id: string, fk: string, data?: Zone, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeUpdateByIdChildren.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeUpdateByIdChildren.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Zone>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/children/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for parents.
     * 
     * @param id Zone id
     * @param fk Foreign key for parents
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeUpdateByIdParents(id: string, fk: string, data?: Zone, observe?: 'body', reportProgress?: boolean): Observable<Zone>;
    public zonePrototypeUpdateByIdParents(id: string, fk: string, data?: Zone, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Zone>>;
    public zonePrototypeUpdateByIdParents(id: string, fk: string, data?: Zone, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Zone>>;
    public zonePrototypeUpdateByIdParents(id: string, fk: string, data?: Zone, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeUpdateByIdParents.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeUpdateByIdParents.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Zone>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/parents/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for professionsts_serving.
     * 
     * @param id Zone id
     * @param fk Foreign key for professionsts_serving
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zonePrototypeUpdateByIdProfessionstsServing(id: string, fk: string, data?: Professionist, observe?: 'body', reportProgress?: boolean): Observable<Professionist>;
    public zonePrototypeUpdateByIdProfessionstsServing(id: string, fk: string, data?: Professionist, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Professionist>>;
    public zonePrototypeUpdateByIdProfessionstsServing(id: string, fk: string, data?: Professionist, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Professionist>>;
    public zonePrototypeUpdateByIdProfessionstsServing(id: string, fk: string, data?: Professionist, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zonePrototypeUpdateByIdProfessionstsServing.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling zonePrototypeUpdateByIdProfessionstsServing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Professionist>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/professionsts_serving/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace attributes for a model instance and persist it into the data source.
     * 
     * @param id Model id
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zoneReplaceByIdPostZonesidReplace(id: string, data?: Zone, observe?: 'body', reportProgress?: boolean): Observable<Zone>;
    public zoneReplaceByIdPostZonesidReplace(id: string, data?: Zone, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Zone>>;
    public zoneReplaceByIdPostZonesidReplace(id: string, data?: Zone, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Zone>>;
    public zoneReplaceByIdPostZonesidReplace(id: string, data?: Zone, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zoneReplaceByIdPostZonesidReplace.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Zone>(`${this.basePath}/zones/${encodeURIComponent(String(id))}/replace`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace attributes for a model instance and persist it into the data source.
     * 
     * @param id Model id
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zoneReplaceByIdPutZonesid(id: string, data?: Zone, observe?: 'body', reportProgress?: boolean): Observable<Zone>;
    public zoneReplaceByIdPutZonesid(id: string, data?: Zone, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Zone>>;
    public zoneReplaceByIdPutZonesid(id: string, data?: Zone, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Zone>>;
    public zoneReplaceByIdPutZonesid(id: string, data?: Zone, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling zoneReplaceByIdPutZonesid.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Zone>(`${this.basePath}/zones/${encodeURIComponent(String(id))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace an existing model instance or insert a new one into the data source.
     * 
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zoneReplaceOrCreatePostZonesReplaceOrCreate(data?: Zone, observe?: 'body', reportProgress?: boolean): Observable<Zone>;
    public zoneReplaceOrCreatePostZonesReplaceOrCreate(data?: Zone, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Zone>>;
    public zoneReplaceOrCreatePostZonesReplaceOrCreate(data?: Zone, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Zone>>;
    public zoneReplaceOrCreatePostZonesReplaceOrCreate(data?: Zone, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Zone>(`${this.basePath}/zones/replaceOrCreate`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace an existing model instance or insert a new one into the data source.
     * 
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zoneReplaceOrCreatePutZones(data?: Zone, observe?: 'body', reportProgress?: boolean): Observable<Zone>;
    public zoneReplaceOrCreatePutZones(data?: Zone, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Zone>>;
    public zoneReplaceOrCreatePutZones(data?: Zone, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Zone>>;
    public zoneReplaceOrCreatePutZones(data?: Zone, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Zone>(`${this.basePath}/zones`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param query 
     * @param sessionToken 
     * @param searchMethod 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zoneSearch(query?: string, sessionToken?: string, searchMethod?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Zone>>;
    public zoneSearch(query?: string, sessionToken?: string, searchMethod?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Zone>>>;
    public zoneSearch(query?: string, sessionToken?: string, searchMethod?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Zone>>>;
    public zoneSearch(query?: string, sessionToken?: string, searchMethod?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (sessionToken !== undefined && sessionToken !== null) {
            queryParameters = queryParameters.set('sessionToken', <any>sessionToken);
        }
        if (searchMethod !== undefined && searchMethod !== null) {
            queryParameters = queryParameters.set('searchMethod', <any>searchMethod);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Zone>>(`${this.basePath}/zones/search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param query 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zoneSearchForNews(query?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Zone>>;
    public zoneSearchForNews(query?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Zone>>>;
    public zoneSearchForNews(query?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Zone>>>;
    public zoneSearchForNews(query?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Zone>>(`${this.basePath}/zones/searchForNews`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param regione 
     * @param provincia 
     * @param comune 
     * @param zona 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zoneSearchGeometry(regione?: string, provincia?: string, comune?: string, zona?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Zone>>;
    public zoneSearchGeometry(regione?: string, provincia?: string, comune?: string, zona?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Zone>>>;
    public zoneSearchGeometry(regione?: string, provincia?: string, comune?: string, zona?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Zone>>>;
    public zoneSearchGeometry(regione?: string, provincia?: string, comune?: string, zona?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (regione !== undefined && regione !== null) {
            queryParameters = queryParameters.set('regione', <any>regione);
        }
        if (provincia !== undefined && provincia !== null) {
            queryParameters = queryParameters.set('provincia', <any>provincia);
        }
        if (comune !== undefined && comune !== null) {
            queryParameters = queryParameters.set('comune', <any>comune);
        }
        if (zona !== undefined && zona !== null) {
            queryParameters = queryParameters.set('zona', <any>zona);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Zone>>(`${this.basePath}/zones/searchGeometry`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update instances of the model matched by {{where}} from the data source.
     * 
     * @param where Criteria to match model instances
     * @param data An object of model property name/value pairs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zoneUpdateAll(where?: string, data?: Zone, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2002>;
    public zoneUpdateAll(where?: string, data?: Zone, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2002>>;
    public zoneUpdateAll(where?: string, data?: Zone, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2002>>;
    public zoneUpdateAll(where?: string, data?: Zone, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse2002>(`${this.basePath}/zones/update`,
            data,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an existing model instance or insert a new one into the data source based on the where criteria.
     * 
     * @param where Criteria to match model instances
     * @param data An object of model property name/value pairs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zoneUpsertWithWhere(where?: string, data?: Zone, observe?: 'body', reportProgress?: boolean): Observable<Zone>;
    public zoneUpsertWithWhere(where?: string, data?: Zone, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Zone>>;
    public zoneUpsertWithWhere(where?: string, data?: Zone, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Zone>>;
    public zoneUpsertWithWhere(where?: string, data?: Zone, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Zone>(`${this.basePath}/zones/upsertWithWhere`,
            data,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

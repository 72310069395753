/**
 * cercacasa_api
 * cercacasa_api
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Agency } from '../model/agency';
import { Agent } from '../model/agent';
import { Analytics } from '../model/analytics';
import { Article } from '../model/article';
import { Attachment } from '../model/attachment';
import { Client } from '../model/client';
import { ContactRequest } from '../model/contactRequest';
import { CountObject } from '../model/countObject';
import { HistoryPayment } from '../model/historyPayment';
import { InlineResponse200 } from '../model/inlineResponse200';
import { InlineResponse2001 } from '../model/inlineResponse2001';
import { InlineResponse2002 } from '../model/inlineResponse2002';
import { LikedListing } from '../model/likedListing';
import { Listing } from '../model/listing';
import { ListingClient } from '../model/listingClient';
import { ListingSearch } from '../model/listingSearch';
import { Member } from '../model/member';
import { MlsRequest } from '../model/mlsRequest';
import { MlsStatistic } from '../model/mlsStatistic';
import { Note } from '../model/note';
import { Professionist } from '../model/professionist';
import { Report } from '../model/report';
import { Review } from '../model/review';
import { SavedListing } from '../model/savedListing';
import { VisibilitySchedule } from '../model/visibilitySchedule';
import { VisitedListing } from '../model/visitedListing';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ListingService {

    protected basePath = 'https://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingClickTelephone(id: string, observe?: 'body', reportProgress?: boolean): Observable<Listing>;
    public listingClickTelephone(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Listing>>;
    public listingClickTelephone(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Listing>>;
    public listingClickTelephone(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingClickTelephone.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.post<Listing>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/clickTelephone`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Count instances of the model matched by where from the data source.
     * 
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingCount(where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public listingCount(where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public listingCount(where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public listingCount(where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/listings/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param query 
     * @param where 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingCountByAddressAndRefCode(query?: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<CountObject>;
    public listingCountByAddressAndRefCode(query?: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CountObject>>;
    public listingCountByAddressAndRefCode(query?: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CountObject>>;
    public listingCountByAddressAndRefCode(query?: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<CountObject>(`${this.basePath}/listings/countByAddressAndRefCode`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingCountDistinctGestionali(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingCountDistinctGestionali(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingCountDistinctGestionali(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingCountDistinctGestionali(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<any>(`${this.basePath}/listings/countDistinctGestionali`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new instance of the model and persist it into the data source.
     * 
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingCreate(data?: Listing, observe?: 'body', reportProgress?: boolean): Observable<Listing>;
    public listingCreate(data?: Listing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Listing>>;
    public listingCreate(data?: Listing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Listing>>;
    public listingCreate(data?: Listing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Listing>(`${this.basePath}/listings`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a change stream.
     * 
     * @param options 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingCreateChangeStreamGetListingsChangeStream(options?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public listingCreateChangeStreamGetListingsChangeStream(options?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public listingCreateChangeStreamGetListingsChangeStream(options?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public listingCreateChangeStreamGetListingsChangeStream(options?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (options !== undefined && options !== null) {
            queryParameters = queryParameters.set('options', <any>options);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get(`${this.basePath}/listings/change-stream`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a change stream.
     * 
     * @param options 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingCreateChangeStreamPostListingsChangeStream(options?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public listingCreateChangeStreamPostListingsChangeStream(options?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public listingCreateChangeStreamPostListingsChangeStream(options?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public listingCreateChangeStreamPostListingsChangeStream(options?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (options !== undefined) {
            formParams.append('options', <any>options);
        }

        return this.httpClient.post(`${this.basePath}/listings/change-stream`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a model instance by {{id}} from the data source.
     * 
     * @param id Model id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingDeleteById(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingDeleteById(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingDeleteById(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingDeleteById(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingDeleteById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check whether a model instance exists in the data source.
     * 
     * @param id Model id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingExistsGetListingsidExists(id: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2001>;
    public listingExistsGetListingsidExists(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2001>>;
    public listingExistsGetListingsidExists(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2001>>;
    public listingExistsGetListingsidExists(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingExistsGetListingsidExists.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse2001>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/exists`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check whether a model instance exists in the data source.
     * 
     * @param id Model id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingExistsHeadListingsid(id: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2001>;
    public listingExistsHeadListingsid(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2001>>;
    public listingExistsHeadListingsid(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2001>>;
    public listingExistsHeadListingsid(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingExistsHeadListingsid.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<InlineResponse2001>(`${this.basePath}/listings/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param limit 
     * @param type 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingExportForPartner(limit?: number, type?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Listing>>;
    public listingExportForPartner(limit?: number, type?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Listing>>>;
    public listingExportForPartner(limit?: number, type?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Listing>>>;
    public listingExportForPartner(limit?: number, type?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Listing>>(`${this.basePath}/listings/exportForPartner`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find all instances of the model matched by filter from the data source.
     * 
     * @param filter Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (&#x60;{\&quot;where\&quot;:{\&quot;something\&quot;:\&quot;value\&quot;}}&#x60;).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingFind(filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Listing>>;
    public listingFind(filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Listing>>>;
    public listingFind(filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Listing>>>;
    public listingFind(filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Listing>>(`${this.basePath}/listings`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a model instance by {{id}} from the data source.
     * 
     * @param id Model id
     * @param filter Filter defining fields and include - must be a JSON-encoded string ({\&quot;something\&quot;:\&quot;value\&quot;})
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingFindById(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Listing>;
    public listingFindById(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Listing>>;
    public listingFindById(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Listing>>;
    public listingFindById(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingFindById.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Listing>(`${this.basePath}/listings/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingFindNearestZones(id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<any>>;
    public listingFindNearestZones(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<any>>>;
    public listingFindNearestZones(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<any>>>;
    public listingFindNearestZones(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingFindNearestZones.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<any>>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/findNearestZones`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingFindNewsCloseBy(id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Article>>;
    public listingFindNewsCloseBy(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Article>>>;
    public listingFindNewsCloseBy(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Article>>>;
    public listingFindNewsCloseBy(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingFindNewsCloseBy.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Article>>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/findNewsCloseBy`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find first instance of the model matched by filter from the data source.
     * 
     * @param filter Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (&#x60;{\&quot;where\&quot;:{\&quot;something\&quot;:\&quot;value\&quot;}}&#x60;).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingFindOne(filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Listing>;
    public listingFindOne(filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Listing>>;
    public listingFindOne(filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Listing>>;
    public listingFindOne(filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Listing>(`${this.basePath}/listings/findOne`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingFindProfessionistCloseBy(id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Professionist>>;
    public listingFindProfessionistCloseBy(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Professionist>>>;
    public listingFindProfessionistCloseBy(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Professionist>>>;
    public listingFindProfessionistCloseBy(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingFindProfessionistCloseBy.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Professionist>>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/findProfessionistCloseBy`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingFindSimilarListings(id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Listing>>;
    public listingFindSimilarListings(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Listing>>>;
    public listingFindSimilarListings(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Listing>>>;
    public listingFindSimilarListings(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingFindSimilarListings.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Listing>>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/findSimilarListings`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingGenerateExclusivityPdf(id: string, data: Listing, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingGenerateExclusivityPdf(id: string, data: Listing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingGenerateExclusivityPdf(id: string, data: Listing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingGenerateExclusivityPdf(id: string, data: Listing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingGenerateExclusivityPdf.');
        }

        if (data === null || data === undefined) {
            throw new Error('Required parameter data was null or undefined when calling listingGenerateExclusivityPdf.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/generateExclusivityPdf`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param position 
     * @param regione 
     * @param provincia 
     * @param comune 
     * @param zona 
     * @param coords 
     * @param radius 
     * @param filters 
     * @param order 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingGetPrevAndPost(position?: number, regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, filters?: string, order?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingGetPrevAndPost(position?: number, regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, filters?: string, order?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingGetPrevAndPost(position?: number, regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, filters?: string, order?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingGetPrevAndPost(position?: number, regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, filters?: string, order?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {










        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (position !== undefined && position !== null) {
            queryParameters = queryParameters.set('position', <any>position);
        }
        if (regione !== undefined && regione !== null) {
            queryParameters = queryParameters.set('regione', <any>regione);
        }
        if (provincia !== undefined && provincia !== null) {
            queryParameters = queryParameters.set('provincia', <any>provincia);
        }
        if (comune !== undefined && comune !== null) {
            queryParameters = queryParameters.set('comune', <any>comune);
        }
        if (zona !== undefined && zona !== null) {
            queryParameters = queryParameters.set('zona', <any>zona);
        }
        if (coords !== undefined && coords !== null) {
            queryParameters = queryParameters.set('coords', <any>coords);
        }
        if (radius !== undefined && radius !== null) {
            queryParameters = queryParameters.set('radius', <any>radius);
        }
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('filters', <any>filters);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<any>(`${this.basePath}/listings/getPrevAndPost`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param ids 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingGetVisitedListing(ids?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Listing>>;
    public listingGetVisitedListing(ids?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Listing>>>;
    public listingGetVisitedListing(ids?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Listing>>>;
    public listingGetVisitedListing(ids?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ids !== undefined && ids !== null) {
            queryParameters = queryParameters.set('ids', <any>ids);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Listing>>(`${this.basePath}/listings/getVisitedListing`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingHandleExclusivity(id: string, data: Listing, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingHandleExclusivity(id: string, data: Listing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingHandleExclusivity(id: string, data: Listing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingHandleExclusivity(id: string, data: Listing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingHandleExclusivity.');
        }

        if (data === null || data === undefined) {
            throw new Error('Required parameter data was null or undefined when calling listingHandleExclusivity.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/handleExclusivity`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingMlsStatistics(observe?: 'body', reportProgress?: boolean): Observable<MlsStatistic>;
    public listingMlsStatistics(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MlsStatistic>>;
    public listingMlsStatistics(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MlsStatistic>>;
    public listingMlsStatistics(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<MlsStatistic>(`${this.basePath}/listings/mlsStatistics`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patch an existing model instance or insert a new one into the data source.
     * 
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPatchOrCreate(data?: Listing, observe?: 'body', reportProgress?: boolean): Observable<Listing>;
    public listingPatchOrCreate(data?: Listing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Listing>>;
    public listingPatchOrCreate(data?: Listing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Listing>>;
    public listingPatchOrCreate(data?: Listing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<Listing>(`${this.basePath}/listings`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts analytics of Listing.
     * 
     * @param id Listing id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeCountAnalytics(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public listingPrototypeCountAnalytics(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public listingPrototypeCountAnalytics(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public listingPrototypeCountAnalytics(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeCountAnalytics.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/analytics/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts clients of Listing.
     * 
     * @param id Listing id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeCountClients(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public listingPrototypeCountClients(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public listingPrototypeCountClients(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public listingPrototypeCountClients(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeCountClients.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/clients/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts clientsWithType of Listing.
     * 
     * @param id Listing id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeCountClientsWithType(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public listingPrototypeCountClientsWithType(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public listingPrototypeCountClientsWithType(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public listingPrototypeCountClientsWithType(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeCountClientsWithType.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/clientsWithType/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts contactRequests of Listing.
     * 
     * @param id Listing id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeCountContactRequests(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public listingPrototypeCountContactRequests(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public listingPrototypeCountContactRequests(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public listingPrototypeCountContactRequests(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeCountContactRequests.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/contactRequests/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts historyPayments of Listing.
     * 
     * @param id Listing id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeCountHistoryPayments(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public listingPrototypeCountHistoryPayments(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public listingPrototypeCountHistoryPayments(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public listingPrototypeCountHistoryPayments(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeCountHistoryPayments.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/historyPayments/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts likedBy of Listing.
     * 
     * @param id Listing id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeCountLikedBy(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public listingPrototypeCountLikedBy(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public listingPrototypeCountLikedBy(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public listingPrototypeCountLikedBy(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeCountLikedBy.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/likedBy/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts members of Listing.
     * 
     * @param id Listing id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeCountMembers(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public listingPrototypeCountMembers(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public listingPrototypeCountMembers(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public listingPrototypeCountMembers(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeCountMembers.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/members/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts mlsRequests of Listing.
     * 
     * @param id Listing id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeCountMlsRequests(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public listingPrototypeCountMlsRequests(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public listingPrototypeCountMlsRequests(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public listingPrototypeCountMlsRequests(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeCountMlsRequests.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/mlsRequests/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts notes of Listing.
     * 
     * @param id Listing id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeCountNotes(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public listingPrototypeCountNotes(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public listingPrototypeCountNotes(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public listingPrototypeCountNotes(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeCountNotes.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/notes/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts reports of Listing.
     * 
     * @param id Listing id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeCountReports(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public listingPrototypeCountReports(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public listingPrototypeCountReports(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public listingPrototypeCountReports(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeCountReports.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/reports/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts reviews of Listing.
     * 
     * @param id Listing id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeCountReviews(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public listingPrototypeCountReviews(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public listingPrototypeCountReviews(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public listingPrototypeCountReviews(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeCountReviews.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/reviews/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts visitedByMembers of Listing.
     * 
     * @param id Listing id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeCountVisitedByMembers(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public listingPrototypeCountVisitedByMembers(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public listingPrototypeCountVisitedByMembers(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public listingPrototypeCountVisitedByMembers(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeCountVisitedByMembers.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/visitedByMembers/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in analytics of this model.
     * 
     * @param id Listing id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeCreateAnalytics(id: string, data?: Analytics, observe?: 'body', reportProgress?: boolean): Observable<Analytics>;
    public listingPrototypeCreateAnalytics(id: string, data?: Analytics, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Analytics>>;
    public listingPrototypeCreateAnalytics(id: string, data?: Analytics, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Analytics>>;
    public listingPrototypeCreateAnalytics(id: string, data?: Analytics, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeCreateAnalytics.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Analytics>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/analytics`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in clients of this model.
     * 
     * @param id Listing id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeCreateClients(id: string, data?: Client, observe?: 'body', reportProgress?: boolean): Observable<Client>;
    public listingPrototypeCreateClients(id: string, data?: Client, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Client>>;
    public listingPrototypeCreateClients(id: string, data?: Client, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Client>>;
    public listingPrototypeCreateClients(id: string, data?: Client, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeCreateClients.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Client>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/clients`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in clientsWithType of this model.
     * 
     * @param id Listing id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeCreateClientsWithType(id: string, data?: ListingClient, observe?: 'body', reportProgress?: boolean): Observable<ListingClient>;
    public listingPrototypeCreateClientsWithType(id: string, data?: ListingClient, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListingClient>>;
    public listingPrototypeCreateClientsWithType(id: string, data?: ListingClient, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListingClient>>;
    public listingPrototypeCreateClientsWithType(id: string, data?: ListingClient, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeCreateClientsWithType.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListingClient>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/clientsWithType`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in contactRequests of this model.
     * 
     * @param id Listing id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeCreateContactRequests(id: string, data?: ContactRequest, observe?: 'body', reportProgress?: boolean): Observable<ContactRequest>;
    public listingPrototypeCreateContactRequests(id: string, data?: ContactRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContactRequest>>;
    public listingPrototypeCreateContactRequests(id: string, data?: ContactRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContactRequest>>;
    public listingPrototypeCreateContactRequests(id: string, data?: ContactRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeCreateContactRequests.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ContactRequest>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/contactRequests`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in historyPayments of this model.
     * 
     * @param id Listing id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeCreateHistoryPayments(id: string, data?: HistoryPayment, observe?: 'body', reportProgress?: boolean): Observable<HistoryPayment>;
    public listingPrototypeCreateHistoryPayments(id: string, data?: HistoryPayment, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HistoryPayment>>;
    public listingPrototypeCreateHistoryPayments(id: string, data?: HistoryPayment, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HistoryPayment>>;
    public listingPrototypeCreateHistoryPayments(id: string, data?: HistoryPayment, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeCreateHistoryPayments.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<HistoryPayment>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/historyPayments`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in likedBy of this model.
     * 
     * @param id Listing id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeCreateLikedBy(id: string, data?: Member, observe?: 'body', reportProgress?: boolean): Observable<Member>;
    public listingPrototypeCreateLikedBy(id: string, data?: Member, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Member>>;
    public listingPrototypeCreateLikedBy(id: string, data?: Member, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Member>>;
    public listingPrototypeCreateLikedBy(id: string, data?: Member, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeCreateLikedBy.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Member>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/likedBy`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in members of this model.
     * 
     * @param id Listing id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeCreateMembers(id: string, data?: Member, observe?: 'body', reportProgress?: boolean): Observable<Member>;
    public listingPrototypeCreateMembers(id: string, data?: Member, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Member>>;
    public listingPrototypeCreateMembers(id: string, data?: Member, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Member>>;
    public listingPrototypeCreateMembers(id: string, data?: Member, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeCreateMembers.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Member>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/members`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in mlsRequests of this model.
     * 
     * @param id Listing id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeCreateMlsRequests(id: string, data?: MlsRequest, observe?: 'body', reportProgress?: boolean): Observable<MlsRequest>;
    public listingPrototypeCreateMlsRequests(id: string, data?: MlsRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MlsRequest>>;
    public listingPrototypeCreateMlsRequests(id: string, data?: MlsRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MlsRequest>>;
    public listingPrototypeCreateMlsRequests(id: string, data?: MlsRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeCreateMlsRequests.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MlsRequest>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/mlsRequests`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in notes of this model.
     * 
     * @param id Listing id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeCreateNotes(id: string, data?: Note, observe?: 'body', reportProgress?: boolean): Observable<Note>;
    public listingPrototypeCreateNotes(id: string, data?: Note, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Note>>;
    public listingPrototypeCreateNotes(id: string, data?: Note, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Note>>;
    public listingPrototypeCreateNotes(id: string, data?: Note, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeCreateNotes.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Note>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/notes`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in reports of this model.
     * 
     * @param id Listing id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeCreateReports(id: string, data?: Report, observe?: 'body', reportProgress?: boolean): Observable<Report>;
    public listingPrototypeCreateReports(id: string, data?: Report, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Report>>;
    public listingPrototypeCreateReports(id: string, data?: Report, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Report>>;
    public listingPrototypeCreateReports(id: string, data?: Report, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeCreateReports.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Report>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/reports`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in reviews of this model.
     * 
     * @param id Listing id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeCreateReviews(id: string, data?: Review, observe?: 'body', reportProgress?: boolean): Observable<Review>;
    public listingPrototypeCreateReviews(id: string, data?: Review, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Review>>;
    public listingPrototypeCreateReviews(id: string, data?: Review, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Review>>;
    public listingPrototypeCreateReviews(id: string, data?: Review, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeCreateReviews.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Review>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/reviews`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in visitedByMembers of this model.
     * 
     * @param id Listing id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeCreateVisitedByMembers(id: string, data?: Member, observe?: 'body', reportProgress?: boolean): Observable<Member>;
    public listingPrototypeCreateVisitedByMembers(id: string, data?: Member, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Member>>;
    public listingPrototypeCreateVisitedByMembers(id: string, data?: Member, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Member>>;
    public listingPrototypeCreateVisitedByMembers(id: string, data?: Member, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeCreateVisitedByMembers.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Member>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/visitedByMembers`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all analytics of this model.
     * 
     * @param id Listing id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeDeleteAnalytics(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingPrototypeDeleteAnalytics(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingPrototypeDeleteAnalytics(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingPrototypeDeleteAnalytics(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeDeleteAnalytics.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/analytics`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all clients of this model.
     * 
     * @param id Listing id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeDeleteClients(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingPrototypeDeleteClients(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingPrototypeDeleteClients(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingPrototypeDeleteClients(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeDeleteClients.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/clients`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all clientsWithType of this model.
     * 
     * @param id Listing id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeDeleteClientsWithType(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingPrototypeDeleteClientsWithType(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingPrototypeDeleteClientsWithType(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingPrototypeDeleteClientsWithType(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeDeleteClientsWithType.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/clientsWithType`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all contactRequests of this model.
     * 
     * @param id Listing id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeDeleteContactRequests(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingPrototypeDeleteContactRequests(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingPrototypeDeleteContactRequests(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingPrototypeDeleteContactRequests(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeDeleteContactRequests.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/contactRequests`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all historyPayments of this model.
     * 
     * @param id Listing id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeDeleteHistoryPayments(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingPrototypeDeleteHistoryPayments(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingPrototypeDeleteHistoryPayments(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingPrototypeDeleteHistoryPayments(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeDeleteHistoryPayments.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/historyPayments`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all likedBy of this model.
     * 
     * @param id Listing id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeDeleteLikedBy(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingPrototypeDeleteLikedBy(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingPrototypeDeleteLikedBy(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingPrototypeDeleteLikedBy(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeDeleteLikedBy.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/likedBy`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all members of this model.
     * 
     * @param id Listing id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeDeleteMembers(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingPrototypeDeleteMembers(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingPrototypeDeleteMembers(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingPrototypeDeleteMembers(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeDeleteMembers.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/members`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all mlsRequests of this model.
     * 
     * @param id Listing id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeDeleteMlsRequests(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingPrototypeDeleteMlsRequests(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingPrototypeDeleteMlsRequests(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingPrototypeDeleteMlsRequests(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeDeleteMlsRequests.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/mlsRequests`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all notes of this model.
     * 
     * @param id Listing id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeDeleteNotes(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingPrototypeDeleteNotes(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingPrototypeDeleteNotes(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingPrototypeDeleteNotes(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeDeleteNotes.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/notes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all reports of this model.
     * 
     * @param id Listing id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeDeleteReports(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingPrototypeDeleteReports(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingPrototypeDeleteReports(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingPrototypeDeleteReports(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeDeleteReports.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/reports`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all reviews of this model.
     * 
     * @param id Listing id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeDeleteReviews(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingPrototypeDeleteReviews(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingPrototypeDeleteReviews(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingPrototypeDeleteReviews(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeDeleteReviews.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/reviews`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all visitedByMembers of this model.
     * 
     * @param id Listing id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeDeleteVisitedByMembers(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingPrototypeDeleteVisitedByMembers(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingPrototypeDeleteVisitedByMembers(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingPrototypeDeleteVisitedByMembers(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeDeleteVisitedByMembers.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/visitedByMembers`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for analytics.
     * 
     * @param id Listing id
     * @param fk Foreign key for analytics
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeDestroyByIdAnalytics(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingPrototypeDestroyByIdAnalytics(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingPrototypeDestroyByIdAnalytics(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingPrototypeDestroyByIdAnalytics(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeDestroyByIdAnalytics.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeDestroyByIdAnalytics.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/analytics/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for clients.
     * 
     * @param id Listing id
     * @param fk Foreign key for clients
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeDestroyByIdClients(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingPrototypeDestroyByIdClients(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingPrototypeDestroyByIdClients(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingPrototypeDestroyByIdClients(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeDestroyByIdClients.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeDestroyByIdClients.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/clients/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for clientsWithType.
     * 
     * @param id Listing id
     * @param fk Foreign key for clientsWithType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeDestroyByIdClientsWithType(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingPrototypeDestroyByIdClientsWithType(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingPrototypeDestroyByIdClientsWithType(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingPrototypeDestroyByIdClientsWithType(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeDestroyByIdClientsWithType.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeDestroyByIdClientsWithType.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/clientsWithType/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for contactRequests.
     * 
     * @param id Listing id
     * @param fk Foreign key for contactRequests
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeDestroyByIdContactRequests(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingPrototypeDestroyByIdContactRequests(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingPrototypeDestroyByIdContactRequests(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingPrototypeDestroyByIdContactRequests(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeDestroyByIdContactRequests.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeDestroyByIdContactRequests.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/contactRequests/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for historyPayments.
     * 
     * @param id Listing id
     * @param fk Foreign key for historyPayments
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeDestroyByIdHistoryPayments(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingPrototypeDestroyByIdHistoryPayments(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingPrototypeDestroyByIdHistoryPayments(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingPrototypeDestroyByIdHistoryPayments(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeDestroyByIdHistoryPayments.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeDestroyByIdHistoryPayments.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/historyPayments/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for likedBy.
     * 
     * @param id Listing id
     * @param fk Foreign key for likedBy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeDestroyByIdLikedBy(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingPrototypeDestroyByIdLikedBy(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingPrototypeDestroyByIdLikedBy(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingPrototypeDestroyByIdLikedBy(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeDestroyByIdLikedBy.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeDestroyByIdLikedBy.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/likedBy/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for members.
     * 
     * @param id Listing id
     * @param fk Foreign key for members
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeDestroyByIdMembers(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingPrototypeDestroyByIdMembers(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingPrototypeDestroyByIdMembers(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingPrototypeDestroyByIdMembers(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeDestroyByIdMembers.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeDestroyByIdMembers.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/members/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for mlsRequests.
     * 
     * @param id Listing id
     * @param fk Foreign key for mlsRequests
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeDestroyByIdMlsRequests(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingPrototypeDestroyByIdMlsRequests(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingPrototypeDestroyByIdMlsRequests(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingPrototypeDestroyByIdMlsRequests(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeDestroyByIdMlsRequests.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeDestroyByIdMlsRequests.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/mlsRequests/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for notes.
     * 
     * @param id Listing id
     * @param fk Foreign key for notes
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeDestroyByIdNotes(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingPrototypeDestroyByIdNotes(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingPrototypeDestroyByIdNotes(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingPrototypeDestroyByIdNotes(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeDestroyByIdNotes.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeDestroyByIdNotes.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/notes/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for reports.
     * 
     * @param id Listing id
     * @param fk Foreign key for reports
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeDestroyByIdReports(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingPrototypeDestroyByIdReports(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingPrototypeDestroyByIdReports(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingPrototypeDestroyByIdReports(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeDestroyByIdReports.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeDestroyByIdReports.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/reports/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for reviews.
     * 
     * @param id Listing id
     * @param fk Foreign key for reviews
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeDestroyByIdReviews(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingPrototypeDestroyByIdReviews(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingPrototypeDestroyByIdReviews(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingPrototypeDestroyByIdReviews(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeDestroyByIdReviews.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeDestroyByIdReviews.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/reviews/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for visitedByMembers.
     * 
     * @param id Listing id
     * @param fk Foreign key for visitedByMembers
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeDestroyByIdVisitedByMembers(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingPrototypeDestroyByIdVisitedByMembers(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingPrototypeDestroyByIdVisitedByMembers(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingPrototypeDestroyByIdVisitedByMembers(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeDestroyByIdVisitedByMembers.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeDestroyByIdVisitedByMembers.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/visitedByMembers/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of clients relation to an item by id.
     * 
     * @param id Listing id
     * @param fk Foreign key for clients
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeExistsClients(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public listingPrototypeExistsClients(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public listingPrototypeExistsClients(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public listingPrototypeExistsClients(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeExistsClients.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeExistsClients.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/clients/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of likedBy relation to an item by id.
     * 
     * @param id Listing id
     * @param fk Foreign key for likedBy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeExistsLikedBy(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public listingPrototypeExistsLikedBy(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public listingPrototypeExistsLikedBy(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public listingPrototypeExistsLikedBy(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeExistsLikedBy.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeExistsLikedBy.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/likedBy/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of members relation to an item by id.
     * 
     * @param id Listing id
     * @param fk Foreign key for members
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeExistsMembers(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public listingPrototypeExistsMembers(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public listingPrototypeExistsMembers(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public listingPrototypeExistsMembers(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeExistsMembers.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeExistsMembers.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/members/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of visitedByMembers relation to an item by id.
     * 
     * @param id Listing id
     * @param fk Foreign key for visitedByMembers
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeExistsVisitedByMembers(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public listingPrototypeExistsVisitedByMembers(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public listingPrototypeExistsVisitedByMembers(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public listingPrototypeExistsVisitedByMembers(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeExistsVisitedByMembers.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeExistsVisitedByMembers.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/visitedByMembers/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for analytics.
     * 
     * @param id Listing id
     * @param fk Foreign key for analytics
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeFindByIdAnalytics(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Analytics>;
    public listingPrototypeFindByIdAnalytics(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Analytics>>;
    public listingPrototypeFindByIdAnalytics(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Analytics>>;
    public listingPrototypeFindByIdAnalytics(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeFindByIdAnalytics.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeFindByIdAnalytics.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Analytics>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/analytics/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for clients.
     * 
     * @param id Listing id
     * @param fk Foreign key for clients
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeFindByIdClients(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Client>;
    public listingPrototypeFindByIdClients(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Client>>;
    public listingPrototypeFindByIdClients(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Client>>;
    public listingPrototypeFindByIdClients(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeFindByIdClients.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeFindByIdClients.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Client>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/clients/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for clientsWithType.
     * 
     * @param id Listing id
     * @param fk Foreign key for clientsWithType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeFindByIdClientsWithType(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<ListingClient>;
    public listingPrototypeFindByIdClientsWithType(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListingClient>>;
    public listingPrototypeFindByIdClientsWithType(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListingClient>>;
    public listingPrototypeFindByIdClientsWithType(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeFindByIdClientsWithType.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeFindByIdClientsWithType.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<ListingClient>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/clientsWithType/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for contactRequests.
     * 
     * @param id Listing id
     * @param fk Foreign key for contactRequests
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeFindByIdContactRequests(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<ContactRequest>;
    public listingPrototypeFindByIdContactRequests(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContactRequest>>;
    public listingPrototypeFindByIdContactRequests(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContactRequest>>;
    public listingPrototypeFindByIdContactRequests(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeFindByIdContactRequests.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeFindByIdContactRequests.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<ContactRequest>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/contactRequests/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for historyPayments.
     * 
     * @param id Listing id
     * @param fk Foreign key for historyPayments
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeFindByIdHistoryPayments(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<HistoryPayment>;
    public listingPrototypeFindByIdHistoryPayments(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HistoryPayment>>;
    public listingPrototypeFindByIdHistoryPayments(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HistoryPayment>>;
    public listingPrototypeFindByIdHistoryPayments(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeFindByIdHistoryPayments.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeFindByIdHistoryPayments.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<HistoryPayment>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/historyPayments/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for likedBy.
     * 
     * @param id Listing id
     * @param fk Foreign key for likedBy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeFindByIdLikedBy(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Member>;
    public listingPrototypeFindByIdLikedBy(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Member>>;
    public listingPrototypeFindByIdLikedBy(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Member>>;
    public listingPrototypeFindByIdLikedBy(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeFindByIdLikedBy.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeFindByIdLikedBy.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Member>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/likedBy/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for members.
     * 
     * @param id Listing id
     * @param fk Foreign key for members
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeFindByIdMembers(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Member>;
    public listingPrototypeFindByIdMembers(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Member>>;
    public listingPrototypeFindByIdMembers(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Member>>;
    public listingPrototypeFindByIdMembers(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeFindByIdMembers.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeFindByIdMembers.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Member>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/members/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for mlsRequests.
     * 
     * @param id Listing id
     * @param fk Foreign key for mlsRequests
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeFindByIdMlsRequests(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<MlsRequest>;
    public listingPrototypeFindByIdMlsRequests(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MlsRequest>>;
    public listingPrototypeFindByIdMlsRequests(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MlsRequest>>;
    public listingPrototypeFindByIdMlsRequests(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeFindByIdMlsRequests.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeFindByIdMlsRequests.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<MlsRequest>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/mlsRequests/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for notes.
     * 
     * @param id Listing id
     * @param fk Foreign key for notes
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeFindByIdNotes(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Note>;
    public listingPrototypeFindByIdNotes(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Note>>;
    public listingPrototypeFindByIdNotes(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Note>>;
    public listingPrototypeFindByIdNotes(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeFindByIdNotes.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeFindByIdNotes.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Note>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/notes/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for reports.
     * 
     * @param id Listing id
     * @param fk Foreign key for reports
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeFindByIdReports(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Report>;
    public listingPrototypeFindByIdReports(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Report>>;
    public listingPrototypeFindByIdReports(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Report>>;
    public listingPrototypeFindByIdReports(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeFindByIdReports.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeFindByIdReports.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Report>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/reports/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for reviews.
     * 
     * @param id Listing id
     * @param fk Foreign key for reviews
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeFindByIdReviews(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Review>;
    public listingPrototypeFindByIdReviews(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Review>>;
    public listingPrototypeFindByIdReviews(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Review>>;
    public listingPrototypeFindByIdReviews(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeFindByIdReviews.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeFindByIdReviews.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Review>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/reviews/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for visitedByMembers.
     * 
     * @param id Listing id
     * @param fk Foreign key for visitedByMembers
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeFindByIdVisitedByMembers(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Member>;
    public listingPrototypeFindByIdVisitedByMembers(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Member>>;
    public listingPrototypeFindByIdVisitedByMembers(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Member>>;
    public listingPrototypeFindByIdVisitedByMembers(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeFindByIdVisitedByMembers.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeFindByIdVisitedByMembers.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Member>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/visitedByMembers/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetches belongsTo relation agency.
     * 
     * @param id Listing id
     * @param refresh 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeGetAgency(id: string, refresh?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Agency>;
    public listingPrototypeGetAgency(id: string, refresh?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agency>>;
    public listingPrototypeGetAgency(id: string, refresh?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agency>>;
    public listingPrototypeGetAgency(id: string, refresh?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeGetAgency.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (refresh !== undefined && refresh !== null) {
            queryParameters = queryParameters.set('refresh', <any>refresh);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Agency>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/agency`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetches belongsTo relation agent.
     * 
     * @param id Listing id
     * @param refresh 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeGetAgent(id: string, refresh?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Agent>;
    public listingPrototypeGetAgent(id: string, refresh?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agent>>;
    public listingPrototypeGetAgent(id: string, refresh?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agent>>;
    public listingPrototypeGetAgent(id: string, refresh?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeGetAgent.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (refresh !== undefined && refresh !== null) {
            queryParameters = queryParameters.set('refresh', <any>refresh);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Agent>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/agent`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries analytics of Listing.
     * 
     * @param id Listing id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeGetAnalytics(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Analytics>>;
    public listingPrototypeGetAnalytics(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Analytics>>>;
    public listingPrototypeGetAnalytics(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Analytics>>>;
    public listingPrototypeGetAnalytics(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeGetAnalytics.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Analytics>>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/analytics`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries clients of Listing.
     * 
     * @param id Listing id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeGetClients(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Client>>;
    public listingPrototypeGetClients(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Client>>>;
    public listingPrototypeGetClients(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Client>>>;
    public listingPrototypeGetClients(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeGetClients.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Client>>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/clients`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries clientsWithType of Listing.
     * 
     * @param id Listing id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeGetClientsWithType(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ListingClient>>;
    public listingPrototypeGetClientsWithType(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ListingClient>>>;
    public listingPrototypeGetClientsWithType(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ListingClient>>>;
    public listingPrototypeGetClientsWithType(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeGetClientsWithType.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<ListingClient>>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/clientsWithType`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries contactRequests of Listing.
     * 
     * @param id Listing id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeGetContactRequests(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ContactRequest>>;
    public listingPrototypeGetContactRequests(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ContactRequest>>>;
    public listingPrototypeGetContactRequests(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ContactRequest>>>;
    public listingPrototypeGetContactRequests(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeGetContactRequests.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<ContactRequest>>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/contactRequests`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries historyPayments of Listing.
     * 
     * @param id Listing id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeGetHistoryPayments(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<HistoryPayment>>;
    public listingPrototypeGetHistoryPayments(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<HistoryPayment>>>;
    public listingPrototypeGetHistoryPayments(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<HistoryPayment>>>;
    public listingPrototypeGetHistoryPayments(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeGetHistoryPayments.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<HistoryPayment>>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/historyPayments`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries likedBy of Listing.
     * 
     * @param id Listing id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeGetLikedBy(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Member>>;
    public listingPrototypeGetLikedBy(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Member>>>;
    public listingPrototypeGetLikedBy(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Member>>>;
    public listingPrototypeGetLikedBy(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeGetLikedBy.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Member>>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/likedBy`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries members of Listing.
     * 
     * @param id Listing id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeGetMembers(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Member>>;
    public listingPrototypeGetMembers(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Member>>>;
    public listingPrototypeGetMembers(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Member>>>;
    public listingPrototypeGetMembers(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeGetMembers.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Member>>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/members`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries mlsRequests of Listing.
     * 
     * @param id Listing id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeGetMlsRequests(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<MlsRequest>>;
    public listingPrototypeGetMlsRequests(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MlsRequest>>>;
    public listingPrototypeGetMlsRequests(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MlsRequest>>>;
    public listingPrototypeGetMlsRequests(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeGetMlsRequests.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<MlsRequest>>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/mlsRequests`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries notes of Listing.
     * 
     * @param id Listing id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeGetNotes(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Note>>;
    public listingPrototypeGetNotes(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Note>>>;
    public listingPrototypeGetNotes(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Note>>>;
    public listingPrototypeGetNotes(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeGetNotes.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Note>>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/notes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetches belongsTo relation owner.
     * 
     * @param id Listing id
     * @param refresh 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeGetOwner(id: string, refresh?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Client>;
    public listingPrototypeGetOwner(id: string, refresh?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Client>>;
    public listingPrototypeGetOwner(id: string, refresh?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Client>>;
    public listingPrototypeGetOwner(id: string, refresh?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeGetOwner.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (refresh !== undefined && refresh !== null) {
            queryParameters = queryParameters.set('refresh', <any>refresh);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Client>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/owner`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries reports of Listing.
     * 
     * @param id Listing id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeGetReports(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Report>>;
    public listingPrototypeGetReports(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Report>>>;
    public listingPrototypeGetReports(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Report>>>;
    public listingPrototypeGetReports(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeGetReports.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Report>>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/reports`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries reviews of Listing.
     * 
     * @param id Listing id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeGetReviews(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Review>>;
    public listingPrototypeGetReviews(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Review>>>;
    public listingPrototypeGetReviews(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Review>>>;
    public listingPrototypeGetReviews(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeGetReviews.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Review>>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/reviews`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetches belongsTo relation visibilitySchedule.
     * 
     * @param id Listing id
     * @param refresh 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeGetVisibilitySchedule(id: string, refresh?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VisibilitySchedule>;
    public listingPrototypeGetVisibilitySchedule(id: string, refresh?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VisibilitySchedule>>;
    public listingPrototypeGetVisibilitySchedule(id: string, refresh?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VisibilitySchedule>>;
    public listingPrototypeGetVisibilitySchedule(id: string, refresh?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeGetVisibilitySchedule.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (refresh !== undefined && refresh !== null) {
            queryParameters = queryParameters.set('refresh', <any>refresh);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<VisibilitySchedule>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/visibilitySchedule`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries visitedByMembers of Listing.
     * 
     * @param id Listing id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeGetVisitedByMembers(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Member>>;
    public listingPrototypeGetVisitedByMembers(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Member>>>;
    public listingPrototypeGetVisitedByMembers(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Member>>>;
    public listingPrototypeGetVisitedByMembers(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeGetVisitedByMembers.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Member>>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/visitedByMembers`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for clients.
     * 
     * @param id Listing id
     * @param fk Foreign key for clients
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeLinkClients(id: string, fk: string, data?: ListingClient, observe?: 'body', reportProgress?: boolean): Observable<ListingClient>;
    public listingPrototypeLinkClients(id: string, fk: string, data?: ListingClient, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListingClient>>;
    public listingPrototypeLinkClients(id: string, fk: string, data?: ListingClient, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListingClient>>;
    public listingPrototypeLinkClients(id: string, fk: string, data?: ListingClient, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeLinkClients.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeLinkClients.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ListingClient>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/clients/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for likedBy.
     * 
     * @param id Listing id
     * @param fk Foreign key for likedBy
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeLinkLikedBy(id: string, fk: string, data?: LikedListing, observe?: 'body', reportProgress?: boolean): Observable<LikedListing>;
    public listingPrototypeLinkLikedBy(id: string, fk: string, data?: LikedListing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LikedListing>>;
    public listingPrototypeLinkLikedBy(id: string, fk: string, data?: LikedListing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LikedListing>>;
    public listingPrototypeLinkLikedBy(id: string, fk: string, data?: LikedListing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeLinkLikedBy.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeLinkLikedBy.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<LikedListing>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/likedBy/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for members.
     * 
     * @param id Listing id
     * @param fk Foreign key for members
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeLinkMembers(id: string, fk: string, data?: SavedListing, observe?: 'body', reportProgress?: boolean): Observable<SavedListing>;
    public listingPrototypeLinkMembers(id: string, fk: string, data?: SavedListing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SavedListing>>;
    public listingPrototypeLinkMembers(id: string, fk: string, data?: SavedListing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SavedListing>>;
    public listingPrototypeLinkMembers(id: string, fk: string, data?: SavedListing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeLinkMembers.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeLinkMembers.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<SavedListing>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/members/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for visitedByMembers.
     * 
     * @param id Listing id
     * @param fk Foreign key for visitedByMembers
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeLinkVisitedByMembers(id: string, fk: string, data?: VisitedListing, observe?: 'body', reportProgress?: boolean): Observable<VisitedListing>;
    public listingPrototypeLinkVisitedByMembers(id: string, fk: string, data?: VisitedListing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VisitedListing>>;
    public listingPrototypeLinkVisitedByMembers(id: string, fk: string, data?: VisitedListing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VisitedListing>>;
    public listingPrototypeLinkVisitedByMembers(id: string, fk: string, data?: VisitedListing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeLinkVisitedByMembers.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeLinkVisitedByMembers.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<VisitedListing>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/visitedByMembers/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patch attributes for a model instance and persist it into the data source.
     * 
     * @param id Listing id
     * @param data An object of model property name/value pairs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypePatchAttributes(id: string, data?: Listing, observe?: 'body', reportProgress?: boolean): Observable<Listing>;
    public listingPrototypePatchAttributes(id: string, data?: Listing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Listing>>;
    public listingPrototypePatchAttributes(id: string, data?: Listing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Listing>>;
    public listingPrototypePatchAttributes(id: string, data?: Listing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypePatchAttributes.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<Listing>(`${this.basePath}/listings/${encodeURIComponent(String(id))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the clients relation to an item by id.
     * 
     * @param id Listing id
     * @param fk Foreign key for clients
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeUnlinkClients(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingPrototypeUnlinkClients(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingPrototypeUnlinkClients(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingPrototypeUnlinkClients(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeUnlinkClients.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeUnlinkClients.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/clients/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the likedBy relation to an item by id.
     * 
     * @param id Listing id
     * @param fk Foreign key for likedBy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeUnlinkLikedBy(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingPrototypeUnlinkLikedBy(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingPrototypeUnlinkLikedBy(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingPrototypeUnlinkLikedBy(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeUnlinkLikedBy.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeUnlinkLikedBy.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/likedBy/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the members relation to an item by id.
     * 
     * @param id Listing id
     * @param fk Foreign key for members
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeUnlinkMembers(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingPrototypeUnlinkMembers(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingPrototypeUnlinkMembers(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingPrototypeUnlinkMembers(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeUnlinkMembers.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeUnlinkMembers.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/members/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the visitedByMembers relation to an item by id.
     * 
     * @param id Listing id
     * @param fk Foreign key for visitedByMembers
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeUnlinkVisitedByMembers(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingPrototypeUnlinkVisitedByMembers(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingPrototypeUnlinkVisitedByMembers(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingPrototypeUnlinkVisitedByMembers(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeUnlinkVisitedByMembers.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeUnlinkVisitedByMembers.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/visitedByMembers/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for analytics.
     * 
     * @param id Listing id
     * @param fk Foreign key for analytics
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeUpdateByIdAnalytics(id: string, fk: string, data?: Analytics, observe?: 'body', reportProgress?: boolean): Observable<Analytics>;
    public listingPrototypeUpdateByIdAnalytics(id: string, fk: string, data?: Analytics, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Analytics>>;
    public listingPrototypeUpdateByIdAnalytics(id: string, fk: string, data?: Analytics, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Analytics>>;
    public listingPrototypeUpdateByIdAnalytics(id: string, fk: string, data?: Analytics, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeUpdateByIdAnalytics.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeUpdateByIdAnalytics.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Analytics>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/analytics/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for clients.
     * 
     * @param id Listing id
     * @param fk Foreign key for clients
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeUpdateByIdClients(id: string, fk: string, data?: Client, observe?: 'body', reportProgress?: boolean): Observable<Client>;
    public listingPrototypeUpdateByIdClients(id: string, fk: string, data?: Client, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Client>>;
    public listingPrototypeUpdateByIdClients(id: string, fk: string, data?: Client, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Client>>;
    public listingPrototypeUpdateByIdClients(id: string, fk: string, data?: Client, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeUpdateByIdClients.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeUpdateByIdClients.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Client>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/clients/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for clientsWithType.
     * 
     * @param id Listing id
     * @param fk Foreign key for clientsWithType
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeUpdateByIdClientsWithType(id: string, fk: string, data?: ListingClient, observe?: 'body', reportProgress?: boolean): Observable<ListingClient>;
    public listingPrototypeUpdateByIdClientsWithType(id: string, fk: string, data?: ListingClient, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListingClient>>;
    public listingPrototypeUpdateByIdClientsWithType(id: string, fk: string, data?: ListingClient, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListingClient>>;
    public listingPrototypeUpdateByIdClientsWithType(id: string, fk: string, data?: ListingClient, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeUpdateByIdClientsWithType.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeUpdateByIdClientsWithType.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ListingClient>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/clientsWithType/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for contactRequests.
     * 
     * @param id Listing id
     * @param fk Foreign key for contactRequests
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeUpdateByIdContactRequests(id: string, fk: string, data?: ContactRequest, observe?: 'body', reportProgress?: boolean): Observable<ContactRequest>;
    public listingPrototypeUpdateByIdContactRequests(id: string, fk: string, data?: ContactRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContactRequest>>;
    public listingPrototypeUpdateByIdContactRequests(id: string, fk: string, data?: ContactRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContactRequest>>;
    public listingPrototypeUpdateByIdContactRequests(id: string, fk: string, data?: ContactRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeUpdateByIdContactRequests.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeUpdateByIdContactRequests.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ContactRequest>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/contactRequests/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for historyPayments.
     * 
     * @param id Listing id
     * @param fk Foreign key for historyPayments
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeUpdateByIdHistoryPayments(id: string, fk: string, data?: HistoryPayment, observe?: 'body', reportProgress?: boolean): Observable<HistoryPayment>;
    public listingPrototypeUpdateByIdHistoryPayments(id: string, fk: string, data?: HistoryPayment, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HistoryPayment>>;
    public listingPrototypeUpdateByIdHistoryPayments(id: string, fk: string, data?: HistoryPayment, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HistoryPayment>>;
    public listingPrototypeUpdateByIdHistoryPayments(id: string, fk: string, data?: HistoryPayment, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeUpdateByIdHistoryPayments.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeUpdateByIdHistoryPayments.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<HistoryPayment>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/historyPayments/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for likedBy.
     * 
     * @param id Listing id
     * @param fk Foreign key for likedBy
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeUpdateByIdLikedBy(id: string, fk: string, data?: Member, observe?: 'body', reportProgress?: boolean): Observable<Member>;
    public listingPrototypeUpdateByIdLikedBy(id: string, fk: string, data?: Member, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Member>>;
    public listingPrototypeUpdateByIdLikedBy(id: string, fk: string, data?: Member, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Member>>;
    public listingPrototypeUpdateByIdLikedBy(id: string, fk: string, data?: Member, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeUpdateByIdLikedBy.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeUpdateByIdLikedBy.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Member>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/likedBy/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for members.
     * 
     * @param id Listing id
     * @param fk Foreign key for members
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeUpdateByIdMembers(id: string, fk: string, data?: Member, observe?: 'body', reportProgress?: boolean): Observable<Member>;
    public listingPrototypeUpdateByIdMembers(id: string, fk: string, data?: Member, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Member>>;
    public listingPrototypeUpdateByIdMembers(id: string, fk: string, data?: Member, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Member>>;
    public listingPrototypeUpdateByIdMembers(id: string, fk: string, data?: Member, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeUpdateByIdMembers.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeUpdateByIdMembers.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Member>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/members/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for mlsRequests.
     * 
     * @param id Listing id
     * @param fk Foreign key for mlsRequests
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeUpdateByIdMlsRequests(id: string, fk: string, data?: MlsRequest, observe?: 'body', reportProgress?: boolean): Observable<MlsRequest>;
    public listingPrototypeUpdateByIdMlsRequests(id: string, fk: string, data?: MlsRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MlsRequest>>;
    public listingPrototypeUpdateByIdMlsRequests(id: string, fk: string, data?: MlsRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MlsRequest>>;
    public listingPrototypeUpdateByIdMlsRequests(id: string, fk: string, data?: MlsRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeUpdateByIdMlsRequests.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeUpdateByIdMlsRequests.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<MlsRequest>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/mlsRequests/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for notes.
     * 
     * @param id Listing id
     * @param fk Foreign key for notes
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeUpdateByIdNotes(id: string, fk: string, data?: Note, observe?: 'body', reportProgress?: boolean): Observable<Note>;
    public listingPrototypeUpdateByIdNotes(id: string, fk: string, data?: Note, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Note>>;
    public listingPrototypeUpdateByIdNotes(id: string, fk: string, data?: Note, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Note>>;
    public listingPrototypeUpdateByIdNotes(id: string, fk: string, data?: Note, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeUpdateByIdNotes.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeUpdateByIdNotes.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Note>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/notes/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for reports.
     * 
     * @param id Listing id
     * @param fk Foreign key for reports
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeUpdateByIdReports(id: string, fk: string, data?: Report, observe?: 'body', reportProgress?: boolean): Observable<Report>;
    public listingPrototypeUpdateByIdReports(id: string, fk: string, data?: Report, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Report>>;
    public listingPrototypeUpdateByIdReports(id: string, fk: string, data?: Report, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Report>>;
    public listingPrototypeUpdateByIdReports(id: string, fk: string, data?: Report, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeUpdateByIdReports.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeUpdateByIdReports.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Report>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/reports/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for reviews.
     * 
     * @param id Listing id
     * @param fk Foreign key for reviews
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeUpdateByIdReviews(id: string, fk: string, data?: Review, observe?: 'body', reportProgress?: boolean): Observable<Review>;
    public listingPrototypeUpdateByIdReviews(id: string, fk: string, data?: Review, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Review>>;
    public listingPrototypeUpdateByIdReviews(id: string, fk: string, data?: Review, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Review>>;
    public listingPrototypeUpdateByIdReviews(id: string, fk: string, data?: Review, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeUpdateByIdReviews.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeUpdateByIdReviews.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Review>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/reviews/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for visitedByMembers.
     * 
     * @param id Listing id
     * @param fk Foreign key for visitedByMembers
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingPrototypeUpdateByIdVisitedByMembers(id: string, fk: string, data?: Member, observe?: 'body', reportProgress?: boolean): Observable<Member>;
    public listingPrototypeUpdateByIdVisitedByMembers(id: string, fk: string, data?: Member, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Member>>;
    public listingPrototypeUpdateByIdVisitedByMembers(id: string, fk: string, data?: Member, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Member>>;
    public listingPrototypeUpdateByIdVisitedByMembers(id: string, fk: string, data?: Member, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingPrototypeUpdateByIdVisitedByMembers.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling listingPrototypeUpdateByIdVisitedByMembers.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Member>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/visitedByMembers/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingRemoveExclusivity(id: string, data?: Listing, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingRemoveExclusivity(id: string, data?: Listing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingRemoveExclusivity(id: string, data?: Listing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingRemoveExclusivity(id: string, data?: Listing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingRemoveExclusivity.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/removeExclusivity`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingRemoveExclusivityOnWhuis(id: string, data: Listing, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingRemoveExclusivityOnWhuis(id: string, data: Listing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingRemoveExclusivityOnWhuis(id: string, data: Listing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingRemoveExclusivityOnWhuis(id: string, data: Listing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingRemoveExclusivityOnWhuis.');
        }

        if (data === null || data === undefined) {
            throw new Error('Required parameter data was null or undefined when calling listingRemoveExclusivityOnWhuis.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/removeExclusivityOnWhuis`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingRenewOnlineDate(id: string, observe?: 'body', reportProgress?: boolean): Observable<Listing>;
    public listingRenewOnlineDate(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Listing>>;
    public listingRenewOnlineDate(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Listing>>;
    public listingRenewOnlineDate(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingRenewOnlineDate.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.post<Listing>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/renewOnlineDate`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace attributes for a model instance and persist it into the data source.
     * 
     * @param id Model id
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingReplaceByIdPostListingsidReplace(id: string, data?: Listing, observe?: 'body', reportProgress?: boolean): Observable<Listing>;
    public listingReplaceByIdPostListingsidReplace(id: string, data?: Listing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Listing>>;
    public listingReplaceByIdPostListingsidReplace(id: string, data?: Listing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Listing>>;
    public listingReplaceByIdPostListingsidReplace(id: string, data?: Listing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingReplaceByIdPostListingsidReplace.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Listing>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/replace`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace attributes for a model instance and persist it into the data source.
     * 
     * @param id Model id
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingReplaceByIdPutListingsid(id: string, data?: Listing, observe?: 'body', reportProgress?: boolean): Observable<Listing>;
    public listingReplaceByIdPutListingsid(id: string, data?: Listing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Listing>>;
    public listingReplaceByIdPutListingsid(id: string, data?: Listing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Listing>>;
    public listingReplaceByIdPutListingsid(id: string, data?: Listing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingReplaceByIdPutListingsid.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Listing>(`${this.basePath}/listings/${encodeURIComponent(String(id))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace an existing model instance or insert a new one into the data source.
     * 
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingReplaceOrCreatePostListingsReplaceOrCreate(data?: Listing, observe?: 'body', reportProgress?: boolean): Observable<Listing>;
    public listingReplaceOrCreatePostListingsReplaceOrCreate(data?: Listing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Listing>>;
    public listingReplaceOrCreatePostListingsReplaceOrCreate(data?: Listing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Listing>>;
    public listingReplaceOrCreatePostListingsReplaceOrCreate(data?: Listing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Listing>(`${this.basePath}/listings/replaceOrCreate`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace an existing model instance or insert a new one into the data source.
     * 
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingReplaceOrCreatePutListings(data?: Listing, observe?: 'body', reportProgress?: boolean): Observable<Listing>;
    public listingReplaceOrCreatePutListings(data?: Listing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Listing>>;
    public listingReplaceOrCreatePutListings(data?: Listing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Listing>>;
    public listingReplaceOrCreatePutListings(data?: Listing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Listing>(`${this.basePath}/listings`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param page 
     * @param limit 
     * @param regione 
     * @param provincia 
     * @param comune 
     * @param zona 
     * @param coords 
     * @param radius 
     * @param filters 
     * @param frontEndObject 
     * @param order 
     * @param isSearchMapQuery 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingSearch(page?: number, limit?: number, regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, filters?: string, frontEndObject?: string, order?: string, isSearchMapQuery?: boolean, observe?: 'body', reportProgress?: boolean): Observable<ListingSearch>;
    public listingSearch(page?: number, limit?: number, regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, filters?: string, frontEndObject?: string, order?: string, isSearchMapQuery?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListingSearch>>;
    public listingSearch(page?: number, limit?: number, regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, filters?: string, frontEndObject?: string, order?: string, isSearchMapQuery?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListingSearch>>;
    public listingSearch(page?: number, limit?: number, regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, filters?: string, frontEndObject?: string, order?: string, isSearchMapQuery?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {













        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (regione !== undefined && regione !== null) {
            queryParameters = queryParameters.set('regione', <any>regione);
        }
        if (provincia !== undefined && provincia !== null) {
            queryParameters = queryParameters.set('provincia', <any>provincia);
        }
        if (comune !== undefined && comune !== null) {
            queryParameters = queryParameters.set('comune', <any>comune);
        }
        if (zona !== undefined && zona !== null) {
            queryParameters = queryParameters.set('zona', <any>zona);
        }
        if (coords !== undefined && coords !== null) {
            queryParameters = queryParameters.set('coords', <any>coords);
        }
        if (radius !== undefined && radius !== null) {
            queryParameters = queryParameters.set('radius', <any>radius);
        }
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('filters', <any>filters);
        }
        if (frontEndObject !== undefined && frontEndObject !== null) {
            queryParameters = queryParameters.set('frontEndObject', <any>frontEndObject);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (isSearchMapQuery !== undefined && isSearchMapQuery !== null) {
            queryParameters = queryParameters.set('isSearchMapQuery', <any>isSearchMapQuery);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<ListingSearch>(`${this.basePath}/listings/search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param query 
     * @param skip 
     * @param limit 
     * @param order 
     * @param where 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingSearchByAddressAndRefCode(query?: string, skip?: number, limit?: number, order?: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Listing>>;
    public listingSearchByAddressAndRefCode(query?: string, skip?: number, limit?: number, order?: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Listing>>>;
    public listingSearchByAddressAndRefCode(query?: string, skip?: number, limit?: number, order?: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Listing>>>;
    public listingSearchByAddressAndRefCode(query?: string, skip?: number, limit?: number, order?: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (skip !== undefined && skip !== null) {
            queryParameters = queryParameters.set('skip', <any>skip);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Listing>>(`${this.basePath}/listings/searchByAddressAndRefCode`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param page 
     * @param limit 
     * @param regione 
     * @param provincia 
     * @param comune 
     * @param zona 
     * @param coords 
     * @param radius 
     * @param filters 
     * @param frontEndObject 
     * @param order 
     * @param isSearchMapQuery 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingSearchForMap(page?: number, limit?: number, regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, filters?: string, frontEndObject?: string, order?: string, isSearchMapQuery?: boolean, observe?: 'body', reportProgress?: boolean): Observable<ListingSearch>;
    public listingSearchForMap(page?: number, limit?: number, regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, filters?: string, frontEndObject?: string, order?: string, isSearchMapQuery?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListingSearch>>;
    public listingSearchForMap(page?: number, limit?: number, regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, filters?: string, frontEndObject?: string, order?: string, isSearchMapQuery?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListingSearch>>;
    public listingSearchForMap(page?: number, limit?: number, regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, filters?: string, frontEndObject?: string, order?: string, isSearchMapQuery?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {













        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (regione !== undefined && regione !== null) {
            queryParameters = queryParameters.set('regione', <any>regione);
        }
        if (provincia !== undefined && provincia !== null) {
            queryParameters = queryParameters.set('provincia', <any>provincia);
        }
        if (comune !== undefined && comune !== null) {
            queryParameters = queryParameters.set('comune', <any>comune);
        }
        if (zona !== undefined && zona !== null) {
            queryParameters = queryParameters.set('zona', <any>zona);
        }
        if (coords !== undefined && coords !== null) {
            queryParameters = queryParameters.set('coords', <any>coords);
        }
        if (radius !== undefined && radius !== null) {
            queryParameters = queryParameters.set('radius', <any>radius);
        }
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('filters', <any>filters);
        }
        if (frontEndObject !== undefined && frontEndObject !== null) {
            queryParameters = queryParameters.set('frontEndObject', <any>frontEndObject);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (isSearchMapQuery !== undefined && isSearchMapQuery !== null) {
            queryParameters = queryParameters.set('isSearchMapQuery', <any>isSearchMapQuery);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<ListingSearch>(`${this.basePath}/listings/searchForMap`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param page 
     * @param limit 
     * @param regione 
     * @param provincia 
     * @param comune 
     * @param zona 
     * @param coords 
     * @param radius 
     * @param filters 
     * @param frontEndObject 
     * @param order 
     * @param isSearchMapQuery 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingSearchForMapMls(page?: number, limit?: number, regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, filters?: string, frontEndObject?: string, order?: string, isSearchMapQuery?: boolean, observe?: 'body', reportProgress?: boolean): Observable<ListingSearch>;
    public listingSearchForMapMls(page?: number, limit?: number, regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, filters?: string, frontEndObject?: string, order?: string, isSearchMapQuery?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListingSearch>>;
    public listingSearchForMapMls(page?: number, limit?: number, regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, filters?: string, frontEndObject?: string, order?: string, isSearchMapQuery?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListingSearch>>;
    public listingSearchForMapMls(page?: number, limit?: number, regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, filters?: string, frontEndObject?: string, order?: string, isSearchMapQuery?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {













        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (regione !== undefined && regione !== null) {
            queryParameters = queryParameters.set('regione', <any>regione);
        }
        if (provincia !== undefined && provincia !== null) {
            queryParameters = queryParameters.set('provincia', <any>provincia);
        }
        if (comune !== undefined && comune !== null) {
            queryParameters = queryParameters.set('comune', <any>comune);
        }
        if (zona !== undefined && zona !== null) {
            queryParameters = queryParameters.set('zona', <any>zona);
        }
        if (coords !== undefined && coords !== null) {
            queryParameters = queryParameters.set('coords', <any>coords);
        }
        if (radius !== undefined && radius !== null) {
            queryParameters = queryParameters.set('radius', <any>radius);
        }
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('filters', <any>filters);
        }
        if (frontEndObject !== undefined && frontEndObject !== null) {
            queryParameters = queryParameters.set('frontEndObject', <any>frontEndObject);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (isSearchMapQuery !== undefined && isSearchMapQuery !== null) {
            queryParameters = queryParameters.set('isSearchMapQuery', <any>isSearchMapQuery);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<ListingSearch>(`${this.basePath}/listings/searchForMapMls`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param page 
     * @param limit 
     * @param regione 
     * @param provincia 
     * @param comune 
     * @param zona 
     * @param coords 
     * @param radius 
     * @param filters 
     * @param order 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingSearchGestionaleMls(page?: number, limit?: number, regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, filters?: string, order?: string, observe?: 'body', reportProgress?: boolean): Observable<ListingSearch>;
    public listingSearchGestionaleMls(page?: number, limit?: number, regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, filters?: string, order?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListingSearch>>;
    public listingSearchGestionaleMls(page?: number, limit?: number, regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, filters?: string, order?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListingSearch>>;
    public listingSearchGestionaleMls(page?: number, limit?: number, regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, filters?: string, order?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {











        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (regione !== undefined && regione !== null) {
            queryParameters = queryParameters.set('regione', <any>regione);
        }
        if (provincia !== undefined && provincia !== null) {
            queryParameters = queryParameters.set('provincia', <any>provincia);
        }
        if (comune !== undefined && comune !== null) {
            queryParameters = queryParameters.set('comune', <any>comune);
        }
        if (zona !== undefined && zona !== null) {
            queryParameters = queryParameters.set('zona', <any>zona);
        }
        if (coords !== undefined && coords !== null) {
            queryParameters = queryParameters.set('coords', <any>coords);
        }
        if (radius !== undefined && radius !== null) {
            queryParameters = queryParameters.set('radius', <any>radius);
        }
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('filters', <any>filters);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<ListingSearch>(`${this.basePath}/listings/searchGestionaleMls`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param page 
     * @param limit 
     * @param regione 
     * @param provincia 
     * @param comune 
     * @param zona 
     * @param coords 
     * @param radius 
     * @param filters 
     * @param order 
     * @param only_gestionale 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingSearchMls(page?: number, limit?: number, regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, filters?: string, order?: string, only_gestionale?: boolean, observe?: 'body', reportProgress?: boolean): Observable<ListingSearch>;
    public listingSearchMls(page?: number, limit?: number, regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, filters?: string, order?: string, only_gestionale?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListingSearch>>;
    public listingSearchMls(page?: number, limit?: number, regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, filters?: string, order?: string, only_gestionale?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListingSearch>>;
    public listingSearchMls(page?: number, limit?: number, regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, filters?: string, order?: string, only_gestionale?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {












        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (regione !== undefined && regione !== null) {
            queryParameters = queryParameters.set('regione', <any>regione);
        }
        if (provincia !== undefined && provincia !== null) {
            queryParameters = queryParameters.set('provincia', <any>provincia);
        }
        if (comune !== undefined && comune !== null) {
            queryParameters = queryParameters.set('comune', <any>comune);
        }
        if (zona !== undefined && zona !== null) {
            queryParameters = queryParameters.set('zona', <any>zona);
        }
        if (coords !== undefined && coords !== null) {
            queryParameters = queryParameters.set('coords', <any>coords);
        }
        if (radius !== undefined && radius !== null) {
            queryParameters = queryParameters.set('radius', <any>radius);
        }
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('filters', <any>filters);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (only_gestionale !== undefined && only_gestionale !== null) {
            queryParameters = queryParameters.set('only_gestionale', <any>only_gestionale);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<ListingSearch>(`${this.basePath}/listings/searchMls`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param page 
     * @param limit 
     * @param regione 
     * @param provincia 
     * @param comune 
     * @param zona 
     * @param coords 
     * @param radius 
     * @param filters 
     * @param frontEndObject 
     * @param order 
     * @param isSearchMapQuery 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingSearchV2(page?: number, limit?: number, regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, filters?: string, frontEndObject?: string, order?: string, isSearchMapQuery?: boolean, observe?: 'body', reportProgress?: boolean): Observable<ListingSearch>;
    public listingSearchV2(page?: number, limit?: number, regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, filters?: string, frontEndObject?: string, order?: string, isSearchMapQuery?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListingSearch>>;
    public listingSearchV2(page?: number, limit?: number, regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, filters?: string, frontEndObject?: string, order?: string, isSearchMapQuery?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListingSearch>>;
    public listingSearchV2(page?: number, limit?: number, regione?: string, provincia?: string, comune?: string, zona?: string, coords?: string, radius?: number, filters?: string, frontEndObject?: string, order?: string, isSearchMapQuery?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {













        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (regione !== undefined && regione !== null) {
            queryParameters = queryParameters.set('regione', <any>regione);
        }
        if (provincia !== undefined && provincia !== null) {
            queryParameters = queryParameters.set('provincia', <any>provincia);
        }
        if (comune !== undefined && comune !== null) {
            queryParameters = queryParameters.set('comune', <any>comune);
        }
        if (zona !== undefined && zona !== null) {
            queryParameters = queryParameters.set('zona', <any>zona);
        }
        if (coords !== undefined && coords !== null) {
            queryParameters = queryParameters.set('coords', <any>coords);
        }
        if (radius !== undefined && radius !== null) {
            queryParameters = queryParameters.set('radius', <any>radius);
        }
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('filters', <any>filters);
        }
        if (frontEndObject !== undefined && frontEndObject !== null) {
            queryParameters = queryParameters.set('frontEndObject', <any>frontEndObject);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (isSearchMapQuery !== undefined && isSearchMapQuery !== null) {
            queryParameters = queryParameters.set('isSearchMapQuery', <any>isSearchMapQuery);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<ListingSearch>(`${this.basePath}/listings/v2/search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingUnlinkListing(id: string, data: Listing, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingUnlinkListing(id: string, data: Listing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingUnlinkListing(id: string, data: Listing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingUnlinkListing(id: string, data: Listing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingUnlinkListing.');
        }

        if (data === null || data === undefined) {
            throw new Error('Required parameter data was null or undefined when calling listingUnlinkListing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/unlinkListing`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update instances of the model matched by {{where}} from the data source.
     * 
     * @param where Criteria to match model instances
     * @param data An object of model property name/value pairs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingUpdateAll(where?: string, data?: Listing, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2002>;
    public listingUpdateAll(where?: string, data?: Listing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2002>>;
    public listingUpdateAll(where?: string, data?: Listing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2002>>;
    public listingUpdateAll(where?: string, data?: Listing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse2002>(`${this.basePath}/listings/update`,
            data,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingUploadFile(id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Attachment>>;
    public listingUploadFile(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Attachment>>>;
    public listingUploadFile(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Attachment>>>;
    public listingUploadFile(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listingUploadFile.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.post<Array<Attachment>>(`${this.basePath}/listings/${encodeURIComponent(String(id))}/uploadFile`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an existing model instance or insert a new one into the data source based on the where criteria.
     * 
     * @param where Criteria to match model instances
     * @param data An object of model property name/value pairs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingUpsertWithWhere(where?: string, data?: Listing, observe?: 'body', reportProgress?: boolean): Observable<Listing>;
    public listingUpsertWithWhere(where?: string, data?: Listing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Listing>>;
    public listingUpsertWithWhere(where?: string, data?: Listing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Listing>>;
    public listingUpsertWithWhere(where?: string, data?: Listing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Listing>(`${this.basePath}/listings/upsertWithWhere`,
            data,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

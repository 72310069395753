
export default class ConstantsProvinceAndMunicipalities {
    public static LIST = [
        {
            name: 'Agrigento',
            municipalities: [
                'Agrigento',
                'Alessandria della Rocca',
                'Aragona',
                'Bivona',
                'Burgio',
                'Calamonaci',
                'Caltabellotta',
                'Camastra',
                'Cammarata',
                'Campobello di Licata',
                'Canicattì',
                'Casteltermini',
                'Castrofilippo',
                'Cattolica Eraclea',
                'Cianciana',
                'Comitini',
                'Favara',
                'Grotte',
                'Joppolo Giancaxio',
                'Lampedusa e Linosa',
                'Licata',
                'Lucca Sicula',
                'Menfi',
                'Montallegro',
                'Montevago',
                'Naro',
                'Palma di Montechiaro',
                'Porto Empedocle',
                'Racalmuto',
                'Raffadali',
                'Ravanusa',
                'Realmonte',
                'Ribera',
                'Sambuca di Sicilia',
                'San Biagio Platani',
                'San Giovanni Gemini',
                'Sant\'Angelo Muxaro',
                'Santa Elisabetta',
                'Santa Margherita di Belice',
                'Santo Stefano Quisquina',
                'Sciacca',
                'Siculiana',
                'Villafranca Sicula'
            ],
            sigla: 'AG'
        },
        {
            name: 'Alessandria',
            municipalities: [
                'Acqui Terme',
                'Albera Ligure',
                'Alessandria',
                'Alfiano Natta',
                'Alice Bel Colle',
                'Alluvioni Piovera',
                'Altavilla Monferrato',
                'Alzano Scrivia',
                'Arquata Scrivia',
                'Avolasca',
                'Balzola',
                'Basaluzzo',
                'Bassignana',
                'Belforte Monferrato',
                'Bergamasco',
                'Berzano di Tortona',
                'Bistagno',
                'Borghetto di Borbera',
                'Borgo San Martino',
                'Borgoratto Alessandrino',
                'Bosco Marengo',
                'Bosio',
                'Bozzole',
                'Brignano-Frascata',
                'Cabella Ligure',
                'Camagna Monferrato',
                'Camino',
                'Cantalupo Ligure',
                'Capriata d\'Orba',
                'Carbonara Scrivia',
                'Carentino',
                'Carezzano',
                'Carpeneto',
                'Carrega Ligure',
                'Carrosio',
                'Cartosio',
                'Casal Cermelli',
                'Casale Monferrato',
                'Casaleggio Boiro',
                'Casalnoceto',
                'Casasco',
                'Cassano Spinola',
                'Cassine',
                'Cassinelle',
                'Castellania Coppi',
                'Castellar Guidobono',
                'Castellazzo Bormida',
                'Castelletto d\'Erro',
                'Castelletto d\'Orba',
                'Castelletto Merli',
                'Castelletto Monferrato',
                'Castelnuovo Bormida',
                'Castelnuovo Scrivia',
                'Castelspina',
                'Cavatore',
                'Cella Monte',
                'Cereseto',
                'Cerreto Grue',
                'Cerrina Monferrato',
                'Coniolo',
                'Conzano',
                'Costa Vescovato',
                'Cremolino',
                'Denice',
                'Dernice',
                'Fabbrica Curone',
                'Felizzano',
                'Fraconalto',
                'Francavilla Bisio',
                'Frascaro',
                'Frassinello Monferrato',
                'Frassineto Po',
                'Fresonara',
                'Frugarolo',
                'Fubine Monferrato',
                'Gabiano',
                'Gamalero',
                'Garbagna',
                'Gavi',
                'Giarole',
                'Gremiasco',
                'Grognardo',
                'Grondona',
                'Guazzora',
                'Isola Sant\'Antonio',
                'Lerma',
                'Lu e Cuccaro Monferrato',
                'Malvicino',
                'Masio',
                'Melazzo',
                'Merana',
                'Mirabello Monferrato',
                'Molare',
                'Molino dei Torti',
                'Mombello Monferrato',
                'Momperone',
                'Moncestino',
                'Mongiardino Ligure',
                'Monleale',
                'Montacuto',
                'Montaldeo',
                'Montaldo Bormida',
                'Montecastello',
                'Montechiaro d\'Acqui',
                'Montegioco',
                'Montemarzino',
                'Morano sul Po',
                'Morbello',
                'Mornese',
                'Morsasco',
                'Murisengo',
                'Novi Ligure',
                'Occimiano',
                'Odalengo Grande',
                'Odalengo Piccolo',
                'Olivola',
                'Orsara Bormida',
                'Ottiglio',
                'Ovada',
                'Oviglio',
                'Ozzano Monferrato',
                'Paderna',
                'Pareto',
                'Parodi Ligure',
                'Pasturana',
                'Pecetto di Valenza',
                'Pietra Marazzi',
                'Pomaro Monferrato',
                'Pontecurone',
                'Pontestura',
                'Ponti',
                'Ponzano Monferrato',
                'Ponzone',
                'Pozzol Groppo',
                'Pozzolo Formigaro',
                'Prasco',
                'Predosa',
                'Quargnento',
                'Quattordio',
                'Ricaldone',
                'Rivalta Bormida',
                'Rivarone',
                'Rocca Grimalda',
                'Roccaforte Ligure',
                'Rocchetta Ligure',
                'Rosignano Monferrato',
                'Sala Monferrato',
                'Sale',
                'San Cristoforo',
                'San Giorgio Monferrato',
                'San Salvatore Monferrato',
                'San Sebastiano Curone',
                'Sant\'Agata Fossili',
                'Sardigliano',
                'Sarezzano',
                'Serralunga di Crea',
                'Serravalle Scrivia',
                'Sezzadio',
                'Silvano d\'Orba',
                'Solero',
                'Solonghello',
                'Spigno Monferrato',
                'Spineto Scrivia',
                'Stazzano',
                'Strevi',
                'Tagliolo Monferrato',
                'Tassarolo',
                'Terruggia',
                'Terzo',
                'Ticineto',
                'Tortona',
                'Treville',
                'Trisobbio',
                'Valenza',
                'Valmacca',
                'Vignale Monferrato',
                'Vignole Borbera',
                'Viguzzolo',
                'Villadeati',
                'Villalvernia',
                'Villamiroglio',
                'Villanova Monferrato',
                'Villaromagnano',
                'Visone',
                'Volpedo',
                'Volpeglino',
                'Voltaggio'
            ],
            sigla: 'AL'
        },
        {
            name: 'Ancona',
            municipalities: [
                'Agugliano',
                'Ancona',
                'Arcevia',
                'Barbara',
                'Belvedere Ostrense',
                'Camerano',
                'Camerata Picena',
                'Castelbellino',
                'Castelfidardo',
                'Castelleone di Suasa',
                'Castelplanio',
                'Cerreto d\'Esi',
                'Chiaravalle',
                'Corinaldo',
                'Cupramontana',
                'Fabriano',
                'Falconara Marittima',
                'Filottrano',
                'Genga',
                'Jesi',
                'Loreto',
                'Maiolati Spontini',
                'Mergo',
                'Monsano',
                'Monte Roberto',
                'Monte San Vito',
                'Montecarotto',
                'Montemarciano',
                'Morro d\'Alba',
                'Numana',
                'Offagna',
                'Osimo',
                'Ostra',
                'Ostra Vetere',
                'Poggio San Marcello',
                'Polverigi',
                'Rosora',
                'San Marcello',
                'San Paolo di Jesi',
                'Santa Maria Nuova',
                'Sassoferrato',
                'Senigallia',
                'Serra de\' Conti',
                'Serra San Quirico',
                'Sirolo',
                'Staffolo',
                'Trecastelli'
            ],
            sigla: 'AN'
        },
        {
            name: 'Arezzo',
            municipalities: [
                'Anghiari',
                'Arezzo',
                'Badia Tedalda',
                'Bibbiena',
                'Bucine',
                'Capolona',
                'Caprese Michelangelo',
                'Castel Focognano',
                'Castel San Niccolò',
                'Castelfranco Piandiscò',
                'Castiglion Fibocchi',
                'Castiglion Fiorentino',
                'Cavriglia',
                'Chitignano',
                'Chiusi della Verna',
                'Civitella in Val di Chiana',
                'Cortona',
                'Foiano della Chiana',
                'Laterina Pergine Valdarno',
                'Loro Ciuffenna',
                'Lucignano',
                'Marciano della Chiana',
                'Monte San Savino',
                'Montemignaio',
                'Monterchi',
                'Montevarchi',
                'Ortignano Raggiolo',
                'Pieve Santo Stefano',
                'Poppi',
                'Pratovecchio Stia',
                'San Giovanni Valdarno',
                'Sansepolcro',
                'Sestino',
                'Subbiano',
                'Talla',
                'Terranuova Bracciolini'
            ],
            sigla: 'AR'
        },
        {
            name: 'Ascoli Piceno',
            municipalities: [
                'Acquasanta Terme',
                'Acquaviva Picena',
                'Appignano del Tronto',
                'Arquata del Tronto',
                'Ascoli Piceno',
                'Carassai',
                'Castel di Lama',
                'Castignano',
                'Castorano',
                'Colli del Tronto',
                'Comunanza',
                'Cossignano',
                'Cupra Marittima',
                'Folignano',
                'Force',
                'Grottammare',
                'Maltignano',
                'Massignano',
                'Monsampolo del Tronto',
                'Montalto delle Marche',
                'Montedinove',
                'Montefiore dell\'Aso',
                'Montegallo',
                'Montemonaco',
                'Monteprandone',
                'Offida',
                'Palmiano',
                'Ripatransone',
                'Roccafluvione',
                'Rotella',
                'San Benedetto del Tronto',
                'Spinetoli',
                'Venarotta'
            ],
            sigla: 'AP'
        },
        {
            name: 'Asti',
            municipalities: [
                'Agliano Terme',
                'Albugnano',
                'Antignano',
                'Aramengo',
                'Asti',
                'Azzano d\'Asti',
                'Baldichieri d\'Asti',
                'Belveglio',
                'Berzano di San Pietro',
                'Bruno',
                'Bubbio',
                'Buttigliera d\'Asti',
                'Calamandrana',
                'Calliano',
                'Calosso',
                'Camerano Casasco',
                'Canelli',
                'Cantarana',
                'Capriglio',
                'Casorzo',
                'Cassinasco',
                'Castagnole delle Lanze',
                'Castagnole Monferrato',
                'Castel Boglione',
                'Castel Rocchero',
                'Castell\'Alfero',
                'Castellero',
                'Castelletto Molina',
                'Castello di Annone',
                'Castelnuovo Belbo',
                'Castelnuovo Calcea',
                'Castelnuovo Don Bosco',
                'Cellarengo',
                'Celle Enomondo',
                'Cerreto d\'Asti',
                'Cerro Tanaro',
                'Cessole',
                'Chiusano d\'Asti',
                'Cinaglio',
                'Cisterna d\'Asti',
                'Coazzolo',
                'Cocconato',
                'Corsione',
                'Cortandone',
                'Cortanze',
                'Cortazzone',
                'Cortiglione',
                'Cossombrato',
                'Costigliole d\'Asti',
                'Cunico',
                'Dusino San Michele',
                'Ferrere',
                'Fontanile',
                'Frinco',
                'Grana',
                'Grazzano Badoglio',
                'Incisa Scapaccino',
                'Isola d\'Asti',
                'Loazzolo',
                'Maranzana',
                'Maretto',
                'Moasca',
                'Mombaldone',
                'Mombaruzzo',
                'Mombercelli',
                'Monale',
                'Monastero Bormida',
                'Moncalvo',
                'Moncucco Torinese',
                'Mongardino',
                'Montabone',
                'Montafia',
                'Montaldo Scarampi',
                'Montechiaro d\'Asti',
                'Montegrosso d\'Asti',
                'Montemagno',
                'Montiglio Monferrato',
                'Moransengo',
                'Nizza Monferrato',
                'Olmo Gentile',
                'Passerano Marmorito',
                'Penango',
                'Piea',
                'Pino d\'Asti',
                'Piovà Massaia',
                'Portacomaro',
                'Quaranti',
                'Refrancore',
                'Revigliasco d\'Asti',
                'Roatto',
                'Robella',
                'Rocca d\'Arazzo',
                'Roccaverano',
                'Rocchetta Palafea',
                'Rocchetta Tanaro',
                'San Damiano d\'Asti',
                'San Giorgio Scarampi',
                'San Martino Alfieri',
                'San Marzano Oliveto',
                'San Paolo Solbrito',
                'Scurzolengo',
                'Serole',
                'Sessame',
                'Settime',
                'Soglio',
                'Tigliole',
                'Tonco',
                'Tonengo',
                'Vaglio Serra',
                'Valfenera',
                'Vesime',
                'Viale',
                'Viarigi',
                'Vigliano d\'Asti',
                'Villa San Secondo',
                'Villafranca d\'Asti',
                'Villanova d\'Asti',
                'Vinchio'
            ],
            sigla: 'AT'
        },
        {
            name: 'Avellino',
            municipalities: [
                'Aiello del Sabato',
                'Altavilla Irpina',
                'Andretta',
                'Aquilonia',
                'Ariano Irpino',
                'Atripalda',
                'Avella',
                'Avellino',
                'Bagnoli Irpino',
                'Baiano',
                'Bisaccia',
                'Bonito',
                'Cairano',
                'Calabritto',
                'Calitri',
                'Candida',
                'Caposele',
                'Capriglia Irpina',
                'Carife',
                'Casalbore',
                'Cassano Irpino',
                'Castel Baronia',
                'Castelfranci',
                'Castelvetere sul Calore',
                'Cervinara',
                'Cesinali',
                'Chianche',
                'Chiusano di San Domenico',
                'Contrada',
                'Conza della Campania',
                'Domicella',
                'Flumeri',
                'Fontanarosa',
                'Forino',
                'Frigento',
                'Gesualdo',
                'Greci',
                'Grottaminarda',
                'Grottolella',
                'Guardia Lombardi',
                'Lacedonia',
                'Lapio',
                'Lauro',
                'Lioni',
                'Luogosano',
                'Manocalzati',
                'Marzano di Nola',
                'Melito Irpino',
                'Mercogliano',
                'Mirabella Eclano',
                'Montaguto',
                'Montecalvo Irpino',
                'Montefalcione',
                'Monteforte Irpino',
                'Montefredane',
                'Montefusco',
                'Montella',
                'Montemarano',
                'Montemiletto',
                'Monteverde',
                'Montoro',
                'Morra De Sanctis',
                'Moschiano',
                'Mugnano del Cardinale',
                'Nusco',
                'Ospedaletto d\'Alpinolo',
                'Pago del Vallo di Lauro',
                'Parolise',
                'Paternopoli',
                'Petruro Irpino',
                'Pietradefusi',
                'Pietrastornina',
                'Prata di Principato Ultra',
                'Pratola Serra',
                'Quadrelle',
                'Quindici',
                'Rocca San Felice',
                'Roccabascerana',
                'Rotondi',
                'Salza Irpina',
                'San Mango sul Calore',
                'San Martino Valle Caudina',
                'San Michele di Serino',
                'San Nicola Baronia',
                'San Potito Ultra',
                'San Sossio Baronia',
                'Sant\'Andrea di Conza',
                'Sant\'Angelo a Scala',
                'Sant\'Angelo all\'Esca',
                'Sant\'Angelo dei Lombardi',
                'Santa Lucia di Serino',
                'Santa Paolina',
                'Santo Stefano del Sole',
                'Savignano Irpino',
                'Scampitella',
                'Senerchia',
                'Serino',
                'Sirignano',
                'Solofra',
                'Sorbo Serpico',
                'Sperone',
                'Sturno',
                'Summonte',
                'Taurano',
                'Taurasi',
                'Teora',
                'Torella dei Lombardi',
                'Torre Le Nocelle',
                'Torrioni',
                'Trevico',
                'Tufo',
                'Vallata',
                'Vallesaccarda',
                'Venticano',
                'Villamaina',
                'Villanova del Battista',
                'Volturara Irpina',
                'Zungoli'
            ],
            sigla: 'AV'
        },
        {
            name: 'Bari',
            municipalities: [
                'Acquaviva delle Fonti',
                'Adelfia',
                'Alberobello',
                'Altamura',
                'Bari',
                'Binetto',
                'Bitetto',
                'Bitonto',
                'Bitritto',
                'Capurso',
                'Casamassima',
                'Cassano delle Murge',
                'Castellana Grotte',
                'Cellamare',
                'Conversano',
                'Corato',
                'Gioia del Colle',
                'Giovinazzo',
                'Gravina in Puglia',
                'Grumo Appula',
                'Locorotondo',
                'Modugno',
                'Mola di Bari',
                'Molfetta',
                'Monopoli',
                'Noci',
                'Noicattaro',
                'Palo del Colle',
                'Poggiorsini',
                'Polignano a Mare',
                'Putignano',
                'Rutigliano',
                'Ruvo di Puglia',
                'Sammichele di Bari',
                'Sannicandro di Bari',
                'Santeramo in Colle',
                'Terlizzi',
                'Toritto',
                'Triggiano',
                'Turi',
                'Valenzano'
            ],
            sigla: 'BA'
        },
        {
            name: 'Barletta-Andria-Trani',
            municipalities: [
                'Andria',
                'Barletta',
                'Bisceglie',
                'Canosa di Puglia',
                'Margherita di Savoia',
                'Minervino Murge',
                'San Ferdinando di Puglia',
                'Spinazzola',
                'Trani',
                'Trinitapoli'
            ],
            sigla: 'BT'
        },
        {
            name: 'Belluno',
            municipalities: [
                'Agordo',
                'Alano di Piave',
                'Alleghe',
                'Alpago',
                'Arsiè',
                'Auronzo di Cadore',
                'Belluno',
                'Borca di Cadore',
                'Borgo Valbelluna',
                'Calalzo di Cadore',
                'Canale d\'Agordo',
                'Cencenighe Agordino',
                'Cesiomaggiore',
                'Chies d\'Alpago',
                'Cibiana di Cadore',
                'Colle Santa Lucia',
                'Comelico Superiore',
                'Cortina d\'Ampezzo',
                'Danta di Cadore',
                'Domegge di Cadore',
                'Falcade',
                'Feltre',
                'Fonzaso',
                'Gosaldo',
                'La Valle Agordina',
                'Lamon',
                'Limana',
                'Livinallongo del Col di Lana',
                'Longarone',
                'Lorenzago di Cadore',
                'Lozzo di Cadore',
                'Ospitale di Cadore',
                'Pedavena',
                'Perarolo di Cadore',
                'Pieve di Cadore',
                'Ponte nelle Alpi',
                'Quero Vas',
                'Rivamonte Agordino',
                'Rocca Pietore',
                'San Gregorio nelle Alpi',
                'San Nicolò di Comelico',
                'San Pietro di Cadore',
                'San Tomaso Agordino',
                'San Vito di Cadore',
                'Santa Giustina',
                'Santo Stefano di Cadore',
                'Sedico',
                'Selva di Cadore',
                'Seren del Grappa',
                'Sospirolo',
                'Soverzene',
                'Sovramonte',
                'Taibon Agordino',
                'Tambre',
                'Val di Zoldo',
                'Vallada Agordina',
                'Valle di Cadore',
                'Vigo di Cadore',
                'Vodo Cadore',
                'Voltago Agordino',
                'Zoppè di Cadore'
            ],
            sigla: 'BL'
        },
        {
            name: 'Benevento',
            municipalities: [
                'Airola',
                'Amorosi',
                'Apice',
                'Apollosa',
                'Arpaia',
                'Arpaise',
                'Baselice',
                'Benevento',
                'Bonea',
                'Bucciano',
                'Buonalbergo',
                'Calvi',
                'Campolattaro',
                'Campoli del Monte Taburno',
                'Casalduni',
                'Castelfranco in Miscano',
                'Castelpagano',
                'Castelpoto',
                'Castelvenere',
                'Castelvetere in Val Fortore',
                'Cautano',
                'Ceppaloni',
                'Cerreto Sannita',
                'Circello',
                'Colle Sannita',
                'Cusano Mutri',
                'Dugenta',
                'Durazzano',
                'Faicchio',
                'Foglianise',
                'Foiano di Val Fortore',
                'Forchia',
                'Fragneto l\'Abate',
                'Fragneto Monforte',
                'Frasso Telesino',
                'Ginestra degli Schiavoni',
                'Guardia Sanframondi',
                'Limatola',
                'Melizzano',
                'Moiano',
                'Molinara',
                'Montefalcone di Val Fortore',
                'Montesarchio',
                'Morcone',
                'Paduli',
                'Pago Veiano',
                'Pannarano',
                'Paolisi',
                'Paupisi',
                'Pesco Sannita',
                'Pietraroja',
                'Pietrelcina',
                'Ponte',
                'Pontelandolfo',
                'Puglianello',
                'Reino',
                'San Bartolomeo in Galdo',
                'San Giorgio del Sannio',
                'San Giorgio La Molara',
                'San Leucio del Sannio',
                'San Lorenzello',
                'San Lorenzo Maggiore',
                'San Lupo',
                'San Marco dei Cavoti',
                'San Martino Sannita',
                'San Nazzaro',
                'San Nicola Manfredi',
                'San Salvatore Telesino',
                'Sant\'Agata de\' Goti',
                'Sant\'Angelo a Cupolo',
                'Sant\'Arcangelo Trimonte',
                'Santa Croce del Sannio',
                'Sassinoro',
                'Solopaca',
                'Telese Terme',
                'Tocco Caudio',
                'Torrecuso',
                'Vitulano'
            ],
            sigla: 'BN'
        },
        {
            name: 'Bergamo',
            municipalities: [
                'Adrara San Martino',
                'Adrara San Rocco',
                'Albano Sant\'Alessandro',
                'Albino',
                'Algua',
                'Almè',
                'Almenno San Bartolomeo',
                'Almenno San Salvatore',
                'Alzano Lombardo',
                'Ambivere',
                'Antegnate',
                'Arcene',
                'Ardesio',
                'Arzago d\'Adda',
                'Averara',
                'Aviatico',
                'Azzano San Paolo',
                'Azzone',
                'Bagnatica',
                'Barbata',
                'Bariano',
                'Barzana',
                'Bedulita',
                'Berbenno',
                'Bergamo',
                'Berzo San Fermo',
                'Bianzano',
                'Blello',
                'Bolgare',
                'Boltiere',
                'Bonate Sopra',
                'Bonate Sotto',
                'Borgo di Terzo',
                'Bossico',
                'Bottanuco',
                'Bracca',
                'Branzi',
                'Brembate',
                'Brembate di Sopra',
                'Brignano Gera d\'Adda',
                'Brumano',
                'Brusaporto',
                'Calcinate',
                'Calcio',
                'Calusco d\'Adda',
                'Calvenzano',
                'Camerata Cornello',
                'Canonica d\'Adda',
                'Capizzone',
                'Capriate San Gervasio',
                'Caprino Bergamasco',
                'Caravaggio',
                'Carobbio degli Angeli',
                'Carona',
                'Carvico',
                'Casazza',
                'Casirate d\'Adda',
                'Casnigo',
                'Cassiglio',
                'Castel Rozzone',
                'Castelli Calepio',
                'Castione della Presolana',
                'Castro',
                'Cavernago',
                'Cazzano Sant\'Andrea',
                'Cenate Sopra',
                'Cenate Sotto',
                'Cene',
                'Cerete',
                'Chignolo d\'Isola',
                'Chiuduno',
                'Cisano Bergamasco',
                'Ciserano',
                'Cividate al Piano',
                'Clusone',
                'Colere',
                'Cologno al Serio',
                'Colzate',
                'Comun Nuovo',
                'Corna Imagna',
                'Cornalba',
                'Cortenuova',
                'Costa di Mezzate',
                'Costa Serina',
                'Costa Valle Imagna',
                'Costa Volpino',
                'Covo',
                'Credaro',
                'Curno',
                'Cusio',
                'Dalmine',
                'Dossena',
                'Endine Gaiano',
                'Entratico',
                'Fara Gera d\'Adda',
                'Fara Olivana con Sola',
                'Filago',
                'Fino del Monte',
                'Fiorano al Serio',
                'Fontanella',
                'Fonteno',
                'Foppolo',
                'Foresto Sparso',
                'Fornovo San Giovanni',
                'Fuipiano Valle Imagna',
                'Gandellino',
                'Gandino',
                'Gandosso',
                'Gaverina Terme',
                'Gazzaniga',
                'Ghisalba',
                'Gorlago',
                'Gorle',
                'Gorno',
                'Grassobbio',
                'Gromo',
                'Grone',
                'Grumello del Monte',
                'Isola di Fondra',
                'Isso',
                'Lallio',
                'Leffe',
                'Lenna',
                'Levate',
                'Locatello',
                'Lovere',
                'Lurano',
                'Luzzana',
                'Madone',
                'Mapello',
                'Martinengo',
                'Medolago',
                'Mezzoldo',
                'Misano di Gera d\'Adda',
                'Moio de\' Calvi',
                'Monasterolo del Castello',
                'Montello',
                'Morengo',
                'Mornico al Serio',
                'Mozzanica',
                'Mozzo',
                'Nembro',
                'Olmo al Brembo',
                'Oltre il Colle',
                'Oltressenda Alta',
                'Oneta',
                'Onore',
                'Orio al Serio',
                'Ornica',
                'Osio Sopra',
                'Osio Sotto',
                'Pagazzano',
                'Paladina',
                'Palazzago',
                'Palosco',
                'Parre',
                'Parzanica',
                'Pedrengo',
                'Peia',
                'Pianico',
                'Piario',
                'Piazza Brembana',
                'Piazzatorre',
                'Piazzolo',
                'Pognano',
                'Ponte Nossa',
                'Ponte San Pietro',
                'Ponteranica',
                'Pontida',
                'Pontirolo Nuovo',
                'Pradalunga',
                'Predore',
                'Premolo',
                'Presezzo',
                'Pumenengo',
                'Ranica',
                'Ranzanico',
                'Riva di Solto',
                'Rogno',
                'Romano di Lombardia',
                'Roncobello',
                'Roncola',
                'Rota d\'Imagna',
                'Rovetta',
                'San Giovanni Bianco',
                'San Paolo d\'Argon',
                'San Pellegrino Terme',
                'Sant\'Omobono Terme',
                'Santa Brigida',
                'Sarnico',
                'Scanzorosciate',
                'Schilpario',
                'Sedrina',
                'Selvino',
                'Seriate',
                'Serina',
                'Solto Collina',
                'Solza',
                'Songavazzo',
                'Sorisole',
                'Sotto il Monte Giovanni XXIII',
                'Sovere',
                'Spinone al Lago',
                'Spirano',
                'Stezzano',
                'Strozza',
                'Suisio',
                'Taleggio',
                'Tavernola Bergamasca',
                'Telgate',
                'Terno d\'Isola',
                'Torre Boldone',
                'Torre de\' Busi',
                'Torre de\' Roveri',
                'Torre Pallavicina',
                'Trescore Balneario',
                'Treviglio',
                'Treviolo',
                'Ubiale Clanezzo',
                'Urgnano',
                'Val Brembilla',
                'Valbondione',
                'Valbrembo',
                'Valgoglio',
                'Valleve',
                'Valnegra',
                'Valtorta',
                'Vedeseta',
                'Verdellino',
                'Verdello',
                'Vertova',
                'Viadanica',
                'Vigano San Martino',
                'Vigolo',
                'Villa d\'Adda',
                'Villa d\'Almè',
                'Villa d\'Ogna',
                'Villa di Serio',
                'Villongo',
                'Vilminore di Scalve',
                'Zandobbio',
                'Zanica',
                'Zogno'
            ],
            sigla: 'BG'
        },
        {
            name: 'Biella',
            municipalities: [
                'Ailoche',
                'Andorno Micca',
                'Benna',
                'Biella',
                'Bioglio',
                'Borriana',
                'Brusnengo',
                'Callabiana',
                'Camandona',
                'Camburzano',
                'Campiglia Cervo',
                'Candelo',
                'Caprile',
                'Casapinta',
                'Castelletto Cervo',
                'Cavaglià',
                'Cerrione',
                'Coggiola',
                'Cossato',
                'Crevacuore',
                'Curino',
                'Donato',
                'Dorzano',
                'Gaglianico',
                'Gifflenga',
                'Graglia',
                'Lessona',
                'Magnano',
                'Massazza',
                'Masserano',
                'Mezzana Mortigliengo',
                'Miagliano',
                'Mongrando',
                'Mottalciata',
                'Muzzano',
                'Netro',
                'Occhieppo Inferiore',
                'Occhieppo Superiore',
                'Pettinengo',
                'Piatto',
                'Piedicavallo',
                'Pollone',
                'Ponderano',
                'Portula',
                'Pralungo',
                'Pray',
                'Quaregna Cerreto',
                'Ronco Biellese',
                'Roppolo',
                'Rosazza',
                'Sagliano Micca',
                'Sala Biellese',
                'Salussola',
                'Sandigliano',
                'Sordevolo',
                'Sostegno',
                'Strona',
                'Tavigliano',
                'Ternengo',
                'Tollegno',
                'Torrazzo',
                'Valdengo',
                'Valdilana',
                'Vallanzengo',
                'Valle San Nicolao',
                'Veglio',
                'Verrone',
                'Vigliano Biellese',
                'Villa del Bosco',
                'Villanova Biellese',
                'Viverone',
                'Zimone',
                'Zubiena',
                'Zumaglia'
            ],
            sigla: 'BI'
        },
        {
            name: 'Bologna',
            municipalities: [
                'Alto Reno Terme',
                'Anzola dell\'Emilia',
                'Argelato',
                'Baricella',
                'Bentivoglio',
                'Bologna',
                'Borgo Tossignano',
                'Budrio',
                'Calderara di Reno',
                'Camugnano',
                'Casalecchio di Reno',
                'Casalfiumanese',
                'Castel d\'Aiano',
                'Castel del Rio',
                'Castel di Casio',
                'Castel Guelfo di Bologna',
                'Castel Maggiore',
                'Castel San Pietro Terme',
                'Castello d\'Argile',
                'Castenaso',
                'Castiglione dei Pepoli',
                'Crevalcore',
                'Dozza',
                'Fontanelice',
                'Gaggio Montano',
                'Galliera',
                'Granarolo dell\'Emilia',
                'Grizzana Morandi',
                'Imola',
                'Lizzano in Belvedere',
                'Loiano',
                'Malalbergo',
                'Marzabotto',
                'Medicina',
                'Minerbio',
                'Molinella',
                'Monghidoro',
                'Monte San Pietro',
                'Monterenzio',
                'Monzuno',
                'Mordano',
                'Ozzano dell\'Emilia',
                'Pianoro',
                'Pieve di Cento',
                'Sala Bolognese',
                'San Benedetto Val di Sambro',
                'San Giorgio di Piano',
                'San Giovanni in Persiceto',
                'San Lazzaro di Savena',
                'San Pietro in Casale',
                'Sant\'Agata Bolognese',
                'Sasso Marconi',
                'Valsamoggia',
                'Vergato',
                'Zola Predosa'
            ],
            sigla: 'BO'
        },
        {
            name: 'Bolzano/Bozen',
            municipalities: [
                'Aldino',
                'Andriano',
                'Anterivo',
                'Appiano sulla strada del vino',
                'Avelengo',
                'Badia',
                'Barbiano',
                'Bolzano',
                'Braies',
                'Brennero',
                'Bressanone',
                'Bronzolo',
                'Brunico',
                'Caines',
                'Caldaro sulla strada del vino',
                'Campo di Trens',
                'Campo Tures',
                'Castelbello-Ciardes',
                'Castelrotto',
                'Cermes',
                'Chienes',
                'Chiusa',
                'Cornedo all\'Isarco',
                'Cortaccia sulla strada del vino',
                'Cortina sulla strada del vino',
                'Corvara in Badia',
                'Curon Venosta',
                'Dobbiaco',
                'Egna',
                'Falzes',
                'Fiè allo Sciliar',
                'Fortezza',
                'Funes',
                'Gais',
                'Gargazzone',
                'Glorenza',
                'La Valle',
                'Laces',
                'Lagundo',
                'Laion',
                'Laives',
                'Lana',
                'Lasa',
                'Lauregno',
                'Luson',
                'Magrè sulla strada del vino',
                'Malles Venosta',
                'Marebbe',
                'Marlengo',
                'Martello',
                'Meltina',
                'Merano',
                'Monguelfo-Tesido',
                'Montagna',
                'Moso in Passiria',
                'Nalles',
                'Naturno',
                'Naz-Sciaves',
                'Nova Levante',
                'Nova Ponente',
                'Ora',
                'Ortisei',
                'Parcines',
                'Perca',
                'Plaus',
                'Ponte Gardena',
                'Postal',
                'Prato allo Stelvio',
                'Predoi',
                'Proves',
                'Racines',
                'Rasun-Anterselva',
                'Renon',
                'Rifiano',
                'Rio di Pusteria',
                'Rodengo',
                'Salorno',
                'San Candido',
                'San Genesio Atesino',
                'San Leonardo in Passiria',
                'San Lorenzo di Sebato',
                'San Martino in Badia',
                'San Martino in Passiria',
                'San Pancrazio',
                'Santa Cristina Valgardena',
                'Sarentino',
                'Scena',
                'Selva dei Molini',
                'Selva di Val Gardena',
                'Senale-San Felice',
                'Senales',
                'Sesto',
                'Silandro',
                'Sluderno',
                'Stelvio',
                'Terento',
                'Terlano',
                'Termeno sulla strada del vino',
                'Tesimo',
                'Tires',
                'Tirolo',
                'Trodena nel parco naturale',
                'Tubre',
                'Ultimo',
                'Vadena',
                'Val di Vizze',
                'Valdaora',
                'Valle Aurina',
                'Valle di Casies',
                'Vandoies',
                'Varna',
                'Velturno',
                'Verano',
                'Villabassa',
                'Villandro',
                'Vipiteno'
            ],
            sigla: 'BZ'
        },
        {
            name: 'Brescia',
            municipalities: [
                'Acquafredda',
                'Adro',
                'Agnosine',
                'Alfianello',
                'Anfo',
                'Angolo Terme',
                'Artogne',
                'Azzano Mella',
                'Bagnolo Mella',
                'Bagolino',
                'Barbariga',
                'Barghe',
                'Bassano Bresciano',
                'Bedizzole',
                'Berlingo',
                'Berzo Demo',
                'Berzo Inferiore',
                'Bienno',
                'Bione',
                'Borgo San Giacomo',
                'Borgosatollo',
                'Borno',
                'Botticino',
                'Bovegno',
                'Bovezzo',
                'Brandico',
                'Braone',
                'Breno',
                'Brescia',
                'Brione',
                'Caino',
                'Calcinato',
                'Calvagese della Riviera',
                'Calvisano',
                'Capo di Ponte',
                'Capovalle',
                'Capriano del Colle',
                'Capriolo',
                'Carpenedolo',
                'Castegnato',
                'Castel Mella',
                'Castelcovati',
                'Castenedolo',
                'Casto',
                'Castrezzato',
                'Cazzago San Martino',
                'Cedegolo',
                'Cellatica',
                'Cerveno',
                'Ceto',
                'Cevo',
                'Chiari',
                'Cigole',
                'Cimbergo',
                'Cividate Camuno',
                'Coccaglio',
                'Collebeato',
                'Collio',
                'Cologne',
                'Comezzano-Cizzago',
                'Concesio',
                'Corte Franca',
                'Corteno Golgi',
                'Corzano',
                'Darfo Boario Terme',
                'Dello',
                'Desenzano del Garda',
                'Edolo',
                'Erbusco',
                'Esine',
                'Fiesse',
                'Flero',
                'Gambara',
                'Gardone Riviera',
                'Gardone Val Trompia',
                'Gargnano',
                'Gavardo',
                'Ghedi',
                'Gianico',
                'Gottolengo',
                'Gussago',
                'Idro',
                'Incudine',
                'Irma',
                'Iseo',
                'Isorella',
                'Lavenone',
                'Leno',
                'Limone sul Garda',
                'Lodrino',
                'Lograto',
                'Lonato del Garda',
                'Longhena',
                'Losine',
                'Lozio',
                'Lumezzane',
                'Maclodio',
                'Magasa',
                'Mairano',
                'Malegno',
                'Malonno',
                'Manerba del Garda',
                'Manerbio',
                'Marcheno',
                'Marmentino',
                'Marone',
                'Mazzano',
                'Milzano',
                'Moniga del Garda',
                'Monno',
                'Monte Isola',
                'Monticelli Brusati',
                'Montichiari',
                'Montirone',
                'Mura',
                'Muscoline',
                'Nave',
                'Niardo',
                'Nuvolento',
                'Nuvolera',
                'Odolo',
                'Offlaga',
                'Ome',
                'Ono San Pietro',
                'Orzinuovi',
                'Orzivecchi',
                'Ospitaletto',
                'Ossimo',
                'Padenghe sul Garda',
                'Paderno Franciacorta',
                'Paisco Loveno',
                'Paitone',
                'Palazzolo sull\'Oglio',
                'Paratico',
                'Paspardo',
                'Passirano',
                'Pavone del Mella',
                'Pertica Alta',
                'Pertica Bassa',
                'Pezzaze',
                'Pian Camuno',
                'Piancogno',
                'Pisogne',
                'Polaveno',
                'Polpenazze del Garda',
                'Pompiano',
                'Poncarale',
                'Ponte di Legno',
                'Pontevico',
                'Pontoglio',
                'Pozzolengo',
                'Pralboino',
                'Preseglie',
                'Prevalle',
                'Provaglio d\'Iseo',
                'Provaglio Val Sabbia',
                'Puegnago del Garda',
                'Quinzano d\'Oglio',
                'Remedello',
                'Rezzato',
                'Roccafranca',
                'Rodengo Saiano',
                'Roè Volciano',
                'Roncadelle',
                'Rovato',
                'Rudiano',
                'Sabbio Chiese',
                'Sale Marasino',
                'Salò',
                'San Felice del Benaco',
                'San Gervasio Bresciano',
                'San Paolo',
                'San Zeno Naviglio',
                'Sarezzo',
                'Saviore dell\'Adamello',
                'Sellero',
                'Seniga',
                'Serle',
                'Sirmione',
                'Soiano del Lago',
                'Sonico',
                'Sulzano',
                'Tavernole sul Mella',
                'Temù',
                'Tignale',
                'Torbole Casaglia',
                'Toscolano-Maderno',
                'Travagliato',
                'Tremosine sul Garda',
                'Trenzano',
                'Treviso Bresciano',
                'Urago d\'Oglio',
                'Vallio Terme',
                'Valvestino',
                'Verolanuova',
                'Verolavecchia',
                'Vestone',
                'Vezza d\'Oglio',
                'Villa Carcina',
                'Villachiara',
                'Villanuova sul Clisi',
                'Vione',
                'Visano',
                'Vobarno',
                'Zone'
            ],
            sigla: 'BS'
        },
        {
            name: 'Brindisi',
            municipalities: [
                'Brindisi',
                'Carovigno',
                'Ceglie Messapica',
                'Cellino San Marco',
                'Cisternino',
                'Erchie',
                'Fasano',
                'Francavilla Fontana',
                'Latiano',
                'Mesagne',
                'Oria',
                'Ostuni',
                'San Donaci',
                'San Michele Salentino',
                'San Pancrazio Salentino',
                'San Pietro Vernotico',
                'San Vito dei Normanni',
                'Torchiarolo',
                'Torre Santa Susanna',
                'Villa Castelli'
            ],
            sigla: 'BR'
        },
        {
            name: 'Cagliari',
            municipalities: [
                'Assemini',
                'Cagliari',
                'Capoterra',
                'Decimomannu',
                'Elmas',
                'Maracalagonis',
                'Monserrato',
                'Pula',
                'Quartu Sant\'Elena',
                'Quartucciu',
                'Sarroch',
                'Selargius',
                'Sestu',
                'Settimo San Pietro',
                'Sinnai',
                'Uta',
                'Villa San Pietro'
            ],
            sigla: 'CA'
        },
        {
            name: 'Caltanissetta',
            municipalities: [
                'Acquaviva Platani',
                'Bompensiere',
                'Butera',
                'Caltanissetta',
                'Campofranco',
                'Delia',
                'Gela',
                'Marianopoli',
                'Mazzarino',
                'Milena',
                'Montedoro',
                'Mussomeli',
                'Niscemi',
                'Resuttano',
                'Riesi',
                'San Cataldo',
                'Santa Caterina Villarmosa',
                'Serradifalco',
                'Sommatino',
                'Sutera',
                'Vallelunga Pratameno',
                'Villalba'
            ],
            sigla: 'CL'
        },
        {
            name: 'Campobasso',
            municipalities: [
                'Acquaviva Collecroce',
                'Baranello',
                'Bojano',
                'Bonefro',
                'Busso',
                'Campobasso',
                'Campochiaro',
                'Campodipietra',
                'Campolieto',
                'Campomarino',
                'Casacalenda',
                'Casalciprano',
                'Castelbottaccio',
                'Castellino del Biferno',
                'Castelmauro',
                'Castropignano',
                'Cercemaggiore',
                'Cercepiccola',
                'Civitacampomarano',
                'Colle d\'Anchise',
                'Colletorto',
                'Duronia',
                'Ferrazzano',
                'Fossalto',
                'Gambatesa',
                'Gildone',
                'Guardialfiera',
                'Guardiaregia',
                'Guglionesi',
                'Jelsi',
                'Larino',
                'Limosano',
                'Lucito',
                'Lupara',
                'Macchia Valfortore',
                'Mafalda',
                'Matrice',
                'Mirabello Sannitico',
                'Molise',
                'Monacilioni',
                'Montagano',
                'Montecilfone',
                'Montefalcone nel Sannio',
                'Montelongo',
                'Montemitro',
                'Montenero di Bisaccia',
                'Montorio nei Frentani',
                'Morrone del Sannio',
                'Oratino',
                'Palata',
                'Petacciato',
                'Petrella Tifernina',
                'Pietracatella',
                'Pietracupa',
                'Portocannone',
                'Provvidenti',
                'Riccia',
                'Ripabottoni',
                'Ripalimosani',
                'Roccavivara',
                'Rotello',
                'Salcito',
                'San Biase',
                'San Felice del Molise',
                'San Giacomo degli Schiavoni',
                'San Giovanni in Galdo',
                'San Giuliano del Sannio',
                'San Giuliano di Puglia',
                'San Martino in Pensilis',
                'San Massimo',
                'San Polo Matese',
                'Sant\'Angelo Limosano',
                'Sant\'Elia a Pianisi',
                'Santa Croce di Magliano',
                'Sepino',
                'Spinete',
                'Tavenna',
                'Termoli',
                'Torella del Sannio',
                'Toro',
                'Trivento',
                'Tufara',
                'Ururi',
                'Vinchiaturo'
            ],
            sigla: 'CB'
        },
        {
            name: 'Caserta',
            municipalities: [
                'Ailano',
                'Alife',
                'Alvignano',
                'Arienzo',
                'Aversa',
                'Baia e Latina',
                'Bellona',
                'Caianello',
                'Caiazzo',
                'Calvi Risorta',
                'Camigliano',
                'Cancello ed Arnone',
                'Capodrise',
                'Capriati a Volturno',
                'Capua',
                'Carinaro',
                'Carinola',
                'Casagiove',
                'Casal di Principe',
                'Casaluce',
                'Casapesenna',
                'Casapulla',
                'Caserta',
                'Castel Campagnano',
                'Castel di Sasso',
                'Castel Morrone',
                'Castel Volturno',
                'Castello del Matese',
                'Cellole',
                'Cervino',
                'Cesa',
                'Ciorlano',
                'Conca della Campania',
                'Curti',
                'Dragoni',
                'Falciano del Massico',
                'Fontegreca',
                'Formicola',
                'Francolise',
                'Frignano',
                'Gallo Matese',
                'Galluccio',
                'Giano Vetusto',
                'Gioia Sannitica',
                'Grazzanise',
                'Gricignano di Aversa',
                'Letino',
                'Liberi',
                'Lusciano',
                'Macerata Campania',
                'Maddaloni',
                'Marcianise',
                'Marzano Appio',
                'Mignano Monte Lungo',
                'Mondragone',
                'Orta di Atella',
                'Parete',
                'Pastorano',
                'Piana di Monte Verna',
                'Piedimonte Matese',
                'Pietramelara',
                'Pietravairano',
                'Pignataro Maggiore',
                'Pontelatone',
                'Portico di Caserta',
                'Prata Sannita',
                'Pratella',
                'Presenzano',
                'Raviscanina',
                'Recale',
                'Riardo',
                'Rocca d\'Evandro',
                'Roccamonfina',
                'Roccaromana',
                'Rocchetta e Croce',
                'Ruviano',
                'San Cipriano d\'Aversa',
                'San Felice a Cancello',
                'San Gregorio Matese',
                'San Marcellino',
                'San Marco Evangelista',
                'San Nicola la Strada',
                'San Pietro Infine',
                'San Potito Sannitico',
                'San Prisco',
                'San Tammaro',
                'Sant\'Angelo d\'Alife',
                'Sant\'Arpino',
                'Santa Maria a Vico',
                'Santa Maria Capua Vetere',
                'Santa Maria la Fossa',
                'Sessa Aurunca',
                'Sparanise',
                'Succivo',
                'Teano',
                'Teverola',
                'Tora e Piccilli',
                'Trentola Ducenta',
                'Vairano Patenora',
                'Valle Agricola',
                'Valle di Maddaloni',
                'Villa di Briano',
                'Villa Literno',
                'Vitulazio'
            ],
            sigla: 'CE'
        },
        {
            name: 'Catania',
            municipalities: [
                'Aci Bonaccorsi',
                'Aci Castello',
                'Aci Catena',
                'Aci Sant\'Antonio',
                'Acireale',
                'Adrano',
                'Belpasso',
                'Biancavilla',
                'Bronte',
                'Calatabiano',
                'Caltagirone',
                'Camporotondo Etneo',
                'Castel di Iudica',
                'Castiglione di Sicilia',
                'Catania',
                'Fiumefreddo di Sicilia',
                'Giarre',
                'Grammichele',
                'Gravina di Catania',
                'Licodia Eubea',
                'Linguaglossa',
                'Maletto',
                'Maniace',
                'Mascali',
                'Mascalucia',
                'Mazzarrone',
                'Militello in Val di Catania',
                'Milo',
                'Mineo',
                'Mirabella Imbaccari',
                'Misterbianco',
                'Motta Sant\'Anastasia',
                'Nicolosi',
                'Palagonia',
                'Paternò',
                'Pedara',
                'Piedimonte Etneo',
                'Raddusa',
                'Ragalna',
                'Ramacca',
                'Randazzo',
                'Riposto',
                'San Cono',
                'San Giovanni la Punta',
                'San Gregorio di Catania',
                'San Michele di Ganzaria',
                'San Pietro Clarenza',
                'Sant\'Agata li Battiati',
                'Sant\'Alfio',
                'Santa Maria di Licodia',
                'Santa Venerina',
                'Scordia',
                'Trecastagni',
                'Tremestieri Etneo',
                'Valverde',
                'Viagrande',
                'Vizzini',
                'Zafferana Etnea'
            ],
            sigla: 'CT'
        },
        {
            name: 'Catanzaro',
            municipalities: [
                'Albi',
                'Amaroni',
                'Amato',
                'Andali',
                'Argusto',
                'Badolato',
                'Belcastro',
                'Borgia',
                'Botricello',
                'Caraffa di Catanzaro',
                'Cardinale',
                'Carlopoli',
                'Catanzaro',
                'Cenadi',
                'Centrache',
                'Cerva',
                'Chiaravalle Centrale',
                'Cicala',
                'Conflenti',
                'Cortale',
                'Cropani',
                'Curinga',
                'Davoli',
                'Decollatura',
                'Falerna',
                'Feroleto Antico',
                'Fossato Serralta',
                'Gagliato',
                'Gasperina',
                'Gimigliano',
                'Girifalco',
                'Gizzeria',
                'Guardavalle',
                'Isca sullo Ionio',
                'Jacurso',
                'Lamezia Terme',
                'Magisano',
                'Maida',
                'Marcedusa',
                'Marcellinara',
                'Martirano',
                'Martirano Lombardo',
                'Miglierina',
                'Montauro',
                'Montepaone',
                'Motta Santa Lucia',
                'Nocera Terinese',
                'Olivadi',
                'Palermiti',
                'Pentone',
                'Petrizzi',
                'Petronà',
                'Pianopoli',
                'Platania',
                'San Floro',
                'San Mango d\'Aquino',
                'San Pietro a Maida',
                'San Pietro Apostolo',
                'San Sostene',
                'San Vito sullo Ionio',
                'Sant\'Andrea Apostolo dello Ionio',
                'Santa Caterina dello Ionio',
                'Satriano',
                'Sellia',
                'Sellia Marina',
                'Serrastretta',
                'Sersale',
                'Settingiano',
                'Simeri Crichi',
                'Sorbo San Basile',
                'Soverato',
                'Soveria Mannelli',
                'Soveria Simeri',
                'Squillace',
                'Stalettì',
                'Taverna',
                'Tiriolo',
                'Torre di Ruggiero',
                'Vallefiorita',
                'Zagarise'
            ],
            sigla: 'CZ'
        },
        {
            name: 'Chieti',
            municipalities: [
                'Altino',
                'Archi',
                'Ari',
                'Arielli',
                'Atessa',
                'Bomba',
                'Borrello',
                'Bucchianico',
                'Canosa Sannita',
                'Carpineto Sinello',
                'Carunchio',
                'Casacanditella',
                'Casalanguida',
                'Casalbordino',
                'Casalincontrada',
                'Casoli',
                'Castel Frentano',
                'Castelguidone',
                'Castiglione Messer Marino',
                'Celenza sul Trigno',
                'Chieti',
                'Civitaluparella',
                'Civitella Messer Raimondo',
                'Colledimacine',
                'Colledimezzo',
                'Crecchio',
                'Cupello',
                'Dogliola',
                'Fallo',
                'Fara Filiorum Petri',
                'Fara San Martino',
                'Filetto',
                'Fossacesia',
                'Fraine',
                'Francavilla al Mare',
                'Fresagrandinaria',
                'Frisa',
                'Furci',
                'Gamberale',
                'Gessopalena',
                'Gissi',
                'Giuliano Teatino',
                'Guardiagrele',
                'Guilmi',
                'Lama dei Peligni',
                'Lanciano',
                'Lentella',
                'Lettopalena',
                'Liscia',
                'Miglianico',
                'Montazzoli',
                'Montebello sul Sangro',
                'Monteferrante',
                'Montelapiano',
                'Montenerodomo',
                'Monteodorisio',
                'Mozzagrogna',
                'Orsogna',
                'Ortona',
                'Paglieta',
                'Palena',
                'Palmoli',
                'Palombaro',
                'Pennadomo',
                'Pennapiedimonte',
                'Perano',
                'Pietraferrazzana',
                'Pizzoferrato',
                'Poggiofiorito',
                'Pollutri',
                'Pretoro',
                'Quadri',
                'Rapino',
                'Ripa Teatina',
                'Rocca San Giovanni',
                'Roccamontepiano',
                'Roccascalegna',
                'Roccaspinalveti',
                'Roio del Sangro',
                'Rosello',
                'San Buono',
                'San Giovanni Lipioni',
                'San Giovanni Teatino',
                'San Martino sulla Marrucina',
                'San Salvo',
                'San Vito Chietino',
                'Sant\'Eusanio del Sangro',
                'Santa Maria Imbaro',
                'Scerni',
                'Schiavi di Abruzzo',
                'Taranta Peligna',
                'Tollo',
                'Torino di Sangro',
                'Tornareccio',
                'Torrebruna',
                'Torrevecchia Teatina',
                'Torricella Peligna',
                'Treglio',
                'Tufillo',
                'Vacri',
                'Vasto',
                'Villa Santa Maria',
                'Villalfonsina',
                'Villamagna'
            ],
            sigla: 'CH'
        },
        {
            name: 'Como',
            municipalities: [
                'Albavilla',
                'Albese con Cassano',
                'Albiolo',
                'Alserio',
                'Alta Valle Intelvi',
                'Alzate Brianza',
                'Anzano del Parco',
                'Appiano Gentile',
                'Argegno',
                'Arosio',
                'Asso',
                'Barni',
                'Bellagio',
                'Bene Lario',
                'Beregazzo con Figliaro',
                'Binago',
                'Bizzarone',
                'Blessagno',
                'Blevio',
                'Bregnano',
                'Brenna',
                'Brienno',
                'Brunate',
                'Bulgarograsso',
                'Cabiate',
                'Cadorago',
                'Caglio',
                'Campione d\'Italia',
                'Cantù',
                'Canzo',
                'Capiago Intimiano',
                'Carate Urio',
                'Carbonate',
                'Carimate',
                'Carlazzo',
                'Carugo',
                'Caslino d\'Erba',
                'Casnate con Bernate',
                'Cassina Rizzardi',
                'Castelmarte',
                'Castelnuovo Bozzente',
                'Cavargna',
                'Centro Valle Intelvi',
                'Cerano d\'Intelvi',
                'Cermenate',
                'Cernobbio',
                'Cirimido',
                'Claino con Osteno',
                'Colonno',
                'Colverde',
                'Como',
                'Corrido',
                'Cremia',
                'Cucciago',
                'Cusino',
                'Dizzasco',
                'Domaso',
                'Dongo',
                'Dosso del Liro',
                'Erba',
                'Eupilio',
                'Faggeto Lario',
                'Faloppio',
                'Fenegrò',
                'Figino Serenza',
                'Fino Mornasco',
                'Garzeno',
                'Gera Lario',
                'Grandate',
                'Grandola ed Uniti',
                'Gravedona ed Uniti',
                'Griante',
                'Guanzate',
                'Inverigo',
                'Laglio',
                'Laino',
                'Lambrugo',
                'Lasnigo',
                'Lezzeno',
                'Limido Comasco',
                'Lipomo',
                'Livo',
                'Locate Varesino',
                'Lomazzo',
                'Longone al Segrino',
                'Luisago',
                'Lurago d\'Erba',
                'Lurago Marinone',
                'Lurate Caccivio',
                'Magreglio',
                'Mariano Comense',
                'Maslianico',
                'Menaggio',
                'Merone',
                'Moltrasio',
                'Monguzzo',
                'Montano Lucino',
                'Montemezzo',
                'Montorfano',
                'Mozzate',
                'Musso',
                'Nesso',
                'Novedrate',
                'Olgiate Comasco',
                'Oltrona di San Mamette',
                'Orsenigo',
                'Peglio',
                'Pianello del Lario',
                'Pigra',
                'Plesio',
                'Pognana Lario',
                'Ponna',
                'Ponte Lambro',
                'Porlezza',
                'Proserpio',
                'Pusiano',
                'Rezzago',
                'Rodero',
                'Ronago',
                'Rovellasca',
                'Rovello Porro',
                'Sala Comacina',
                'San Bartolomeo Val Cavargna',
                'San Fermo della Battaglia',
                'San Nazzaro Val Cavargna',
                'San Siro',
                'Schignano',
                'Senna Comasco',
                'Solbiate con Cagno',
                'Sorico',
                'Sormano',
                'Stazzona',
                'Tavernerio',
                'Torno',
                'Tremezzina',
                'Trezzone',
                'Turate',
                'Uggiate-Trevano',
                'Val Rezzo',
                'Valbrona',
                'Valmorea',
                'Valsolda',
                'Veleso',
                'Veniano',
                'Vercana',
                'Vertemate con Minoprio',
                'Villa Guardia',
                'Zelbio'
            ],
            sigla: 'CO'
        },
        {
            name: 'Cosenza',
            municipalities: [
                'Acquaformosa',
                'Acquappesa',
                'Acri',
                'Aiello Calabro',
                'Aieta',
                'Albidona',
                'Alessandria del Carretto',
                'Altilia',
                'Altomonte',
                'Amantea',
                'Amendolara',
                'Aprigliano',
                'Belmonte Calabro',
                'Belsito',
                'Belvedere Marittimo',
                'Bianchi',
                'Bisignano',
                'Bocchigliero',
                'Bonifati',
                'Buonvicino',
                'Calopezzati',
                'Caloveto',
                'Campana',
                'Canna',
                'Cariati',
                'Carolei',
                'Carpanzano',
                'Casali del Manco',
                'Cassano all\'Ionio',
                'Castiglione Cosentino',
                'Castrolibero',
                'Castroregio',
                'Castrovillari',
                'Celico',
                'Cellara',
                'Cerchiara di Calabria',
                'Cerisano',
                'Cervicati',
                'Cerzeto',
                'Cetraro',
                'Civita',
                'Cleto',
                'Colosimi',
                'Corigliano-Rossano',
                'Cosenza',
                'Cropalati',
                'Crosia',
                'Diamante',
                'Dipignano',
                'Domanico',
                'Fagnano Castello',
                'Falconara Albanese',
                'Figline Vegliaturo',
                'Firmo',
                'Fiumefreddo Bruzio',
                'Francavilla Marittima',
                'Frascineto',
                'Fuscaldo',
                'Grimaldi',
                'Grisolia',
                'Guardia Piemontese',
                'Lago',
                'Laino Borgo',
                'Laino Castello',
                'Lappano',
                'Lattarico',
                'Longobardi',
                'Longobucco',
                'Lungro',
                'Luzzi',
                'Maierà',
                'Malito',
                'Malvito',
                'Mandatoriccio',
                'Mangone',
                'Marano Marchesato',
                'Marano Principato',
                'Marzi',
                'Mendicino',
                'Mongrassano',
                'Montalto Uffugo',
                'Montegiordano',
                'Morano Calabro',
                'Mormanno',
                'Mottafollone',
                'Nocara',
                'Oriolo',
                'Orsomarso',
                'Paludi',
                'Panettieri',
                'Paola',
                'Papasidero',
                'Parenti',
                'Paterno Calabro',
                'Pedivigliano',
                'Piane Crati',
                'Pietrafitta',
                'Pietrapaola',
                'Plataci',
                'Praia a Mare',
                'Rende',
                'Rocca Imperiale',
                'Roggiano Gravina',
                'Rogliano',
                'Rose',
                'Roseto Capo Spulico',
                'Rota Greca',
                'Rovito',
                'San Basile',
                'San Benedetto Ullano',
                'San Cosmo Albanese',
                'San Demetrio Corone',
                'San Donato di Ninea',
                'San Fili',
                'San Giorgio Albanese',
                'San Giovanni in Fiore',
                'San Lorenzo Bellizzi',
                'San Lorenzo del Vallo',
                'San Lucido',
                'San Marco Argentano',
                'San Martino di Finita',
                'San Nicola Arcella',
                'San Pietro in Amantea',
                'San Pietro in Guarano',
                'San Sosti',
                'San Vincenzo La Costa',
                'Sangineto',
                'Sant\'Agata di Esaro',
                'Santa Caterina Albanese',
                'Santa Domenica Talao',
                'Santa Maria del Cedro',
                'Santa Sofia d\'Epiro',
                'Santo Stefano di Rogliano',
                'Saracena',
                'Scala Coeli',
                'Scalea',
                'Scigliano',
                'Serra d\'Aiello',
                'Spezzano Albanese',
                'Spezzano della Sila',
                'Tarsia',
                'Terranova da Sibari',
                'Terravecchia',
                'Torano Castello',
                'Tortora',
                'Trebisacce',
                'Vaccarizzo Albanese',
                'Verbicaro',
                'Villapiana',
                'Zumpano'
            ],
            sigla: 'CS'
        },
        {
            name: 'Cremona',
            municipalities: [
                'Acquanegra Cremonese',
                'Agnadello',
                'Annicco',
                'Azzanello',
                'Bagnolo Cremasco',
                'Bonemerse',
                'Bordolano',
                'Calvatone',
                'Camisano',
                'Campagnola Cremasca',
                'Capergnanica',
                'Cappella Cantone',
                'Cappella de\' Picenardi',
                'Capralba',
                'Casalbuttano ed Uniti',
                'Casale Cremasco-Vidolasco',
                'Casaletto Ceredano',
                'Casaletto di Sopra',
                'Casaletto Vaprio',
                'Casalmaggiore',
                'Casalmorano',
                'Castel Gabbiano',
                'Casteldidone',
                'Castelleone',
                'Castelverde',
                'Castelvisconti',
                'Cella Dati',
                'Chieve',
                'Cicognolo',
                'Cingia de\' Botti',
                'Corte de\' Cortesi con Cignone',
                'Corte de\' Frati',
                'Credera Rubbiano',
                'Crema',
                'Cremona',
                'Cremosano',
                'Crotta d\'Adda',
                'Cumignano sul Naviglio',
                'Derovere',
                'Dovera',
                'Fiesco',
                'Formigara',
                'Gabbioneta-Binanuova',
                'Gadesco-Pieve Delmona',
                'Genivolta',
                'Gerre de\' Caprioli',
                'Gombito',
                'Grontardo',
                'Grumello Cremonese ed Uniti',
                'Gussola',
                'Isola Dovarese',
                'Izano',
                'Madignano',
                'Malagnino',
                'Martignana di Po',
                'Monte Cremasco',
                'Montodine',
                'Moscazzano',
                'Motta Baluffi',
                'Offanengo',
                'Olmeneta',
                'Ostiano',
                'Paderno Ponchielli',
                'Palazzo Pignano',
                'Pandino',
                'Persico Dosimo',
                'Pescarolo ed Uniti',
                'Pessina Cremonese',
                'Piadena Drizzona',
                'Pianengo',
                'Pieranica',
                'Pieve d\'Olmi',
                'Pieve San Giacomo',
                'Pizzighettone',
                'Pozzaglio ed Uniti',
                'Quintano',
                'Ricengo',
                'Ripalta Arpina',
                'Ripalta Cremasca',
                'Ripalta Guerina',
                'Rivarolo del Re ed Uniti',
                'Rivolta d\'Adda',
                'Robecco d\'Oglio',
                'Romanengo',
                'Salvirola',
                'San Bassano',
                'San Daniele Po',
                'San Giovanni in Croce',
                'San Martino del Lago',
                'Scandolara Ravara',
                'Scandolara Ripa d\'Oglio',
                'Sergnano',
                'Sesto ed Uniti',
                'Solarolo Rainerio',
                'Soncino',
                'Soresina',
                'Sospiro',
                'Spinadesco',
                'Spineda',
                'Spino d\'Adda',
                'Stagno Lombardo',
                'Ticengo',
                'Torlino Vimercati',
                'Tornata',
                'Torre de\' Picenardi',
                'Torricella del Pizzo',
                'Trescore Cremasco',
                'Trigolo',
                'Vaiano Cremasco',
                'Vailate',
                'Vescovato',
                'Volongo',
                'Voltido'
            ],
            sigla: 'CR'
        },
        {
            name: 'Crotone',
            municipalities: [
                'Belvedere di Spinello',
                'Caccuri',
                'Carfizzi',
                'Casabona',
                'Castelsilano',
                'Cerenzia',
                'Cirò',
                'Cirò Marina',
                'Cotronei',
                'Crotone',
                'Crucoli',
                'Cutro',
                'Isola di Capo Rizzuto',
                'Melissa',
                'Mesoraca',
                'Pallagorio',
                'Petilia Policastro',
                'Rocca di Neto',
                'Roccabernarda',
                'San Mauro Marchesato',
                'San Nicola dell\'Alto',
                'Santa Severina',
                'Savelli',
                'Scandale',
                'Strongoli',
                'Umbriatico',
                'Verzino'
            ],
            sigla: 'KR'
        },
        {
            name: 'Cuneo',
            municipalities: [
                'Acceglio',
                'Aisone',
                'Alba',
                'Albaretto della Torre',
                'Alto',
                'Argentera',
                'Arguello',
                'Bagnasco',
                'Bagnolo Piemonte',
                'Baldissero d\'Alba',
                'Barbaresco',
                'Barge',
                'Barolo',
                'Bastia Mondovì',
                'Battifollo',
                'Beinette',
                'Bellino',
                'Belvedere Langhe',
                'Bene Vagienna',
                'Benevello',
                'Bergolo',
                'Bernezzo',
                'Bonvicino',
                'Borgo San Dalmazzo',
                'Borgomale',
                'Bosia',
                'Bossolasco',
                'Boves',
                'Bra',
                'Briaglia',
                'Briga Alta',
                'Brondello',
                'Brossasco',
                'Busca',
                'Camerana',
                'Canale',
                'Canosio',
                'Caprauna',
                'Caraglio',
                'Caramagna Piemonte',
                'Cardè',
                'Carrù',
                'Cartignano',
                'Casalgrasso',
                'Castagnito',
                'Casteldelfino',
                'Castelletto Stura',
                'Castelletto Uzzone',
                'Castellinaldo d\'Alba',
                'Castellino Tanaro',
                'Castelmagno',
                'Castelnuovo di Ceva',
                'Castiglione Falletto',
                'Castiglione Tinella',
                'Castino',
                'Cavallerleone',
                'Cavallermaggiore',
                'Celle di Macra',
                'Centallo',
                'Ceresole Alba',
                'Cerretto Langhe',
                'Cervasca',
                'Cervere',
                'Ceva',
                'Cherasco',
                'Chiusa di Pesio',
                'Cigliè',
                'Cissone',
                'Clavesana',
                'Corneliano d\'Alba',
                'Cortemilia',
                'Cossano Belbo',
                'Costigliole Saluzzo',
                'Cravanzana',
                'Crissolo',
                'Cuneo',
                'Demonte',
                'Diano d\'Alba',
                'Dogliani',
                'Dronero',
                'Elva',
                'Entracque',
                'Envie',
                'Farigliano',
                'Faule',
                'Feisoglio',
                'Fossano',
                'Frabosa Soprana',
                'Frabosa Sottana',
                'Frassino',
                'Gaiola',
                'Gambasca',
                'Garessio',
                'Genola',
                'Gorzegno',
                'Gottasecca',
                'Govone',
                'Grinzane Cavour',
                'Guarene',
                'Igliano',
                'Isasca',
                'La Morra',
                'Lagnasco',
                'Lequio Berria',
                'Lequio Tanaro',
                'Lesegno',
                'Levice',
                'Limone Piemonte',
                'Lisio',
                'Macra',
                'Magliano Alfieri',
                'Magliano Alpi',
                'Mango',
                'Manta',
                'Marene',
                'Margarita',
                'Marmora',
                'Marsaglia',
                'Martiniana Po',
                'Melle',
                'Moiola',
                'Mombarcaro',
                'Mombasiglio',
                'Monastero di Vasco',
                'Monasterolo Casotto',
                'Monasterolo di Savigliano',
                'Monchiero',
                'Mondovì',
                'Monesiglio',
                'Monforte d\'Alba',
                'Montà',
                'Montaldo di Mondovì',
                'Montaldo Roero',
                'Montanera',
                'Montelupo Albese',
                'Montemale di Cuneo',
                'Monterosso Grana',
                'Monteu Roero',
                'Montezemolo',
                'Monticello d\'Alba',
                'Moretta',
                'Morozzo',
                'Murazzano',
                'Murello',
                'Narzole',
                'Neive',
                'Neviglie',
                'Niella Belbo',
                'Niella Tanaro',
                'Novello',
                'Nucetto',
                'Oncino',
                'Ormea',
                'Ostana',
                'Paesana',
                'Pagno',
                'Pamparato',
                'Paroldo',
                'Perletto',
                'Perlo',
                'Peveragno',
                'Pezzolo Valle Uzzone',
                'Pianfei',
                'Piasco',
                'Pietraporzio',
                'Piobesi d\'Alba',
                'Piozzo',
                'Pocapaglia',
                'Polonghera',
                'Pontechianale',
                'Pradleves',
                'Prazzo',
                'Priero',
                'Priocca',
                'Priola',
                'Prunetto',
                'Racconigi',
                'Revello',
                'Rifreddo',
                'Rittana',
                'Roaschia',
                'Roascio',
                'Robilante',
                'Roburent',
                'Rocca Cigliè',
                'Rocca de\' Baldi',
                'Roccabruna',
                'Roccaforte Mondovì',
                'Roccasparvera',
                'Roccavione',
                'Rocchetta Belbo',
                'Roddi',
                'Roddino',
                'Rodello',
                'Rossana',
                'Ruffia',
                'Sale delle Langhe',
                'Sale San Giovanni',
                'Saliceto',
                'Salmour',
                'Saluzzo',
                'Sambuco',
                'Sampeyre',
                'San Benedetto Belbo',
                'San Damiano Macra',
                'San Michele Mondovì',
                'Sanfrè',
                'Sanfront',
                'Sant\'Albano Stura',
                'Santa Vittoria d\'Alba',
                'Santo Stefano Belbo',
                'Santo Stefano Roero',
                'Savigliano',
                'Scagnello',
                'Scarnafigi',
                'Serralunga d\'Alba',
                'Serravalle Langhe',
                'Sinio',
                'Somano',
                'Sommariva del Bosco',
                'Sommariva Perno',
                'Stroppo',
                'Tarantasca',
                'Torre Bormida',
                'Torre Mondovì',
                'Torre San Giorgio',
                'Torresina',
                'Treiso',
                'Trezzo Tinella',
                'Trinità',
                'Valdieri',
                'Valgrana',
                'Valloriate',
                'Venasca',
                'Verduno',
                'Vernante',
                'Verzuolo',
                'Vezza d\'Alba',
                'Vicoforte',
                'Vignolo',
                'Villafalletto',
                'Villanova Mondovì',
                'Villanova Solaro',
                'Villar San Costanzo',
                'Vinadio',
                'Viola',
                'Vottignasco'
            ],
            sigla: 'CN'
        },
        {
            name: 'Enna',
            municipalities: [
                'Agira',
                'Aidone',
                'Assoro',
                'Barrafranca',
                'Calascibetta',
                'Catenanuova',
                'Centuripe',
                'Cerami',
                'Enna',
                'Gagliano Castelferrato',
                'Leonforte',
                'Nicosia',
                'Nissoria',
                'Piazza Armerina',
                'Pietraperzia',
                'Regalbuto',
                'Sperlinga',
                'Troina',
                'Valguarnera Caropepe',
                'Villarosa'
            ],
            sigla: 'EN'
        },
        {
            name: 'Fermo',
            municipalities: [
                'Altidona',
                'Amandola',
                'Belmonte Piceno',
                'Campofilone',
                'Falerone',
                'Fermo',
                'Francavilla d\'Ete',
                'Grottazzolina',
                'Lapedona',
                'Magliano di Tenna',
                'Massa Fermana',
                'Monsampietro Morico',
                'Montappone',
                'Monte Giberto',
                'Monte Rinaldo',
                'Monte San Pietrangeli',
                'Monte Urano',
                'Monte Vidon Combatte',
                'Monte Vidon Corrado',
                'Montefalcone Appennino',
                'Montefortino',
                'Montegiorgio',
                'Montegranaro',
                'Monteleone di Fermo',
                'Montelparo',
                'Monterubbiano',
                'Montottone',
                'Moresco',
                'Ortezzano',
                'Pedaso',
                'Petritoli',
                'Ponzano di Fermo',
                'Porto San Giorgio',
                'Porto Sant\'Elpidio',
                'Rapagnano',
                'Sant\'Elpidio a Mare',
                'Santa Vittoria in Matenano',
                'Servigliano',
                'Smerillo',
                'Torre San Patrizio'
            ],
            sigla: 'FM'
        },
        {
            name: 'Ferrara',
            municipalities: [
                'Argenta',
                'Bondeno',
                'Cento',
                'Codigoro',
                'Comacchio',
                'Copparo',
                'Ferrara',
                'Fiscaglia',
                'Goro',
                'Jolanda di Savoia',
                'Lagosanto',
                'Masi Torello',
                'Mesola',
                'Ostellato',
                'Poggio Renatico',
                'Portomaggiore',
                'Riva del Po',
                'Terre del Reno',
                'Tresignana',
                'Vigarano Mainarda',
                'Voghiera'
            ],
            sigla: 'FE'
        },
        {
            name: 'Firenze',
            municipalities: [
                'Bagno a Ripoli',
                'Barberino di Mugello',
                'Barberino Tavarnelle',
                'Borgo San Lorenzo',
                'Calenzano',
                'Campi Bisenzio',
                'Capraia e Limite',
                'Castelfiorentino',
                'Cerreto Guidi',
                'Certaldo',
                'Dicomano',
                'Empoli',
                'Fiesole',
                'Figline e Incisa Valdarno',
                'Firenze',
                'Firenzuola',
                'Fucecchio',
                'Gambassi Terme',
                'Greve in Chianti',
                'Impruneta',
                'Lastra a Signa',
                'Londa',
                'Marradi',
                'Montaione',
                'Montelupo Fiorentino',
                'Montespertoli',
                'Palazzuolo sul Senio',
                'Pelago',
                'Pontassieve',
                'Reggello',
                'Rignano sull\'Arno',
                'Rufina',
                'San Casciano in Val di Pesa',
                'San Godenzo',
                'Scandicci',
                'Scarperia e San Piero',
                'Sesto Fiorentino',
                'Signa',
                'Vaglia',
                'Vicchio',
                'Vinci'
            ],
            sigla: 'FI'
        },
        {
            name: 'Foggia',
            municipalities: [
                'Accadia',
                'Alberona',
                'Anzano di Puglia',
                'Apricena',
                'Ascoli Satriano',
                'Biccari',
                'Bovino',
                'Cagnano Varano',
                'Candela',
                'Carapelle',
                'Carlantino',
                'Carpino',
                'Casalnuovo Monterotaro',
                'Casalvecchio di Puglia',
                'Castelluccio dei Sauri',
                'Castelluccio Valmaggiore',
                'Castelnuovo della Daunia',
                'Celenza Valfortore',
                'Celle di San Vito',
                'Cerignola',
                'Chieuti',
                'Deliceto',
                'Faeto',
                'Foggia',
                'Ischitella',
                'Isole Tremiti',
                'Lesina',
                'Lucera',
                'Manfredonia',
                'Mattinata',
                'Monte Sant\'Angelo',
                'Monteleone di Puglia',
                'Motta Montecorvino',
                'Ordona',
                'Orsara di Puglia',
                'Orta Nova',
                'Panni',
                'Peschici',
                'Pietramontecorvino',
                'Poggio Imperiale',
                'Rignano Garganico',
                'Rocchetta Sant\'Antonio',
                'Rodi Garganico',
                'Roseto Valfortore',
                'San Giovanni Rotondo',
                'San Marco in Lamis',
                'San Marco la Catola',
                'San Nicandro Garganico',
                'San Paolo di Civitate',
                'San Severo',
                'Sant\'Agata di Puglia',
                'Serracapriola',
                'Stornara',
                'Stornarella',
                'Torremaggiore',
                'Troia',
                'Vico del Gargano',
                'Vieste',
                'Volturara Appula',
                'Volturino',
                'Zapponeta'
            ],
            sigla: 'FG'
        },
        {
            name: 'Forlì-Cesena',
            municipalities: [
                'Bagno di Romagna',
                'Bertinoro',
                'Borghi',
                'Castrocaro Terme e Terra del Sole',
                'Cesena',
                'Cesenatico',
                'Civitella di Romagna',
                'Dovadola',
                'Forlì',
                'Forlimpopoli',
                'Galeata',
                'Gambettola',
                'Gatteo',
                'Longiano',
                'Meldola',
                'Mercato Saraceno',
                'Modigliana',
                'Montiano',
                'Portico e San Benedetto',
                'Predappio',
                'Premilcuore',
                'Rocca San Casciano',
                'Roncofreddo',
                'San Mauro Pascoli',
                'Santa Sofia',
                'Sarsina',
                'Savignano sul Rubicone',
                'Sogliano al Rubicone',
                'Tredozio',
                'Verghereto'
            ],
            sigla: 'FC'
        },
        {
            name: 'Frosinone',
            municipalities: [
                'Acquafondata',
                'Acuto',
                'Alatri',
                'Alvito',
                'Amaseno',
                'Anagni',
                'Aquino',
                'Arce',
                'Arnara',
                'Arpino',
                'Atina',
                'Ausonia',
                'Belmonte Castello',
                'Boville Ernica',
                'Broccostella',
                'Campoli Appennino',
                'Casalattico',
                'Casalvieri',
                'Cassino',
                'Castelliri',
                'Castelnuovo Parano',
                'Castro dei Volsci',
                'Castrocielo',
                'Ceccano',
                'Ceprano',
                'Cervaro',
                'Colfelice',
                'Colle San Magno',
                'Collepardo',
                'Coreno Ausonio',
                'Esperia',
                'Falvaterra',
                'Ferentino',
                'Filettino',
                'Fiuggi',
                'Fontana Liri',
                'Fontechiari',
                'Frosinone',
                'Fumone',
                'Gallinaro',
                'Giuliano di Roma',
                'Guarcino',
                'Isola del Liri',
                'Monte San Giovanni Campano',
                'Morolo',
                'Paliano',
                'Pastena',
                'Patrica',
                'Pescosolido',
                'Picinisco',
                'Pico',
                'Piedimonte San Germano',
                'Piglio',
                'Pignataro Interamna',
                'Pofi',
                'Pontecorvo',
                'Posta Fibreno',
                'Ripi',
                'Rocca d\'Arce',
                'Roccasecca',
                'San Biagio Saracinisco',
                'San Donato Val di Comino',
                'San Giorgio a Liri',
                'San Giovanni Incarico',
                'San Vittore del Lazio',
                'Sant\'Ambrogio sul Garigliano',
                'Sant\'Andrea del Garigliano',
                'Sant\'Apollinare',
                'Sant\'Elia Fiumerapido',
                'Santopadre',
                'Serrone',
                'Settefrati',
                'Sgurgola',
                'Sora',
                'Strangolagalli',
                'Supino',
                'Terelle',
                'Torre Cajetani',
                'Torrice',
                'Trevi nel Lazio',
                'Trivigliano',
                'Vallecorsa',
                'Vallemaio',
                'Vallerotonda',
                'Veroli',
                'Vicalvi',
                'Vico nel Lazio',
                'Villa Latina',
                'Villa Santa Lucia',
                'Villa Santo Stefano',
                'Viticuso'
            ],
            sigla: 'FR'
        },
        {
            name: 'Genova',
            municipalities: [
                'Arenzano',
                'Avegno',
                'Bargagli',
                'Bogliasco',
                'Borzonasca',
                'Busalla',
                'Camogli',
                'Campo Ligure',
                'Campomorone',
                'Carasco',
                'Casarza Ligure',
                'Casella',
                'Castiglione Chiavarese',
                'Ceranesi',
                'Chiavari',
                'Cicagna',
                'Cogoleto',
                'Cogorno',
                'Coreglia Ligure',
                'Crocefieschi',
                'Davagna',
                'Fascia',
                'Favale di Malvaro',
                'Fontanigorda',
                'Genova',
                'Gorreto',
                'Isola del Cantone',
                'Lavagna',
                'Leivi',
                'Lorsica',
                'Lumarzo',
                'Masone',
                'Mele',
                'Mezzanego',
                'Mignanego',
                'Moconesi',
                'Moneglia',
                'Montebruno',
                'Montoggio',
                'Ne',
                'Neirone',
                'Orero',
                'Pieve Ligure',
                'Portofino',
                'Propata',
                'Rapallo',
                'Recco',
                'Rezzoaglio',
                'Ronco Scrivia',
                'Rondanina',
                'Rossiglione',
                'Rovegno',
                'San Colombano Certenoli',
                'Sant\'Olcese',
                'Santa Margherita Ligure',
                'Santo Stefano d\'Aveto',
                'Savignone',
                'Serra Riccò',
                'Sestri Levante',
                'Sori',
                'Tiglieto',
                'Torriglia',
                'Tribogna',
                'Uscio',
                'Valbrevenna',
                'Vobbia',
                'Zoagli'
            ],
            sigla: 'GE'
        },
        {
            name: 'Gorizia',
            municipalities: [
                'Capriva del Friuli',
                'Cormons',
                'Doberdò del Lago',
                'Dolegna del Collio',
                'Farra d\'Isonzo',
                'Fogliano Redipuglia',
                'Gorizia',
                'Gradisca d\'Isonzo',
                'Grado',
                'Mariano del Friuli',
                'Medea',
                'Monfalcone',
                'Moraro',
                'Mossa',
                'Romans d\'Isonzo',
                'Ronchi dei Legionari',
                'Sagrado',
                'San Canzian d\'Isonzo',
                'San Floriano del Collio',
                'San Lorenzo Isontino',
                'San Pier d\'Isonzo',
                'Savogna d\'Isonzo',
                'Staranzano',
                'Turriaco',
                'Villesse'
            ],
            sigla: 'GO'
        },
        {
            name: 'Grosseto',
            municipalities: [
                'Arcidosso',
                'Campagnatico',
                'Capalbio',
                'Castel del Piano',
                'Castell\'Azzara',
                'Castiglione della Pescaia',
                'Cinigiano',
                'Civitella Paganico',
                'Follonica',
                'Gavorrano',
                'Grosseto',
                'Isola del Giglio',
                'Magliano in Toscana',
                'Manciano',
                'Massa Marittima',
                'Monte Argentario',
                'Monterotondo Marittimo',
                'Montieri',
                'Orbetello',
                'Pitigliano',
                'Roccalbegna',
                'Roccastrada',
                'Santa Fiora',
                'Scansano',
                'Scarlino',
                'Seggiano',
                'Semproniano',
                'Sorano'
            ],
            sigla: 'GR'
        },
        {
            name: 'Imperia',
            municipalities: [
                'Airole',
                'Apricale',
                'Aquila d\'Arroscia',
                'Armo',
                'Aurigo',
                'Badalucco',
                'Bajardo',
                'Bordighera',
                'Borghetto d\'Arroscia',
                'Borgomaro',
                'Camporosso',
                'Caravonica',
                'Castel Vittorio',
                'Castellaro',
                'Ceriana',
                'Cervo',
                'Cesio',
                'Chiusanico',
                'Chiusavecchia',
                'Cipressa',
                'Civezza',
                'Cosio d\'Arroscia',
                'Costarainera',
                'Diano Arentino',
                'Diano Castello',
                'Diano Marina',
                'Diano San Pietro',
                'Dolceacqua',
                'Dolcedo',
                'Imperia',
                'Isolabona',
                'Lucinasco',
                'Mendatica',
                'Molini di Triora',
                'Montalto Carpasio',
                'Montegrosso Pian Latte',
                'Olivetta San Michele',
                'Ospedaletti',
                'Perinaldo',
                'Pietrabruna',
                'Pieve di Teco',
                'Pigna',
                'Pompeiana',
                'Pontedassio',
                'Pornassio',
                'Prelà',
                'Ranzo',
                'Rezzo',
                'Riva Ligure',
                'Rocchetta Nervina',
                'San Bartolomeo al Mare',
                'San Biagio della Cima',
                'San Lorenzo al Mare',
                'Sanremo',
                'Santo Stefano al Mare',
                'Seborga',
                'Soldano',
                'Taggia',
                'Terzorio',
                'Triora',
                'Vallebona',
                'Vallecrosia',
                'Vasia',
                'Ventimiglia',
                'Vessalico',
                'Villa Faraldi'
            ],
            sigla: 'IM'
        },
        {
            name: 'Isernia',
            municipalities: [
                'Acquaviva d\'Isernia',
                'Agnone',
                'Bagnoli del Trigno',
                'Belmonte del Sannio',
                'Cantalupo nel Sannio',
                'Capracotta',
                'Carovilli',
                'Carpinone',
                'Castel del Giudice',
                'Castel San Vincenzo',
                'Castelpetroso',
                'Castelpizzuto',
                'Castelverrino',
                'Cerro al Volturno',
                'Chiauci',
                'Civitanova del Sannio',
                'Colli a Volturno',
                'Conca Casale',
                'Filignano',
                'Forlì del Sannio',
                'Fornelli',
                'Frosolone',
                'Isernia',
                'Longano',
                'Macchia d\'Isernia',
                'Macchiagodena',
                'Miranda',
                'Montaquila',
                'Montenero Val Cocchiara',
                'Monteroduni',
                'Pesche',
                'Pescolanciano',
                'Pescopennataro',
                'Pettoranello del Molise',
                'Pietrabbondante',
                'Pizzone',
                'Poggio Sannita',
                'Pozzilli',
                'Rionero Sannitico',
                'Roccamandolfi',
                'Roccasicura',
                'Rocchetta a Volturno',
                'San Pietro Avellana',
                'Sant\'Agapito',
                'Sant\'Angelo del Pesco',
                'Sant\'Elena Sannita',
                'Santa Maria del Molise',
                'Scapoli',
                'Sessano del Molise',
                'Sesto Campano',
                'Vastogirardi',
                'Venafro'
            ],
            sigla: 'IS'
        },
        {
            name: 'L\'Aquila',
            municipalities: [
                'Acciano',
                'Aielli',
                'Alfedena',
                'Anversa degli Abruzzi',
                'Ateleta',
                'Avezzano',
                'Balsorano',
                'Barete',
                'Barisciano',
                'Barrea',
                'Bisegna',
                'Bugnara',
                'Cagnano Amiterno',
                'Calascio',
                'Campo di Giove',
                'Campotosto',
                'Canistro',
                'Cansano',
                'Capestrano',
                'Capistrello',
                'Capitignano',
                'Caporciano',
                'Cappadocia',
                'Carapelle Calvisio',
                'Carsoli',
                'Castel del Monte',
                'Castel di Ieri',
                'Castel di Sangro',
                'Castellafiume',
                'Castelvecchio Calvisio',
                'Castelvecchio Subequo',
                'Celano',
                'Cerchio',
                'Civita d\'Antino',
                'Civitella Alfedena',
                'Civitella Roveto',
                'Cocullo',
                'Collarmele',
                'Collelongo',
                'Collepietro',
                'Corfinio',
                'Fagnano Alto',
                'Fontecchio',
                'Fossa',
                'Gagliano Aterno',
                'Gioia dei Marsi',
                'Goriano Sicoli',
                'Introdacqua',
                'L\'Aquila',
                'Lecce nei Marsi',
                'Luco dei Marsi',
                'Lucoli',
                'Magliano de\' Marsi',
                'Massa d\'Albe',
                'Molina Aterno',
                'Montereale',
                'Morino',
                'Navelli',
                'Ocre',
                'Ofena',
                'Opi',
                'Oricola',
                'Ortona dei Marsi',
                'Ortucchio',
                'Ovindoli',
                'Pacentro',
                'Pereto',
                'Pescasseroli',
                'Pescina',
                'Pescocostanzo',
                'Pettorano sul Gizio',
                'Pizzoli',
                'Poggio Picenze',
                'Prata d\'Ansidonia',
                'Pratola Peligna',
                'Prezza',
                'Raiano',
                'Rivisondoli',
                'Rocca di Botte',
                'Rocca di Cambio',
                'Rocca di Mezzo',
                'Rocca Pia',
                'Roccacasale',
                'Roccaraso',
                'San Benedetto dei Marsi',
                'San Benedetto in Perillis',
                'San Demetrio ne\' Vestini',
                'San Pio delle Camere',
                'San Vincenzo Valle Roveto',
                'Sant\'Eusanio Forconese',
                'Sante Marie',
                'Santo Stefano di Sessanio',
                'Scanno',
                'Scontrone',
                'Scoppito',
                'Scurcola Marsicana',
                'Secinaro',
                'Sulmona',
                'Tagliacozzo',
                'Tione degli Abruzzi',
                'Tornimparte',
                'Trasacco',
                'Villa Sant\'Angelo',
                'Villa Santa Lucia degli Abruzzi',
                'Villalago',
                'Villavallelonga',
                'Villetta Barrea',
                'Vittorito'
            ],
            sigla: 'AQ'
        },
        {
            name: 'La Spezia',
            municipalities: [
                'Ameglia',
                'Arcola',
                'Beverino',
                'Bolano',
                'Bonassola',
                'Borghetto di Vara',
                'Brugnato',
                'Calice al Cornoviglio',
                'Carro',
                'Carrodano',
                'Castelnuovo Magra',
                'Deiva Marina',
                'Follo',
                'Framura',
                'La Spezia',
                'Lerici',
                'Levanto',
                'Luni',
                'Maissana',
                'Monterosso al Mare',
                'Pignone',
                'Portovenere',
                'Riccò del Golfo di Spezia',
                'Riomaggiore',
                'Rocchetta di Vara',
                'Santo Stefano di Magra',
                'Sarzana',
                'Sesta Godano',
                'Varese Ligure',
                'Vernazza',
                'Vezzano Ligure',
                'Zignago'
            ],
            sigla: 'SP'
        },
        {
            name: 'Latina',
            municipalities: [
                'Aprilia',
                'Bassiano',
                'Campodimele',
                'Castelforte',
                'Cisterna di Latina',
                'Cori',
                'Fondi',
                'Formia',
                'Gaeta',
                'Itri',
                'Latina',
                'Lenola',
                'Maenza',
                'Minturno',
                'Monte San Biagio',
                'Norma',
                'Pontinia',
                'Ponza',
                'Priverno',
                'Prossedi',
                'Rocca Massima',
                'Roccagorga',
                'Roccasecca dei Volsci',
                'Sabaudia',
                'San Felice Circeo',
                'Santi Cosma e Damiano',
                'Sermoneta',
                'Sezze',
                'Sonnino',
                'Sperlonga',
                'Spigno Saturnia',
                'Terracina',
                'Ventotene'
            ],
            sigla: 'LT'
        },
        {
            name: 'Lecce',
            municipalities: [
                'Alessano',
                'Alezio',
                'Alliste',
                'Andrano',
                'Aradeo',
                'Arnesano',
                'Bagnolo del Salento',
                'Botrugno',
                'Calimera',
                'Campi Salentina',
                'Cannole',
                'Caprarica di Lecce',
                'Carmiano',
                'Carpignano Salentino',
                'Casarano',
                'Castri di Lecce',
                'Castrignano de\' Greci',
                'Castrignano del Capo',
                'Castro',
                'Cavallino',
                'Collepasso',
                'Copertino',
                'Corigliano d\'Otranto',
                'Corsano',
                'Cursi',
                'Cutrofiano',
                'Diso',
                'Gagliano del Capo',
                'Galatina',
                'Galatone',
                'Gallipoli',
                'Giuggianello',
                'Giurdignano',
                'Guagnano',
                'Lecce',
                'Lequile',
                'Leverano',
                'Lizzanello',
                'Maglie',
                'Martano',
                'Martignano',
                'Matino',
                'Melendugno',
                'Melissano',
                'Melpignano',
                'Miggiano',
                'Minervino di Lecce',
                'Monteroni di Lecce',
                'Montesano Salentino',
                'Morciano di Leuca',
                'Muro Leccese',
                'Nardò',
                'Neviano',
                'Nociglia',
                'Novoli',
                'Ortelle',
                'Otranto',
                'Palmariggi',
                'Parabita',
                'Patù',
                'Poggiardo',
                'Porto Cesareo',
                'Presicce-Acquarica',
                'Racale',
                'Ruffano',
                'Salice Salentino',
                'Salve',
                'San Cassiano',
                'San Cesario di Lecce',
                'San Donato di Lecce',
                'San Pietro in Lama',
                'Sanarica',
                'Sannicola',
                'Santa Cesarea Terme',
                'Scorrano',
                'Seclì',
                'Sogliano Cavour',
                'Soleto',
                'Specchia',
                'Spongano',
                'Squinzano',
                'Sternatia',
                'Supersano',
                'Surano',
                'Surbo',
                'Taurisano',
                'Taviano',
                'Tiggiano',
                'Trepuzzi',
                'Tricase',
                'Tuglie',
                'Ugento',
                'Uggiano la Chiesa',
                'Veglie',
                'Vernole',
                'Zollino'
            ],
            sigla: 'LE'
        },
        {
            name: 'Lecco',
            municipalities: [
                'Abbadia Lariana',
                'Airuno',
                'Annone di Brianza',
                'Ballabio',
                'Barzago',
                'Barzanò',
                'Barzio',
                'Bellano',
                'Bosisio Parini',
                'Brivio',
                'Bulciago',
                'Calco',
                'Calolziocorte',
                'Carenno',
                'Casargo',
                'Casatenovo',
                'Cassago Brianza',
                'Cassina Valsassina',
                'Castello di Brianza',
                'Cernusco Lombardone',
                'Cesana Brianza',
                'Civate',
                'Colico',
                'Colle Brianza',
                'Cortenova',
                'Costa Masnaga',
                'Crandola Valsassina',
                'Cremella',
                'Cremeno',
                'Dervio',
                'Dolzago',
                'Dorio',
                'Ello',
                'Erve',
                'Esino Lario',
                'Galbiate',
                'Garbagnate Monastero',
                'Garlate',
                'Imbersago',
                'Introbio',
                'La Valletta Brianza',
                'Lecco',
                'Lierna',
                'Lomagna',
                'Malgrate',
                'Mandello del Lario',
                'Margno',
                'Merate',
                'Missaglia',
                'Moggio',
                'Molteno',
                'Monte Marenzo',
                'Montevecchia',
                'Monticello Brianza',
                'Morterone',
                'Nibionno',
                'Oggiono',
                'Olgiate Molgora',
                'Olginate',
                'Oliveto Lario',
                'Osnago',
                'Paderno d\'Adda',
                'Pagnona',
                'Parlasco',
                'Pasturo',
                'Perledo',
                'Pescate',
                'Premana',
                'Primaluna',
                'Robbiate',
                'Rogeno',
                'Santa Maria Hoè',
                'Sirone',
                'Sirtori',
                'Sueglio',
                'Suello',
                'Taceno',
                'Valgreghentino',
                'Valmadrera',
                'Valvarrone',
                'Varenna',
                'Vercurago',
                'Verderio',
                'Viganò'
            ],
            sigla: 'LC'
        },
        {
            name: 'Livorno',
            municipalities: [
                'Bibbona',
                'Campiglia Marittima',
                'Campo nell\'Elba',
                'Capoliveri',
                'Capraia Isola',
                'Castagneto Carducci',
                'Cecina',
                'Collesalvetti',
                'Livorno',
                'Marciana',
                'Marciana Marina',
                'Piombino',
                'Porto Azzurro',
                'Portoferraio',
                'Rio',
                'Rosignano Marittimo',
                'San Vincenzo',
                'Sassetta',
                'Suvereto'
            ],
            sigla: 'LI'
        },
        {
            name: 'Lodi',
            municipalities: [
                'Abbadia Cerreto',
                'Bertonico',
                'Boffalora d\'Adda',
                'Borghetto Lodigiano',
                'Borgo San Giovanni',
                'Brembio',
                'Casaletto Lodigiano',
                'Casalmaiocco',
                'Casalpusterlengo',
                'Caselle Landi',
                'Caselle Lurani',
                'Castelgerundo',
                'Castelnuovo Bocca d\'Adda',
                'Castiglione d\'Adda',
                'Castiraga Vidardo',
                'Cavenago d\'Adda',
                'Cervignano d\'Adda',
                'Codogno',
                'Comazzo',
                'Cornegliano Laudense',
                'Corno Giovine',
                'Cornovecchio',
                'Corte Palasio',
                'Crespiatica',
                'Fombio',
                'Galgagnano',
                'Graffignana',
                'Guardamiglio',
                'Livraga',
                'Lodi',
                'Lodi Vecchio',
                'Maccastorna',
                'Mairago',
                'Maleo',
                'Marudo',
                'Massalengo',
                'Meleti',
                'Merlino',
                'Montanaso Lombardo',
                'Mulazzano',
                'Orio Litta',
                'Ospedaletto Lodigiano',
                'Ossago Lodigiano',
                'Pieve Fissiraga',
                'Salerano sul Lambro',
                'San Fiorano',
                'San Martino in Strada',
                'San Rocco al Porto',
                'Sant\'Angelo Lodigiano',
                'Santo Stefano Lodigiano',
                'Secugnago',
                'Senna Lodigiana',
                'Somaglia',
                'Sordio',
                'Tavazzano con Villavesco',
                'Terranova dei Passerini',
                'Turano Lodigiano',
                'Valera Fratta',
                'Villanova del Sillaro',
                'Zelo Buon Persico'
            ],
            sigla: 'LO'
        },
        {
            name: 'Lucca',
            municipalities: [
                'Altopascio',
                'Bagni di Lucca',
                'Barga',
                'Borgo a Mozzano',
                'Camaiore',
                'Camporgiano',
                'Capannori',
                'Careggine',
                'Castelnuovo di Garfagnana',
                'Castiglione di Garfagnana',
                'Coreglia Antelminelli',
                'Fabbriche di Vergemoli',
                'Forte dei Marmi',
                'Fosciandora',
                'Gallicano',
                'Lucca',
                'Massarosa',
                'Minucciano',
                'Molazzana',
                'Montecarlo',
                'Pescaglia',
                'Piazza al Serchio',
                'Pietrasanta',
                'Pieve Fosciana',
                'Porcari',
                'San Romano in Garfagnana',
                'Seravezza',
                'Sillano Giuncugnano',
                'Stazzema',
                'Vagli Sotto',
                'Viareggio',
                'Villa Basilica',
                'Villa Collemandina'
            ],
            sigla: 'LU'
        },
        {
            name: 'Macerata',
            municipalities: [
                'Apiro',
                'Appignano',
                'Belforte del Chienti',
                'Bolognola',
                'Caldarola',
                'Camerino',
                'Camporotondo di Fiastrone',
                'Castelraimondo',
                'Castelsantangelo sul Nera',
                'Cessapalombo',
                'Cingoli',
                'Civitanova Marche',
                'Colmurano',
                'Corridonia',
                'Esanatoglia',
                'Fiastra',
                'Fiuminata',
                'Gagliole',
                'Gualdo',
                'Loro Piceno',
                'Macerata',
                'Matelica',
                'Mogliano',
                'Monte Cavallo',
                'Monte San Giusto',
                'Monte San Martino',
                'Montecassiano',
                'Montecosaro',
                'Montefano',
                'Montelupone',
                'Morrovalle',
                'Muccia',
                'Penna San Giovanni',
                'Petriolo',
                'Pieve Torina',
                'Pioraco',
                'Poggio San Vicino',
                'Pollenza',
                'Porto Recanati',
                'Potenza Picena',
                'Recanati',
                'Ripe San Ginesio',
                'San Ginesio',
                'San Severino Marche',
                'Sant\'Angelo in Pontano',
                'Sarnano',
                'Sefro',
                'Serrapetrona',
                'Serravalle di Chienti',
                'Tolentino',
                'Treia',
                'Urbisaglia',
                'Ussita',
                'Valfornace',
                'Visso'
            ],
            sigla: 'MC'
        },
        {
            name: 'Mantova',
            municipalities: [
                'Acquanegra sul Chiese',
                'Asola',
                'Bagnolo San Vito',
                'Borgo Mantovano',
                'Borgo Virgilio',
                'Borgocarbonara',
                'Bozzolo',
                'Canneto sull\'Oglio',
                'Casalmoro',
                'Casaloldo',
                'Casalromano',
                'Castel d\'Ario',
                'Castel Goffredo',
                'Castelbelforte',
                'Castellucchio',
                'Castiglione delle Stiviere',
                'Cavriana',
                'Ceresara',
                'Commessaggio',
                'Curtatone',
                'Dosolo',
                'Gazoldo degli Ippoliti',
                'Gazzuolo',
                'Goito',
                'Gonzaga',
                'Guidizzolo',
                'Magnacavallo',
                'Mantova',
                'Marcaria',
                'Mariana Mantovana',
                'Marmirolo',
                'Medole',
                'Moglia',
                'Monzambano',
                'Motteggiana',
                'Ostiglia',
                'Pegognaga',
                'Piubega',
                'Poggio Rusco',
                'Pomponesco',
                'Ponti sul Mincio',
                'Porto Mantovano',
                'Quingentole',
                'Quistello',
                'Redondesco',
                'Rivarolo Mantovano',
                'Rodigo',
                'Roncoferraro',
                'Roverbella',
                'Sabbioneta',
                'San Benedetto Po',
                'San Giacomo delle Segnate',
                'San Giorgio Bigarello',
                'San Giovanni del Dosso',
                'San Martino dall\'Argine',
                'Schivenoglia',
                'Sermide e Felonica',
                'Serravalle a Po',
                'Solferino',
                'Sustinente',
                'Suzzara',
                'Viadana',
                'Villimpenta',
                'Volta Mantovana'
            ],
            sigla: 'MN'
        },
        {
            name: 'Massa-Carrara',
            municipalities: [
                'Aulla',
                'Bagnone',
                'Carrara',
                'Casola in Lunigiana',
                'Comano',
                'Filattiera',
                'Fivizzano',
                'Fosdinovo',
                'Licciana Nardi',
                'Massa',
                'Montignoso',
                'Mulazzo',
                'Podenzana',
                'Pontremoli',
                'Tresana',
                'Villafranca in Lunigiana',
                'Zeri'
            ],
            sigla: 'MS'
        },
        {
            name: 'Matera',
            municipalities: [
                'Accettura',
                'Aliano',
                'Bernalda',
                'Calciano',
                'Cirigliano',
                'Colobraro',
                'Craco',
                'Ferrandina',
                'Garaguso',
                'Gorgoglione',
                'Grassano',
                'Grottole',
                'Irsina',
                'Matera',
                'Miglionico',
                'Montalbano Jonico',
                'Montescaglioso',
                'Nova Siri',
                'Oliveto Lucano',
                'Pisticci',
                'Policoro',
                'Pomarico',
                'Rotondella',
                'Salandra',
                'San Giorgio Lucano',
                'San Mauro Forte',
                'Scanzano Jonico',
                'Stigliano',
                'Tricarico',
                'Tursi',
                'Valsinni'
            ],
            sigla: 'MT'
        },
        {
            name: 'Messina',
            municipalities: [
                'Acquedolci',
                'Alcara li Fusi',
                'Alì',
                'Alì Terme',
                'Antillo',
                'Barcellona Pozzo di Gotto',
                'Basicò',
                'Brolo',
                'Capizzi',
                'Capo d\'Orlando',
                'Capri Leone',
                'Caronia',
                'Casalvecchio Siculo',
                'Castel di Lucio',
                'Castell\'Umberto',
                'Castelmola',
                'Castroreale',
                'Cesarò',
                'Condrò',
                'Falcone',
                'Ficarra',
                'Fiumedinisi',
                'Floresta',
                'Fondachelli-Fantina',
                'Forza d\'Agrò',
                'Francavilla di Sicilia',
                'Frazzanò',
                'Furci Siculo',
                'Furnari',
                'Gaggi',
                'Galati Mamertino',
                'Gallodoro',
                'Giardini-Naxos',
                'Gioiosa Marea',
                'Graniti',
                'Gualtieri Sicaminò',
                'Itala',
                'Leni',
                'Letojanni',
                'Librizzi',
                'Limina',
                'Lipari',
                'Longi',
                'Malfa',
                'Malvagna',
                'Mandanici',
                'Mazzarrà Sant\'Andrea',
                'Merì',
                'Messina',
                'Milazzo',
                'Militello Rosmarino',
                'Mirto',
                'Mistretta',
                'Moio Alcantara',
                'Monforte San Giorgio',
                'Mongiuffi Melia',
                'Montagnareale',
                'Montalbano Elicona',
                'Motta Camastra',
                'Motta d\'Affermo',
                'Naso',
                'Nizza di Sicilia',
                'Novara di Sicilia',
                'Oliveri',
                'Pace del Mela',
                'Pagliara',
                'Patti',
                'Pettineo',
                'Piraino',
                'Raccuja',
                'Reitano',
                'Roccafiorita',
                'Roccalumera',
                'Roccavaldina',
                'Roccella Valdemone',
                'Rodì Milici',
                'Rometta',
                'San Filippo del Mela',
                'San Fratello',
                'San Marco d\'Alunzio',
                'San Pier Niceto',
                'San Piero Patti',
                'San Salvatore di Fitalia',
                'San Teodoro',
                'Sant\'Agata di Militello',
                'Sant\'Alessio Siculo',
                'Sant\'Angelo di Brolo',
                'Santa Domenica Vittoria',
                'Santa Lucia del Mela',
                'Santa Marina Salina',
                'Santa Teresa di Riva',
                'Santo Stefano di Camastra',
                'Saponara',
                'Savoca',
                'Scaletta Zanclea',
                'Sinagra',
                'Spadafora',
                'Taormina',
                'Terme Vigliatore',
                'Torregrotta',
                'Torrenova',
                'Tortorici',
                'Tripi',
                'Tusa',
                'Ucria',
                'Valdina',
                'Venetico',
                'Villafranca Tirrena'
            ],
            sigla: 'ME'
        },
        {
            name: 'Milano',
            municipalities: [
                'Abbiategrasso',
                'Albairate',
                'Arconate',
                'Arese',
                'Arluno',
                'Assago',
                'Baranzate',
                'Bareggio',
                'Basiano',
                'Basiglio',
                'Bellinzago Lombardo',
                'Bernate Ticino',
                'Besate',
                'Binasco',
                'Boffalora sopra Ticino',
                'Bollate',
                'Bresso',
                'Bubbiano',
                'Buccinasco',
                'Buscate',
                'Bussero',
                'Busto Garolfo',
                'Calvignasco',
                'Cambiago',
                'Canegrate',
                'Carpiano',
                'Carugate',
                'Casarile',
                'Casorezzo',
                'Cassano d\'Adda',
                'Cassina de\' Pecchi',
                'Cassinetta di Lugagnano',
                'Castano Primo',
                'Cernusco sul Naviglio',
                'Cerro al Lambro',
                'Cerro Maggiore',
                'Cesano Boscone',
                'Cesate',
                'Cinisello Balsamo',
                'Cisliano',
                'Cologno Monzese',
                'Colturano',
                'Corbetta',
                'Cormano',
                'Cornaredo',
                'Corsico',
                'Cuggiono',
                'Cusago',
                'Cusano Milanino',
                'Dairago',
                'Dresano',
                'Gaggiano',
                'Garbagnate Milanese',
                'Gessate',
                'Gorgonzola',
                'Grezzago',
                'Gudo Visconti',
                'Inveruno',
                'Inzago',
                'Lacchiarella',
                'Lainate',
                'Legnano',
                'Liscate',
                'Locate di Triulzi',
                'Magenta',
                'Magnago',
                'Marcallo con Casone',
                'Masate',
                'Mediglia',
                'Melegnano',
                'Melzo',
                'Mesero',
                'Milano',
                'Morimondo',
                'Motta Visconti',
                'Nerviano',
                'Nosate',
                'Novate Milanese',
                'Noviglio',
                'Opera',
                'Ossona',
                'Ozzero',
                'Paderno Dugnano',
                'Pantigliate',
                'Parabiago',
                'Paullo',
                'Pero',
                'Peschiera Borromeo',
                'Pessano con Bornago',
                'Pieve Emanuele',
                'Pioltello',
                'Pogliano Milanese',
                'Pozzo d\'Adda',
                'Pozzuolo Martesana',
                'Pregnana Milanese',
                'Rescaldina',
                'Rho',
                'Robecchetto con Induno',
                'Robecco sul Naviglio',
                'Rodano',
                'Rosate',
                'Rozzano',
                'San Colombano al Lambro',
                'San Donato Milanese',
                'San Giorgio su Legnano',
                'San Giuliano Milanese',
                'San Vittore Olona',
                'San Zenone al Lambro',
                'Santo Stefano Ticino',
                'Sedriano',
                'Segrate',
                'Senago',
                'Sesto San Giovanni',
                'Settala',
                'Settimo Milanese',
                'Solaro',
                'Trezzano Rosa',
                'Trezzano sul Naviglio',
                'Trezzo sull\'Adda',
                'Tribiano',
                'Truccazzano',
                'Turbigo',
                'Vanzaghello',
                'Vanzago',
                'Vaprio d\'Adda',
                'Vermezzo con Zelo',
                'Vernate',
                'Vignate',
                'Villa Cortese',
                'Vimodrone',
                'Vittuone',
                'Vizzolo Predabissi',
                'Zibido San Giacomo'
            ],
            sigla: 'MI'
        },
        {
            name: 'Modena',
            municipalities: [
                'Bastiglia',
                'Bomporto',
                'Campogalliano',
                'Camposanto',
                'Carpi',
                'Castelfranco Emilia',
                'Castelnuovo Rangone',
                'Castelvetro di Modena',
                'Cavezzo',
                'Concordia sulla Secchia',
                'Fanano',
                'Finale Emilia',
                'Fiorano Modenese',
                'Fiumalbo',
                'Formigine',
                'Frassinoro',
                'Guiglia',
                'Lama Mocogno',
                'Maranello',
                'Marano sul Panaro',
                'Medolla',
                'Mirandola',
                'Modena',
                'Montecreto',
                'Montefiorino',
                'Montese',
                'Nonantola',
                'Novi di Modena',
                'Palagano',
                'Pavullo nel Frignano',
                'Pievepelago',
                'Polinago',
                'Prignano sulla Secchia',
                'Ravarino',
                'Riolunato',
                'San Cesario sul Panaro',
                'San Felice sul Panaro',
                'San Possidonio',
                'San Prospero',
                'Sassuolo',
                'Savignano sul Panaro',
                'Serramazzoni',
                'Sestola',
                'Soliera',
                'Spilamberto',
                'Vignola',
                'Zocca'
            ],
            sigla: 'MO'
        },
        {
            name: 'Monza e della Brianza',
            municipalities: [
                'Agrate Brianza',
                'Aicurzio',
                'Albiate',
                'Arcore',
                'Barlassina',
                'Bellusco',
                'Bernareggio',
                'Besana in Brianza',
                'Biassono',
                'Bovisio-Masciago',
                'Briosco',
                'Brugherio',
                'Burago di Molgora',
                'Busnago',
                'Camparada',
                'Caponago',
                'Carate Brianza',
                'Carnate',
                'Cavenago di Brianza',
                'Ceriano Laghetto',
                'Cesano Maderno',
                'Cogliate',
                'Concorezzo',
                'Cornate d\'Adda',
                'Correzzana',
                'Desio',
                'Giussano',
                'Lazzate',
                'Lentate sul Seveso',
                'Lesmo',
                'Limbiate',
                'Lissone',
                'Macherio',
                'Meda',
                'Mezzago',
                'Misinto',
                'Monza',
                'Muggiò',
                'Nova Milanese',
                'Ornago',
                'Renate',
                'Roncello',
                'Ronco Briantino',
                'Seregno',
                'Seveso',
                'Sovico',
                'Sulbiate',
                'Triuggio',
                'Usmate Velate',
                'Varedo',
                'Vedano al Lambro',
                'Veduggio con Colzano',
                'Verano Brianza',
                'Villasanta',
                'Vimercate'
            ],
            sigla: 'MB'
        },
        {
            name: 'Napoli',
            municipalities: [
                'Acerra',
                'Afragola',
                'Agerola',
                'Anacapri',
                'Arzano',
                'Bacoli',
                'Barano d\'Ischia',
                'Boscoreale',
                'Boscotrecase',
                'Brusciano',
                'Caivano',
                'Calvizzano',
                'Camposano',
                'Capri',
                'Carbonara di Nola',
                'Cardito',
                'Casalnuovo di Napoli',
                'Casamarciano',
                'Casamicciola Terme',
                'Casandrino',
                'Casavatore',
                'Casola di Napoli',
                'Casoria',
                'Castellammare di Stabia',
                'Castello di Cisterna',
                'Cercola',
                'Cicciano',
                'Cimitile',
                'Comiziano',
                'Crispano',
                'Ercolano',
                'Forio',
                'Frattamaggiore',
                'Frattaminore',
                'Giugliano in Campania',
                'Gragnano',
                'Grumo Nevano',
                'Ischia',
                'Lacco Ameno',
                'Lettere',
                'Liveri',
                'Marano di Napoli',
                'Mariglianella',
                'Marigliano',
                'Massa di Somma',
                'Massa Lubrense',
                'Melito di Napoli',
                'Meta',
                'Monte di Procida',
                'Mugnano di Napoli',
                'Napoli',
                'Nola',
                'Ottaviano',
                'Palma Campania',
                'Piano di Sorrento',
                'Pimonte',
                'Poggiomarino',
                'Pollena Trocchia',
                'Pomigliano d\'Arco',
                'Pompei',
                'Portici',
                'Pozzuoli',
                'Procida',
                'Qualiano',
                'Quarto',
                'Roccarainola',
                'San Gennaro Vesuviano',
                'San Giorgio a Cremano',
                'San Giuseppe Vesuviano',
                'San Paolo Bel Sito',
                'San Sebastiano al Vesuvio',
                'San Vitaliano',
                'Sant\'Agnello',
                'Sant\'Anastasia',
                'Sant\'Antimo',
                'Sant\'Antonio Abate',
                'Santa Maria la Carità',
                'Saviano',
                'Scisciano',
                'Serrara Fontana',
                'Somma Vesuviana',
                'Sorrento',
                'Striano',
                'Terzigno',
                'Torre Annunziata',
                'Torre del Greco',
                'Trecase',
                'Tufino',
                'Vico Equense',
                'Villaricca',
                'Visciano',
                'Volla'
            ],
            sigla: 'NA'
        },
        {
            name: 'Novara',
            municipalities: [
                'Agrate Conturbia',
                'Ameno',
                'Armeno',
                'Arona',
                'Barengo',
                'Bellinzago Novarese',
                'Biandrate',
                'Boca',
                'Bogogno',
                'Bolzano Novarese',
                'Borgo Ticino',
                'Borgolavezzaro',
                'Borgomanero',
                'Briga Novarese',
                'Briona',
                'Caltignaga',
                'Cameri',
                'Carpignano Sesia',
                'Casalbeltrame',
                'Casaleggio Novara',
                'Casalino',
                'Casalvolone',
                'Castellazzo Novarese',
                'Castelletto sopra Ticino',
                'Cavaglietto',
                'Cavaglio d\'Agogna',
                'Cavallirio',
                'Cerano',
                'Colazza',
                'Comignago',
                'Cressa',
                'Cureggio',
                'Divignano',
                'Dormelletto',
                'Fara Novarese',
                'Fontaneto d\'Agogna',
                'Galliate',
                'Garbagna Novarese',
                'Gargallo',
                'Gattico-Veruno',
                'Ghemme',
                'Gozzano',
                'Granozzo con Monticello',
                'Grignasco',
                'Invorio',
                'Landiona',
                'Lesa',
                'Maggiora',
                'Mandello Vitta',
                'Marano Ticino',
                'Massino Visconti',
                'Meina',
                'Mezzomerico',
                'Miasino',
                'Momo',
                'Nebbiuno',
                'Nibbiola',
                'Novara',
                'Oleggio',
                'Oleggio Castello',
                'Orta San Giulio',
                'Paruzzaro',
                'Pella',
                'Pettenasco',
                'Pisano',
                'Pogno',
                'Pombia',
                'Prato Sesia',
                'Recetto',
                'Romagnano Sesia',
                'Romentino',
                'San Maurizio d\'Opaglio',
                'San Nazzaro Sesia',
                'San Pietro Mosezzo',
                'Sillavengo',
                'Sizzano',
                'Soriso',
                'Sozzago',
                'Suno',
                'Terdobbiate',
                'Tornaco',
                'Trecate',
                'Vaprio d\'Agogna',
                'Varallo Pombia',
                'Vespolate',
                'Vicolungo',
                'Vinzaglio'
            ],
            sigla: 'NO'
        },
        {
            name: 'Nuoro',
            municipalities: [
                'Aritzo',
                'Arzana',
                'Atzara',
                'Austis',
                'Bari Sardo',
                'Baunei',
                'Belvì',
                'Birori',
                'Bitti',
                'Bolotana',
                'Borore',
                'Bortigali',
                'Cardedu',
                'Desulo',
                'Dorgali',
                'Dualchi',
                'Elini',
                'Fonni',
                'Gadoni',
                'Gairo',
                'Galtellì',
                'Gavoi',
                'Girasole',
                'Ilbono',
                'Irgoli',
                'Jerzu',
                'Lanusei',
                'Lei',
                'Loceri',
                'Loculi',
                'Lodè',
                'Lodine',
                'Lotzorai',
                'Lula',
                'Macomer',
                'Mamoiada',
                'Meana Sardo',
                'Noragugume',
                'Nuoro',
                'Oliena',
                'Ollolai',
                'Olzai',
                'Onanì',
                'Onifai',
                'Oniferi',
                'Orani',
                'Orgosolo',
                'Orosei',
                'Orotelli',
                'Ortueri',
                'Orune',
                'Osidda',
                'Osini',
                'Ottana',
                'Ovodda',
                'Perdasdefogu',
                'Posada',
                'Sarule',
                'Silanus',
                'Sindia',
                'Siniscola',
                'Sorgono',
                'Talana',
                'Tertenia',
                'Teti',
                'Tiana',
                'Tonara',
                'Torpè',
                'Tortolì',
                'Triei',
                'Ulassai',
                'Urzulei',
                'Ussassai',
                'Villagrande Strisaili'
            ],
            sigla: 'NU'
        },
        {
            name: 'Oristano',
            municipalities: [
                'Abbasanta',
                'Aidomaggiore',
                'Albagiara',
                'Ales',
                'Allai',
                'Arborea',
                'Ardauli',
                'Assolo',
                'Asuni',
                'Baradili',
                'Baratili San Pietro',
                'Baressa',
                'Bauladu',
                'Bidonì',
                'Bonarcado',
                'Boroneddu',
                'Bosa',
                'Busachi',
                'Cabras',
                'Cuglieri',
                'Curcuris',
                'Flussio',
                'Fordongianus',
                'Ghilarza',
                'Gonnoscodina',
                'Gonnosnò',
                'Gonnostramatza',
                'Laconi',
                'Magomadas',
                'Marrubiu',
                'Masullas',
                'Milis',
                'Modolo',
                'Mogorella',
                'Mogoro',
                'Montresta',
                'Morgongiori',
                'Narbolia',
                'Neoneli',
                'Norbello',
                'Nughedu Santa Vittoria',
                'Nurachi',
                'Nureci',
                'Ollastra',
                'Oristano',
                'Palmas Arborea',
                'Pau',
                'Paulilatino',
                'Pompu',
                'Riola Sardo',
                'Ruinas',
                'Sagama',
                'Samugheo',
                'San Nicolò d\'Arcidano',
                'San Vero Milis',
                'Santa Giusta',
                'Santu Lussurgiu',
                'Scano di Montiferro',
                'Sedilo',
                'Seneghe',
                'Senis',
                'Sennariolo',
                'Siamaggiore',
                'Siamanna',
                'Siapiccia',
                'Simala',
                'Simaxis',
                'Sini',
                'Siris',
                'Soddì',
                'Solarussa',
                'Sorradile',
                'Suni',
                'Tadasuni',
                'Terralba',
                'Tinnura',
                'Tramatza',
                'Tresnuraghes',
                'Ulà Tirso',
                'Uras',
                'Usellus',
                'Villa Sant\'Antonio',
                'Villa Verde',
                'Villanova Truschedu',
                'Villaurbana',
                'Zeddiani',
                'Zerfaliu'
            ],
            sigla: 'OR'
        },
        {
            name: 'Padova',
            municipalities: [
                'Abano Terme',
                'Agna',
                'Albignasego',
                'Anguillara Veneta',
                'Arquà Petrarca',
                'Arre',
                'Arzergrande',
                'Bagnoli di Sopra',
                'Baone',
                'Barbona',
                'Battaglia Terme',
                'Boara Pisani',
                'Borgo Veneto',
                'Borgoricco',
                'Bovolenta',
                'Brugine',
                'Cadoneghe',
                'Campo San Martino',
                'Campodarsego',
                'Campodoro',
                'Camposampiero',
                'Candiana',
                'Carceri',
                'Carmignano di Brenta',
                'Cartura',
                'Casale di Scodosia',
                'Casalserugo',
                'Castelbaldo',
                'Cervarese Santa Croce',
                'Cinto Euganeo',
                'Cittadella',
                'Codevigo',
                'Conselve',
                'Correzzola',
                'Curtarolo',
                'Due Carrare',
                'Este',
                'Fontaniva',
                'Galliera Veneta',
                'Galzignano Terme',
                'Gazzo',
                'Grantorto',
                'Granze',
                'Legnaro',
                'Limena',
                'Loreggia',
                'Lozzo Atestino',
                'Maserà di Padova',
                'Masi',
                'Massanzago',
                'Megliadino San Vitale',
                'Merlara',
                'Mestrino',
                'Monselice',
                'Montagnana',
                'Montegrotto Terme',
                'Noventa Padovana',
                'Ospedaletto Euganeo',
                'Padova',
                'Pernumia',
                'Piacenza d\'Adige',
                'Piazzola sul Brenta',
                'Piombino Dese',
                'Piove di Sacco',
                'Polverara',
                'Ponso',
                'Ponte San Nicolò',
                'Pontelongo',
                'Pozzonovo',
                'Rovolon',
                'Rubano',
                'Saccolongo',
                'San Giorgio delle Pertiche',
                'San Giorgio in Bosco',
                'San Martino di Lupari',
                'San Pietro in Gu',
                'San Pietro Viminario',
                'Sant\'Angelo di Piove di Sacco',
                'Sant\'Elena',
                'Sant\'Urbano',
                'Santa Giustina in Colle',
                'Saonara',
                'Selvazzano Dentro',
                'Solesino',
                'Stanghella',
                'Teolo',
                'Terrassa Padovana',
                'Tombolo',
                'Torreglia',
                'Trebaseleghe',
                'Tribano',
                'Urbana',
                'Veggiano',
                'Vescovana',
                'Vighizzolo d\'Este',
                'Vigodarzere',
                'Vigonza',
                'Villa del Conte',
                'Villa Estense',
                'Villafranca Padovana',
                'Villanova di Camposampiero',
                'Vo\''
            ],
            sigla: 'PD'
        },
        {
            name: 'Palermo',
            municipalities: [
                'Alia',
                'Alimena',
                'Aliminusa',
                'Altavilla Milicia',
                'Altofonte',
                'Bagheria',
                'Balestrate',
                'Baucina',
                'Belmonte Mezzagno',
                'Bisacquino',
                'Blufi',
                'Bolognetta',
                'Bompietro',
                'Borgetto',
                'Caccamo',
                'Caltavuturo',
                'Campofelice di Fitalia',
                'Campofelice di Roccella',
                'Campofiorito',
                'Camporeale',
                'Capaci',
                'Carini',
                'Castelbuono',
                'Casteldaccia',
                'Castellana Sicula',
                'Castronovo di Sicilia',
                'Cefalà Diana',
                'Cefalù',
                'Cerda',
                'Chiusa Sclafani',
                'Ciminna',
                'Cinisi',
                'Collesano',
                'Contessa Entellina',
                'Corleone',
                'Ficarazzi',
                'Gangi',
                'Geraci Siculo',
                'Giardinello',
                'Giuliana',
                'Godrano',
                'Gratteri',
                'Isnello',
                'Isola delle Femmine',
                'Lascari',
                'Lercara Friddi',
                'Marineo',
                'Mezzojuso',
                'Misilmeri',
                'Monreale',
                'Montelepre',
                'Montemaggiore Belsito',
                'Palazzo Adriano',
                'Palermo',
                'Partinico',
                'Petralia Soprana',
                'Petralia Sottana',
                'Piana degli Albanesi',
                'Polizzi Generosa',
                'Pollina',
                'Prizzi',
                'Roccamena',
                'Roccapalumba',
                'San Cipirello',
                'San Giuseppe Jato',
                'San Mauro Castelverde',
                'Santa Cristina Gela',
                'Santa Flavia',
                'Sciara',
                'Scillato',
                'Sclafani Bagni',
                'Termini Imerese',
                'Terrasini',
                'Torretta',
                'Trabia',
                'Trappeto',
                'Ustica',
                'Valledolmo',
                'Ventimiglia di Sicilia',
                'Vicari',
                'Villabate',
                'Villafrati'
            ],
            sigla: 'PA'
        },
        {
            name: 'Parma',
            municipalities: [
                'Albareto',
                'Bardi',
                'Bedonia',
                'Berceto',
                'Bore',
                'Borgo Val di Taro',
                'Busseto',
                'Calestano',
                'Collecchio',
                'Colorno',
                'Compiano',
                'Corniglio',
                'Felino',
                'Fidenza',
                'Fontanellato',
                'Fontevivo',
                'Fornovo di Taro',
                'Langhirano',
                'Lesignano de\' Bagni',
                'Medesano',
                'Monchio delle Corti',
                'Montechiarugolo',
                'Neviano degli Arduini',
                'Noceto',
                'Palanzano',
                'Parma',
                'Pellegrino Parmense',
                'Polesine Zibello',
                'Roccabianca',
                'Sala Baganza',
                'Salsomaggiore Terme',
                'San Secondo Parmense',
                'Sissa Trecasali',
                'Solignano',
                'Soragna',
                'Sorbolo Mezzani',
                'Terenzo',
                'Tizzano Val Parma',
                'Tornolo',
                'Torrile',
                'Traversetolo',
                'Valmozzola',
                'Varano de\' Melegari',
                'Varsi'
            ],
            sigla: 'PR'
        },
        {
            name: 'Pavia',
            municipalities: [
                'Alagna',
                'Albaredo Arnaboldi',
                'Albonese',
                'Albuzzano',
                'Arena Po',
                'Badia Pavese',
                'Bagnaria',
                'Barbianello',
                'Bascapè',
                'Bastida Pancarana',
                'Battuda',
                'Belgioioso',
                'Bereguardo',
                'Borgarello',
                'Borgo Priolo',
                'Borgo San Siro',
                'Borgoratto Mormorolo',
                'Bornasco',
                'Bosnasco',
                'Brallo di Pregola',
                'Breme',
                'Bressana Bottarone',
                'Broni',
                'Calvignano',
                'Campospinoso',
                'Candia Lomellina',
                'Canneto Pavese',
                'Carbonara al Ticino',
                'Casanova Lonati',
                'Casatisma',
                'Casei Gerola',
                'Casorate Primo',
                'Cassolnovo',
                'Castana',
                'Casteggio',
                'Castelletto di Branduzzo',
                'Castello d\'Agogna',
                'Castelnovetto',
                'Cava Manara',
                'Cecima',
                'Ceranova',
                'Ceretto Lomellina',
                'Cergnago',
                'Certosa di Pavia',
                'Cervesina',
                'Chignolo Po',
                'Cigognola',
                'Cilavegna',
                'Codevilla',
                'Colli Verdi',
                'Confienza',
                'Copiano',
                'Corana',
                'Cornale e Bastida',
                'Corteolona e Genzone',
                'Corvino San Quirico',
                'Costa de\' Nobili',
                'Cozzo',
                'Cura Carpignano',
                'Dorno',
                'Ferrera Erbognone',
                'Filighera',
                'Fortunago',
                'Frascarolo',
                'Galliavola',
                'Gambarana',
                'Gambolò',
                'Garlasco',
                'Gerenzago',
                'Giussago',
                'Godiasco Salice Terme',
                'Golferenzo',
                'Gravellona Lomellina',
                'Gropello Cairoli',
                'Inverno e Monteleone',
                'Landriano',
                'Langosco',
                'Lardirago',
                'Linarolo',
                'Lirio',
                'Lomello',
                'Lungavilla',
                'Magherno',
                'Marcignago',
                'Marzano',
                'Mede',
                'Menconico',
                'Mezzana Bigli',
                'Mezzana Rabattone',
                'Mezzanino',
                'Miradolo Terme',
                'Montalto Pavese',
                'Montebello della Battaglia',
                'Montecalvo Versiggia',
                'Montescano',
                'Montesegale',
                'Monticelli Pavese',
                'Montù Beccaria',
                'Mornico Losana',
                'Mortara',
                'Nicorvo',
                'Olevano di Lomellina',
                'Oliva Gessi',
                'Ottobiano',
                'Palestro',
                'Pancarana',
                'Parona',
                'Pavia',
                'Pietra de\' Giorgi',
                'Pieve Albignola',
                'Pieve del Cairo',
                'Pieve Porto Morone',
                'Pinarolo Po',
                'Pizzale',
                'Ponte Nizza',
                'Portalbera',
                'Rea',
                'Redavalle',
                'Retorbido',
                'Rivanazzano Terme',
                'Robbio',
                'Robecco Pavese',
                'Rocca de\' Giorgi',
                'Rocca Susella',
                'Rognano',
                'Romagnese',
                'Roncaro',
                'Rosasco',
                'Rovescala',
                'San Cipriano Po',
                'San Damiano al Colle',
                'San Genesio ed Uniti',
                'San Giorgio di Lomellina',
                'San Martino Siccomario',
                'San Zenone al Po',
                'Sannazzaro de\' Burgondi',
                'Sant\'Alessio con Vialone',
                'Sant\'Angelo Lomellina',
                'Santa Cristina e Bissone',
                'Santa Giuletta',
                'Santa Margherita di Staffora',
                'Santa Maria della Versa',
                'Sartirana Lomellina',
                'Scaldasole',
                'Semiana',
                'Silvano Pietra',
                'Siziano',
                'Sommo',
                'Spessa',
                'Stradella',
                'Suardi',
                'Torrazza Coste',
                'Torre Beretti e Castellaro',
                'Torre d\'Arese',
                'Torre d\'Isola',
                'Torre de\' Negri',
                'Torrevecchia Pia',
                'Torricella Verzate',
                'Travacò Siccomario',
                'Trivolzio',
                'Tromello',
                'Trovo',
                'Val di Nizza',
                'Valeggio',
                'Valle Lomellina',
                'Valle Salimbene',
                'Varzi',
                'Velezzo Lomellina',
                'Vellezzo Bellini',
                'Verretto',
                'Verrua Po',
                'Vidigulfo',
                'Vigevano',
                'Villa Biscossi',
                'Villanova d\'Ardenghi',
                'Villanterio',
                'Vistarino',
                'Voghera',
                'Volpara',
                'Zavattarello',
                'Zeccone',
                'Zeme',
                'Zenevredo',
                'Zerbo',
                'Zerbolò',
                'Zinasco'
            ],
            sigla: 'PV'
        },
        {
            name: 'Perugia',
            municipalities: [
                'Assisi',
                'Bastia Umbra',
                'Bettona',
                'Bevagna',
                'Campello sul Clitunno',
                'Cannara',
                'Cascia',
                'Castel Ritaldi',
                'Castiglione del Lago',
                'Cerreto di Spoleto',
                'Citerna',
                'Città della Pieve',
                'Città di Castello',
                'Collazzone',
                'Corciano',
                'Costacciaro',
                'Deruta',
                'Foligno',
                'Fossato di Vico',
                'Fratta Todina',
                'Giano dell\'Umbria',
                'Gualdo Cattaneo',
                'Gualdo Tadino',
                'Gubbio',
                'Lisciano Niccone',
                'Magione',
                'Marsciano',
                'Massa Martana',
                'Monte Castello di Vibio',
                'Monte Santa Maria Tiberina',
                'Montefalco',
                'Monteleone di Spoleto',
                'Montone',
                'Nocera Umbra',
                'Norcia',
                'Paciano',
                'Panicale',
                'Passignano sul Trasimeno',
                'Perugia',
                'Piegaro',
                'Pietralunga',
                'Poggiodomo',
                'Preci',
                'San Giustino',
                'Sant\'Anatolia di Narco',
                'Scheggia e Pascelupo',
                'Scheggino',
                'Sellano',
                'Sigillo',
                'Spello',
                'Spoleto',
                'Todi',
                'Torgiano',
                'Trevi',
                'Tuoro sul Trasimeno',
                'Umbertide',
                'Valfabbrica',
                'Vallo di Nera',
                'Valtopina'
            ],
            sigla: 'PG'
        },
        {
            name: 'Pesaro e Urbino',
            municipalities: [
                'Acqualagna',
                'Apecchio',
                'Belforte all\'Isauro',
                'Borgo Pace',
                'Cagli',
                'Cantiano',
                'Carpegna',
                'Cartoceto',
                'Colli al Metauro',
                'Fano',
                'Fermignano',
                'Fossombrone',
                'Fratte Rosa',
                'Frontino',
                'Frontone',
                'Gabicce Mare',
                'Gradara',
                'Isola del Piano',
                'Lunano',
                'Macerata Feltria',
                'Mercatello sul Metauro',
                'Mercatino Conca',
                'Mombaroccio',
                'Mondavio',
                'Mondolfo',
                'Monte Cerignone',
                'Monte Grimano Terme',
                'Monte Porzio',
                'Montecalvo in Foglia',
                'Monteciccardo',
                'Montecopiolo',
                'Montefelcino',
                'Montelabbate',
                'Peglio',
                'Pergola',
                'Pesaro',
                'Petriano',
                'Piandimeleto',
                'Pietrarubbia',
                'Piobbico',
                'San Costanzo',
                'San Lorenzo in Campo',
                'Sant\'Angelo in Vado',
                'Sant\'Ippolito',
                'Sassocorvaro Auditore',
                'Sassofeltrio',
                'Serra Sant\'Abbondio',
                'Tavoleto',
                'Tavullia',
                'Terre Roveresche',
                'Urbania',
                'Urbino',
                'Vallefoglia'
            ],
            sigla: 'PU'
        },
        {
            name: 'Pescara',
            municipalities: [
                'Abbateggio',
                'Alanno',
                'Bolognano',
                'Brittoli',
                'Bussi sul Tirino',
                'Cappelle sul Tavo',
                'Caramanico Terme',
                'Carpineto della Nora',
                'Castiglione a Casauria',
                'Catignano',
                'Cepagatti',
                'Città Sant\'Angelo',
                'Civitaquana',
                'Civitella Casanova',
                'Collecorvino',
                'Corvara',
                'Cugnoli',
                'Elice',
                'Farindola',
                'Lettomanoppello',
                'Loreto Aprutino',
                'Manoppello',
                'Montebello di Bertona',
                'Montesilvano',
                'Moscufo',
                'Nocciano',
                'Penne',
                'Pescara',
                'Pescosansonesco',
                'Pianella',
                'Picciano',
                'Pietranico',
                'Popoli',
                'Roccamorice',
                'Rosciano',
                'Salle',
                'San Valentino in Abruzzo Citeriore',
                'Sant\'Eufemia a Maiella',
                'Scafa',
                'Serramonacesca',
                'Spoltore',
                'Tocco da Casauria',
                'Torre de\' Passeri',
                'Turrivalignani',
                'Vicoli',
                'Villa Celiera'
            ],
            sigla: 'PE'
        },
        {
            name: 'Piacenza',
            municipalities: [
                'Agazzano',
                'Alseno',
                'Alta Val Tidone',
                'Besenzone',
                'Bettola',
                'Bobbio',
                'Borgonovo Val Tidone',
                'Cadeo',
                'Calendasco',
                'Caorso',
                'Carpaneto Piacentino',
                'Castel San Giovanni',
                'Castell\'Arquato',
                'Castelvetro Piacentino',
                'Cerignale',
                'Coli',
                'Corte Brugnatella',
                'Cortemaggiore',
                'Farini',
                'Ferriere',
                'Fiorenzuola d\'Arda',
                'Gazzola',
                'Gossolengo',
                'Gragnano Trebbiense',
                'Gropparello',
                'Lugagnano Val d\'Arda',
                'Monticelli d\'Ongina',
                'Morfasso',
                'Ottone',
                'Piacenza',
                'Pianello Val Tidone',
                'Piozzano',
                'Podenzano',
                'Ponte dell\'Olio',
                'Pontenure',
                'Rivergaro',
                'Rottofreno',
                'San Giorgio Piacentino',
                'San Pietro in Cerro',
                'Sarmato',
                'Travo',
                'Vernasca',
                'Vigolzone',
                'Villanova sull\'Arda',
                'Zerba',
                'Ziano Piacentino'
            ],
            sigla: 'PC'
        },
        {
            name: 'Pisa',
            municipalities: [
                'Bientina',
                'Buti',
                'Calci',
                'Calcinaia',
                'Capannoli',
                'Casale Marittimo',
                'Casciana Terme Lari',
                'Cascina',
                'Castelfranco di Sotto',
                'Castellina Marittima',
                'Castelnuovo di Val di Cecina',
                'Chianni',
                'Crespina Lorenzana',
                'Fauglia',
                'Guardistallo',
                'Lajatico',
                'Montecatini Val di Cecina',
                'Montescudaio',
                'Monteverdi Marittimo',
                'Montopoli in Val d\'Arno',
                'Orciano Pisano',
                'Palaia',
                'Peccioli',
                'Pisa',
                'Pomarance',
                'Ponsacco',
                'Pontedera',
                'Riparbella',
                'San Giuliano Terme',
                'San Miniato',
                'Santa Croce sull\'Arno',
                'Santa Luce',
                'Santa Maria a Monte',
                'Terricciola',
                'Vecchiano',
                'Vicopisano',
                'Volterra'
            ],
            sigla: 'PI'
        },
        {
            name: 'Pistoia',
            municipalities: [
                'Abetone Cutigliano',
                'Agliana',
                'Buggiano',
                'Chiesina Uzzanese',
                'Lamporecchio',
                'Larciano',
                'Marliana',
                'Massa e Cozzile',
                'Monsummano Terme',
                'Montale',
                'Montecatini-Terme',
                'Pescia',
                'Pieve a Nievole',
                'Pistoia',
                'Ponte Buggianese',
                'Quarrata',
                'Sambuca Pistoiese',
                'San Marcello Piteglio',
                'Serravalle Pistoiese',
                'Uzzano'
            ],
            sigla: 'PT'
        },
        {
            name: 'Pordenone',
            municipalities: [
                'Andreis',
                'Arba',
                'Aviano',
                'Azzano Decimo',
                'Barcis',
                'Brugnera',
                'Budoia',
                'Caneva',
                'Casarsa della Delizia',
                'Castelnovo del Friuli',
                'Cavasso Nuovo',
                'Chions',
                'Cimolais',
                'Claut',
                'Clauzetto',
                'Cordenons',
                'Cordovado',
                'Erto e Casso',
                'Fanna',
                'Fiume Veneto',
                'Fontanafredda',
                'Frisanco',
                'Maniago',
                'Meduno',
                'Montereale Valcellina',
                'Morsano al Tagliamento',
                'Pasiano di Pordenone',
                'Pinzano al Tagliamento',
                'Polcenigo',
                'Porcia',
                'Pordenone',
                'Prata di Pordenone',
                'Pravisdomini',
                'Roveredo in Piano',
                'Sacile',
                'San Giorgio della Richinvelda',
                'San Martino al Tagliamento',
                'San Quirino',
                'San Vito al Tagliamento',
                'Sequals',
                'Sesto al Reghena',
                'Spilimbergo',
                'Tramonti di Sopra',
                'Tramonti di Sotto',
                'Travesio',
                'Vajont',
                'Valvasone Arzene',
                'Vito d\'Asio',
                'Vivaro',
                'Zoppola'
            ],
            sigla: 'PN'
        },
        {
            name: 'Potenza',
            municipalities: [
                'Abriola',
                'Acerenza',
                'Albano di Lucania',
                'Anzi',
                'Armento',
                'Atella',
                'Avigliano',
                'Balvano',
                'Banzi',
                'Baragiano',
                'Barile',
                'Bella',
                'Brienza',
                'Brindisi Montagna',
                'Calvello',
                'Calvera',
                'Campomaggiore',
                'Cancellara',
                'Carbone',
                'Castelgrande',
                'Castelluccio Inferiore',
                'Castelluccio Superiore',
                'Castelmezzano',
                'Castelsaraceno',
                'Castronuovo di Sant\'Andrea',
                'Cersosimo',
                'Chiaromonte',
                'Corleto Perticara',
                'Episcopia',
                'Fardella',
                'Filiano',
                'Forenza',
                'Francavilla in Sinni',
                'Gallicchio',
                'Genzano di Lucania',
                'Ginestra',
                'Grumento Nova',
                'Guardia Perticara',
                'Lagonegro',
                'Latronico',
                'Laurenzana',
                'Lauria',
                'Lavello',
                'Maratea',
                'Marsico Nuovo',
                'Marsicovetere',
                'Maschito',
                'Melfi',
                'Missanello',
                'Moliterno',
                'Montemilone',
                'Montemurro',
                'Muro Lucano',
                'Nemoli',
                'Noepoli',
                'Oppido Lucano',
                'Palazzo San Gervasio',
                'Paterno',
                'Pescopagano',
                'Picerno',
                'Pietragalla',
                'Pietrapertosa',
                'Pignola',
                'Potenza',
                'Rapolla',
                'Rapone',
                'Rionero in Vulture',
                'Ripacandida',
                'Rivello',
                'Roccanova',
                'Rotonda',
                'Ruoti',
                'Ruvo del Monte',
                'San Chirico Nuovo',
                'San Chirico Raparo',
                'San Costantino Albanese',
                'San Fele',
                'San Martino d\'Agri',
                'San Paolo Albanese',
                'San Severino Lucano',
                'Sant\'Angelo Le Fratte',
                'Sant\'Arcangelo',
                'Sarconi',
                'Sasso di Castalda',
                'Satriano di Lucania',
                'Savoia di Lucania',
                'Senise',
                'Spinoso',
                'Teana',
                'Terranova di Pollino',
                'Tito',
                'Tolve',
                'Tramutola',
                'Trecchina',
                'Trivigno',
                'Vaglio Basilicata',
                'Venosa',
                'Vietri di Potenza',
                'Viggianello',
                'Viggiano'
            ],
            sigla: 'PZ'
        },
        {
            name: 'Prato',
            municipalities: [
                'Cantagallo',
                'Carmignano',
                'Montemurlo',
                'Poggio a Caiano',
                'Prato',
                'Vaiano',
                'Vernio'
            ],
            sigla: 'PO'
        },
        {
            name: 'Ragusa',
            municipalities: [
                'Acate',
                'Chiaramonte Gulfi',
                'Comiso',
                'Giarratana',
                'Ispica',
                'Modica',
                'Monterosso Almo',
                'Pozzallo',
                'Ragusa',
                'Santa Croce Camerina',
                'Scicli',
                'Vittoria'
            ],
            sigla: 'RG'
        },
        {
            name: 'Ravenna',
            municipalities: [
                'Alfonsine',
                'Bagnacavallo',
                'Bagnara di Romagna',
                'Brisighella',
                'Casola Valsenio',
                'Castel Bolognese',
                'Cervia',
                'Conselice',
                'Cotignola',
                'Faenza',
                'Fusignano',
                'Lugo',
                'Massa Lombarda',
                'Ravenna',
                'Riolo Terme',
                'Russi',
                'Sant\'Agata sul Santerno',
                'Solarolo'
            ],
            sigla: 'RA'
        },
        {
            name: 'Reggio Calabria',
            municipalities: [
                'Africo',
                'Agnana Calabra',
                'Anoia',
                'Antonimina',
                'Ardore',
                'Bagaladi',
                'Bagnara Calabra',
                'Benestare',
                'Bianco',
                'Bivongi',
                'Bova',
                'Bova Marina',
                'Bovalino',
                'Brancaleone',
                'Bruzzano Zeffirio',
                'Calanna',
                'Camini',
                'Campo Calabro',
                'Candidoni',
                'Canolo',
                'Caraffa del Bianco',
                'Cardeto',
                'Careri',
                'Casignana',
                'Caulonia',
                'Ciminà',
                'Cinquefrondi',
                'Cittanova',
                'Condofuri',
                'Cosoleto',
                'Delianuova',
                'Feroleto della Chiesa',
                'Ferruzzano',
                'Fiumara',
                'Galatro',
                'Gerace',
                'Giffone',
                'Gioia Tauro',
                'Gioiosa Ionica',
                'Grotteria',
                'Laganadi',
                'Laureana di Borrello',
                'Locri',
                'Mammola',
                'Marina di Gioiosa Ionica',
                'Maropati',
                'Martone',
                'Melicuccà',
                'Melicucco',
                'Melito di Porto Salvo',
                'Molochio',
                'Monasterace',
                'Montebello Jonico',
                'Motta San Giovanni',
                'Oppido Mamertina',
                'Palizzi',
                'Palmi',
                'Pazzano',
                'Placanica',
                'Platì',
                'Polistena',
                'Portigliola',
                'Reggio di Calabria',
                'Riace',
                'Rizziconi',
                'Roccaforte del Greco',
                'Roccella Ionica',
                'Roghudi',
                'Rosarno',
                'Samo',
                'San Ferdinando',
                'San Giorgio Morgeto',
                'San Giovanni di Gerace',
                'San Lorenzo',
                'San Luca',
                'San Pietro di Caridà',
                'San Procopio',
                'San Roberto',
                'Sant\'Agata del Bianco',
                'Sant\'Alessio in Aspromonte',
                'Sant\'Eufemia d\'Aspromonte',
                'Sant\'Ilario dello Ionio',
                'Santa Cristina d\'Aspromonte',
                'Santo Stefano in Aspromonte',
                'Scido',
                'Scilla',
                'Seminara',
                'Serrata',
                'Siderno',
                'Sinopoli',
                'Staiti',
                'Stignano',
                'Stilo',
                'Taurianova',
                'Terranova Sappo Minulio',
                'Varapodio',
                'Villa San Giovanni'
            ],
            sigla: 'RC'
        },
        {
            name: 'Reggio nell\'Emilia',
            municipalities: [
                'Albinea',
                'Bagnolo in Piano',
                'Baiso',
                'Bibbiano',
                'Boretto',
                'Brescello',
                'Cadelbosco di Sopra',
                'Campagnola Emilia',
                'Campegine',
                'Canossa',
                'Carpineti',
                'Casalgrande',
                'Casina',
                'Castellarano',
                'Castelnovo di Sotto',
                'Castelnovo ne\' Monti',
                'Cavriago',
                'Correggio',
                'Fabbrico',
                'Gattatico',
                'Gualtieri',
                'Guastalla',
                'Luzzara',
                'Montecchio Emilia',
                'Novellara',
                'Poviglio',
                'Quattro Castella',
                'Reggio nell\'Emilia',
                'Reggiolo',
                'Rio Saliceto',
                'Rolo',
                'Rubiera',
                'San Martino in Rio',
                'San Polo d\'Enza',
                'Sant\'Ilario d\'Enza',
                'Scandiano',
                'Toano',
                'Ventasso',
                'Vetto',
                'Vezzano sul Crostolo',
                'Viano',
                'Villa Minozzo'
            ],
            sigla: 'RE'
        },
        {
            name: 'Rieti',
            municipalities: [
                'Accumoli',
                'Amatrice',
                'Antrodoco',
                'Ascrea',
                'Belmonte in Sabina',
                'Borbona',
                'Borgo Velino',
                'Borgorose',
                'Cantalice',
                'Cantalupo in Sabina',
                'Casaprota',
                'Casperia',
                'Castel di Tora',
                'Castel Sant\'Angelo',
                'Castelnuovo di Farfa',
                'Cittaducale',
                'Cittareale',
                'Collalto Sabino',
                'Colle di Tora',
                'Collegiove',
                'Collevecchio',
                'Colli sul Velino',
                'Concerviano',
                'Configni',
                'Contigliano',
                'Cottanello',
                'Fara in Sabina',
                'Fiamignano',
                'Forano',
                'Frasso Sabino',
                'Greccio',
                'Labro',
                'Leonessa',
                'Longone Sabino',
                'Magliano Sabina',
                'Marcetelli',
                'Micigliano',
                'Mompeo',
                'Montasola',
                'Monte San Giovanni in Sabina',
                'Montebuono',
                'Monteleone Sabino',
                'Montenero Sabino',
                'Montopoli di Sabina',
                'Morro Reatino',
                'Nespolo',
                'Orvinio',
                'Paganico Sabino',
                'Pescorocchiano',
                'Petrella Salto',
                'Poggio Bustone',
                'Poggio Catino',
                'Poggio Mirteto',
                'Poggio Moiano',
                'Poggio Nativo',
                'Poggio San Lorenzo',
                'Posta',
                'Pozzaglia Sabina',
                'Rieti',
                'Rivodutri',
                'Rocca Sinibalda',
                'Roccantica',
                'Salisano',
                'Scandriglia',
                'Selci',
                'Stimigliano',
                'Tarano',
                'Toffia',
                'Torri in Sabina',
                'Torricella in Sabina',
                'Turania',
                'Vacone',
                'Varco Sabino'
            ],
            sigla: 'RI'
        },
        {
            name: 'Rimini',
            municipalities: [
                'Bellaria-Igea Marina',
                'Casteldelci',
                'Cattolica',
                'Coriano',
                'Gemmano',
                'Maiolo',
                'Misano Adriatico',
                'Mondaino',
                'Montefiore Conca',
                'Montegridolfo',
                'Montescudo-Monte Colombo',
                'Morciano di Romagna',
                'Novafeltria',
                'Pennabilli',
                'Poggio Torriana',
                'Riccione',
                'Rimini',
                'Saludecio',
                'San Clemente',
                'San Giovanni in Marignano',
                'San Leo',
                'Sant\'Agata Feltria',
                'Santarcangelo di Romagna',
                'Talamello',
                'Verucchio'
            ],
            sigla: 'RN'
        },
        {
            name: 'Roma',
            municipalities: [
                'Affile',
                'Agosta',
                'Albano Laziale',
                'Allumiere',
                'Anguillara Sabazia',
                'Anticoli Corrado',
                'Anzio',
                'Arcinazzo Romano',
                'Ardea',
                'Ariccia',
                'Arsoli',
                'Artena',
                'Bellegra',
                'Bracciano',
                'Camerata Nuova',
                'Campagnano di Roma',
                'Canale Monterano',
                'Canterano',
                'Capena',
                'Capranica Prenestina',
                'Carpineto Romano',
                'Casape',
                'Castel Gandolfo',
                'Castel Madama',
                'Castel San Pietro Romano',
                'Castelnuovo di Porto',
                'Cave',
                'Cerreto Laziale',
                'Cervara di Roma',
                'Cerveteri',
                'Ciampino',
                'Ciciliano',
                'Cineto Romano',
                'Civitavecchia',
                'Civitella San Paolo',
                'Colleferro',
                'Colonna',
                'Fiano Romano',
                'Filacciano',
                'Fiumicino',
                'Fonte Nuova',
                'Formello',
                'Frascati',
                'Gallicano nel Lazio',
                'Gavignano',
                'Genazzano',
                'Genzano di Roma',
                'Gerano',
                'Gorga',
                'Grottaferrata',
                'Guidonia Montecelio',
                'Jenne',
                'Labico',
                'Ladispoli',
                'Lanuvio',
                'Lariano',
                'Licenza',
                'Magliano Romano',
                'Mandela',
                'Manziana',
                'Marano Equo',
                'Marcellina',
                'Marino',
                'Mazzano Romano',
                'Mentana',
                'Monte Compatri',
                'Monte Porzio Catone',
                'Monteflavio',
                'Montelanico',
                'Montelibretti',
                'Monterotondo',
                'Montorio Romano',
                'Moricone',
                'Morlupo',
                'Nazzano',
                'Nemi',
                'Nerola',
                'Nettuno',
                'Olevano Romano',
                'Palestrina',
                'Palombara Sabina',
                'Percile',
                'Pisoniano',
                'Poli',
                'Pomezia',
                'Ponzano Romano',
                'Riano',
                'Rignano Flaminio',
                'Riofreddo',
                'Rocca Canterano',
                'Rocca di Cave',
                'Rocca di Papa',
                'Rocca Priora',
                'Rocca Santo Stefano',
                'Roccagiovine',
                'Roiate',
                'Roma',
                'Roviano',
                'Sacrofano',
                'Sambuci',
                'San Cesareo',
                'San Gregorio da Sassola',
                'San Polo dei Cavalieri',
                'San Vito Romano',
                'Sant\'Angelo Romano',
                'Sant\'Oreste',
                'Santa Marinella',
                'Saracinesco',
                'Segni',
                'Subiaco',
                'Tivoli',
                'Tolfa',
                'Torrita Tiberina',
                'Trevignano Romano',
                'Vallepietra',
                'Vallinfreda',
                'Valmontone',
                'Velletri',
                'Vicovaro',
                'Vivaro Romano',
                'Zagarolo'
            ],
            sigla: 'RM'
        },
        {
            name: 'Rovigo',
            municipalities: [
                'Adria',
                'Ariano nel Polesine',
                'Arquà Polesine',
                'Badia Polesine',
                'Bagnolo di Po',
                'Bergantino',
                'Bosaro',
                'Calto',
                'Canaro',
                'Canda',
                'Castelguglielmo',
                'Castelmassa',
                'Castelnovo Bariano',
                'Ceneselli',
                'Ceregnano',
                'Corbola',
                'Costa di Rovigo',
                'Crespino',
                'Ficarolo',
                'Fiesso Umbertiano',
                'Frassinelle Polesine',
                'Fratta Polesine',
                'Gaiba',
                'Gavello',
                'Giacciano con Baruchella',
                'Guarda Veneta',
                'Lendinara',
                'Loreo',
                'Lusia',
                'Melara',
                'Occhiobello',
                'Papozze',
                'Pettorazza Grimani',
                'Pincara',
                'Polesella',
                'Pontecchio Polesine',
                'Porto Tolle',
                'Porto Viro',
                'Rosolina',
                'Rovigo',
                'Salara',
                'San Bellino',
                'San Martino di Venezze',
                'Stienta',
                'Taglio di Po',
                'Trecenta',
                'Villadose',
                'Villamarzana',
                'Villanova del Ghebbo',
                'Villanova Marchesana'
            ],
            sigla: 'RO'
        },
        {
            name: 'Salerno',
            municipalities: [
                'Acerno',
                'Agropoli',
                'Albanella',
                'Alfano',
                'Altavilla Silentina',
                'Amalfi',
                'Angri',
                'Aquara',
                'Ascea',
                'Atena Lucana',
                'Atrani',
                'Auletta',
                'Baronissi',
                'Battipaglia',
                'Bellizzi',
                'Bellosguardo',
                'Bracigliano',
                'Buccino',
                'Buonabitacolo',
                'Caggiano',
                'Calvanico',
                'Camerota',
                'Campagna',
                'Campora',
                'Cannalonga',
                'Capaccio Paestum',
                'Casal Velino',
                'Casalbuono',
                'Casaletto Spartano',
                'Caselle in Pittari',
                'Castel San Giorgio',
                'Castel San Lorenzo',
                'Castelcivita',
                'Castellabate',
                'Castelnuovo Cilento',
                'Castelnuovo di Conza',
                'Castiglione del Genovesi',
                'Cava de\' Tirreni',
                'Celle di Bulgheria',
                'Centola',
                'Ceraso',
                'Cetara',
                'Cicerale',
                'Colliano',
                'Conca dei Marini',
                'Controne',
                'Contursi Terme',
                'Corbara',
                'Corleto Monforte',
                'Cuccaro Vetere',
                'Eboli',
                'Felitto',
                'Fisciano',
                'Furore',
                'Futani',
                'Giffoni Sei Casali',
                'Giffoni Valle Piana',
                'Gioi',
                'Giungano',
                'Ispani',
                'Laureana Cilento',
                'Laurino',
                'Laurito',
                'Laviano',
                'Lustra',
                'Magliano Vetere',
                'Maiori',
                'Mercato San Severino',
                'Minori',
                'Moio della Civitella',
                'Montano Antilia',
                'Monte San Giacomo',
                'Montecorice',
                'Montecorvino Pugliano',
                'Montecorvino Rovella',
                'Monteforte Cilento',
                'Montesano sulla Marcellana',
                'Morigerati',
                'Nocera Inferiore',
                'Nocera Superiore',
                'Novi Velia',
                'Ogliastro Cilento',
                'Olevano sul Tusciano',
                'Oliveto Citra',
                'Omignano',
                'Orria',
                'Ottati',
                'Padula',
                'Pagani',
                'Palomonte',
                'Pellezzano',
                'Perdifumo',
                'Perito',
                'Pertosa',
                'Petina',
                'Piaggine',
                'Pisciotta',
                'Polla',
                'Pollica',
                'Pontecagnano Faiano',
                'Positano',
                'Postiglione',
                'Praiano',
                'Prignano Cilento',
                'Ravello',
                'Ricigliano',
                'Roccadaspide',
                'Roccagloriosa',
                'Roccapiemonte',
                'Rofrano',
                'Romagnano al Monte',
                'Roscigno',
                'Rutino',
                'Sacco',
                'Sala Consilina',
                'Salento',
                'Salerno',
                'Salvitelle',
                'San Cipriano Picentino',
                'San Giovanni a Piro',
                'San Gregorio Magno',
                'San Mango Piemonte',
                'San Marzano sul Sarno',
                'San Mauro Cilento',
                'San Mauro la Bruca',
                'San Pietro al Tanagro',
                'San Rufo',
                'San Valentino Torio',
                'Sant\'Angelo a Fasanella',
                'Sant\'Arsenio',
                'Sant\'Egidio del Monte Albino',
                'Santa Marina',
                'Santomenna',
                'Sanza',
                'Sapri',
                'Sarno',
                'Sassano',
                'Scafati',
                'Scala',
                'Serramezzana',
                'Serre',
                'Sessa Cilento',
                'Siano',
                'Sicignano degli Alburni',
                'Stella Cilento',
                'Stio',
                'Teggiano',
                'Torchiara',
                'Torraca',
                'Torre Orsaia',
                'Tortorella',
                'Tramonti',
                'Trentinara',
                'Valle dell\'Angelo',
                'Vallo della Lucania',
                'Valva',
                'Vibonati',
                'Vietri sul Mare'
            ],
            sigla: 'SA'
        },
        {
            name: 'Sassari',
            municipalities: [
                'Aggius',
                'Aglientu',
                'Alà dei Sardi',
                'Alghero',
                'Anela',
                'Ardara',
                'Arzachena',
                'Badesi',
                'Banari',
                'Benetutti',
                'Berchidda',
                'Bessude',
                'Bonnanaro',
                'Bono',
                'Bonorva',
                'Bortigiadas',
                'Borutta',
                'Bottidda',
                'Buddusò',
                'Budoni',
                'Bultei',
                'Bulzi',
                'Burgos',
                'Calangianus',
                'Cargeghe',
                'Castelsardo',
                'Cheremule',
                'Chiaramonti',
                'Codrongianos',
                'Cossoine',
                'Erula',
                'Esporlatu',
                'Florinas',
                'Giave',
                'Golfo Aranci',
                'Illorai',
                'Ittireddu',
                'Ittiri',
                'La Maddalena',
                'Laerru',
                'Loiri Porto San Paolo',
                'Luogosanto',
                'Luras',
                'Mara',
                'Martis',
                'Monteleone Rocca Doria',
                'Monti',
                'Mores',
                'Muros',
                'Nughedu San Nicolò',
                'Nule',
                'Nulvi',
                'Olbia',
                'Olmedo',
                'Oschiri',
                'Osilo',
                'Ossi',
                'Ozieri',
                'Padria',
                'Padru',
                'Palau',
                'Pattada',
                'Perfugas',
                'Ploaghe',
                'Porto Torres',
                'Pozzomaggiore',
                'Putifigari',
                'Romana',
                'San Teodoro',
                'Sant\'Antonio di Gallura',
                'Santa Maria Coghinas',
                'Santa Teresa Gallura',
                'Sassari',
                'Sedini',
                'Semestene',
                'Sennori',
                'Siligo',
                'Sorso',
                'Stintino',
                'Telti',
                'Tempio Pausania',
                'Tergu',
                'Thiesi',
                'Tissi',
                'Torralba',
                'Trinità d\'Agultu e Vignola',
                'Tula',
                'Uri',
                'Usini',
                'Valledoria',
                'Viddalba',
                'Villanova Monteleone'
            ],
            sigla: 'SS'
        },
        {
            name: 'Savona',
            municipalities: [
                'Alassio',
                'Albenga',
                'Albisola Superiore',
                'Albissola Marina',
                'Altare',
                'Andora',
                'Arnasco',
                'Balestrino',
                'Bardineto',
                'Bergeggi',
                'Boissano',
                'Borghetto Santo Spirito',
                'Borgio Verezzi',
                'Bormida',
                'Cairo Montenotte',
                'Calice Ligure',
                'Calizzano',
                'Carcare',
                'Casanova Lerrone',
                'Castelbianco',
                'Castelvecchio di Rocca Barbena',
                'Celle Ligure',
                'Cengio',
                'Ceriale',
                'Cisano sul Neva',
                'Cosseria',
                'Dego',
                'Erli',
                'Finale Ligure',
                'Garlenda',
                'Giustenice',
                'Giusvalla',
                'Laigueglia',
                'Loano',
                'Magliolo',
                'Mallare',
                'Massimino',
                'Millesimo',
                'Mioglia',
                'Murialdo',
                'Nasino',
                'Noli',
                'Onzo',
                'Orco Feglino',
                'Ortovero',
                'Osiglia',
                'Pallare',
                'Piana Crixia',
                'Pietra Ligure',
                'Plodio',
                'Pontinvrea',
                'Quiliano',
                'Rialto',
                'Roccavignale',
                'Sassello',
                'Savona',
                'Spotorno',
                'Stella',
                'Stellanello',
                'Testico',
                'Toirano',
                'Tovo San Giacomo',
                'Urbe',
                'Vado Ligure',
                'Varazze',
                'Vendone',
                'Vezzi Portio',
                'Villanova d\'Albenga',
                'Zuccarello'
            ],
            sigla: 'SV'
        },
        {
            name: 'Siena',
            municipalities: [
                'Abbadia San Salvatore',
                'Asciano',
                'Buonconvento',
                'Casole d\'Elsa',
                'Castellina in Chianti',
                'Castelnuovo Berardenga',
                'Castiglione d\'Orcia',
                'Cetona',
                'Chianciano Terme',
                'Chiusdino',
                'Chiusi',
                'Colle di Val d\'Elsa',
                'Gaiole in Chianti',
                'Montalcino',
                'Montepulciano',
                'Monteriggioni',
                'Monteroni d\'Arbia',
                'Monticiano',
                'Murlo',
                'Piancastagnaio',
                'Pienza',
                'Poggibonsi',
                'Radda in Chianti',
                'Radicofani',
                'Radicondoli',
                'Rapolano Terme',
                'San Casciano dei Bagni',
                'San Gimignano',
                'San Quirico d\'Orcia',
                'Sarteano',
                'Siena',
                'Sinalunga',
                'Sovicille',
                'Torrita di Siena',
                'Trequanda'
            ],
            sigla: 'SI'
        },
        {
            name: 'Siracusa',
            municipalities: [
                'Augusta',
                'Avola',
                'Buccheri',
                'Buscemi',
                'Canicattini Bagni',
                'Carlentini',
                'Cassaro',
                'Ferla',
                'Floridia',
                'Francofonte',
                'Lentini',
                'Melilli',
                'Noto',
                'Pachino',
                'Palazzolo Acreide',
                'Portopalo di Capo Passero',
                'Priolo Gargallo',
                'Rosolini',
                'Siracusa',
                'Solarino',
                'Sortino'
            ],
            sigla: 'SR'
        },
        {
            name: 'Sondrio',
            municipalities: [
                'Albaredo per San Marco',
                'Albosaggia',
                'Andalo Valtellino',
                'Aprica',
                'Ardenno',
                'Bema',
                'Berbenno di Valtellina',
                'Bianzone',
                'Bormio',
                'Buglio in Monte',
                'Caiolo',
                'Campodolcino',
                'Caspoggio',
                'Castello dell\'Acqua',
                'Castione Andevenno',
                'Cedrasco',
                'Cercino',
                'Chiavenna',
                'Chiesa in Valmalenco',
                'Chiuro',
                'Cino',
                'Civo',
                'Colorina',
                'Cosio Valtellino',
                'Dazio',
                'Delebio',
                'Dubino',
                'Faedo Valtellino',
                'Forcola',
                'Fusine',
                'Gerola Alta',
                'Gordona',
                'Grosio',
                'Grosotto',
                'Lanzada',
                'Livigno',
                'Lovero',
                'Madesimo',
                'Mantello',
                'Mazzo di Valtellina',
                'Mello',
                'Mese',
                'Montagna in Valtellina',
                'Morbegno',
                'Novate Mezzola',
                'Pedesina',
                'Piantedo',
                'Piateda',
                'Piuro',
                'Poggiridenti',
                'Ponte in Valtellina',
                'Postalesio',
                'Prata Camportaccio',
                'Rasura',
                'Rogolo',
                'Samolaco',
                'San Giacomo Filippo',
                'Sernio',
                'Sondalo',
                'Sondrio',
                'Spriana',
                'Talamona',
                'Tartano',
                'Teglio',
                'Tirano',
                'Torre di Santa Maria',
                'Tovo di Sant\'Agata',
                'Traona',
                'Tresivio',
                'Val Masino',
                'Valdidentro',
                'Valdisotto',
                'Valfurva',
                'Verceia',
                'Vervio',
                'Villa di Chiavenna',
                'Villa di Tirano'
            ],
            sigla: 'SO'
        },
        {
            name: 'Sud Sardegna',
            municipalities: [
                'Arbus',
                'Armungia',
                'Ballao',
                'Barrali',
                'Barumini',
                'Buggerru',
                'Burcei',
                'Calasetta',
                'Carbonia',
                'Carloforte',
                'Castiadas',
                'Collinas',
                'Decimoputzu',
                'Dolianova',
                'Domus de Maria',
                'Domusnovas',
                'Donori',
                'Escalaplano',
                'Escolca',
                'Esterzili',
                'Fluminimaggiore',
                'Furtei',
                'Genoni',
                'Genuri',
                'Gergei',
                'Gesico',
                'Gesturi',
                'Giba',
                'Goni',
                'Gonnesa',
                'Gonnosfanadiga',
                'Guamaggiore',
                'Guasila',
                'Guspini',
                'Iglesias',
                'Isili',
                'Las Plassas',
                'Lunamatrona',
                'Mandas',
                'Masainas',
                'Monastir',
                'Muravera',
                'Musei',
                'Narcao',
                'Nuragus',
                'Nurallao',
                'Nuraminis',
                'Nurri',
                'Nuxis',
                'Orroli',
                'Ortacesus',
                'Pabillonis',
                'Pauli Arbarei',
                'Perdaxius',
                'Pimentel',
                'Piscinas',
                'Portoscuso',
                'Sadali',
                'Samassi',
                'Samatzai',
                'San Basilio',
                'San Gavino Monreale',
                'San Giovanni Suergiu',
                'San Nicolò Gerrei',
                'San Sperate',
                'San Vito',
                'Sanluri',
                'Sant\'Andrea Frius',
                'Sant\'Anna Arresi',
                'Sant\'Antioco',
                'Santadi',
                'Sardara',
                'Segariu',
                'Selegas',
                'Senorbì',
                'Serdiana',
                'Serramanna',
                'Serrenti',
                'Serri',
                'Setzu',
                'Seui',
                'Seulo',
                'Siddi',
                'Siliqua',
                'Silius',
                'Siurgus Donigala',
                'Soleminis',
                'Suelli',
                'Teulada',
                'Tratalias',
                'Tuili',
                'Turri',
                'Ussana',
                'Ussaramanna',
                'Vallermosa',
                'Villacidro',
                'Villamar',
                'Villamassargia',
                'Villanova Tulo',
                'Villanovaforru',
                'Villanovafranca',
                'Villaperuccio',
                'Villaputzu',
                'Villasalto',
                'Villasimius',
                'Villasor',
                'Villaspeciosa'
            ],
            sigla: 'SU'
        },
        {
            name: 'Taranto',
            municipalities: [
                'Avetrana',
                'Carosino',
                'Castellaneta',
                'Crispiano',
                'Faggiano',
                'Fragagnano',
                'Ginosa',
                'Grottaglie',
                'Laterza',
                'Leporano',
                'Lizzano',
                'Manduria',
                'Martina Franca',
                'Maruggio',
                'Massafra',
                'Monteiasi',
                'Montemesola',
                'Monteparano',
                'Mottola',
                'Palagianello',
                'Palagiano',
                'Pulsano',
                'Roccaforzata',
                'San Giorgio Ionico',
                'San Marzano di San Giuseppe',
                'Sava',
                'Statte',
                'Taranto',
                'Torricella'
            ],
            sigla: 'TA'
        },
        {
            name: 'Teramo',
            municipalities: [
                'Alba Adriatica',
                'Ancarano',
                'Arsita',
                'Atri',
                'Basciano',
                'Bellante',
                'Bisenti',
                'Campli',
                'Canzano',
                'Castel Castagna',
                'Castellalto',
                'Castelli',
                'Castiglione Messer Raimondo',
                'Castilenti',
                'Cellino Attanasio',
                'Cermignano',
                'Civitella del Tronto',
                'Colledara',
                'Colonnella',
                'Controguerra',
                'Corropoli',
                'Cortino',
                'Crognaleto',
                'Fano Adriano',
                'Giulianova',
                'Isola del Gran Sasso d\'Italia',
                'Martinsicuro',
                'Montefino',
                'Montorio al Vomano',
                'Morro d\'Oro',
                'Mosciano Sant\'Angelo',
                'Nereto',
                'Notaresco',
                'Penna Sant\'Andrea',
                'Pietracamela',
                'Pineto',
                'Rocca Santa Maria',
                'Roseto degli Abruzzi',
                'Sant\'Egidio alla Vibrata',
                'Sant\'Omero',
                'Silvi',
                'Teramo',
                'Torano Nuovo',
                'Torricella Sicura',
                'Tortoreto',
                'Tossicia',
                'Valle Castellana'
            ],
            sigla: 'TE'
        },
        {
            name: 'Terni',
            municipalities: [
                'Acquasparta',
                'Allerona',
                'Alviano',
                'Amelia',
                'Arrone',
                'Attigliano',
                'Avigliano Umbro',
                'Baschi',
                'Calvi dell\'Umbria',
                'Castel Giorgio',
                'Castel Viscardo',
                'Fabro',
                'Ferentillo',
                'Ficulle',
                'Giove',
                'Guardea',
                'Lugnano in Teverina',
                'Montecastrilli',
                'Montecchio',
                'Montefranco',
                'Montegabbione',
                'Monteleone d\'Orvieto',
                'Narni',
                'Orvieto',
                'Otricoli',
                'Parrano',
                'Penna in Teverina',
                'Polino',
                'Porano',
                'San Gemini',
                'San Venanzo',
                'Stroncone',
                'Terni'
            ],
            sigla: 'TR'
        },
        {
            name: 'Torino',
            municipalities: [
                'Agliè',
                'Airasca',
                'Ala di Stura',
                'Albiano d\'Ivrea',
                'Almese',
                'Alpette',
                'Alpignano',
                'Andezeno',
                'Andrate',
                'Angrogna',
                'Arignano',
                'Avigliana',
                'Azeglio',
                'Bairo',
                'Balangero',
                'Baldissero Canavese',
                'Baldissero Torinese',
                'Balme',
                'Banchette',
                'Barbania',
                'Bardonecchia',
                'Barone Canavese',
                'Beinasco',
                'Bibiana',
                'Bobbio Pellice',
                'Bollengo',
                'Borgaro Torinese',
                'Borgiallo',
                'Borgofranco d\'Ivrea',
                'Borgomasino',
                'Borgone Susa',
                'Bosconero',
                'Brandizzo',
                'Bricherasio',
                'Brosso',
                'Brozolo',
                'Bruino',
                'Brusasco',
                'Bruzolo',
                'Buriasco',
                'Burolo',
                'Busano',
                'Bussoleno',
                'Buttigliera Alta',
                'Cafasse',
                'Caluso',
                'Cambiano',
                'Campiglione Fenile',
                'Candia Canavese',
                'Candiolo',
                'Canischio',
                'Cantalupa',
                'Cantoira',
                'Caprie',
                'Caravino',
                'Carema',
                'Carignano',
                'Carmagnola',
                'Casalborgone',
                'Cascinette d\'Ivrea',
                'Caselette',
                'Caselle Torinese',
                'Castagneto Po',
                'Castagnole Piemonte',
                'Castellamonte',
                'Castelnuovo Nigra',
                'Castiglione Torinese',
                'Cavagnolo',
                'Cavour',
                'Cercenasco',
                'Ceres',
                'Ceresole Reale',
                'Cesana Torinese',
                'Chialamberto',
                'Chianocco',
                'Chiaverano',
                'Chieri',
                'Chiesanuova',
                'Chiomonte',
                'Chiusa di San Michele',
                'Chivasso',
                'Ciconio',
                'Cintano',
                'Cinzano',
                'Ciriè',
                'Claviere',
                'Coassolo Torinese',
                'Coazze',
                'Collegno',
                'Colleretto Castelnuovo',
                'Colleretto Giacosa',
                'Condove',
                'Corio',
                'Cossano Canavese',
                'Cuceglio',
                'Cumiana',
                'Cuorgnè',
                'Druento',
                'Exilles',
                'Favria',
                'Feletto',
                'Fenestrelle',
                'Fiano',
                'Fiorano Canavese',
                'Foglizzo',
                'Forno Canavese',
                'Frassinetto',
                'Front',
                'Frossasco',
                'Garzigliana',
                'Gassino Torinese',
                'Germagnano',
                'Giaglione',
                'Giaveno',
                'Givoletto',
                'Gravere',
                'Groscavallo',
                'Grosso',
                'Grugliasco',
                'Ingria',
                'Inverso Pinasca',
                'Isolabella',
                'Issiglio',
                'Ivrea',
                'La Cassa',
                'La Loggia',
                'Lanzo Torinese',
                'Lauriano',
                'Leini',
                'Lemie',
                'Lessolo',
                'Levone',
                'Locana',
                'Lombardore',
                'Lombriasco',
                'Loranzè',
                'Luserna San Giovanni',
                'Lusernetta',
                'Lusigliè',
                'Macello',
                'Maglione',
                'Mappano',
                'Marentino',
                'Massello',
                'Mathi',
                'Mattie',
                'Mazzè',
                'Meana di Susa',
                'Mercenasco',
                'Mezzenile',
                'Mombello di Torino',
                'Mompantero',
                'Monastero di Lanzo',
                'Moncalieri',
                'Moncenisio',
                'Montaldo Torinese',
                'Montalenghe',
                'Montalto Dora',
                'Montanaro',
                'Monteu da Po',
                'Moriondo Torinese',
                'Nichelino',
                'Noasca',
                'Nole',
                'Nomaglio',
                'None',
                'Novalesa',
                'Oglianico',
                'Orbassano',
                'Orio Canavese',
                'Osasco',
                'Osasio',
                'Oulx',
                'Ozegna',
                'Palazzo Canavese',
                'Pancalieri',
                'Parella',
                'Pavarolo',
                'Pavone Canavese',
                'Pecetto Torinese',
                'Perosa Argentina',
                'Perosa Canavese',
                'Perrero',
                'Pertusio',
                'Pessinetto',
                'Pianezza',
                'Pinasca',
                'Pinerolo',
                'Pino Torinese',
                'Piobesi Torinese',
                'Piossasco',
                'Piscina',
                'Piverone',
                'Poirino',
                'Pomaretto',
                'Pont-Canavese',
                'Porte',
                'Pragelato',
                'Prali',
                'Pralormo',
                'Pramollo',
                'Prarostino',
                'Prascorsano',
                'Pratiglione',
                'Quagliuzzo',
                'Quassolo',
                'Quincinetto',
                'Reano',
                'Ribordone',
                'Riva presso Chieri',
                'Rivalba',
                'Rivalta di Torino',
                'Rivara',
                'Rivarolo Canavese',
                'Rivarossa',
                'Rivoli',
                'Robassomero',
                'Rocca Canavese',
                'Roletto',
                'Romano Canavese',
                'Ronco Canavese',
                'Rondissone',
                'Rorà',
                'Rosta',
                'Roure',
                'Rubiana',
                'Rueglio',
                'Salassa',
                'Salbertrand',
                'Salerano Canavese',
                'Salza di Pinerolo',
                'Samone',
                'San Benigno Canavese',
                'San Carlo Canavese',
                'San Colombano Belmonte',
                'San Didero',
                'San Francesco al Campo',
                'San Germano Chisone',
                'San Gillio',
                'San Giorgio Canavese',
                'San Giorio di Susa',
                'San Giusto Canavese',
                'San Martino Canavese',
                'San Maurizio Canavese',
                'San Mauro Torinese',
                'San Pietro Val Lemina',
                'San Ponso',
                'San Raffaele Cimena',
                'San Sebastiano da Po',
                'San Secondo di Pinerolo',
                'Sangano',
                'Sant\'Ambrogio di Torino',
                'Sant\'Antonino di Susa',
                'Santena',
                'Sauze d\'Oulx',
                'Sauze di Cesana',
                'Scalenghe',
                'Scarmagno',
                'Sciolze',
                'Sestriere',
                'Settimo Rottaro',
                'Settimo Torinese',
                'Settimo Vittone',
                'Sparone',
                'Strambinello',
                'Strambino',
                'Susa',
                'Tavagnasco',
                'Torino',
                'Torrazza Piemonte',
                'Torre Canavese',
                'Torre Pellice',
                'Trana',
                'Traversella',
                'Traves',
                'Trofarello',
                'Usseaux',
                'Usseglio',
                'Vaie',
                'Val della Torre',
                'Val di Chy',
                'Valchiusa',
                'Valgioie',
                'Vallo Torinese',
                'Valperga',
                'Valprato Soana',
                'Varisella',
                'Vauda Canavese',
                'Venaria Reale',
                'Venaus',
                'Verolengo',
                'Verrua Savoia',
                'Vestignè',
                'Vialfrè',
                'Vidracco',
                'Vigone',
                'Villafranca Piemonte',
                'Villanova Canavese',
                'Villar Dora',
                'Villar Focchiardo',
                'Villar Pellice',
                'Villar Perosa',
                'Villarbasse',
                'Villareggia',
                'Villastellone',
                'Vinovo',
                'Virle Piemonte',
                'Vische',
                'Vistrorio',
                'Viù',
                'Volpiano',
                'Volvera'
            ],
            sigla: 'TO'
        },
        {
            name: 'Trapani',
            municipalities: [
                'Alcamo',
                'Buseto Palizzolo',
                'Calatafimi-Segesta',
                'Campobello di Mazara',
                'Castellammare del Golfo',
                'Castelvetrano',
                'Custonaci',
                'Erice',
                'Favignana',
                'Gibellina',
                'Marsala',
                'Mazara del Vallo',
                'Paceco',
                'Pantelleria',
                'Partanna',
                'Petrosino',
                'Poggioreale',
                'Salaparuta',
                'Salemi',
                'San Vito Lo Capo',
                'Santa Ninfa',
                'Trapani',
                'Valderice',
                'Vita'
            ],
            sigla: 'TP'
        },
        {
            name: 'Trento',
            municipalities: [
                'Ala',
                'Albiano',
                'Aldeno',
                'Altavalle',
                'Altopiano della Vigolana',
                'Amblar-Don',
                'Andalo',
                'Arco',
                'Avio',
                'Baselga di Pinè',
                'Bedollo',
                'Besenello',
                'Bieno',
                'Bleggio Superiore',
                'Bocenago',
                'Bondone',
                'Borgo Chiese',
                'Borgo d\'Anaunia',
                'Borgo Lares',
                'Borgo Valsugana',
                'Brentonico',
                'Bresimo',
                'Caderzone Terme',
                'Calceranica al Lago',
                'Caldes',
                'Caldonazzo',
                'Calliano',
                'Campitello di Fassa',
                'Campodenno',
                'Canal San Bovo',
                'Canazei',
                'Capriana',
                'Carisolo',
                'Carzano',
                'Castel Condino',
                'Castel Ivano',
                'Castello Tesino',
                'Castello-Molina di Fiemme',
                'Castelnuovo',
                'Cavalese',
                'Cavareno',
                'Cavedago',
                'Cavedine',
                'Cavizzana',
                'Cembra Lisignago',
                'Cimone',
                'Cinte Tesino',
                'Cis',
                'Civezzano',
                'Cles',
                'Comano Terme',
                'Commezzadura',
                'Contà',
                'Croviana',
                'Dambel',
                'Denno',
                'Dimaro Folgarida',
                'Drena',
                'Dro',
                'Fai della Paganella',
                'Fiavè',
                'Fierozzo',
                'Folgaria',
                'Fornace',
                'Frassilongo',
                'Garniga Terme',
                'Giovo',
                'Giustino',
                'Grigno',
                'Imer',
                'Isera',
                'Lavarone',
                'Lavis',
                'Ledro',
                'Levico Terme',
                'Livo',
                'Lona-Lases',
                'Luserna',
                'Madruzzo',
                'Malé',
                'Massimeno',
                'Mazzin',
                'Mezzana',
                'Mezzano',
                'Mezzocorona',
                'Mezzolombardo',
                'Moena',
                'Molveno',
                'Mori',
                'Nago-Torbole',
                'Nogaredo',
                'Nomi',
                'Novaledo',
                'Novella',
                'Ospedaletto',
                'Ossana',
                'Palù del Fersina',
                'Panchià',
                'Peio',
                'Pellizzano',
                'Pelugo',
                'Pergine Valsugana',
                'Pieve di Bono-Prezzo',
                'Pieve Tesino',
                'Pinzolo',
                'Pomarolo',
                'Porte di Rendena',
                'Predaia',
                'Predazzo',
                'Primiero San Martino di Castrozza',
                'Rabbi',
                'Riva del Garda',
                'Romeno',
                'Roncegno Terme',
                'Ronchi Valsugana',
                'Ronzo-Chienis',
                'Ronzone',
                'Roverè della Luna',
                'Rovereto',
                'Ruffrè-Mendola',
                'Rumo',
                'Sagron Mis',
                'Samone',
                'San Giovanni di Fassa',
                'San Lorenzo Dorsino',
                'San Michele all\'Adige',
                'Sant\'Orsola Terme',
                'Sanzeno',
                'Sarnonico',
                'Scurelle',
                'Segonzano',
                'Sella Giudicarie',
                'Sfruz',
                'Soraga di Fassa',
                'Sover',
                'Spiazzo',
                'Spormaggiore',
                'Sporminore',
                'Stenico',
                'Storo',
                'Strembo',
                'Telve',
                'Telve di Sopra',
                'Tenna',
                'Tenno',
                'Terragnolo',
                'Terre d\'Adige',
                'Terzolas',
                'Tesero',
                'Tione di Trento',
                'Ton',
                'Torcegno',
                'Trambileno',
                'Tre Ville',
                'Trento',
                'Valdaone',
                'Valfloriana',
                'Vallarsa',
                'Vallelaghi',
                'Vermiglio',
                'Vignola-Falesina',
                'Villa Lagarina',
                'Ville d\'Anaunia',
                'Ville di Fiemme',
                'Volano',
                'Ziano di Fiemme'
            ],
            sigla: 'TN'
        },
        {
            name: 'Treviso',
            municipalities: [
                'Altivole',
                'Arcade',
                'Asolo',
                'Borso del Grappa',
                'Breda di Piave',
                'Caerano di San Marco',
                'Cappella Maggiore',
                'Carbonera',
                'Casale sul Sile',
                'Casier',
                'Castelcucco',
                'Castelfranco Veneto',
                'Castello di Godego',
                'Cavaso del Tomba',
                'Cessalto',
                'Chiarano',
                'Cimadolmo',
                'Cison di Valmarino',
                'Codognè',
                'Colle Umberto',
                'Conegliano',
                'Cordignano',
                'Cornuda',
                'Crocetta del Montello',
                'Farra di Soligo',
                'Follina',
                'Fontanelle',
                'Fonte',
                'Fregona',
                'Gaiarine',
                'Giavera del Montello',
                'Godega di Sant\'Urbano',
                'Gorgo al Monticano',
                'Istrana',
                'Loria',
                'Mansuè',
                'Mareno di Piave',
                'Maser',
                'Maserada sul Piave',
                'Meduna di Livenza',
                'Miane',
                'Mogliano Veneto',
                'Monastier di Treviso',
                'Monfumo',
                'Montebelluna',
                'Morgano',
                'Moriago della Battaglia',
                'Motta di Livenza',
                'Nervesa della Battaglia',
                'Oderzo',
                'Ormelle',
                'Orsago',
                'Paese',
                'Pederobba',
                'Pieve del Grappa',
                'Pieve di Soligo',
                'Ponte di Piave',
                'Ponzano Veneto',
                'Portobuffolè',
                'Possagno',
                'Povegliano',
                'Preganziol',
                'Quinto di Treviso',
                'Refrontolo',
                'Resana',
                'Revine Lago',
                'Riese Pio X',
                'Roncade',
                'Salgareda',
                'San Biagio di Callalta',
                'San Fior',
                'San Pietro di Feletto',
                'San Polo di Piave',
                'San Vendemiano',
                'San Zenone degli Ezzelini',
                'Santa Lucia di Piave',
                'Sarmede',
                'Segusino',
                'Sernaglia della Battaglia',
                'Silea',
                'Spresiano',
                'Susegana',
                'Tarzo',
                'Trevignano',
                'Treviso',
                'Valdobbiadene',
                'Vazzola',
                'Vedelago',
                'Vidor',
                'Villorba',
                'Vittorio Veneto',
                'Volpago del Montello',
                'Zenson di Piave',
                'Zero Branco'
            ],
            sigla: 'TV'
        },
        {
            name: 'Trieste',
            municipalities: [
                'Duino Aurisina',
                'Monrupino',
                'Muggia',
                'San Dorligo della Valle',
                'Sgonico',
                'Trieste'
            ],
            sigla: 'TS'
        },
        {
            name: 'Udine',
            municipalities: [
                'Aiello del Friuli',
                'Amaro',
                'Ampezzo',
                'Aquileia',
                'Arta Terme',
                'Artegna',
                'Attimis',
                'Bagnaria Arsa',
                'Basiliano',
                'Bertiolo',
                'Bicinicco',
                'Bordano',
                'Buja',
                'Buttrio',
                'Camino al Tagliamento',
                'Campoformido',
                'Campolongo Tapogliano',
                'Carlino',
                'Cassacco',
                'Castions di Strada',
                'Cavazzo Carnico',
                'Cercivento',
                'Cervignano del Friuli',
                'Chiopris-Viscone',
                'Chiusaforte',
                'Cividale del Friuli',
                'Codroipo',
                'Colloredo di Monte Albano',
                'Comeglians',
                'Corno di Rosazzo',
                'Coseano',
                'Dignano',
                'Dogna',
                'Drenchia',
                'Enemonzo',
                'Faedis',
                'Fagagna',
                'Fiumicello Villa Vicentina',
                'Flaibano',
                'Forgaria nel Friuli',
                'Forni Avoltri',
                'Forni di Sopra',
                'Forni di Sotto',
                'Gemona del Friuli',
                'Gonars',
                'Grimacco',
                'Latisana',
                'Lauco',
                'Lestizza',
                'Lignano Sabbiadoro',
                'Lusevera',
                'Magnano in Riviera',
                'Majano',
                'Malborghetto Valbruna',
                'Manzano',
                'Marano Lagunare',
                'Martignacco',
                'Mereto di Tomba',
                'Moggio Udinese',
                'Moimacco',
                'Montenars',
                'Mortegliano',
                'Moruzzo',
                'Muzzana del Turgnano',
                'Nimis',
                'Osoppo',
                'Ovaro',
                'Pagnacco',
                'Palazzolo dello Stella',
                'Palmanova',
                'Paluzza',
                'Pasian di Prato',
                'Paularo',
                'Pavia di Udine',
                'Pocenia',
                'Pontebba',
                'Porpetto',
                'Povoletto',
                'Pozzuolo del Friuli',
                'Pradamano',
                'Prato Carnico',
                'Precenicco',
                'Premariacco',
                'Preone',
                'Prepotto',
                'Pulfero',
                'Ragogna',
                'Ravascletto',
                'Raveo',
                'Reana del Rojale',
                'Remanzacco',
                'Resia',
                'Resiutta',
                'Rigolato',
                'Rive d\'Arcano',
                'Rivignano Teor',
                'Ronchis',
                'Ruda',
                'San Daniele del Friuli',
                'San Giorgio di Nogaro',
                'San Giovanni al Natisone',
                'San Leonardo',
                'San Pietro al Natisone',
                'San Vito al Torre',
                'San Vito di Fagagna',
                'Santa Maria la Longa',
                'Sappada',
                'Sauris',
                'Savogna',
                'Sedegliano',
                'Socchieve',
                'Stregna',
                'Sutrio',
                'Taipana',
                'Talmassons',
                'Tarcento',
                'Tarvisio',
                'Tavagnacco',
                'Terzo d\'Aquileia',
                'Tolmezzo',
                'Torreano',
                'Torviscosa',
                'Trasaghis',
                'Treppo Grande',
                'Treppo Ligosullo',
                'Tricesimo',
                'Trivignano Udinese',
                'Udine',
                'Varmo',
                'Venzone',
                'Verzegnis',
                'Villa Santina',
                'Visco',
                'Zuglio'
            ],
            sigla: 'UD'
        },
        {
            name: 'Valle d\'Aosta/Vallée d\'Aoste',
            municipalities: [
                'Allein',
                'Antey-Saint-André',
                'Aosta',
                'Arnad',
                'Arvier',
                'Avise',
                'Ayas',
                'Aymavilles',
                'Bard',
                'Bionaz',
                'Brissogne',
                'Brusson',
                'Challand-Saint-Anselme',
                'Challand-Saint-Victor',
                'Chambave',
                'Chamois',
                'Champdepraz',
                'Champorcher',
                'Charvensod',
                'Châtillon',
                'Cogne',
                'Courmayeur',
                'Donnas',
                'Doues',
                'Emarèse',
                'Etroubles',
                'Fénis',
                'Fontainemore',
                'Gaby',
                'Gignod',
                'Gressan',
                'Gressoney-La-Trinité',
                'Gressoney-Saint-Jean',
                'Hône',
                'Introd',
                'Issime',
                'Issogne',
                'Jovençan',
                'La Magdeleine',
                'La Salle',
                'La Thuile',
                'Lillianes',
                'Montjovet',
                'Morgex',
                'Nus',
                'Ollomont',
                'Oyace',
                'Perloz',
                'Pollein',
                'Pont-Saint-Martin',
                'Pontboset',
                'Pontey',
                'Pré-Saint-Didier',
                'Quart',
                'Rhêmes-Notre-Dame',
                'Rhêmes-Saint-Georges',
                'Roisan',
                'Saint-Christophe',
                'Saint-Denis',
                'Saint-Marcel',
                'Saint-Nicolas',
                'Saint-Oyen',
                'Saint-Pierre',
                'Saint-Rhémy-en-Bosses',
                'Saint-Vincent',
                'Sarre',
                'Torgnon',
                'Valgrisenche',
                'Valpelline',
                'Valsavarenche',
                'Valtournenche',
                'Verrayes',
                'Verrès',
                'Villeneuve'
            ],
            sigla: 'AO'
        },
        {
            name: 'Varese',
            municipalities: [
                'Agra',
                'Albizzate',
                'Angera',
                'Arcisate',
                'Arsago Seprio',
                'Azzate',
                'Azzio',
                'Barasso',
                'Bardello',
                'Bedero Valcuvia',
                'Besano',
                'Besnate',
                'Besozzo',
                'Biandronno',
                'Bisuschio',
                'Bodio Lomnago',
                'Brebbia',
                'Bregano',
                'Brenta',
                'Brezzo di Bedero',
                'Brinzio',
                'Brissago-Valtravaglia',
                'Brunello',
                'Brusimpiano',
                'Buguggiate',
                'Busto Arsizio',
                'Cadegliano-Viconago',
                'Cadrezzate con Osmate',
                'Cairate',
                'Cantello',
                'Caravate',
                'Cardano al Campo',
                'Carnago',
                'Caronno Pertusella',
                'Caronno Varesino',
                'Casale Litta',
                'Casalzuigno',
                'Casciago',
                'Casorate Sempione',
                'Cassano Magnago',
                'Cassano Valcuvia',
                'Castellanza',
                'Castello Cabiaglio',
                'Castelseprio',
                'Castelveccana',
                'Castiglione Olona',
                'Castronno',
                'Cavaria con Premezzo',
                'Cazzago Brabbia',
                'Cislago',
                'Cittiglio',
                'Clivio',
                'Cocquio-Trevisago',
                'Comabbio',
                'Comerio',
                'Cremenaga',
                'Crosio della Valle',
                'Cuasso al Monte',
                'Cugliate-Fabiasco',
                'Cunardo',
                'Curiglia con Monteviasco',
                'Cuveglio',
                'Cuvio',
                'Daverio',
                'Dumenza',
                'Duno',
                'Fagnano Olona',
                'Ferno',
                'Ferrera di Varese',
                'Gallarate',
                'Galliate Lombardo',
                'Gavirate',
                'Gazzada Schianno',
                'Gemonio',
                'Gerenzano',
                'Germignaga',
                'Golasecca',
                'Gorla Maggiore',
                'Gorla Minore',
                'Gornate Olona',
                'Grantola',
                'Inarzo',
                'Induno Olona',
                'Ispra',
                'Jerago con Orago',
                'Lavena Ponte Tresa',
                'Laveno-Mombello',
                'Leggiuno',
                'Lonate Ceppino',
                'Lonate Pozzolo',
                'Lozza',
                'Luino',
                'Luvinate',
                'Maccagno con Pino e Veddasca',
                'Malgesso',
                'Malnate',
                'Marchirolo',
                'Marnate',
                'Marzio',
                'Masciago Primo',
                'Mercallo',
                'Mesenzana',
                'Montegrino Valtravaglia',
                'Monvalle',
                'Morazzone',
                'Mornago',
                'Oggiona con Santo Stefano',
                'Olgiate Olona',
                'Origgio',
                'Orino',
                'Porto Ceresio',
                'Porto Valtravaglia',
                'Rancio Valcuvia',
                'Ranco',
                'Saltrio',
                'Samarate',
                'Sangiano',
                'Saronno',
                'Sesto Calende',
                'Solbiate Arno',
                'Solbiate Olona',
                'Somma Lombardo',
                'Sumirago',
                'Taino',
                'Ternate',
                'Tradate',
                'Travedona-Monate',
                'Tronzano Lago Maggiore',
                'Uboldo',
                'Valganna',
                'Varano Borghi',
                'Varese',
                'Vedano Olona',
                'Venegono Inferiore',
                'Venegono Superiore',
                'Vergiate',
                'Viggiù',
                'Vizzola Ticino'
            ],
            sigla: 'VA'
        },
        {
            name: 'Venezia',
            municipalities: [
                'Annone Veneto',
                'Campagna Lupia',
                'Campolongo Maggiore',
                'Camponogara',
                'Caorle',
                'Cavallino-Treporti',
                'Cavarzere',
                'Ceggia',
                'Chioggia',
                'Cinto Caomaggiore',
                'Cona',
                'Concordia Sagittaria',
                'Dolo',
                'Eraclea',
                'Fiesso d\'Artico',
                'Fossalta di Piave',
                'Fossalta di Portogruaro',
                'Fossò',
                'Gruaro',
                'Jesolo',
                'Marcon',
                'Martellago',
                'Meolo',
                'Mira',
                'Mirano',
                'Musile di Piave',
                'Noale',
                'Noventa di Piave',
                'Pianiga',
                'Portogruaro',
                'Pramaggiore',
                'Quarto d\'Altino',
                'Salzano',
                'San Donà di Piave',
                'San Michele al Tagliamento',
                'San Stino di Livenza',
                'Santa Maria di Sala',
                'Scorzè',
                'Spinea',
                'Stra',
                'Teglio Veneto',
                'Torre di Mosto',
                'Venezia',
                'Vigonovo'
            ],
            sigla: 'VE'
        },
        {
            name: 'Verbano-Cusio-Ossola',
            municipalities: [
                'Antrona Schieranco',
                'Anzola d\'Ossola',
                'Arizzano',
                'Arola',
                'Aurano',
                'Baceno',
                'Bannio Anzino',
                'Baveno',
                'Bee',
                'Belgirate',
                'Beura-Cardezza',
                'Bognanco',
                'Borgomezzavalle',
                'Brovello-Carpugnino',
                'Calasca-Castiglione',
                'Cambiasca',
                'Cannero Riviera',
                'Cannobio',
                'Caprezzo',
                'Casale Corte Cerro',
                'Ceppo Morelli',
                'Cesara',
                'Cossogno',
                'Craveggia',
                'Crevoladossola',
                'Crodo',
                'Domodossola',
                'Druogno',
                'Formazza',
                'Germagno',
                'Ghiffa',
                'Gignese',
                'Gravellona Toce',
                'Gurro',
                'Intragna',
                'Loreglia',
                'Macugnaga',
                'Madonna del Sasso',
                'Malesco',
                'Masera',
                'Massiola',
                'Mergozzo',
                'Miazzina',
                'Montecrestese',
                'Montescheno',
                'Nonio',
                'Oggebbio',
                'Omegna',
                'Ornavasso',
                'Pallanzeno',
                'Piedimulera',
                'Pieve Vergonte',
                'Premeno',
                'Premia',
                'Premosello-Chiovenda',
                'Quarna Sopra',
                'Quarna Sotto',
                'Re',
                'San Bernardino Verbano',
                'Santa Maria Maggiore',
                'Stresa',
                'Toceno',
                'Trarego Viggiona',
                'Trasquera',
                'Trontano',
                'Valle Cannobina',
                'Valstrona',
                'Vanzone con San Carlo',
                'Varzo',
                'Verbania',
                'Vignone',
                'Villadossola',
                'Villette',
                'Vogogna'
            ],
            sigla: 'VB'
        },
        {
            name: 'Vercelli',
            municipalities: [
                'Alagna Valsesia',
                'Albano Vercellese',
                'Alice Castello',
                'Alto Sermenza',
                'Arborio',
                'Asigliano Vercellese',
                'Balmuccia',
                'Balocco',
                'Bianzè',
                'Boccioleto',
                'Borgo d\'Ale',
                'Borgo Vercelli',
                'Borgosesia',
                'Buronzo',
                'Campertogno',
                'Carcoforo',
                'Caresana',
                'Caresanablot',
                'Carisio',
                'Casanova Elvo',
                'Cellio con Breia',
                'Cervatto',
                'Cigliano',
                'Civiasco',
                'Collobiano',
                'Costanzana',
                'Cravagliana',
                'Crescentino',
                'Crova',
                'Desana',
                'Fobello',
                'Fontanetto Po',
                'Formigliana',
                'Gattinara',
                'Ghislarengo',
                'Greggio',
                'Guardabosone',
                'Lamporo',
                'Lenta',
                'Lignana',
                'Livorno Ferraris',
                'Lozzolo',
                'Mollia',
                'Moncrivello',
                'Motta de\' Conti',
                'Olcenengo',
                'Oldenico',
                'Palazzolo Vercellese',
                'Pertengo',
                'Pezzana',
                'Pila',
                'Piode',
                'Postua',
                'Prarolo',
                'Quarona',
                'Quinto Vercellese',
                'Rassa',
                'Rimella',
                'Rive',
                'Roasio',
                'Ronsecco',
                'Rossa',
                'Rovasenda',
                'Salasco',
                'Sali Vercellese',
                'Saluggia',
                'San Germano Vercellese',
                'San Giacomo Vercellese',
                'Santhià',
                'Scopa',
                'Scopello',
                'Serravalle Sesia',
                'Stroppiana',
                'Tricerro',
                'Trino',
                'Tronzano Vercellese',
                'Valduggia',
                'Varallo',
                'Vercelli',
                'Villarboit',
                'Villata',
                'Vocca'
            ],
            sigla: 'VC'
        },
        {
            name: 'Verona',
            municipalities: [
                'Affi',
                'Albaredo d\'Adige',
                'Angiari',
                'Arcole',
                'Badia Calavena',
                'Bardolino',
                'Belfiore',
                'Bevilacqua',
                'Bonavigo',
                'Boschi Sant\'Anna',
                'Bosco Chiesanuova',
                'Bovolone',
                'Brentino Belluno',
                'Brenzone sul Garda',
                'Bussolengo',
                'Buttapietra',
                'Caldiero',
                'Caprino Veronese',
                'Casaleone',
                'Castagnaro',
                'Castel d\'Azzano',
                'Castelnuovo del Garda',
                'Cavaion Veronese',
                'Cazzano di Tramigna',
                'Cerea',
                'Cerro Veronese',
                'Cologna Veneta',
                'Colognola ai Colli',
                'Concamarise',
                'Costermano sul Garda',
                'Dolcè',
                'Erbè',
                'Erbezzo',
                'Ferrara di Monte Baldo',
                'Fumane',
                'Garda',
                'Gazzo Veronese',
                'Grezzana',
                'Illasi',
                'Isola della Scala',
                'Isola Rizza',
                'Lavagno',
                'Lazise',
                'Legnago',
                'Malcesine',
                'Marano di Valpolicella',
                'Mezzane di Sotto',
                'Minerbe',
                'Montecchia di Crosara',
                'Monteforte d\'Alpone',
                'Mozzecane',
                'Negrar di Valpolicella',
                'Nogara',
                'Nogarole Rocca',
                'Oppeano',
                'Palù',
                'Pastrengo',
                'Pescantina',
                'Peschiera del Garda',
                'Povegliano Veronese',
                'Pressana',
                'Rivoli Veronese',
                'Roncà',
                'Ronco all\'Adige',
                'Roverchiara',
                'Roverè Veronese',
                'Roveredo di Guà',
                'Salizzole',
                'San Bonifacio',
                'San Giovanni Ilarione',
                'San Giovanni Lupatoto',
                'San Martino Buon Albergo',
                'San Mauro di Saline',
                'San Pietro di Morubio',
                'San Pietro in Cariano',
                'San Zeno di Montagna',
                'Sanguinetto',
                'Sant\'Ambrogio di Valpolicella',
                'Sant\'Anna d\'Alfaedo',
                'Selva di Progno',
                'Soave',
                'Sommacampagna',
                'Sona',
                'Sorgà',
                'Terrazzo',
                'Torri del Benaco',
                'Tregnago',
                'Trevenzuolo',
                'Valeggio sul Mincio',
                'Velo Veronese',
                'Verona',
                'Veronella',
                'Vestenanova',
                'Vigasio',
                'Villa Bartolomea',
                'Villafranca di Verona',
                'Zevio',
                'Zimella'
            ],
            sigla: 'VR'
        },
        {
            name: 'Vibo Valentia',
            municipalities: [
                'Acquaro',
                'Arena',
                'Briatico',
                'Brognaturo',
                'Capistrano',
                'Cessaniti',
                'Dasà',
                'Dinami',
                'Drapia',
                'Fabrizia',
                'Filadelfia',
                'Filandari',
                'Filogaso',
                'Francavilla Angitola',
                'Francica',
                'Gerocarne',
                'Ionadi',
                'Joppolo',
                'Limbadi',
                'Maierato',
                'Mileto',
                'Mongiana',
                'Monterosso Calabro',
                'Nardodipace',
                'Nicotera',
                'Parghelia',
                'Pizzo',
                'Pizzoni',
                'Polia',
                'Ricadi',
                'Rombiolo',
                'San Calogero',
                'San Costantino Calabro',
                'San Gregorio d\'Ippona',
                'San Nicola da Crissa',
                'Sant\'Onofrio',
                'Serra San Bruno',
                'Simbario',
                'Sorianello',
                'Soriano Calabro',
                'Spadola',
                'Spilinga',
                'Stefanaconi',
                'Tropea',
                'Vallelonga',
                'Vazzano',
                'Vibo Valentia',
                'Zaccanopoli',
                'Zambrone',
                'Zungri'
            ],
            sigla: 'VV'
        },
        {
            name: 'Vicenza',
            municipalities: [
                'Agugliaro',
                'Albettone',
                'Alonte',
                'Altavilla Vicentina',
                'Altissimo',
                'Arcugnano',
                'Arsiero',
                'Arzignano',
                'Asiago',
                'Asigliano Veneto',
                'Barbarano Mossano',
                'Bassano del Grappa',
                'Bolzano Vicentino',
                'Breganze',
                'Brendola',
                'Bressanvido',
                'Brogliano',
                'Caldogno',
                'Caltrano',
                'Calvene',
                'Camisano Vicentino',
                'Campiglia dei Berici',
                'Carrè',
                'Cartigliano',
                'Cassola',
                'Castegnero',
                'Castelgomberto',
                'Chiampo',
                'Chiuppano',
                'Cogollo del Cengio',
                'Colceresa',
                'Cornedo Vicentino',
                'Costabissara',
                'Creazzo',
                'Crespadoro',
                'Dueville',
                'Enego',
                'Fara Vicentino',
                'Foza',
                'Gallio',
                'Gambellara',
                'Gambugliano',
                'Grisignano di Zocco',
                'Grumolo delle Abbadesse',
                'Isola Vicentina',
                'Laghi',
                'Lastebasse',
                'Longare',
                'Lonigo',
                'Lugo di Vicenza',
                'Lusiana Conco',
                'Malo',
                'Marano Vicentino',
                'Marostica',
                'Monte di Malo',
                'Montebello Vicentino',
                'Montecchio Maggiore',
                'Montecchio Precalcino',
                'Montegalda',
                'Montegaldella',
                'Monteviale',
                'Monticello Conte Otto',
                'Montorso Vicentino',
                'Mussolente',
                'Nanto',
                'Nogarole Vicentino',
                'Nove',
                'Noventa Vicentina',
                'Orgiano',
                'Pedemonte',
                'Pianezze',
                'Piovene Rocchette',
                'Pojana Maggiore',
                'Posina',
                'Pove del Grappa',
                'Pozzoleone',
                'Quinto Vicentino',
                'Recoaro Terme',
                'Roana',
                'Romano d\'Ezzelino',
                'Rosà',
                'Rossano Veneto',
                'Rotzo',
                'Salcedo',
                'San Pietro Mussolino',
                'San Vito di Leguzzano',
                'Sandrigo',
                'Santorso',
                'Sarcedo',
                'Sarego',
                'Schiavon',
                'Schio',
                'Solagna',
                'Sossano',
                'Sovizzo',
                'Tezze sul Brenta',
                'Thiene',
                'Tonezza del Cimone',
                'Torrebelvicino',
                'Torri di Quartesolo',
                'Trissino',
                'Val Liona',
                'Valbrenta',
                'Valdagno',
                'Valdastico',
                'Valli del Pasubio',
                'Velo d\'Astico',
                'Vicenza',
                'Villaga',
                'Villaverla',
                'Zanè',
                'Zermeghedo',
                'Zovencedo',
                'Zugliano'
            ],
            sigla: 'VI'
        },
        {
            name: 'Viterbo',
            municipalities: [
                'Acquapendente',
                'Arlena di Castro',
                'Bagnoregio',
                'Barbarano Romano',
                'Bassano in Teverina',
                'Bassano Romano',
                'Blera',
                'Bolsena',
                'Bomarzo',
                'Calcata',
                'Canepina',
                'Canino',
                'Capodimonte',
                'Capranica',
                'Caprarola',
                'Carbognano',
                'Castel Sant\'Elia',
                'Castiglione in Teverina',
                'Celleno',
                'Cellere',
                'Civita Castellana',
                'Civitella d\'Agliano',
                'Corchiano',
                'Fabrica di Roma',
                'Faleria',
                'Farnese',
                'Gallese',
                'Gradoli',
                'Graffignano',
                'Grotte di Castro',
                'Ischia di Castro',
                'Latera',
                'Lubriano',
                'Marta',
                'Montalto di Castro',
                'Monte Romano',
                'Montefiascone',
                'Monterosi',
                'Nepi',
                'Onano',
                'Oriolo Romano',
                'Orte',
                'Piansano',
                'Proceno',
                'Ronciglione',
                'San Lorenzo Nuovo',
                'Soriano nel Cimino',
                'Sutri',
                'Tarquinia',
                'Tessennano',
                'Tuscania',
                'Valentano',
                'Vallerano',
                'Vasanello',
                'Vejano',
                'Vetralla',
                'Vignanello',
                'Villa San Giovanni in Tuscia',
                'Viterbo',
                'Vitorchiano'
            ],
            sigla: 'VT'
        }
    ];
}

/**
 * cercacasa_api
 * cercacasa_api
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Agency } from '../model/agency';
import { Agent } from '../model/agent';
import { Client } from '../model/client';
import { InlineResponse200 } from '../model/inlineResponse200';
import { InlineResponse2001 } from '../model/inlineResponse2001';
import { InlineResponse2002 } from '../model/inlineResponse2002';
import { Listing } from '../model/listing';
import { ListingClient } from '../model/listingClient';
import { Member } from '../model/member';
import { Professionist } from '../model/professionist';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ClientService {

    protected basePath = 'https://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Count instances of the model matched by where from the data source.
     * 
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientCount(where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public clientCount(where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public clientCount(where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public clientCount(where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/clients/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new instance of the model and persist it into the data source.
     * 
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientCreate(data?: Client, observe?: 'body', reportProgress?: boolean): Observable<Client>;
    public clientCreate(data?: Client, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Client>>;
    public clientCreate(data?: Client, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Client>>;
    public clientCreate(data?: Client, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Client>(`${this.basePath}/clients`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a change stream.
     * 
     * @param options 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientCreateChangeStreamGetClientsChangeStream(options?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public clientCreateChangeStreamGetClientsChangeStream(options?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public clientCreateChangeStreamGetClientsChangeStream(options?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public clientCreateChangeStreamGetClientsChangeStream(options?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (options !== undefined && options !== null) {
            queryParameters = queryParameters.set('options', <any>options);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get(`${this.basePath}/clients/change-stream`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a change stream.
     * 
     * @param options 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientCreateChangeStreamPostClientsChangeStream(options?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public clientCreateChangeStreamPostClientsChangeStream(options?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public clientCreateChangeStreamPostClientsChangeStream(options?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public clientCreateChangeStreamPostClientsChangeStream(options?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (options !== undefined) {
            formParams.append('options', <any>options);
        }

        return this.httpClient.post(`${this.basePath}/clients/change-stream`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a model instance by {{id}} from the data source.
     * 
     * @param id Model id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientDeleteById(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public clientDeleteById(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public clientDeleteById(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public clientDeleteById(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientDeleteById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/clients/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientDeleteClient(id: string, observe?: 'body', reportProgress?: boolean): Observable<Client>;
    public clientDeleteClient(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Client>>;
    public clientDeleteClient(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Client>>;
    public clientDeleteClient(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientDeleteClient.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<Client>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/deleteClient`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check whether a model instance exists in the data source.
     * 
     * @param id Model id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientExistsGetClientsidExists(id: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2001>;
    public clientExistsGetClientsidExists(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2001>>;
    public clientExistsGetClientsidExists(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2001>>;
    public clientExistsGetClientsidExists(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientExistsGetClientsidExists.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse2001>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/exists`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check whether a model instance exists in the data source.
     * 
     * @param id Model id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientExistsHeadClientsid(id: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2001>;
    public clientExistsHeadClientsid(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2001>>;
    public clientExistsHeadClientsid(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2001>>;
    public clientExistsHeadClientsid(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientExistsHeadClientsid.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<InlineResponse2001>(`${this.basePath}/clients/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find all instances of the model matched by filter from the data source.
     * 
     * @param filter Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (&#x60;{\&quot;where\&quot;:{\&quot;something\&quot;:\&quot;value\&quot;}}&#x60;).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientFind(filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Client>>;
    public clientFind(filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Client>>>;
    public clientFind(filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Client>>>;
    public clientFind(filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Client>>(`${this.basePath}/clients`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a model instance by {{id}} from the data source.
     * 
     * @param id Model id
     * @param filter Filter defining fields and include - must be a JSON-encoded string ({\&quot;something\&quot;:\&quot;value\&quot;})
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientFindById(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Client>;
    public clientFindById(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Client>>;
    public clientFindById(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Client>>;
    public clientFindById(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientFindById.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Client>(`${this.basePath}/clients/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find first instance of the model matched by filter from the data source.
     * 
     * @param filter Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (&#x60;{\&quot;where\&quot;:{\&quot;something\&quot;:\&quot;value\&quot;}}&#x60;).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientFindOne(filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Client>;
    public clientFindOne(filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Client>>;
    public clientFindOne(filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Client>>;
    public clientFindOne(filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Client>(`${this.basePath}/clients/findOne`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patch an existing model instance or insert a new one into the data source.
     * 
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPatchOrCreate(data?: Client, observe?: 'body', reportProgress?: boolean): Observable<Client>;
    public clientPatchOrCreate(data?: Client, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Client>>;
    public clientPatchOrCreate(data?: Client, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Client>>;
    public clientPatchOrCreate(data?: Client, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<Client>(`${this.basePath}/clients`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts listingLinked of Client.
     * 
     * @param id Client id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPrototypeCountListingLinked(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public clientPrototypeCountListingLinked(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public clientPrototypeCountListingLinked(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public clientPrototypeCountListingLinked(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientPrototypeCountListingLinked.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/listingLinked/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts listingLinkedWithType of Client.
     * 
     * @param id Client id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPrototypeCountListingLinkedWithType(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public clientPrototypeCountListingLinkedWithType(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public clientPrototypeCountListingLinkedWithType(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public clientPrototypeCountListingLinkedWithType(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientPrototypeCountListingLinkedWithType.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/listingLinkedWithType/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts listings of Client.
     * 
     * @param id Client id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPrototypeCountListings(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public clientPrototypeCountListings(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public clientPrototypeCountListings(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public clientPrototypeCountListings(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientPrototypeCountListings.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/listings/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in listingLinked of this model.
     * 
     * @param id Client id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPrototypeCreateListingLinked(id: string, data?: Listing, observe?: 'body', reportProgress?: boolean): Observable<Listing>;
    public clientPrototypeCreateListingLinked(id: string, data?: Listing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Listing>>;
    public clientPrototypeCreateListingLinked(id: string, data?: Listing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Listing>>;
    public clientPrototypeCreateListingLinked(id: string, data?: Listing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientPrototypeCreateListingLinked.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Listing>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/listingLinked`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in listingLinkedWithType of this model.
     * 
     * @param id Client id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPrototypeCreateListingLinkedWithType(id: string, data?: ListingClient, observe?: 'body', reportProgress?: boolean): Observable<ListingClient>;
    public clientPrototypeCreateListingLinkedWithType(id: string, data?: ListingClient, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListingClient>>;
    public clientPrototypeCreateListingLinkedWithType(id: string, data?: ListingClient, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListingClient>>;
    public clientPrototypeCreateListingLinkedWithType(id: string, data?: ListingClient, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientPrototypeCreateListingLinkedWithType.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListingClient>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/listingLinkedWithType`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in listings of this model.
     * 
     * @param id Client id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPrototypeCreateListings(id: string, data?: Listing, observe?: 'body', reportProgress?: boolean): Observable<Listing>;
    public clientPrototypeCreateListings(id: string, data?: Listing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Listing>>;
    public clientPrototypeCreateListings(id: string, data?: Listing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Listing>>;
    public clientPrototypeCreateListings(id: string, data?: Listing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientPrototypeCreateListings.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Listing>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/listings`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all listingLinked of this model.
     * 
     * @param id Client id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPrototypeDeleteListingLinked(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public clientPrototypeDeleteListingLinked(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public clientPrototypeDeleteListingLinked(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public clientPrototypeDeleteListingLinked(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientPrototypeDeleteListingLinked.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/listingLinked`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all listingLinkedWithType of this model.
     * 
     * @param id Client id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPrototypeDeleteListingLinkedWithType(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public clientPrototypeDeleteListingLinkedWithType(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public clientPrototypeDeleteListingLinkedWithType(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public clientPrototypeDeleteListingLinkedWithType(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientPrototypeDeleteListingLinkedWithType.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/listingLinkedWithType`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all listings of this model.
     * 
     * @param id Client id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPrototypeDeleteListings(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public clientPrototypeDeleteListings(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public clientPrototypeDeleteListings(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public clientPrototypeDeleteListings(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientPrototypeDeleteListings.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/listings`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for listingLinked.
     * 
     * @param id Client id
     * @param fk Foreign key for listingLinked
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPrototypeDestroyByIdListingLinked(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public clientPrototypeDestroyByIdListingLinked(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public clientPrototypeDestroyByIdListingLinked(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public clientPrototypeDestroyByIdListingLinked(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientPrototypeDestroyByIdListingLinked.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling clientPrototypeDestroyByIdListingLinked.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/listingLinked/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for listingLinkedWithType.
     * 
     * @param id Client id
     * @param fk Foreign key for listingLinkedWithType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPrototypeDestroyByIdListingLinkedWithType(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public clientPrototypeDestroyByIdListingLinkedWithType(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public clientPrototypeDestroyByIdListingLinkedWithType(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public clientPrototypeDestroyByIdListingLinkedWithType(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientPrototypeDestroyByIdListingLinkedWithType.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling clientPrototypeDestroyByIdListingLinkedWithType.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/listingLinkedWithType/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for listings.
     * 
     * @param id Client id
     * @param fk Foreign key for listings
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPrototypeDestroyByIdListings(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public clientPrototypeDestroyByIdListings(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public clientPrototypeDestroyByIdListings(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public clientPrototypeDestroyByIdListings(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientPrototypeDestroyByIdListings.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling clientPrototypeDestroyByIdListings.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/listings/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of listingLinked relation to an item by id.
     * 
     * @param id Client id
     * @param fk Foreign key for listingLinked
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPrototypeExistsListingLinked(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public clientPrototypeExistsListingLinked(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public clientPrototypeExistsListingLinked(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public clientPrototypeExistsListingLinked(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientPrototypeExistsListingLinked.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling clientPrototypeExistsListingLinked.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/listingLinked/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for listingLinked.
     * 
     * @param id Client id
     * @param fk Foreign key for listingLinked
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPrototypeFindByIdListingLinked(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Listing>;
    public clientPrototypeFindByIdListingLinked(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Listing>>;
    public clientPrototypeFindByIdListingLinked(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Listing>>;
    public clientPrototypeFindByIdListingLinked(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientPrototypeFindByIdListingLinked.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling clientPrototypeFindByIdListingLinked.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Listing>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/listingLinked/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for listingLinkedWithType.
     * 
     * @param id Client id
     * @param fk Foreign key for listingLinkedWithType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPrototypeFindByIdListingLinkedWithType(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<ListingClient>;
    public clientPrototypeFindByIdListingLinkedWithType(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListingClient>>;
    public clientPrototypeFindByIdListingLinkedWithType(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListingClient>>;
    public clientPrototypeFindByIdListingLinkedWithType(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientPrototypeFindByIdListingLinkedWithType.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling clientPrototypeFindByIdListingLinkedWithType.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<ListingClient>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/listingLinkedWithType/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for listings.
     * 
     * @param id Client id
     * @param fk Foreign key for listings
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPrototypeFindByIdListings(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Listing>;
    public clientPrototypeFindByIdListings(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Listing>>;
    public clientPrototypeFindByIdListings(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Listing>>;
    public clientPrototypeFindByIdListings(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientPrototypeFindByIdListings.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling clientPrototypeFindByIdListings.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Listing>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/listings/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetches belongsTo relation agency.
     * 
     * @param id Client id
     * @param refresh 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPrototypeGetAgency(id: string, refresh?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Agency>;
    public clientPrototypeGetAgency(id: string, refresh?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agency>>;
    public clientPrototypeGetAgency(id: string, refresh?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agency>>;
    public clientPrototypeGetAgency(id: string, refresh?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientPrototypeGetAgency.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (refresh !== undefined && refresh !== null) {
            queryParameters = queryParameters.set('refresh', <any>refresh);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Agency>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/agency`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetches belongsTo relation agent.
     * 
     * @param id Client id
     * @param refresh 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPrototypeGetAgent(id: string, refresh?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Agent>;
    public clientPrototypeGetAgent(id: string, refresh?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agent>>;
    public clientPrototypeGetAgent(id: string, refresh?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agent>>;
    public clientPrototypeGetAgent(id: string, refresh?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientPrototypeGetAgent.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (refresh !== undefined && refresh !== null) {
            queryParameters = queryParameters.set('refresh', <any>refresh);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Agent>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/agent`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries listingLinked of Client.
     * 
     * @param id Client id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPrototypeGetListingLinked(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Listing>>;
    public clientPrototypeGetListingLinked(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Listing>>>;
    public clientPrototypeGetListingLinked(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Listing>>>;
    public clientPrototypeGetListingLinked(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientPrototypeGetListingLinked.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Listing>>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/listingLinked`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries listingLinkedWithType of Client.
     * 
     * @param id Client id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPrototypeGetListingLinkedWithType(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ListingClient>>;
    public clientPrototypeGetListingLinkedWithType(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ListingClient>>>;
    public clientPrototypeGetListingLinkedWithType(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ListingClient>>>;
    public clientPrototypeGetListingLinkedWithType(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientPrototypeGetListingLinkedWithType.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<ListingClient>>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/listingLinkedWithType`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries listings of Client.
     * 
     * @param id Client id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPrototypeGetListings(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Listing>>;
    public clientPrototypeGetListings(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Listing>>>;
    public clientPrototypeGetListings(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Listing>>>;
    public clientPrototypeGetListings(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientPrototypeGetListings.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Listing>>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/listings`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetches belongsTo relation member.
     * 
     * @param id Client id
     * @param refresh 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPrototypeGetMember(id: string, refresh?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Member>;
    public clientPrototypeGetMember(id: string, refresh?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Member>>;
    public clientPrototypeGetMember(id: string, refresh?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Member>>;
    public clientPrototypeGetMember(id: string, refresh?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientPrototypeGetMember.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (refresh !== undefined && refresh !== null) {
            queryParameters = queryParameters.set('refresh', <any>refresh);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Member>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/member`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetches belongsTo relation professionist.
     * 
     * @param id Client id
     * @param refresh 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPrototypeGetProfessionist(id: string, refresh?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Professionist>;
    public clientPrototypeGetProfessionist(id: string, refresh?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Professionist>>;
    public clientPrototypeGetProfessionist(id: string, refresh?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Professionist>>;
    public clientPrototypeGetProfessionist(id: string, refresh?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientPrototypeGetProfessionist.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (refresh !== undefined && refresh !== null) {
            queryParameters = queryParameters.set('refresh', <any>refresh);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Professionist>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/professionist`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for listingLinked.
     * 
     * @param id Client id
     * @param fk Foreign key for listingLinked
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPrototypeLinkListingLinked(id: string, fk: string, data?: Listing, observe?: 'body', reportProgress?: boolean): Observable<Listing>;
    public clientPrototypeLinkListingLinked(id: string, fk: string, data?: Listing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Listing>>;
    public clientPrototypeLinkListingLinked(id: string, fk: string, data?: Listing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Listing>>;
    public clientPrototypeLinkListingLinked(id: string, fk: string, data?: Listing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientPrototypeLinkListingLinked.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling clientPrototypeLinkListingLinked.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Listing>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/listingLinked/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patch attributes for a model instance and persist it into the data source.
     * 
     * @param id Client id
     * @param data An object of model property name/value pairs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPrototypePatchAttributes(id: string, data?: Client, observe?: 'body', reportProgress?: boolean): Observable<Client>;
    public clientPrototypePatchAttributes(id: string, data?: Client, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Client>>;
    public clientPrototypePatchAttributes(id: string, data?: Client, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Client>>;
    public clientPrototypePatchAttributes(id: string, data?: Client, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientPrototypePatchAttributes.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<Client>(`${this.basePath}/clients/${encodeURIComponent(String(id))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the listingLinked relation to an item by id.
     * 
     * @param id Client id
     * @param fk Foreign key for listingLinked
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPrototypeUnlinkListingLinked(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public clientPrototypeUnlinkListingLinked(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public clientPrototypeUnlinkListingLinked(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public clientPrototypeUnlinkListingLinked(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientPrototypeUnlinkListingLinked.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling clientPrototypeUnlinkListingLinked.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/listingLinked/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for listingLinked.
     * 
     * @param id Client id
     * @param fk Foreign key for listingLinked
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPrototypeUpdateByIdListingLinked(id: string, fk: string, data?: Listing, observe?: 'body', reportProgress?: boolean): Observable<Listing>;
    public clientPrototypeUpdateByIdListingLinked(id: string, fk: string, data?: Listing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Listing>>;
    public clientPrototypeUpdateByIdListingLinked(id: string, fk: string, data?: Listing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Listing>>;
    public clientPrototypeUpdateByIdListingLinked(id: string, fk: string, data?: Listing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientPrototypeUpdateByIdListingLinked.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling clientPrototypeUpdateByIdListingLinked.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Listing>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/listingLinked/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for listingLinkedWithType.
     * 
     * @param id Client id
     * @param fk Foreign key for listingLinkedWithType
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPrototypeUpdateByIdListingLinkedWithType(id: string, fk: string, data?: ListingClient, observe?: 'body', reportProgress?: boolean): Observable<ListingClient>;
    public clientPrototypeUpdateByIdListingLinkedWithType(id: string, fk: string, data?: ListingClient, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListingClient>>;
    public clientPrototypeUpdateByIdListingLinkedWithType(id: string, fk: string, data?: ListingClient, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListingClient>>;
    public clientPrototypeUpdateByIdListingLinkedWithType(id: string, fk: string, data?: ListingClient, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientPrototypeUpdateByIdListingLinkedWithType.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling clientPrototypeUpdateByIdListingLinkedWithType.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ListingClient>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/listingLinkedWithType/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for listings.
     * 
     * @param id Client id
     * @param fk Foreign key for listings
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientPrototypeUpdateByIdListings(id: string, fk: string, data?: Listing, observe?: 'body', reportProgress?: boolean): Observable<Listing>;
    public clientPrototypeUpdateByIdListings(id: string, fk: string, data?: Listing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Listing>>;
    public clientPrototypeUpdateByIdListings(id: string, fk: string, data?: Listing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Listing>>;
    public clientPrototypeUpdateByIdListings(id: string, fk: string, data?: Listing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientPrototypeUpdateByIdListings.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling clientPrototypeUpdateByIdListings.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Listing>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/listings/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace attributes for a model instance and persist it into the data source.
     * 
     * @param id Model id
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientReplaceByIdPostClientsidReplace(id: string, data?: Client, observe?: 'body', reportProgress?: boolean): Observable<Client>;
    public clientReplaceByIdPostClientsidReplace(id: string, data?: Client, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Client>>;
    public clientReplaceByIdPostClientsidReplace(id: string, data?: Client, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Client>>;
    public clientReplaceByIdPostClientsidReplace(id: string, data?: Client, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientReplaceByIdPostClientsidReplace.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Client>(`${this.basePath}/clients/${encodeURIComponent(String(id))}/replace`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace attributes for a model instance and persist it into the data source.
     * 
     * @param id Model id
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientReplaceByIdPutClientsid(id: string, data?: Client, observe?: 'body', reportProgress?: boolean): Observable<Client>;
    public clientReplaceByIdPutClientsid(id: string, data?: Client, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Client>>;
    public clientReplaceByIdPutClientsid(id: string, data?: Client, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Client>>;
    public clientReplaceByIdPutClientsid(id: string, data?: Client, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clientReplaceByIdPutClientsid.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Client>(`${this.basePath}/clients/${encodeURIComponent(String(id))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace an existing model instance or insert a new one into the data source.
     * 
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientReplaceOrCreatePostClientsReplaceOrCreate(data?: Client, observe?: 'body', reportProgress?: boolean): Observable<Client>;
    public clientReplaceOrCreatePostClientsReplaceOrCreate(data?: Client, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Client>>;
    public clientReplaceOrCreatePostClientsReplaceOrCreate(data?: Client, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Client>>;
    public clientReplaceOrCreatePostClientsReplaceOrCreate(data?: Client, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Client>(`${this.basePath}/clients/replaceOrCreate`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace an existing model instance or insert a new one into the data source.
     * 
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientReplaceOrCreatePutClients(data?: Client, observe?: 'body', reportProgress?: boolean): Observable<Client>;
    public clientReplaceOrCreatePutClients(data?: Client, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Client>>;
    public clientReplaceOrCreatePutClients(data?: Client, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Client>>;
    public clientReplaceOrCreatePutClients(data?: Client, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Client>(`${this.basePath}/clients`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param query 
     * @param agentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientSearchByName(query?: string, agentId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Client>>;
    public clientSearchByName(query?: string, agentId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Client>>>;
    public clientSearchByName(query?: string, agentId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Client>>>;
    public clientSearchByName(query?: string, agentId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (agentId !== undefined && agentId !== null) {
            queryParameters = queryParameters.set('agentId', <any>agentId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Client>>(`${this.basePath}/clients/searchByName`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update instances of the model matched by {{where}} from the data source.
     * 
     * @param where Criteria to match model instances
     * @param data An object of model property name/value pairs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientUpdateAll(where?: string, data?: Client, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2002>;
    public clientUpdateAll(where?: string, data?: Client, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2002>>;
    public clientUpdateAll(where?: string, data?: Client, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2002>>;
    public clientUpdateAll(where?: string, data?: Client, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse2002>(`${this.basePath}/clients/update`,
            data,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an existing model instance or insert a new one into the data source based on the where criteria.
     * 
     * @param where Criteria to match model instances
     * @param data An object of model property name/value pairs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clientUpsertWithWhere(where?: string, data?: Client, observe?: 'body', reportProgress?: boolean): Observable<Client>;
    public clientUpsertWithWhere(where?: string, data?: Client, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Client>>;
    public clientUpsertWithWhere(where?: string, data?: Client, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Client>>;
    public clientUpsertWithWhere(where?: string, data?: Client, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Client>(`${this.basePath}/clients/upsertWithWhere`,
            data,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

import { DOCUMENT, isPlatformBrowser, Location, LocationStrategy, NgIf, PathLocationStrategy, } from '@angular/common';
import {
  AfterContentChecked,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
  RouterOutlet
} from '@angular/router';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';
import {
  Agency,
  Agent,
  Article,
  Brand,
  FavouriteAgencyService,
  FavouriteAgentService,
  FavouriteProductService,
  FavouriteProfessionistService,
  Listing,
  Member,
  MemberService,
  MutuiConfig,
  MutuiConfigService,
  Product,
  Professionist,
  SavedListingService,
  UserSearch,
  VisitedAgencyService,
  VisitedAgentService,
  VisitedListingService,
  VisitedProfessionistService,
  Zone
} from './api';
import { CommonComponent } from './common/common/common.component';
import { AuthenticationService } from './services/authentication.service';
import { ConsoleLoggerService } from './services/console-logger.service';
import { ShareService } from './services/share.service';
import Constants, { SearchQuery } from './utils/Constants';
import ConstantsProvinceAndMunicipalities from './utils/ConstantsProvinceAndMunicipalities';
import {GoogleTagManagerService} from 'angular-google-tag-manager';

// declare let gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [Location, {
    provide: LocationStrategy,
    useClass: PathLocationStrategy,
  }],
  standalone: true,
  imports: [NgIf, RouterOutlet],
})

export class AppComponent extends CommonComponent implements OnInit, AfterContentChecked, OnDestroy {

  private unsubscribe$ = new Subject();

  constructor(@Inject(DOCUMENT) private document: Document, private authenticationService: AuthenticationService, private router: Router, private route: ActivatedRoute, private member: MemberService, private savedListingsService: SavedListingService, private favoriteAgentService: FavouriteAgentService, private visitedAgentService: VisitedAgentService, private favoriteAgencyService: FavouriteAgencyService, private visitedAgencyService: VisitedAgencyService, private favoriteProfessionistService: FavouriteProfessionistService, private favouriteProductsService: FavouriteProductService, private visitedProfessionistService: VisitedProfessionistService, public toastr: ToastrService, private shareService: ShareService, @Inject(PLATFORM_ID) private platformId: object, private mutuiConfigService: MutuiConfigService, private console: ConsoleLoggerService, private visitedListingService: VisitedListingService, private cookieService: SsrCookieService, private elementRef: ElementRef, private gtmService: GoogleTagManagerService) {
    super(authenticationService);
    if (isPlatformBrowser(this.platformId)) {
      this.gtmService.addGtmToDom();
      this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe(event => {
        if (event instanceof NavigationEnd) {
          // this.gtmService.pushTag({ event: 'page_view', pageName: this.router.url });
        }
        if (event instanceof NavigationStart) {
          window.scrollTo(0, 0);
        }
        if (event instanceof RouteConfigLoadStart) {
          this.routeLazyLoading = true;
        }
        if (event instanceof RouteConfigLoadEnd) {
          this.routeLazyLoading = false;
        }
        if (event instanceof NavigationCancel) {
          this.routeLazyLoading = false;
        }
      });
    }
  }

  // PARAMETRI COMPONENTI SEARCH-BAR-INPUT
  searchType = 'agente'; // TIPOLOGIA DI RICERCA: VENDITA, AFFITTO O AGENZIA
  searchMethodType = 'name'; // TIPO DI RICERCA: NOME, PLACE
  isAgentSearch = true; // switch ricerca tra agents e professionist

  searchDropdown = false; // DECIDE SE MOSTRARE IL SUGGERIMENTO DELLA BARRA DI RICERCA
  searchCategoryDropdown = false; // DECIDI SE MOSTRARE IL DROPDOWN DELLE CATEGORIA
  searchMethodTypeDropdown = false; // DROPDOWN RICERCA PER ZONA
  searchNameDropdown = false; // DROPDOWN RICERCA PER NOME
  searchDropdownCityZones = false; // DECIDE SE MOSTRARE IL SUGGERIMENTO DELLA BARRA DI RICERCA PER LE ZONE DEL COMUNE

  location: any;
  routerSubscription: any;
  modalUserOpen = false;

  userSearches: UserSearch[] = [];
  savedListings: Listing[] = [];
  visitedListings: Listing[] = [];

  savedAgentsList: Agent[] = [];
  visitedAgentsList: Agent[] = [];

  savedAgencyList: Agency[] = [];
  visitedAgencyList: Agency[] = [];
  savedProfessionistsList: Professionist[] = [];
  visitedProfessionistsList: Professionist[] = [];

  savedProductsList: Product[] = [];
  likedAgenciesArray: Agency[] = [];
  likedAgentsArray: Agent[] = [];
  likedListingsArray: Listing[] = [];
  likedBrandsArray: Brand[] = [];
  likedProfessionistsArray: Professionist[] = [] = [];
  likedProductsArray: Product[] = [];
  likedArticleArray: Article[] = [];

  listOfTypologies: any = Constants.LIST_OF_TYPOLOGY;

  provincesMap: Zone[] = [];
  objRicercheRecenti = []; // GESTIONE RICERCHE RECENTI

  dashboardLink: string;

  newsSearchValue = ''; // Valore di ricerca
  newsSearchCategory = '';
  newsSearchComune = '';
  newsSearchProvincia = '';
  newsSearchRegione = '';
  newsSearchZona = '';

  zoneSelectedValue = '';

  activities = [{
    name: 'compravendite',
    value: 'compravendite',
    orderName: 'agent_skill_percentage.compravendite DESC',
  }, {
    name: 'locazioni',
    value: 'locazioni',
    orderName: 'agent_skill_percentage.locazioni DESC',
  }, {
    name: 'aste',
    value: 'aste',
    orderName: 'agent_skill_percentage.aste DESC',
  }, {
    name: 'property finder',
    value: 'property_finder',
    orderName: 'agent_skill_percentage.property_finder DESC',
  }, {
    name: 'mutui e prestiti',
    value: 'mutui_e_prestiti',
    orderName: 'agent_skill_percentage.mutui_e_prestiti DESC',
  }, {
    name: 'assicurazioni',
    value: 'assicurazioni',
    orderName: 'agent_skill_percentage.assicurazioni DESC',
  }];

  orderFilters = [{
    name: 'Più Recenti',
    orderName: 'created_at DESC',
    type: 'listing',
  }, {
    name: 'Meno Recenti',
    orderName: 'created_at ASC',
    type: 'listing',
  }, {
    name: 'Meno Costosi',
    orderName: 'price ASC',
    type: 'listing',
  }, {
    name: 'Più Costosi',
    orderName: 'price DESC',
    type: 'listing',
  }, {
    name: 'Meno Grandi',
    orderName: 'surface ASC',
    type: 'listing',
  }, {
    name: 'Più Grandi',
    orderName: 'surface DESC',
    type: 'listing',
  }, {
    name: 'Più Salvati',
    orderName: 'numberOfFavourites DESC',
    type: 'listing',
  }, {
    name: 'Numero immobili venduti',
    orderName: 'sold DESC',
    type: 'agent',
  }, {
    name: 'Numero immobili affittati',
    orderName: 'rented DESC',
    type: 'agent',
  }, {
    name: 'Recensioni più alte',
    orderName: 'average_stars DESC',
    type: 'agent',
  }, {
    name: 'Più salvati',
    orderName: 'numberOfFavourites DESC',
    type: 'agent',
  }, {
    name: 'Numero immobili venduti',
    orderName: 'sold DESC',
    type: 'agency',
  }, {
    name: 'Numero immobili affittati',
    orderName: 'rented DESC',
    type: 'agency',
  }, {
    name: 'Recensioni più alte',
    orderName: 'average_stars DESC',
    type: 'agency',
  }, {
    name: 'Più salvati',
    orderName: 'numberOfFavourites DESC',
    type: 'agency',
  }, {
    name: 'Recensioni più alte',
    orderName: 'average_stars DESC',
    type: 'professionist',
  }, {
    name: 'Più salvati',
    orderName: 'numberOfFavourites DESC',
    type: 'professionist',
  }];

  languages = ['arabo', 'cinese', 'danese', 'finlandese', 'francese', 'giapponese', 'greco', 'inglese', 'islandese', 'italiano', 'norvegese', 'olandese', 'polacco', 'portoghese', 'russo', 'spagnolo', 'svedese', 'tedesco', 'turco', 'ungherese'];

  specializations = [{
    id: 'alberghiero',
    name: 'Alberghiero',
  }, {
    id: 'aste',
    name: 'Aste',
  }, {
    id: 'immobili-a-reddito',
    name: 'Immobili a reddito',
  }, {
    id: 'immobili-commerciali',
    name: 'Immobili commerciali',
  }, {
    id: 'immobili-di-lusso',
    name: 'Immobili di lusso',
  }, {
    id: 'licenze-e-attivita-commerciali',
    name: 'Licenze e attività commerciali',
  }, {
    id: 'locazioni-turistiche',
    name: 'Locazioni turistiche',
  }, {
    id: 'nuove-costruzioni',
    name: 'Nuove costruzioni',
  }, {
    id: 'residenziale',
    name: 'Residenziale',
  }, {
    id: 'sviluppo-immobiliare',
    name: 'Sviluppo immobiliare',
  }, {
    id: 'terreni-agricoli',
    name: 'Terreni agricoli',
  }, {
    id: 'terreni-edificabili',
    name: 'Terreni edificabili',
  }, {
    id: 'turistico',
    name: 'Turistico',
  }, {
    id: 'home-staging',
    name: 'Home staging',
  }, {
    id: 'pratiche-catastali',
    name: 'Pratiche catastali',
  }, {
    id: 'finanziamenti',
    name: 'Finanziamenti ',
  }, {
    id: 'mutui',
    name: 'Mutui',
  }, {
    id: 'assicurazioni',
    name: 'Assicurazioni',
  }, {
    id: 'cessione-del-quinto',
    name: 'Cessione del quinto',
  }];

  mandates = [{
    id: 'in-esclusiva',
    name: 'In esclusiva',
  }, {
    id: 'non-in-esclusiva',
    name: 'Non esclusiva',
  }, {
    id: 'verbale',
    name: 'Verbale',
  }];

  newsFilters = [{
    id: 'created_at DESC',
    name: 'Più recenti',
    slug: 'recenti',
  }, {
    id: 'created_at ASC',
    name: 'Meno recenti',
    slug: 'meno_recenti',
  }];

  specializationsProfessionists = [{
    id: 'mutui',
    name: 'Mutui',
  }, {
    id: 'prestiti-personali',
    name: 'Prestiti personali',
  }, {
    id: 'cessione-quinto',
    name: 'Cessione del quinto',
  }, {
    id: 'delega-pagamento',
    name: 'Delega di pagamento',
  }, {
    id: 'microcredito',
    name: 'Microcredito',
  }, {
    id: 'finanziamenti-pmi',
    name: 'Finanziamenti pmi',
  }, {
    id: 'assicurazioni',
    name: 'Assicurazioni',
  }];

  likedReviews: any[] = [];

  // Filtraggio
  public filtersList = {
    minPrice: null,
    maxPrice: null,
    minMQ: null,
    maxMQ: null,
    minLocali: null,
    maxLocali: null,
    minFinanziamento: null,
    maxFinanziamento: null,
    associations: null,
    languages: null,
    specializations: null,
    minExp: null,
    maxExp: null,
    mandates: null,
    opportunity: null,
    exclusive: null,
    collab: null,

    conditions: null,
    context: null,
    heating: null,
    kitchen: null,
    bathrooms: null,
    floors: null,
    garden: null,
    extra: null,
    garage: null,
    is_auction: null
  };

  public orderList = {
    activities: '',
    order: '',
  };
  sitePath: string;
  provinceComuni: any;
  browserLoaded: boolean;

  // Route loading
  routeLazyLoading = false;

  // Gestione Loading Like Buttons
  public loadAgentLikeBtn = false;
  public loadAgencyLikeBtn = false;
  public loadListingLikeBtn = false;
  public loadBrandLikeBtn = false;
  public loadProfessionistLikeBtn = false;
  public loadProductLikeBtn = false;
  public loadArticlesLikeBtn = false;
  public showSaveAndLikeBtn = false;

  public oldUrlSearchPage: '';
  public newUrlSearchPage: '';

  // GESTIONE FUNZIONI DI SCROLL
  containerAnnuncio: any;
  containerAnnuncioHeight: any;

  header: any;
  headerHeight: any;

  // FILTRI PAGINE UTENTE PREFERITI E VISITATI
  filterSavedAndVisitedAgent = JSON.stringify({
    where: {
      memberId: this.currentUser?.id,
    },
    order: 'created_at DESC',
    include: [
      {
        relation: 'agent',
        scope: {
          include: [
            {
              relation: 'agency',
              scope: {
                fields: ['slug', 'name', 'address', 'color', 'logo_img']
              }
            },
            {
              relation: 'zones_served',
              scope: {
                fields: ['display_name']
              }
            },
            {
              relation: 'reviews',
              scope: {
                fields: ['stars']
              }
            },
            'listings',
          ]
        }
      }
    ],
  });

  filterFavoriteAndVisitedAgency = JSON.stringify({
    where: {
      memberId: this.currentUser?.id,
    },
    order: 'created_at DESC',
    include: [
      {
        relation: 'agency',
        scope: {
          include: [
            {
              relation: 'listings',
              where: {
                isDeleted: false,
                historical: false,
                status: { ne: 'draft' },
              },
              fields: ['slug', 'status', 'contract_type']
            },
            {
              relation: 'managing_agents',
              scope: {
                include: [{ relation: 'zones_served', scope: { fields: ['display_name'] } }]
              }
            },
          ],
        }
      }
    ]
  });

  filterFavoriteAndVisitedProfessionist = JSON.stringify({
    include: 'professionist',
    where: {
      memberId: this.currentUser?.id,
    },
    order: 'created_at DESC'
  });

  ngOnInit(): void {
    // if (isPlatformBrowser(this.platformId)) {
    //   if (environment.gtag) {
    //     const gtagScript = document.createElement('script');
    //     gtagScript.type = 'text/javascript';
    //     gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gtag}`;
    //     this.elementRef.nativeElement.appendChild(gtagScript);
    //   }
    // }
    this.recallJsFuntions();
    this.shareService.setFacebookTags(environment.sitePath,
      'Case in vendita, annunci immobiliare in vendita - cercacasa.it',
      'CercaCasa.it è il portale di annunci immobiliari e proposte relative ad appartamenti, ville, rustici, case al mare, case in affitto e vacanza', environment.sitePath + '/assets/img/share-logo.jpg',
    );
    this.dashboardLink = environment.dashboard;
    this.sitePath = environment.sitePath;
    this.getProvinceEComuni();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.unsubscribe();
    return super.ngOnDestroy();
  }

  getInnerHeight(element: any): number | undefined {
    if (element) {
      const computed = getComputedStyle(element);
      const padding = parseInt(computed.paddingTop, 10) + parseInt(computed.paddingBottom, 10);
      return element.clientHeight - padding;
    }
    return undefined;
  }

  ngAfterContentChecked(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.browserLoaded = true;
      this.containerAnnuncio = document.querySelector('.detailInfo');
      this.containerAnnuncioHeight = this.getInnerHeight(this.containerAnnuncio) + 50;

      this.header = document.getElementById('header-cercacasa');
      this.headerHeight = this.getInnerHeight(this.header) + 15;
    }
  }

  getProvinceEComuni(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.provinceComuni = ConstantsProvinceAndMunicipalities.LIST;
    }
  }

  getCurrentUser(): any {
    if (this.cookieService.get('currentUser')) {
      return JSON.parse(this.cookieService.get('currentUser'));
    } else {
      return false;
    }
  }

  recallJsFuntions(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.routerSubscription = this.router.events.pipe(filter((event) => event instanceof NavigationEnd || event instanceof NavigationCancel)).pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
        this.location = this.router.url;
        if (!(event instanceof NavigationEnd)) {
          return;
        }
      });
    }
  }

  counterNgFor(i: number): any[] {
    return new Array(i);
  }

  async logout(): Promise<void> {
    await this.authenticationService.logout();
    location.href = '/';
    this.cookieService.delete('currentUser');
  }

  showExtraInfo(booleano: any, value: string | number, type: any): string {
    let description: string = booleano ? 'Si' : 'No';
    if (type !== null && type) {
      description = this.showGardenType(type);
    }
    if (value !== null && value !== 0) {
      description += ' (' + value + ' m<sup>2</sup>)';
    }
    return description;
  }

  showParkingSpace(booleano: any, value: string, type: any): string {
    let description: string = booleano ? 'Si' : 'No';
    if (type !== null && type) {
      description = this.showGardenType(type);
    }
    if (value) {
      description += ' (' + value.charAt(0).toUpperCase() + value.slice(1) + ')';
    }
    return description;
  }

  showRiscaldamento(value: string): string {
    if (!value || value === '' || value === 'non-disponibile') {
      return 'Non disp.';
    }
    switch (value.toString().toUpperCase()) {
      case 'CN':
        return 'Centralizzato';
      case 'AU':
        return 'Autonomo';
      case 'AP':
        return 'A Pavimento';
      case 'IN':
        return 'Inesistente';
      case 'ND':
        return 'Non disponibile';
      default:
        return value;
    }
  }

  showEnergyClass(value: string): string {
    if (!value || value === '') {
      return 'Non disp.';
    }
    switch (value.toString().toUpperCase()) {
      default:
        return value;
    }
  }

  showPiano(value: any): string {
    if (!value || value === '' || value === -100) {
      return 'Non disp.';
    }
    switch (value.toString()) {
      case '-99':
        return 'Su più piani';
      case '0.5':
        return 'Piano rialzato';
      case '0':
        return 'Piano terra';
      case '-0.5':
        return 'Piano interrato';
      case '1000':
        return 'Intero Edificio';
      default:
        return value + '° Piano';
    }
  }

  showPianos(value: number): string {
    return value <= 0 ? ' 1 Piano ' : value > 1 ? value + ' Piani' : value + ' Piano';
  }

  showKitchen(value: string): string {
    if (!value || value === '' || value === 'non-disponibile') {
      return 'Non disp.';
    }
    const kitchen = Constants.TYPE_CUCINA.find(cucina => cucina.value === value);
    return kitchen?.text || value;
  }

  showGardenType(value: string): string {
    if (!value || value === '' || value === 'non-disponibile') {
      return 'Non disp.';
    }
    switch (value.toString().toUpperCase()) {
      case 'CM':
        return 'Comune';
      case 'PR':
        return 'Privato';
      default:
        return value;
    }
  }

  showConditions(value: string): string {
    if (!value || value === '' || value === 'non-disponibile' || value === 'non-definito') {
      return 'Non disp.';
    }
    const condition = Constants.CONDIZIONE.find(cond => cond.value === value);
    return condition?.text || value;
  }

  showTypologyCategory(value): string {
    if (!value || value === '') {
      return 'Non disp.';
    }
    switch (value.toString().toLowerCase()) {
      case 'commericale':
        return 'Commerciale';

      case 'residenziale':
        return 'Residenziale';

      case 'stagionale-vacanza':
        return 'Stagionale / Vacanza';

      case 'terreni-e-aziende-agricole':
        return 'Terreni e Aziende Agricole';

      case 'uffici-e-capannoni':
        return 'Uffici e Capannoni';

      default:
        return value.toString().toLowerCase();
    }
  }

  capitalizeFirstLetter(s: string): any {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  scrollTo(id): void {
    if (isPlatformBrowser(this.platformId)) {

      document.getElementById(id).scrollIntoView();
    }
  }

  // TORNA IL NUMERO DI GIORNI
  getDays(date: Date, isNew: boolean): string | boolean {
    try {
      const date1 = new Date();
      const date2 = new Date(date);
      // tslint:disable-next-line: variable-name
      const Difference_In_Time = date1.getTime() - date2.getTime();
      // tslint:disable-next-line: variable-name
      const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (isNew !== null && isNew) {
        return Difference_In_Days <= 7;
      }
      if (Difference_In_Days === 0) {
        return '';
      } else if (Difference_In_Days === 1) {
        return '(' + parseInt(Difference_In_Days.toString(), 10) + ' Giorno)';
      } else {
        return '(' + parseInt(Difference_In_Days.toString(), 10) + ' Giorni)';
      }
    } catch (error) {
      if (isNew !== null && isNew) {
        return false;
      } else {
        return '';
      }
    }
  }

  getNumericDays(date: Date): number {
    const date1 = new Date();
    const date2 = new Date(date);
    // tslint:disable-next-line: variable-name
    const Difference_In_Time = date1.getTime() - date2.getTime();
    // tslint:disable-next-line: variable-name
    return Difference_In_Time / (1000 * 3600 * 24);
  }

  isChild(obj, parentID): boolean {
    const parent = document.getElementById(parentID) as HTMLInputElement;
    while (obj !== undefined && obj !== null && obj.tagName.toUpperCase() !== 'BODY') {
      if (obj === parent) {
        return true;
      }
      obj = obj.parentNode;
    }
    return false;
  }

  hasParentWithClass(obj, className): boolean {
    try {
      while (obj !== undefined && typeof obj !== 'undefined' && obj !== null && obj.tagName.toUpperCase() !== 'BODY') {
        if (obj.classList.contains(className)) {
          return true;
        }
        obj = obj.parentNode;
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  @HostListener('window:scroll', ['$event']) onScroll(_: any): void {
    if (isPlatformBrowser(this.platformId)) {
      if (window.scrollY > this.containerAnnuncioHeight) {
        document.querySelector('.detailInfoFixed')?.classList.add('showDetailBar');
      } else {
        document.querySelector('.detailInfoFixed')?.classList.remove('showDetailBar');
      }

      if (window.scrollY > this.headerHeight) {
        document.getElementById('header-cercacasa')?.classList.add('scrollHeader');
      } else {
        document.getElementById('header-cercacasa')?.classList.remove('scrollHeader');
      }
    }
  }


  // CONTROLLA SE è APERTO IL SUGGERIMENTO DI RICERCA O IL DROPDOWN DELLE CATEGORIE
  @HostListener('click', ['$event']) onClick(element: any): void {
    if (!this.hasParentWithClass(element.target, 'option') && !this.isChild(element.target, 'cityZonesSelector')) {
      this.searchDropdownCityZones = false;
    }
    if (this.isChild(element.target, 'globalSearchBarTypeContainer')) {
      this.searchMethodTypeDropdown = !this.searchMethodTypeDropdown;
      this.searchDropdown = false;
      this.searchNameDropdown = false;
      this.searchCategoryDropdown = false;
    } else if (this.isChild(element.target, 'globalDropdownContainer')) {
      if (this.searchType === 'vendita' || this.searchType === 'affitto') {
        if ((element.target).classList.contains('button-width-open')) {
          this.searchCategoryDropdown = !this.searchCategoryDropdown;
        } else {
          this.searchCategoryDropdown = true;
        }
        // fix overflow body --> dropdown)
        if (isPlatformBrowser(this.platformId)) {
          if (window.innerWidth > 550 && this.document.querySelector('html').style.overflowY === 'hidden') {
            this.document.querySelector('html').style.overflowY = 'unset';
          }
        }
      } else {
        this.searchCategoryDropdown = !this.searchCategoryDropdown;
      }

      this.searchDropdown = false;
      this.searchMethodTypeDropdown = false;
      this.searchNameDropdown = false;
    } else if (this.isChild(element.target, 'globalSearchBarContainer')) {
      this.searchDropdown = true;
      this.searchNameDropdown = true;
      this.searchMethodTypeDropdown = false;
      this.searchCategoryDropdown = false;
    } else {
      if (isPlatformBrowser(this.platformId)) {
        this.searchMethodTypeDropdown = false;
        this.searchCategoryDropdown = false;

        // DROPDOWN PROFESSIONISTI APERTO AL CLICK
        if (this.searchType === 'agente' && (element.target).classList.contains('agente') && this.showProfessionist) {
          this.searchCategoryDropdown = true;
        }

        this.searchNameDropdown = false;
        this.searchDropdown = false;
        this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe(async (params: any) => {
          this.getParamsURL(this.router.url, params, 'vendita');
        });
      }
    }
  }

  // AGGIUNGI ANNUNCIO TRA I PREFERITI
  async savedListing(annuncio: Listing): Promise<number> {
    if (this.isLogged) {
      if (!this.savedAnnuncio(annuncio.id)) {
        try {
          await lastValueFrom(this.member.memberPrototypeUnlinkSavedListings(this.currentUser.id, annuncio.id));
          this.toastr.success('Trovi i tuoi annunci preferiti all\'interno della pagina profilo', 'Annuncio rimosso');
          const a = this.savedListings.findIndex((ann) => ann.id === annuncio.id);
          this.savedListings.splice(a, 1);
          return -1;
        } catch {
          this.toastr.error('Si è verificato un errore. Riprova più tardi', 'Annuncio non salvato');
          return 0;
        }
      } else {
        try {
          await lastValueFrom(this.member.memberPrototypeLinkSavedListings(this.currentUser.id, annuncio.id));
          this.toastr.success('Trovi i tuoi annunci preferiti all\'interno della pagina profilo', 'Annuncio salvato');
          if (!this.savedListings) {
            this.savedListings = [];
          }
          this.savedListings.push(annuncio);

          return +1;
        } catch {
          this.toastr.error('Si è verificato un errore. Riprova più tardi', 'Annuncio non salvato');
          return 0;
        }
      }
    } else {
      this.modalUserOpen = !this.modalUserOpen;
    }
  }

  // CONTROLLA SE L'ANNUNCIO è SALVATO NEL PROFILO
  savedAnnuncio(idAnnuncio: string): boolean {
    if (this.isLogged && this.savedListings && this.savedListings.length > 0) {
      const a = this.savedListings.filter((ann) => ann.id === idAnnuncio);
      return a.length === 0;
    } else {
      return true;
    }
  }

  // AGGIUNGI AGENTE TRA I PREFERITI
  async savedAgentListing(agente: Agent): Promise<number> {
    if (this.isLogged) {
      if (!this.savedAgent(agente.id)) {
        try {
          await lastValueFrom(this.member.memberPrototypeUnlinkFavouriteAgents('me', agente.id));
          this.toastr.success('Trovi i tuoi agenti preferiti all\'interno della pagina profilo', 'Agente rimosso');
          const a = this.savedAgentsList.findIndex((ag) => ag.id === agente.id);
          this.savedAgentsList.splice(a, 1);
          return -1;
        } catch (e) {
          console.error(e);
          this.toastr.error('Si è verificato un errore. Riprova più tardi', 'Agente non salvato');
          return 0;
        }
      } else {
        try {
          await lastValueFrom(this.member.memberPrototypeLinkFavouriteAgents('me', agente.id));
          this.toastr.success('Trovi i tuoi agenti preferiti all\'interno della pagina profilo', 'Agente salvato');
          this.savedAgentsList.unshift(agente);
          return 1;
        } catch (e) {
          console.error(e);
          this.toastr.error('Si è verificato un errore. Riprova più tardi', 'Agente non salvato');
          return 0;
        }
      }
    }
    return 0;
  }

  // CONTROLLA SE L'AGENTE è STATO SALVATO TRA I PREFERITI
  savedAgent(idAgente: string): boolean {
    if (this.isLogged && this.savedAgentsList && this.savedAgentsList.length > 0) {
      const a = this.savedAgentsList.filter((ag) => ag.id === idAgente);
      return a.length === 0;
    } else {
      return true;
    }
  }

  // RIMUOVI AGENTI VISITATI
  async deleteAgentListing(agente: Agent): Promise<void> {
    const index = this.visitedAgentsList.indexOf(agente);
    // tslint:disable-next-line: no-string-literal
    if (!this.visitedAgentsList[index]['loading']) {
      // tslint:disable-next-line: no-string-literal
      this.visitedAgentsList[index]['loading'] = true;
      await this.member.memberPrototypeUnlinkVisitedAgents('me', agente.id).pipe(takeUntil(this.unsubscribe$)).subscribe(async (_) => {
        await this.getVisitedAgent();
        this.toastr.success('L\' agente è stato rimosso dalla lista dei visitati', 'Agente rimosso');
      }, (_) => {
        this.toastr.error('Si è verificato un errore. Riprova più tardi!', 'Errore rimozione');
      });
    }
  }

  // AGGIUNGI AGENZIA TRA I PREFERITI
  async savedAgencyListing(agenzia: Agency): Promise<number> {
    if (this.isLogged) {
      if (!this.savedAgency(agenzia.id)) {
        try {
          await lastValueFrom(this.member.memberPrototypeUnlinkFavouriteAgencies('me', agenzia.id));
          this.toastr.success('Trovi le tue agenzie preferite all\'interno della pagina profilo', 'Agenzia rimossa');
          const a = this.savedAgencyList.findIndex((ag) => ag.id === agenzia.id);
          this.savedAgencyList.splice(a, 1);
          return -1;
        } catch {
          this.toastr.error('Si è verificato un errore. Riprova più tardi', 'Agenzia non salvata');
          return 0;
        }
      } else {
        try {
          await lastValueFrom(this.member.memberPrototypeLinkFavouriteAgencies('me', agenzia.id));
          this.toastr.success('Trovi le tue agenzie preferiti all\'interno della pagina profilo', 'Agenzia salvata');
          this.savedAgencyList.push(agenzia);
          return +1;
        } catch {
          this.toastr.error('Si è verificato un errore. Riprova più tardi', 'Agenzia non salvata');
          return 0;
        }
      }
    }
    return 0;
  }

  // CONTROLLA SE L'AGENZIA è GIA' SALVATA TRA I PREFERITI
  savedAgency(idAgenzia): boolean {
    if (this.isLogged && this.savedAgencyList && this.savedAgencyList.length > 0) {
      const a = this.savedAgencyList.filter((ag) => ag.id === idAgenzia);
      return a.length === 0;
    } else {
      return true;
    }
  }

  // AGGIUNGI CONSULENTE TRA I PREFERITI
  async savedProfessionistListing(professionista: Professionist): Promise<number> {
    if (this.isLogged) {
      if (!this.savedProfessionist(professionista.id)) {
        try {
          await lastValueFrom(this.member.memberPrototypeUnlinkFavouriteProfessionists('me', professionista.id));
          this.toastr.success('Trovi i tuoi consulenti preferiti all\'interno della pagina profilo', 'Consulente rimosso');
          const a = this.savedProfessionistsList.findIndex((ag) => ag.id === professionista.id);
          this.savedProfessionistsList.splice(a, 1);
          return -1;
        } catch (e) {
          console.error(e);
          this.toastr.error('Si è verificato un errore. Riprova più tardi', 'Consulente non salvato');
          return 0;
        }
      } else {
        try {
          await lastValueFrom(this.member.memberPrototypeLinkFavouriteProfessionists('me', professionista.id));

          this.toastr.success('Trovi i tuoi consulenti preferiti all\'interno della pagina profilo', 'Consulente salvato');
          this.savedProfessionistsList.unshift(professionista);
          return +1;
        } catch (e) {
          console.error(e);
          this.toastr.error('Si è verificato un errore. Riprova più tardi', 'Consulente non salvato');
          return 0;
        }
      }
    }
    return 0;
  }

  // CONTROLLA SE IL PROFESSIONISTA è GIA' SALVATA TRA I PREFERITI
  savedProfessionist(idProfessionista): boolean {
    if (this.isLogged && this.savedProfessionistsList && this.savedProfessionistsList.length > 0) {
      const a = this.savedProfessionistsList.filter((ag) => ag.id === idProfessionista);
      return a.length === 0;
    } else {
      return true;
    }
  }

  // AGGIUNGI PRODOTTO TRA I PREFERITI
  async savedProductListing(prodotto: Product): Promise<number> {
    if (this.isLogged) {

      if (!this.savedProduct(prodotto.id)) {
        try {
          await lastValueFrom(this.member.memberPrototypeUnlinkFavouriteProducts('me', prodotto.id));
          this.toastr.success('Trovi i tuoi prodotti preferiti all\'interno della pagina profilo', 'Prodotto rimosso');
          const a = this.savedProductsList.findIndex((prod) => prod.id === prodotto.id);
          this.savedProductsList.splice(a, 1);

          return -1;
        } catch {
          this.toastr.error('Si è verificato un errore. Riprova più tardi', 'Prodotto non salvato');
          return 0;
        }
      } else {
        try {
          await lastValueFrom(this.member.memberPrototypeLinkFavouriteProducts('me', prodotto.id));

          this.toastr.success('Trovi i tuoi prodotti preferiti all\'interno della pagina profilo', 'Prodotto salvato');
          this.savedProductsList.unshift(prodotto);
          return +1;
        } catch {
          this.toastr.error('Si è verificato un errore. Riprova più tardi', 'Prodotto non salvato');
          return 0;
        }
      }
    }
    return 0;
  }

  // CONTROLLA SE IL PRODOTTO è GIA' SALVATA TRA I PREFERITI
  savedProduct(idProdotto): boolean {
    if (this.isLogged && this.savedProductsList && this.savedProductsList.length > 0) {
      const a = this.savedProductsList.filter((prod) => prod.id === idProdotto);
      return a.length === 0;
    } else {
      return true;
    }
  }

  // RIMUOVI PROFESSIONIST VISITATI
  async deleteProfessionistListing(professionista: Professionist): Promise<void> {
    const index = this.visitedProfessionistsList.indexOf(professionista);
    // tslint:disable-next-line: no-string-literal
    if (!this.visitedProfessionistsList[index]['loading']) {
      // tslint:disable-next-line: no-string-literal
      this.visitedProfessionistsList[index]['loading'] = true;
      await this.member.memberPrototypeUnlinkVisitedProfessionists('me', professionista.id).pipe(takeUntil(this.unsubscribe$)).subscribe(async (_) => {
        this.toastr.success('Il consulente è stato rimosso dalla lista dei visitati', 'Consulente rimosso');
        await this.getVisitedProfessionists();
      }, (_) => {
        this.toastr.error('Si è verificato un errore. Riprova più tardi!', 'Errore rimozione');
      });
    }
  }

  // RIMUOVI AGENZIE VISITATE
  async deleteAgencyListing(agenzia): Promise<void> {
    const index = this.visitedAgencyList.indexOf(agenzia);
    // tslint:disable-next-line: no-string-literal
    if (!this.visitedAgencyList[index]['loading']) {
      // tslint:disable-next-line: no-string-literal
      this.visitedAgencyList[index]['loading'] = true;
      await this.member.memberPrototypeUnlinkVisitedAgencies('me', agenzia.id).pipe(takeUntil(this.unsubscribe$)).subscribe(async (_) => {
        await this.getVisitedAgency();
        this.toastr.success('L\' agenzia è stato rimosso dalla lista dei visitati', 'Agenzia rimossa');
      }, (_) => {
        this.toastr.error('Si è verificato un errore. Riprova più tardi!', 'Errore rimozione');
      });
    }
  }

  async getVisitedListing(): Promise<any[]> {
    if (this.isLogged) {
      const filters = {
        where: { memberId: this.getCurrentUser().id },
        include: {
          relation: 'listing',
          scope: {
            where: { status: { nin: ['draft', 'expired', 'suspended', 'proposta', 'notizia', 'rating'] } },
            include: ['agent', 'agency'],
          },
        },
        order: 'created_at ASC',
      };
      const response: any = await lastValueFrom(this.visitedListingService.visitedListingFind(JSON.stringify(filters)));
      return response.map(r => r.listing).filter((l: Listing) => l);

    } else {
      let visitedListingStrings = [];
      if (this.cookieService.get('visitedListings')) {
        visitedListingStrings = JSON.parse(this.cookieService.get('visitedListings')).filter((x: any) => typeof x !== 'object');
      }
      this.cookieService.set('visitedListings', JSON.stringify(visitedListingStrings), {
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        sameSite: 'Lax',
        secure: true,
      });
      return visitedListingStrings;
    }
  }

  async getRicercheSalvate(): Promise<UserSearch[]> {
    if (isPlatformBrowser(this.platformId)) {
      if (this.isLogged) {
        const queryFilter = JSON.stringify({
          include: ['userSearches', // tslint:disable-next-line: object-literal-key-quotes
            { 'relation': 'userSearches' }],
        });
        const member = await lastValueFrom(this.member.memberFindById('me', queryFilter));
        return member.userSearches.filter((us: any) => us.saved).sort((a: any, b: any) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  async getRicercheRecenti(): Promise<UserSearch[]> {
    if (isPlatformBrowser(this.platformId)) {
      if (this.isLogged) {
        const queryFilter = JSON.stringify({
          include: ['userSearches', // tslint:disable-next-line: object-literal-key-quotes
            { 'relation': 'userSearches' }],
        });
        const member = await lastValueFrom(this.member.memberFindById('me', queryFilter));
        return member.userSearches.sort((a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  // GESTIONE LIKES AGENZIA
  async likedAgencyEvt(agenzia: Agency): Promise<number> {
    if (this.isLogged) {
      if (!this.checkLikedAgency(agenzia.id)) {
        try {
          if (this.loadAgencyLikeBtn === false) {
            this.loadAgencyLikeBtn = true;
            await lastValueFrom(this.member.memberPrototypeUnlinkLikedAgencies('me', agenzia.id));
            const a = this.likedAgenciesArray.findIndex((ag) => ag.id === agenzia.id);
            this.likedAgenciesArray.splice(a, 1);
            this.loadAgencyLikeBtn = false;
            return -1;
          }
        } catch (e) {
          this.console.log(e);
          return 0;
        }
      } else {
        if (this.loadAgencyLikeBtn === false) {
          try {
            this.loadAgencyLikeBtn = true;
            await lastValueFrom(this.member.memberPrototypeLinkLikedAgencies('me', agenzia.id));
            this.likedAgenciesArray.push(agenzia);
            this.loadAgencyLikeBtn = false;
            return +1;
          } catch (e) {
            this.console.log(e);
            return 0;
          }
        }
      }
    }
    return 0;
  }

  // CONTROLLA SE L'AGENZIA HA GIA' IL LIKE
  checkLikedAgency(idAgenzia): boolean {
    if (this.isLogged && this.likedAgenciesArray && this.likedAgenciesArray.length > 0) {
      const a = this.likedAgenciesArray.filter((ag) => ag.id === idAgenzia);
      return a.length === 0;
    } else {
      return true;
    }
  }

  // GESTIONE LIKES AGENTE
  async likedAgentEvt(agente: Agent): Promise<number> {
    if (this.isLogged) {
      if (!this.checkLikedAgent(agente.id)) {
        try {
          if (this.loadAgentLikeBtn === false) {
            this.loadAgentLikeBtn = true;
            await lastValueFrom(this.member.memberPrototypeUnlinkLikedAgents('me', agente.id));
            const a = this.likedAgentsArray.findIndex((ag) => ag.id === agente.id);
            this.likedAgentsArray.splice(a, 1);
            this.loadAgentLikeBtn = false;
            return -1;
          }
        } catch (e) {
          this.console.log(e);
          return 0;
        }
      } else {
        if (this.loadAgentLikeBtn === false) {
          try {
            await this.getLikedAgent();
            this.loadAgentLikeBtn = true;
            await lastValueFrom(this.member.memberPrototypeLinkLikedAgents('me', agente.id));
            this.likedAgentsArray.push(agente);
            this.loadAgentLikeBtn = false;
            return +1;
          } catch (e) {
            this.console.log(e);
            return 0;
          }
        }
      }
    }
    return 0;
  }

  // CONTROLLA SE L'AGENTE HA GIA' IL LIKE
  checkLikedAgent(idAgente): boolean {
    if (this.isLogged && this.likedAgentsArray && this.likedAgentsArray.length > 0) {
      const a = this.likedAgentsArray.filter((ag) => ag.id === idAgente);
      return a.length === 0;
    } else {
      return true;
    }
  }

  // GESTIONE LIKES PROFESSIONIST
  async likedProfessionistEvt(professionista: Professionist): Promise<number> {
    if (this.isLogged) {
      if (!this.checkLikedProfessionist(professionista.id)) {

        try {
          if (this.loadProfessionistLikeBtn === false) {
            this.loadProfessionistLikeBtn = true;
            await lastValueFrom(this.member.memberPrototypeUnlinkLikedProfessionists('me', professionista.id));
            const a = this.likedProfessionistsArray.findIndex((ag) => ag.id === professionista.id);
            this.likedProfessionistsArray.splice(a, 1);
            this.loadProfessionistLikeBtn = false;
            return -1;
          }
        } catch (e) {
          this.console.log(e);
          return 0;
        }
      } else {
        if (this.loadProfessionistLikeBtn === false) {
          try {
            this.loadProfessionistLikeBtn = true;
            await lastValueFrom(this.member.memberPrototypeLinkLikedProfessionists('me', professionista.id));
            this.likedProfessionistsArray.push(professionista);
            this.loadProfessionistLikeBtn = false;
            return +1;
          } catch (e) {
            this.console.log(e);
            return 0;
          }
        }
      }
    }
    return 0;
  }

  // CONTROLLA SE PROFESSIONIST HA GIA' IL LIKE
  checkLikedProfessionist(idProfessionista): boolean {
    if (this.isLogged && this.likedProfessionistsArray && this.likedProfessionistsArray.length > 0) {
      const a = this.likedProfessionistsArray.filter((ag) => ag.id === idProfessionista);
      return a.length === 0;
    } else {
      return true;
    }
  }

  // GESTIONE LIKES PRODOTTO
  async likedProductEvt(prodotto: Product): Promise<number> {
    if (this.isLogged) {
      if (!this.checkLikedProduct(prodotto.id)) {

        try {
          if (this.loadProductLikeBtn === false) {
            this.loadProductLikeBtn = true;
            await lastValueFrom(this.member.memberPrototypeUnlinkLikedProducts('me', prodotto.id));
            const a = this.likedProductsArray.findIndex((pr) => pr.id === prodotto.id);
            this.likedProductsArray.splice(a, 1);
            this.loadProductLikeBtn = false;
            return -1;
          }
        } catch (e) {
          this.console.log(e);
          return 0;
        }
      } else {
        if (this.loadProductLikeBtn === false) {
          try {
            this.loadProductLikeBtn = true;
            await lastValueFrom(this.member.memberPrototypeLinkLikedProducts('me', prodotto.id));
            this.likedProductsArray.push(prodotto);
            this.loadProductLikeBtn = false;
            return +1;
          } catch (e) {
            this.console.log(e);
            return 0;
          }
        }
      }
    }
    return 0;
  }

  // CONTROLLA SE IL PRODUCT HA IL LIKE
  checkLikedProduct(idProdotto): boolean {
    if (this.isLogged && this.likedProductsArray && this.likedProductsArray.length > 0) {
      const a = this.likedProductsArray.filter((prod) => prod.id === idProdotto);
      return a.length === 0;
    } else {
      return true;
    }
  }

  // GESTIONE LIKES BRAND
  async likedBrandEvt(brand: Brand): Promise<number> {
    if (this.isLogged) {
      if (!this.checkLikedBrand(brand.id)) {
        try {
          if (this.loadBrandLikeBtn === false) {
            this.loadBrandLikeBtn = true;
            await lastValueFrom(this.member.memberPrototypeUnlinkLikedBrand('me', brand.id));
            const a = this.likedBrandsArray.findIndex((ag) => ag.id === brand.id);
            this.likedBrandsArray.splice(a, 1);
            this.loadBrandLikeBtn = false;
            return -1;
          }
        } catch (e) {
          this.console.log(e);
          return 0;
        }
      } else {
        if (this.loadBrandLikeBtn === false) {
          try {
            this.loadBrandLikeBtn = true;
            await lastValueFrom(this.member.memberPrototypeLinkLikedBrand('me', brand.id));
            this.likedBrandsArray.push(brand);
            this.loadBrandLikeBtn = false;
            return +1;
          } catch (e) {
            this.console.log(e);
            return 0;
          }
        }
      }
    }
    return 0;
  }

  // CONTROLLA SE IL BRAND HA GIA' IL LIKE
  checkLikedBrand(idBrand): boolean {
    if (this.isLogged && this.likedBrandsArray && this.likedBrandsArray.length > 0) {
      const a = this.likedBrandsArray.filter((ag) => ag.id === idBrand);
      return a.length === 0;
    } else {
      return true;
    }
  }

  // GESTIONE LIKES ANNUNCIO
  async likedAnnuncioEvt(annuncio): Promise<number> {
    if (this.isLogged) {
      if (!this.checkLikedAnnuncio(annuncio.id)) {
        try {
          if (this.loadListingLikeBtn === false) {
            this.loadListingLikeBtn = true;
            await lastValueFrom(this.member.memberPrototypeUnlinkLikedListing('me', annuncio.id));
            const a = this.likedListingsArray.findIndex((an) => an.id === annuncio.id);
            this.likedListingsArray.splice(a, 1);
            this.loadListingLikeBtn = false;
            return -1;
          }
        } catch (e) {
          this.console.log(e);
          return 0;
        }
      } else {
        try {
          if (this.loadListingLikeBtn === false) {
            this.loadListingLikeBtn = true;
            await lastValueFrom(this.member.memberPrototypeLinkLikedListing('me', annuncio.id));
            this.likedListingsArray.push(annuncio);
            this.loadListingLikeBtn = false;
            return +1;
          }
        } catch (e) {
          this.console.log(e);
          return 0;
        }
      }
    }
    return 0;
  }

  // CONTROLLA SE L'ANNUNCIO HA GIA' IL LIKE
  checkLikedAnnuncio(idAnnuncio): boolean {
    if (this.isLogged && this.likedListingsArray && this.likedListingsArray.length > 0) {
      const a = this.likedListingsArray.filter((an) => an.id === idAnnuncio);
      return a.length === 0;
    } else {
      return true;
    }
  }

  // GESTIONE LIKES ARTICOLO
  async likedArticleEvt(article): Promise<number> {
    if (this.isLogged) {
      if (!this.checkLikedArticle(article.id)) {
        try {
          if (this.loadArticlesLikeBtn === false) {
            this.loadArticlesLikeBtn = true;
            await lastValueFrom(this.member.memberPrototypeUnlinkLikedArticle('me', article.id));
            const a = this.likedArticleArray.findIndex((art) => art.id === article.id);
            this.likedArticleArray.splice(a, 1);
            this.loadArticlesLikeBtn = false;
            return -1;
          }
        } catch (e) {
          this.console.log(e);
          return 0;
        }
      } else {
        try {
          if (this.loadArticlesLikeBtn === false) {
            this.loadArticlesLikeBtn = true;
            await lastValueFrom(this.member.memberPrototypeLinkLikedArticle('me', article.id));
            this.likedArticleArray.push(article);
            this.loadArticlesLikeBtn = false;
            return +1;
          }
        } catch (e) {
          this.console.log(e);
          return 0;
        }
      }
    }
    return 0;
  }

  // CONTROLLA SE L'ARTICOLO HA GIÀ IL LIKE
  checkLikedArticle(idArticle): boolean {
    if (this.isLogged && this.likedArticleArray && this.likedArticleArray.length > 0) {
      const a = this.likedArticleArray.filter((ar) => ar.id === idArticle);
      return a.length === 0;
    } else {
      return true;
    }
  }

  // **************************
  // **************************
  // **************************
  // GESTIONE DELLE GET: VISITATI, PREFERITI E LIKE
  // **************************
  // **************************
  // **************************


  async getSavedListing(): Promise<void> {
    if (this.isLogged) {
      try {
        if (isPlatformBrowser(this.platformId)) {
          const filterSavedListing = JSON.stringify({
            include: [
              {
                relation: 'listing',
                scope: {
                  include: [
                    {
                      relation: 'agent',
                    },
                    {
                      relation: 'agency',
                      scope: {
                        fields: ['slug', 'name', 'address']
                      }
                    },
                  ]
                }
              }
            ],
            where: {
              memberId: this.currentUser?.id,
              isDeleted: false,
            },
            order: 'created_at DESC'
          });

          this.savedListings = (await lastValueFrom(this.savedListingsService.savedListingFind(filterSavedListing))).filter((sl: any) => sl.listing).map((sl: any) => sl.listing);
          this.showSaveAndLikeBtn = true;
        }
      } catch (e) {
        this.console.log(e);
        // throw new Error(e);
      }
    }
  }

  async getVisitedListings(): Promise<void> {
    if (this.isLogged) {
      try {
        if (isPlatformBrowser(this.platformId)) {
          const filterVisitedListing = JSON.stringify({
            include: [
              {
                relation: 'listing',
                scope: {
                  include: [
                    {
                      relation: 'agent',
                    },
                    {
                      relation: 'agency',
                      scope: {
                        fields: ['slug', 'name', 'address']
                      }
                    },
                  ]
                }
              }
            ],
            where: {
              memberId: this.currentUser?.id,
              isDeleted: false,
            },
            order: 'created_at ASC'
          });
          this.visitedListings = (await lastValueFrom(this.visitedListingService.visitedListingFind(filterVisitedListing))).filter((sl: any) => sl.listing).map((sl: any) => sl.listing);
          this.showSaveAndLikeBtn = true;
        }
      } catch (e) {
        this.console.log(e);
        // throw new Error(e);
      }
    }
  }

  async getLikedListing(): Promise<void> {
    this.likedListingsArray = [];
    if (this.isLogged) {
      try {
        if (isPlatformBrowser(this.platformId)) {
          const queryFilter = JSON.stringify(
            {
              include: ['likedListing'],
            }
          );
          const response = await lastValueFrom(this.member.memberFindById('me', queryFilter));
          this.likedListingsArray = response.likedListing.filter((a: any) => a.status !== 'suspended');
          this.showSaveAndLikeBtn = true;
          delete response.likedListing;
        }
      } catch (e) {
        this.console.log(e);
        // throw new Error(e);
      }
    }
  }

  async getFavouriteAgent(): Promise<void> {
    if (this.isLogged) {
      try {
        if (isPlatformBrowser(this.platformId)) {
          this.savedAgentsList = (await lastValueFrom(this.favoriteAgentService.favouriteAgentFind(this.filterSavedAndVisitedAgent))).filter((el: any) => el.agent).map((el: any) => el.agent);
          this.showSaveAndLikeBtn = true;
        }
      } catch (e) {
        this.console.log(e);
        // throw new Error(e);
      }
    }
  }

  async getVisitedAgent(): Promise<void> {
    if (this.isLogged) {
      try {
        if (isPlatformBrowser(this.platformId)) {
          this.visitedAgentsList = (await lastValueFrom(this.visitedAgentService.visitedAgentFind(this.filterSavedAndVisitedAgent))).filter((el: any) => el.agent).map((el: any) => el.agent);
          this.showSaveAndLikeBtn = true;
        }
      } catch (e) {
        this.console.log(e);
        throw new Error(e);
      }
    }
  }

  async getLikedReviews(): Promise<void> {
    this.likedReviews = [];
    if (this.isLogged) {
      try {
        if (isPlatformBrowser(this.platformId)) {
          const queryFilter = JSON.stringify(
            {
              include: ['helpfulReview'],
            }
          );

          const response = await lastValueFrom(this.member.memberFindById('me', queryFilter));
          this.likedReviews = response.helpfulReview.filter((a: any) => !a.suspended);
          delete response.helpfulReview;
        }

      } catch (e) {
        this.console.log(e);
        throw new Error(e);
      }
    }
  }

  async getLikedAgent(): Promise<void> {
    this.likedAgentsArray = [];
    if (this.isLogged) {
      try {
        if (isPlatformBrowser(this.platformId)) {
          const queryFilter = JSON.stringify(
            {
              include: ['likedAgents'],
            }
          );
          const response = await lastValueFrom(this.member.memberFindById('me', queryFilter));
          this.likedAgentsArray = response.likedAgents.filter((a: any) => a.status !== 'suspended');
          this.showSaveAndLikeBtn = true;
          delete response.likedAgents;
        }
      } catch (e) {
        this.console.log(e);
        throw new Error(e);
      }
    }
  }

  async getFavouriteAgency(): Promise<void> {
    this.savedAgencyList = [];
    if (this.isLogged) {
      try {
        if (isPlatformBrowser(this.platformId)) {
          this.savedAgencyList = (await lastValueFrom(this.favoriteAgencyService.favouriteAgencyFind(this.filterFavoriteAndVisitedAgency))).filter((el: any) => el.agency).map((el: any) => el.agency);
          this.showSaveAndLikeBtn = true;
        }
      } catch (e) {
        this.console.log(e);
        throw new Error(e);
      }
    }
  }

  async getVisitedAgency(): Promise<void> {
    if (this.isLogged) {
      try {
        if (isPlatformBrowser(this.platformId)) {
          this.visitedAgencyList = (await lastValueFrom(this.visitedAgencyService.visitedAgencyFind(this.filterFavoriteAndVisitedAgency))).filter((el: any) => el.agency).map((el: any) => el.agency);
          this.showSaveAndLikeBtn = true;
        }
      } catch (e) {
        this.console.log(e);
        throw new Error(e);
      }
    }
  }

  async getLikedAgency(): Promise<void> {
    this.likedAgenciesArray = [];
    if (this.isLogged) {
      try {
        if (isPlatformBrowser(this.platformId)) {
          const queryFilter = JSON.stringify(
            {
              include: ['likedAgencies'],
            }
          );
          const response = await lastValueFrom(this.member.memberFindById('me', queryFilter));
          this.likedAgenciesArray = response.likedAgencies.filter((a: any) => !a.suspended);
          this.showSaveAndLikeBtn = true;
          delete response.likedAgencies;
        }
      } catch (e) {
        this.console.log(e);
        throw new Error(e);
      }
    }
  }

  async getFavouriteProducts(): Promise<void> {
    if (this.isLogged) {
      try {
        if (isPlatformBrowser(this.platformId)) {
          const queryFilter = JSON.stringify(
            {
              order: 'created_at ASC',
              include: [
                {
                  relation: 'favouriteProducts',
                  scope: {
                    where: { and: [{ status: { neq: 'draft' } }, { isDeleted: false }] },
                    include: ['brand', 'professionist'],
                  },
                }
              ],
            }
          );
          const response = await lastValueFrom(this.member.memberFindById('me', queryFilter));
          this.savedProductsList = response.favouriteProducts.filter((p: any) => p.status === 'available');
          this.showSaveAndLikeBtn = true;
          delete response.favouriteProducts;
        }
      } catch (e) {
        this.console.log(e);
        throw new Error(e);
      }
    }
  }

  async getLikedProducts(): Promise<void> {
    this.likedProductsArray = [];
    if (this.isLogged) {
      try {
        if (isPlatformBrowser(this.platformId)) {
          const queryFilter = JSON.stringify(
            {
              include: [{
                relation: 'likedProducts',
                scope: {
                  where: { and: [{ status: { neq: 'draft' } }, { isDeleted: false }] },
                  include: ['brand', 'professionist'],
                },
              }],
            }
          );
          const response = await lastValueFrom(this.member.memberFindById('me', queryFilter));
          this.likedProductsArray = response.likedProducts.filter((p: any) => p.status === 'available');
          this.showSaveAndLikeBtn = true;
          delete response.likedProducts;
        }
      } catch (e) {
        this.console.log(e);
        throw new Error(e);
      }
    }
  }

  async getFavouriteProfessionists(): Promise<void> {
    if (this.isLogged) {
      try {
        if (isPlatformBrowser(this.platformId)) {
          this.savedProfessionistsList = (await lastValueFrom(this.favoriteProfessionistService.favouriteProfessionistFind(this.filterFavoriteAndVisitedProfessionist))).filter((el: any) => el.professionist).map((el: any) => el.professionist);
          this.showSaveAndLikeBtn = true;
        }
      } catch (e) {
        this.console.log(e);
        throw new Error(e);
      }
    }
  }

  async getVisitedProfessionists(): Promise<void> {
    if (this.isLogged) {
      try {
        if (isPlatformBrowser(this.platformId)) {
          this.visitedProfessionistsList = (await lastValueFrom(this.visitedProfessionistService.visitedProfessionistFind(this.filterFavoriteAndVisitedProfessionist))).filter((el: any) => el.professionist).map((el: any) => el.professionist);
          this.showSaveAndLikeBtn = true;
        }
      } catch (e) {
        this.console.log(e);
        throw new Error(e);
      }
    }
  }

  async getLikedProfessionists(): Promise<void> {
    this.likedProfessionistsArray = [];
    if (this.isLogged) {
      try {
        if (isPlatformBrowser(this.platformId)) {
          const queryFilter = JSON.stringify(
            {
              include: ['likedProfessionists'],
            }
          );
          const response = await lastValueFrom(this.member.memberFindById('me', queryFilter));
          this.likedProfessionistsArray = response.likedProfessionists.filter((a: any) => a.status !== 'suspended');
          this.showSaveAndLikeBtn = true;
          delete response.likedProfessionists;
        }
      } catch (e) {
        this.console.log(e);
        throw new Error(e);
      }
    }
  }

  async getLikedBrand(): Promise<void> {
    this.likedBrandsArray = [];
    if (this.isLogged) {
      try {
        if (isPlatformBrowser(this.platformId)) {
          const queryFilter = JSON.stringify(
            {
              include: ['likedBrand'],
            }
          );
          const response = await lastValueFrom(this.member.memberFindById('me', queryFilter));
          this.likedBrandsArray = response.likedBrand.filter((a: any) => a.status !== 'suspended');
          this.showSaveAndLikeBtn = true;
          delete response.likedBrand;
        }
      } catch (e) {
        this.console.log(e);
        throw new Error(e);
      }
    }
  }

  async getLikedArticle(): Promise<void> {
    this.likedArticleArray = [];
    if (this.isLogged) {
      try {
        if (isPlatformBrowser(this.platformId)) {
          const queryFilter = JSON.stringify(
            {
              include: ['likedArticle'],
            }
          );
          const response = await lastValueFrom(this.member.memberFindById('me', queryFilter));
          this.likedArticleArray = response.likedArticle;
          this.showSaveAndLikeBtn = true;
          delete response.likedArticle;
        }
      } catch (e) {
        this.console.log(e);
        throw new Error(e);
      }
    }
  }

  // RESTITUISCE L'OGGETTO USER
  async getUserData(): Promise<void> {
    this.visitedListings = [];
    this.visitedAgentsList = [];
    this.visitedAgencyList = [];

    // likes array
    this.likedListingsArray = [];
    this.likedAgentsArray = [];
    this.likedAgenciesArray = [];
    this.likedBrandsArray = [];
    this.likedProfessionistsArray = [];
    this.likedProductsArray = [];
    this.likedReviews = [];
    this.likedArticleArray = [];

    if (isPlatformBrowser(this.platformId)) {

      if (this.isLogged) {
        try {
          this.visitedListings = [];
          this.visitedAgentsList = [];
          this.visitedAgencyList = [];
          this.visitedProfessionistsList = [];

          this.likedListingsArray = [];
          this.likedAgentsArray = [];
          this.likedBrandsArray = [];
          this.likedProfessionistsArray = [];
          this.likedProductsArray = [];
          this.likedAgenciesArray = [];
          this.likedReviews = [];
          this.likedArticleArray = [];


          if (isPlatformBrowser(this.platformId)) {
            const queryFilter = JSON.stringify({
              include: ['userSearches', 'likedAgencies', 'likedAgents', 'likedListing', 'likedBrand', 'likedProfessionists', 'helpfulReview', 'likedArticle',

                {
                  relation: 'favouriteProducts',
                  scope: {
                    where: { and: [{ status: { neq: 'draft' } }, { isDeleted: false }] },
                    include: ['brand', 'professionist'],
                  },
                }, {
                  relation: 'likedProducts',
                  scope: {
                    where: { and: [{ status: { neq: 'draft' } }, { isDeleted: false }] },
                    include: ['brand', 'professionist'],
                  },
                }, {
                  relation: 'savedListings',
                  scope: {
                    where: { and: [{ status: { neq: 'draft' } }, { isDeleted: false }] },
                    include: ['agent', 'agency'],
                  },
                }, {
                  relation: 'visitedListings',
                  scope: {
                    where: { and: [{ status: { neq: 'draft' } }, { isDeleted: false }] },
                    include: ['agent', 'agency'],
                  },
                }, {
                  relation: 'favouriteAgents',
                  scope: {
                    where: { isDeleted: false },
                    include: ['member', 'agency', 'listings', 'zones_served'],
                  },
                }, {
                  relation: 'visitedAgents',
                  scope: {
                    where: { isDeleted: false },
                    include: ['member', 'agency', 'listings', 'zones_served'],
                  },
                }, {
                  relation: 'visitedProfessionists',
                  scope: {
                    where: { isDeleted: false },
                    include: ['member', 'brand', 'products', 'branded_products', 'zones_served'],
                  },
                }, {
                  relation: 'favouriteAgencies',
                  scope: {
                    where: { isDeleted: false },
                    include: [{
                      relation: 'listings',
                      scope: { include: ['agent', 'agency'] },
                    }, {
                      relation: 'managing_agents',
                      scope: { include: ['zones_served'] },
                    }],
                  },
                }, {
                  relation: 'favouriteProfessionists',
                  scope: {
                    where: { isDeleted: false },
                    include: ['member', 'brand', 'products', 'branded_products', 'zones_served'],
                  },
                }, {
                  relation: 'visitedAgencies',
                  scope: {
                    where: { isDeleted: false },
                    include: [{
                      relation: 'listings',
                      scope: { include: ['agent', 'agency'] },
                    }, {
                      relation: 'managing_agents',
                      scope: { include: ['zones_served'] },
                    }],
                  },
                }],
            });
            const response = await lastValueFrom(this.member.memberFindById('me', queryFilter));
            this.userSearches = response.userSearches;
            this.visitedListings = response.visitedListings.reverse().filter((a: any) => a.status !== 'suspended');
            this.savedListings = response.savedListings.filter((a: any) => a.status !== 'suspended');
            this.savedAgentsList = response.favouriteAgents.filter((a: any) => a.status !== 'suspended');
            this.savedAgencyList = response.favouriteAgencies.reverse().filter((a: any) => !a.suspended);
            this.savedProfessionistsList = response.favouriteProfessionists.filter((a: any) => a.status !== 'suspended');
            this.visitedAgentsList = response.visitedAgents.reverse().filter((a: any) => a.status !== 'suspended');
            this.visitedProfessionistsList = response.visitedProfessionists.reverse().filter((a: any) => a.status !== 'suspended');
            this.visitedAgencyList = response.visitedAgencies.reverse().filter((a: any) => !a.suspended);
            this.likedAgenciesArray = response.likedAgencies.filter((a: any) => !a.suspended);
            this.likedAgentsArray = response.likedAgents.filter((a: any) => a.status !== 'suspended');
            this.likedListingsArray = response.likedListing.filter((a: any) => a.status !== 'suspended');
            this.likedReviews = response.helpfulReview.filter((a: any) => a.status !== 'suspended');
            this.likedBrandsArray = response.likedBrand.filter((a: any) => a.status !== 'suspended');
            this.likedProfessionistsArray = response.likedProfessionists.filter((a: any) => a.status !== 'suspended');
            this.likedProductsArray = response.likedProducts.filter((p: any) => p.status === 'available');
            this.savedProductsList = response.favouriteProducts.filter((p: any) => p.status === 'available');
            this.likedArticleArray = response.likedArticle;

            const ricercheRecentiSaved = response.userSearches.reverse().filter((item) => !item.saved).slice(0, 5);
            this.composeRicercheRecenti(ricercheRecentiSaved);

            this.showSaveAndLikeBtn = true;

            delete response.likedArticle;
            delete response.userSearches;
            delete response.visitedListings;
            delete response.savedListings;
            delete response.favouriteAgents;
            delete response.favouriteAgencies;
            delete response.favouriteProfessionists;
            delete response.visitedAgents;
            delete response.visitedProfessionists;
            delete response.visitedAgencies;
            delete response.likedAgencies;
            delete response.likedAgents;
            delete response.likedListing;
            delete response.helpfulReview;
            delete response.likedBrand;
            delete response.likedProfessionists;
            delete response.likedProducts;
            delete response.favouriteProducts;
            delete response.userSearches;
            this.updateLocalStorageUser(response);
          }
          // const response2 = await this.member
          //   .memberFindById('me')
          //   .toPromise();
        } catch (e) {
          this.console.log(e);
          this.visitedListings = [];
          this.visitedAgencyList = [];
          this.visitedAgentsList = [];
          throw new Error(e);
        }
      } else {
        if (this.cookieService.get('visitedListings')) {
          this.visitedListings = [];
          this.visitedListings = JSON.parse(this.cookieService.get('visitedListings'));
        } else if (this.cookieService.get('visitedAgents')) {
          this.visitedAgentsList = [];
          this.visitedAgentsList = JSON.parse(this.cookieService.get('visitedAgents'));
        } else if (this.cookieService.get('visitedProfessionists')) {
          this.visitedProfessionistsList = [];
          this.visitedProfessionistsList = JSON.parse(this.cookieService.get('visitedProfessionists'));
        } else if (this.cookieService.get('visitedAgencies')) {
          this.visitedAgencyList = [];
          this.visitedAgencyList = JSON.parse(this.cookieService.get('visitedAgencies'));
        }
        if (this.cookieService.get('ricercheRecenti')) {
          this.composeRicercheRecenti(JSON.parse(this.cookieService.get('ricercheRecenti')));
        }
      }
    }
  }

  updateLocalStorageUser(data: Member): void {
    this.cookieService.delete('currentUser');
    this.authenticationService.setCurrentUser = data;
  }

  // GENERA L'URL LATO SEO PER LA RICERCA DEGLI APPARTAMENTI
  generateSearchURL(regione: string, provincia: string, comune: string, zona: string, parentType: any, categoria: string, order: any, queryParams: SearchQuery): string {
    let url = '';
    if (regione) {
      url += '/regione-' + this.parseURL(regione);
    }
    if (provincia) {
      url += '/provincia-' + this.parseURL(provincia);
    }
    if (comune) {
      url += '/comune-' + this.parseURL(comune);
    }
    if (zona) {
      url += '/zona-' + this.parseURL(zona);
    }
    if (categoria) {
      // check if macro was selected
      if (parentType) {
        const values = parentType.value.map(subtype => subtype.value).join(',');
        url += `/${values === categoria ? parentType.id : categoria}`;
      } else {
        url += `/${categoria}`;
      }

    }

    if (order !== null) {
      if (order.activities) {
        url += '/esperti_in_' + order.activities.split('%')[0].split('?')[0];
      }
    }
    // let conLocaliMin = 0;
    // let conLocaliMax = 0;
    // console.log('queryParams', queryParams);
    // if (queryParams) {
    //   for (const [key, value] of Object.entries(queryParams)) {
    //     if (value) {
    //       if (key === 'con_locali_min') {
    //         conLocaliMin = value;
    //       } else if (key === 'con_locali_max') {
    //         conLocaliMax = value;
    //       } else {
    //         url += this.parseURL(`/${key.replace(/[\W_]+/g, '-')}_${value}`);
    //       }
    //     }
    //   }
    // }

    // if (conLocaliMin === conLocaliMax && conLocaliMin > 0) {
    //   if (conLocaliMax === 1) {
    //     url += '/monolocali';
    //   } else if (conLocaliMax === 2) {
    //     url += '/bilocali';
    //   } else if (conLocaliMax === 3) {
    //     url += '/trilocali';
    //   } else if (conLocaliMax === 4) {
    //     url += '/quadrilocali';
    //   } else if (conLocaliMax === 5) {
    //     url += '/pentalocali';
    //   }
    // }
    // if (conLocaliMin > 0 && conLocaliMin !== conLocaliMax) {
    //   url += this.parseURL(`/${'con_locali_min'.replace(/[\W_]+/g, '-')}_${conLocaliMin}`);
    // }
    // if (conLocaliMax > 0 && conLocaliMin !== conLocaliMax) {
    //   url += this.parseURL(`/${'con_locali_max'.replace(/[\W_]+/g, '-')}_${conLocaliMax}`);
    // }

    return url + '/';
  }

  // SOSTITUISCE GLI SPACI O I CARATTERI PARTICOLARI CON SIMBOLI LEGGIBILI SULL'URL
  parseURL(value: string): string {
    return value.replace(/ /g, '-').toLowerCase();
  }

  getInfoFromlastSearch(): any {
    const ricercheRecenti = this.cookieService.get('ricercheRecenti') ? JSON.parse(this.cookieService.get('ricercheRecenti')) : [];
    const ultima = this.isLogged ? ricercheRecenti[0] : ricercheRecenti.reverse()[0];
    const obj = {
      coords: null,
      zona: null,
      comune: null,
      provincia: null,
      regione: null,
    };

    if (ultima !== undefined && ultima.coords !== undefined && ultima.coords !== '') {
      obj.coords = JSON.stringify(ultima.coords);
    }
    if (ultima !== undefined && ultima.zona !== undefined && ultima.zona !== '') {
      obj.zona = ultima.zona.split(',')[0];
    }
    if (ultima !== undefined && ultima.comune !== undefined && ultima.comune !== '') {
      obj.comune = ultima.comune.split(',')[0];
    }
    if (ultima !== undefined && ultima.provincia !== undefined && ultima.provincia !== '') {
      obj.provincia = ultima.provincia.split(',')[0];
    }
    if (ultima !== undefined && ultima.regione !== undefined && ultima.regione !== '') {
      obj.regione = ultima.regione.split(',')[0];
    }
    return obj;
  }

  // IMPOSTA LE RICERCHE RECENTI DAL PROFILO AL LOCALSTORAGE
  composeRicercheRecenti(ricercheRecenti: any[]): void {
    if (isPlatformBrowser(this.platformId)) {
      const array: any[] = [];
      ricercheRecenti.forEach((item) => {
        let element = item;
        let queryFilter = item.filters;
        if (item.frontEndObject) {
          element = item.frontEndObject;
        }
        if (item.queryObject && item.queryObject.filters_req) {
          queryFilter = JSON.parse(item.queryObject.filters_req);
        }
        const toPush: any = {
          placeholder: element.placeholder,
          url: element.url,
          metodo: element.metodo,
          type: element.type,
          filters: queryFilter.filter((itm: { contract_type: any; }) => !itm.contract_type),
        };
        if (element.raggio) {
          toPush.raggio = element.raggio;
        }
        if (element.coords) {
          toPush.coords = element.coords;
        }
        if (element.metodo) {
          toPush.metodo = element.metodo;
        }
        if (element.regione) {
          toPush.regione = element.regione;
        }
        if (element.provincia) {
          toPush.provincia = element.provincia;
        }
        if (element.comune) {
          toPush.comune = element.comune;
        }
        if (element.zona) {
          toPush.zona = element.zona;
        }
        if (element.categoria) {
          toPush.categoria = element.categoria;
        }
        array.push(toPush);
      });
      if (this.isLogged) {
        this.objRicercheRecenti = array;
      } else {
        this.objRicercheRecenti = array.reverse();
      }
      this.cookieService.set('ricercheRecenti', JSON.stringify(array.slice(0, 5)), {
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        sameSite: 'Lax',
        secure: true,
      });
    }
  }

  // *********************************************
  // *********************************************
  // *********************************************
  // GESTIONE DELLA BREADCRUMB
  // *********************************************
  // *********************************************
  // *********************************************


  showBreadcrumb(annuncio: Listing, type: string): any[] {
    const breadcrumb = [];
    const breadcrumbLocalStorage = null;
    // if (this.cookieService.get('breadcrumb')){
    //   breadcrumbLocalStorage = JSON.parse(this.cookieService.get('breadcrumb'));
    // } else {
    //   breadcrumbLocalStorage = null;
    // }
    if (breadcrumbLocalStorage) {
      breadcrumbLocalStorage.forEach((element) => {
        if (element.label) {
          element.label = element.label.toString().toLowerCase().replace('provincia di ', '');
        }
        breadcrumb.push({
          label: element.label + ' (' + element.type + ')',
          url: '/' + type + '/' + element.url.toLowerCase(),
        });
      });
    } else if (annuncio) {
      if (annuncio && annuncio.state) {
        breadcrumb.push({
          label: 'Provincia di ' + annuncio.state,
          url: '/' + type + '/' + 'provincia-' + annuncio.state.toLowerCase(),
        });
      }
    }

    if (annuncio && annuncio.city && (breadcrumbLocalStorage === null || (annuncio.city.toString().toLowerCase() !== breadcrumbLocalStorage[breadcrumbLocalStorage.length - 1].label.toString().toLowerCase() && annuncio.city.toString().toLowerCase() !== breadcrumbLocalStorage[breadcrumbLocalStorage.length - 2].label.toString().toLowerCase()))) {
      breadcrumb.push({
        label: 'Comune di ' + annuncio.city,
        url: '/' + type + '/' + 'comune-' + annuncio.city.toLowerCase(),
      });
    }
    if (annuncio && annuncio.address) {
      breadcrumb.push({
        label: annuncio.address,
        url: '',
      });
    }

    return breadcrumb;
  }

  // *********************************************
  // *********************************************
  // *********************************************
  // GESTIONE DELL'URL DI RICERCA
  // *********************************************
  // *********************************************
  // *********************************************

  getParamsURL(
    routeURL: string,
    params: {
      raggio: number;
      coords: number;
      placeholder: string;
      metodo: number;
      ordine: string;
      ordineAttivita: string;
    },
    type: string
  ): {
    searchRadius?: number;
    searchCoords?: number;
    searchCategoria?: string;
    searchType?: any;
    searchComune?: string;
    searchProvincia?: string;
    searchZona?: string;
    searchRegione?: string;
    searchPlaceholder?: string;
    searchValue?: string;
    searchMethod?: number;
    associations?: string;
    order?: string;
    activitiesOrder?: string;
  } {
    let searchRadius: number;
    let searchCoords: number;
    let searchCategoria: string;
    let searchType = type;
    let searchComune: string;
    let searchProvincia: string;
    let searchZona: string;
    let searchRegione: string;
    let searchPlaceholder: string;
    let searchValue: string;
    let searchMethod = 1;

    let order: string;
    let activitiesOrder: string;

    const r = routeURL.replace('/bnl', '');
    const route = r.includes('?') ? r.split('?')[0].split('/') : r.split('/');

    // PRENDE LA TIPOLOGIA DI APPARTAMENTO
    if (route[1]) {
      searchType = route[1];
    }

    // PRENDE IL ZONA O COMUNE O PROVINCIA O REGIONE
    const p = [];
    route.forEach((rout) => {
      const sectionRoute = rout.split('-')[0];
      if (rout && sectionRoute === 'zona') {
        if (rout.includes('zona-zona-')) {
          rout = rout.replace('zona-', '');
        } else {
          rout = rout.split('zona-')[1];
        }
        searchZona = decodeURIComponent(rout.replace(/---/g, ' &&& ').replace(/-/g, ' ').replace(/&&&/g, '-'));
        p.push(searchZona + ' • (Zona)');
      }
      if (rout && sectionRoute === 'comune') {
        searchComune = decodeURIComponent(rout.split('comune-')[1].replace(/---/g, ' &&& ').replace(/-/g, ' ').replace(/&&&/g, '-'));
        p.push(searchComune + ' • (Comune)');
      }
      if (rout && sectionRoute === 'provincia') {
        searchProvincia = decodeURIComponent(rout.split('provincia-')[1].replace(/---/g, ' &&& ').replace(/-/g, ' ').replace(/&&&/g, '-'));
        p.push(searchProvincia + ' • (Provincia)');
      }
      if (rout && sectionRoute === 'regione') {
        searchRegione = decodeURIComponent(rout.split('regione-')[1].replace(/---/g, ' &&& ').replace(/-/g, ' ').replace(/&&&/g, '-'));
        p.push(searchRegione + ' • (Regione)');
      }
    });

    // GENERAL SEARCH
    searchPlaceholder = p.join(' - ');
    if (params.raggio) {
      searchRadius = params.raggio;
    }
    if (params.coords) {
      searchCoords = params.coords;
    }
    if (params.placeholder) {
      searchPlaceholder = params.placeholder;
    }
    if (params.metodo) {
      searchMethod = params.metodo;
    }
    if (params.ordine) {
      order = params.ordine;
    }

    // PRENDE LA CATEGORIA
    if (['vendita', 'affitto'].includes(searchType)) {
      const extractedCategoriesFromRoute = this.extractCategoriesFromRouteURL(r);
      const matchedMacroItem = this.listOfTypologies.find(item => item.id === extractedCategoriesFromRoute);
      searchCategoria = matchedMacroItem
        ? matchedMacroItem.value.map(({ value }) => value).join(',')
        : extractedCategoriesFromRoute;
    }

    if (searchType === 'agenzia') {
      searchCategoria = this.extractCategoriesFromRouteURL(r);
    }

    if (searchType === 'agente' && this.isAgentSearch) {
      const splitResult = r.split('esperti_in_');
      if (splitResult.length > 1) {
        activitiesOrder = splitResult[1].replace(/_/g, ' ').replace('/', '');

        if (splitResult[1].includes('%')) {
          activitiesOrder = activitiesOrder.split('%')[0];
        }

        if (splitResult[1].includes('?')) {
          activitiesOrder = activitiesOrder.split('?')[0];
        }

        if (activitiesOrder.includes('property')) {
          activitiesOrder = 'property_finder';
        }

        if (activitiesOrder.includes('mutui')) {
          activitiesOrder = 'mutui_e_prestiti';
        }
      }
    }

    searchValue = searchPlaceholder;

    return {
      searchRadius,
      searchCoords,
      searchCategoria,
      searchType,
      searchComune,
      searchZona,
      searchPlaceholder,
      searchProvincia,
      searchRegione,
      searchValue,
      searchMethod,

      order,
      activitiesOrder,
    };
  }

  generateNewUrl(fullUrl: string): string {
    // throw new Error('Method not implemented.');
    let url = fullUrl.replace('/bnl', '');
    // remove all params from url and save them, check if there are any params
    let params = url.split('?');
    if (params.length > 1) {
      params = params[1].split('&');
    }
    const queryParams: {
      [key: string]: any;
    } = {};
    params.forEach((param) => {
      const [key, value] = param.split('=');
      queryParams[key] = value;
    });
    url = url.split('?')[0];
    // console.log(`Url without placeholder and metodo: ${url}`);
    // console.log(`Query params: ${JSON.stringify(queryParams)}`);
    const searchType = url.split('/')[1];
    switch (searchType) {
      case 'vendita':
      case 'affitto':
        return `${environment.ssrSitePath}${this.generatePathVenditaAffitto(searchType, url, queryParams)}`;
      case 'agenzia':
        return `${environment.ssrSitePath}${this.generatePathAgenzia('agenzia', url, queryParams)}`;
      case 'agente':
        return `${environment.ssrSitePath}${this.generatePathAgente('agente', url, queryParams)}`;
      case 'professionista':
        return `${environment.ssrSitePath}${this.generatePathProfessionista('professionista', url, queryParams)}`;
      default:
        return '';
    }
  }

  // Function to extract the comma-separated string
  extractCategoriesFromRouteURL(url: string): string {
    const parts = url.split('/');
    const categorySegment = parts[3];
    return categorySegment ? categorySegment.split('?')[0] : '';
  }

  getAssociationName(associationString: string | undefined): string {
    if (!associationString || associationString === '') {
      return '';
    }
    switch (associationString) {
      case 'air':
        return '• AIR - Agenti Immobiliari Riuniti';

      case 'anama':
        return '• ANAMA - Associazione Nazionale Agenti e Mediatori d\'Affari';

      case 'ance':
        return '• Associazione Nazionale Costruttori Edili';

      case 'fiaip':
        return '• FIAIP - Federazione Italiana Agenti Immobiliari Professionali';

      case 'fimaa':
        return '• FIMAA - Federazione Italiana Mediatori Agenti d\'Affari';

      case 'oam':
        return '• OAM Organismo Agenti e Mediatori';

      default:
        return `• ${associationString}`;
    }
  }

  // Genera url annuncio
  generateRouteByContractAndStatus(annuncio: Listing): string {
    switch (annuncio?.contract_type) {
      case 'buy':
        if (annuncio.status === 'sold') {
          return 'venduto';
        } else {
          if (!annuncio?.is_auction) {
            return 'in-vendita';
          } else {
            return 'in-asta';
          }
        }
      case 'rent':
        if (annuncio.status === 'rented') {
          return 'affittato';
        } else {
          return 'in-affitto';
        }
    }
  }

  // *********************************************
  // *********************************************
  // *********************************************
  // GESTIONE DEL FILTRAGGIO DI RICERCA
  // *********************************************
  // *********************************************
  // *********************************************

  // VENGONO PULITI I FILTRAGGI DI RICERCA
  resetSearchFilter(): void {
    this.filtersList = {
      minPrice: null,
      maxPrice: null,
      minMQ: null,
      maxMQ: null,
      minLocali: null,
      maxLocali: null,
      minFinanziamento: null,
      maxFinanziamento: null,
      associations: null,
      languages: null,
      specializations: null,
      minExp: null,
      maxExp: null,
      mandates: null,
      opportunity: null,
      exclusive: null,
      collab: null,
      conditions: null,
      context: null,
      heating: null,
      kitchen: null,
      bathrooms: null,
      floors: null,
      garden: null,
      garage: null,
      extra: null,
      is_auction: null
    };
    this.orderList = {
      activities: null,
      order: null,
    };
  }

  // CONTA I FILTRI INSERITI
  countFilters(): number {
    let counter = 0;
    if (this.filtersList.minPrice || this.filtersList.maxPrice) {
      counter++;
    }
    if (this.filtersList.minMQ || this.filtersList.maxMQ) {
      counter++;
    }
    if (this.filtersList.minLocali || this.filtersList.maxLocali) {
      counter++;
    }
    if (this.filtersList.minFinanziamento || this.filtersList.maxFinanziamento) {
      counter++;
    }
    if (this.filtersList.minExp || this.filtersList.maxExp) {
      counter++;
    }
    if (this.filtersList.opportunity) {
      counter++;
    }
    if (this.filtersList.exclusive) {
      counter++;
    }
    if (this.filtersList.collab) {
      counter++;
    }
    if (this.filtersList.associations && this.filtersList.associations.length) {
      counter++;
    }
    if (this.filtersList.languages && this.filtersList.languages.length) {
      counter++;
    }
    if (this.filtersList.specializations && this.filtersList.specializations.length) {
      counter++;
    }
    if (this.filtersList.mandates && this.filtersList.mandates.length) {
      counter++;
    }
    if (this.orderList.activities && this.orderList.activities.length) {
      counter++;
    }

    if (this.filtersList.bathrooms && this.filtersList.bathrooms > 0) {
      counter++;
    }
    if (this.filtersList.conditions) {
      counter += this.filtersList.conditions.length;
    }
    if (this.filtersList.context) {
      counter += this.filtersList.context.length;
    }
    if (this.filtersList.extra) {
      counter += this.filtersList.extra.length;
    }
    if (this.filtersList.floors) {
      counter += this.filtersList.floors.length;
    }
    if (this.filtersList.garage) {
      counter += this.filtersList.garage.length;
    }
    if (this.filtersList.garden) {
      counter += this.filtersList.garden.length;
    }
    // if (this.filtersList.kitchen) {
    //   counter += this.filtersList.kitchen.length;
    // }

    if (this.filtersList.heating) {
      counter += this.filtersList.heating.length;
    }

    if (this.filtersList.is_auction) {
      counter++;
    }

    return counter;
  }

  // GENERA IL PARAMETRO FILTER DA INVIARE ALLE API
  returnFilter(finanziamentoConfig?: MutuiConfig): any[] {
    let array = [];

    switch (this.searchType) {
      case 'vendita':
        array = this.handleFiltersVenditaAffitto(array, finanziamentoConfig);
        break;
      case 'affitto':
        array = this.handleFiltersVenditaAffitto(array, null);
        break;
      case 'agenzia':
        if (this.filtersList.associations) {
          array.push({
            association: {
              inq: this.filtersList.associations,
            },
          });
        }
        break;
      case 'agente':
        if (this.filtersList.associations) {
          array.push({
            association: {
              inq: this.filtersList.associations,
            },
          });
        }

        if (this.isAgentSearch) {
          array = this.handleFiltersForAgentAndProfessionist(array);
          if (this.filtersList.specializations) {
            if (!Array.isArray(this.filtersList.specializations)) {
              const pushSpecializations = [];
              pushSpecializations.push(this.filtersList.specializations);
              this.filtersList.specializations = pushSpecializations;
            }
            const listSpec = [];
            this.filtersList.specializations.map((element) => {
              const specialization = this.specializations.find((item) => element === item.id);
              if (specialization) {
                listSpec.push(specialization.name);
              }
            });
            if (listSpec.length > 0) {
              array.push({
                specialization: {
                  inq: listSpec,
                },
              });
            }
          }
          if (this.filtersList.mandates) {
            if (!Array.isArray(this.filtersList.mandates)) {
              const pushMandates = [];
              pushMandates.push(this.filtersList.mandates);
              this.filtersList.mandates = pushMandates;
            }
            const listMandate = [];
            this.filtersList.mandates.map((element) => listMandate.push(this.mandates.find((item) => element === item.id).name));
            if (listMandate.length > 0) {
              array.push({
                selling_type: {
                  inq: listMandate,
                },
              });
            }
          }
          if (this.filtersList.opportunity) {
            array.push({
              status: 'looking-for-job',
            });
          }
          if (this.filtersList.collab) {
            array.push({
              collaborates_with_other_agents: true,
            });
          }
        }
        break;
      case 'professionista':
        if (this.filtersList.specializations) {
          if (!Array.isArray(this.filtersList.specializations)) {
            const pushSpecializations = [];
            pushSpecializations.push(this.filtersList.specializations);
            this.filtersList.specializations = pushSpecializations;
          }
          const listSpec = [];
          this.filtersList.specializations.map((element) => {
            listSpec.push(this.specializationsProfessionists.find((item) => element === item.id).name);
          });

          if (listSpec.length > 0) {
            array.push({
              specialization: {
                inq: listSpec,
              },
            });
          }
        }
        array = this.handleFiltersForAgentAndProfessionist(array);
        break;
      default:
        break;
    }
    return array;
  }

  private handleFiltersVenditaAffitto(array: any, finanziamentoConfig?: MutuiConfig): [] {
    if (this.searchType === 'vendita') {
      if ((this.filtersList.minPrice || this.filtersList.minFinanziamento) && finanziamentoConfig) {
        array.push({
          price: {
            gte: Math.round(this.getPriceFilter(finanziamentoConfig).min),
          },
        });
      }
      if ((this.filtersList.maxPrice || this.filtersList.maxFinanziamento) && finanziamentoConfig) {
        array.push({
          price: {
            lte: Math.round(this.getPriceFilter(finanziamentoConfig).max),
          },
        });
      }
    }
    if (this.searchType === 'affitto') {
      if (this.filtersList.minPrice) {
        array.push({
          price: {
            gte: parseInt(this.filtersList.minPrice, 10),
          },
        });
      }
      if (this.filtersList.maxPrice) {
        array.push({
          price: {
            lte: parseInt(this.filtersList.maxPrice, 10),
          },
        });
      }
    }
    if (this.filtersList.minMQ) {
      array.push({
        surface: {
          gte: parseInt(this.filtersList.minMQ, 10),
        },
      });
    }
    if (this.filtersList.maxMQ) {
      array.push({
        surface: {
          lte: parseInt(this.filtersList.maxMQ, 10),
        },
      });
    }
    if (this.filtersList.minLocali) {
      array.push({
        total_rooms: {
          gte: parseInt(this.filtersList.minLocali, 10),
        },
      });
    }
    if (this.filtersList.maxLocali) {
      array.push({
        total_rooms: {
          lte: parseInt(this.filtersList.maxLocali, 10),
        },
      });
    }
    if (this.filtersList.bathrooms) {
      array.push({
        bathrooms: { gte: this.filtersList.bathrooms },
      });
    }
    if (this.filtersList.conditions) {
      if (!Array.isArray(this.filtersList.conditions)) {
        const pushConditions = [];
        pushConditions.push(this.filtersList.conditions);
        this.filtersList.conditions = pushConditions;
      }
      array.push({
        conditions: { inq: this.filtersList.conditions },
      });
    }
    if (this.filtersList.context) {
      if (!Array.isArray(this.filtersList.context)) {
        const pushContext = [];
        pushContext.push(this.filtersList.context);
        this.filtersList.context = pushContext;
      }
      array.push({
        context: { inq: this.filtersList.context },
      });
    }
    if (this.filtersList.extra) {
      if (!Array.isArray(this.filtersList.extra)) {
        // after reacharging page if 1 have only one item in filterlist.extra i have a string instead a ['']
        const pushExtra = [];
        pushExtra.push(this.filtersList.extra);
        this.filtersList.extra = pushExtra;
      }
      this.filtersList.extra.forEach(element => {
        const objExt = Constants.FILTER_EXTRA.find((item) => element === item.value);
        if (objExt) {
          const listExt = {
            [objExt.valueQuery]: true,
          };
          array.push(listExt);
        }
      });

    }
    if (this.filtersList.floors) {
      if (!Array.isArray(this.filtersList.floors)) {
        // after reacharging page if 1 have only one item in filterlist.floor i have a string instead a ['']
        const myArray = [];
        myArray.push(this.filtersList.floors);
        this.filtersList.floors = myArray;
      }
      const pushFloors = [];
      this.filtersList.floors.forEach(element => {
        const objFloors = Constants.FILTER_FLOORS.find((item) => element === item.value);
        if (objFloors) {
          pushFloors.push(objFloors.valueQuery);
        }
      });
      if (pushFloors.length > 0) {
        array.push({
          floors: { inq: pushFloors },
        });
      }
    }
    if (this.filtersList.garage) {
      if (!Array.isArray(this.filtersList.garage)) {
        // after reacharging page if 1 have only one item in filterlist.garage i have a string instead a ['']
        const myArray = [];
        myArray.push(this.filtersList.garage);
        this.filtersList.garage = myArray;
      }
      const pushGarageType = [];
      this.filtersList.garage.forEach(element => {
        const objGarage = Constants.FILTER_GARAGE.find((item) => element === item.value);
        if (objGarage) {
          pushGarageType.push(objGarage.valueQuery);
        }
      });

      if (pushGarageType.length > 0) {
        array.push({
          garage_type: { inq: pushGarageType },
        });
      }
    }
    if (this.filtersList.garden) {
      if (!Array.isArray(this.filtersList.garden)) {
        // after reacharging page if 1 have only one item in filterlist.garden i have a string instead a ['']
        const myArray = [];
        myArray.push(this.filtersList.garden);
        this.filtersList.garden = myArray;
      }
      const pushGardenType = [];
      this.filtersList.garden.forEach(element => {
        const objGarden = Constants.FILTER_GARDEN.find((item) => element === item.value);
        if (objGarden) {
          pushGardenType.push(objGarden.valueQuery);
        }
      });
      if (pushGardenType.length > 0) {
        array.push({
          garden_type: { inq: pushGardenType },
        });
      }
    }
    if (this.filtersList.kitchen) {
      if (!Array.isArray(this.filtersList.kitchen)) {
        const pushKitchen = [];
        pushKitchen.push(this.filtersList.kitchen);
        this.filtersList.kitchen = pushKitchen;
      }
      array.push({
        kitchen: { inq: this.filtersList.kitchen },
      });
    }
    if (this.filtersList.heating) {
      if (!Array.isArray(this.filtersList.heating)) {
        const pushHeating = [];
        pushHeating.push(this.filtersList.heating);
        this.filtersList.heating = pushHeating;
      }
      array.push({
        heating: { inq: this.filtersList.heating },
      });
    }
    if (this.filtersList.is_auction) {
      array.push({
        is_auction: 'in-asta',
      });
    }
    if (this.filtersList.exclusive) {
      array.push({
        mandate_type: 'in-esclusiva',
      });
      array.push({
        mandate_from: {
          lte: new Date().toISOString(),
        },
      });
    }
    return array;
  }

  private handleFiltersForAgentAndProfessionist(array): [] {
    if (this.filtersList.languages) {
      if (!Array.isArray(this.filtersList.languages)) {
        const pushLanguages = [];
        pushLanguages.push(this.filtersList.languages);
        this.filtersList.languages = pushLanguages;
      }
      array.push({
        spoken_languages: {
          inq: this.filtersList.languages.map(a => a.charAt(0).toUpperCase() + a.substring(1)),
        },
      });
    }
    if (this.filtersList.minExp) {
      array.push({
        agent_from_year: {
          lte: (new Date()).getFullYear() - this.filtersList.minExp,
        },
      });
    }
    if (this.filtersList.maxExp) {
      array.push({
        agent_from_year: {
          gte: (new Date()).getFullYear() - this.filtersList.maxExp,
        },
      });
    }

    return array;
  }

  getActivity(name: string): string {
    const index = this.activities.findIndex(a => a.name === name);
    if (index >= 0 && this.orderList.order !== '') {
      return this.activities[index].orderName + ',';
    } else if (index >= 0) {
      return this.activities[index].orderName;
    } else {
      return '';
    }
  }

  getOrder(name: string): string {
    const index = this.orderFilters.findIndex(a => a.name === name);
    if (index >= 0) {
      return this.orderFilters[index].orderName;
    } else {
      return '';
    }
  }

  linkBlank(url: string): void {

    if (isPlatformBrowser(this.platformId)) {
      if (!window.matchMedia('(display-mode: standalone)').matches) {
        window.open(url, '_blank');
      } else {
        // PWA NOT BLANK
        if (url.includes('http') || url.includes('https')) {
          window.open(url, '_blank');
        } else {
          document.documentElement.style.scrollBehavior = 'unset';
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 10);
          this.router.navigateByUrl('/RELOAD_PLACEHOLDER', { skipLocationChange: true }).then(() => {
            this.router.navigate([url]).then(() => {
              document.documentElement.removeAttribute('style');
            });
          });
        }
      }
    }
  }

  toTitleCase = (phrase: string) => {
    if (phrase) {
      return phrase.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }
  }

  // Redirect to 404
  redirectToNotFound(): void {
    this.router.navigate(['/pagina-non-trovata'], { replaceUrl: true });
  }

  // funzione calcolo totale da rata finanziamento

  getPriceFilter(finanziamentoConfig: MutuiConfig): { min?: number, max?: number } {
    const price: {
      min?: number, max?: number
    } = {};
    const finanziamentoMin: number = this.loanToPrice(finanziamentoConfig).min;
    const finanziamentoMax: number = this.loanToPrice(finanziamentoConfig).max;

    if (this.filtersList.minPrice) {
      price.min = parseInt(this.filtersList.minPrice, 10);
    } else {
      if (finanziamentoMin) {
        price.min = finanziamentoMin;
      }
    }
    if (this.filtersList.maxPrice) {
      price.max = parseInt(this.filtersList.maxPrice, 10);
    } else {
      if (finanziamentoMax) {
        price.max = finanziamentoMax;
      }
    }
    return price;
  }

  loanToPrice(finanziamentoConfig: MutuiConfig): any {
    const tpm = (finanziamentoConfig.rate / 12) / 100;
    let min: number;
    let max: number;
    if (this.filtersList.minFinanziamento) {
      min = (this.filtersList.minFinanziamento / (tpm / (1 - (1 / Math.pow(1 + tpm, finanziamentoConfig.duration * 12))))) / (finanziamentoConfig.percentage / 100);
    }
    if (this.filtersList.maxFinanziamento) {
      max = (this.filtersList.maxFinanziamento / (tpm / (1 - (1 / Math.pow(1 + tpm, finanziamentoConfig.duration * 12))))) / (finanziamentoConfig.percentage / 100);
    }
    return {
      min,
      max,
    };
  }

  async getMutuiConfig(): Promise<MutuiConfig> {
    try {
      let mutuoConfig: MutuiConfig;
      mutuoConfig = await lastValueFrom(this.mutuiConfigService.mutuiConfigGetMutuiConfig());
      return mutuoConfig;
    } catch (e) {
      this.console.log(e);
    }
  }

  reduceString(text: string, length: number): string {
    if (text.length < length) {
      return text;
    } else {
      let trimmedString = text.substring(0, length);
      trimmedString = trimmedString.substring(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')));
      return trimmedString + '...';
    }
  }

  // Piano ridotto

  returnSmallFloor(floor?: number): string {
    if (floor || floor === 0) {
      if (typeof floor !== 'undefined') {
        const floorString = floor.toString();
        switch (floorString) {
          case '-0.5': {
            return 'I';
          }
          case '0': {
            return 'T';
          }
          case '0.5': {
            return 'R';
          }
          case '-99': {
            return '1+';
          }
          case '-100': {
            return '';
          }
          default: {
            return floorString;
          }
        }
      }
    }
  }

  showContext(value: string | undefined): string {
    if (!value || value === '' || value === 'non-disponibile' || value === 'non-definito') {
      return 'Non def.';
    }
    const condition = Constants.CONTEXT.find(cond => cond.value === value);
    return condition?.text || value;
  }

  // GESTIONE NOME CATEGORIA ANNUNCIO
  handleCategoryArticle(cat: string): any {
    if (cat === 'news_casa') {
      return 'News casa';
    } else if (cat === 'lifestyle') {
      return 'Lifestyle';
    } else if (cat === 'casa_ecologica') {
      return 'Casa ecologica';
    } else if (cat === 'lavori') {
      return 'Lavori in casa';
    } else if (cat === 'ristrutturare') {
      return 'Ristrutturare';
    } else if (cat === 'quartiere') {
      return 'News di quartiere';
    }
  }

  // *************
  //  NEWS
  // *************

  // CLICK PULSANTE DI RICERCA
  async applyNewsSearch(): Promise<void> {
    await this.router.navigateByUrl('/', { skipLocationChange: true });
    await this.router.navigate(['news' + this.generateNewsURL(this.newsSearchCategory, this.newsSearchRegione, this.newsSearchProvincia, this.newsSearchComune, this.newsSearchZona, this.newsSearchValue)]);
  }

  // SELEZIONA IL METODO DI RICERCA
  async selectOption(zone: any, pageType: string): Promise<void> {
    this.newsSearchRegione = null;
    this.newsSearchProvincia = null;
    this.newsSearchComune = null;
    this.newsSearchZona = null;
    this.zoneSelectedValue = null;
    switch (zone.type) {
      case 'Regione':
        this.newsSearchRegione = zone.name;
        this.zoneSelectedValue = zone.display_name + ' • Regione';
        break;
      case 'Comune':
        this.newsSearchComune = zone.name;
        this.zoneSelectedValue = zone.display_name + ' • Comune';
        break;
      case 'Provincia':
        this.newsSearchProvincia = zone.name;
        this.zoneSelectedValue = zone.display_name + ' • Provincia';
        break;
      case 'Zona':
        this.newsSearchZona = zone.name;
        this.zoneSelectedValue = zone.display_name + ' • Zona';
        break;
    }
    // eseguo la chiamata dalla barra di ricerca in pag ricerca
    if (pageType === 'searchPage') {
      await this.applyNewsSearch();
    }
  }

  // GENERA L'URL LATO SEO PER LA RICERCA NEWS
  generateNewsURL(categoria: string, regione: string, provincia: string, comune: string, zona: string, queryValue: string): string {
    let url = '/ricerca';
    if (categoria) {
      url += '/categoria__' + categoria;
    }
    if (regione) {
      url += '/regione-' + this.parseURL(regione);
    }
    if (provincia) {
      url += '/provincia-' + this.parseURL(provincia);
    }

    if (comune) {
      url += '/comune-' + this.parseURL(comune);

    }
    if (zona) {
      url += '/zona-' + this.parseURL(zona);
    }
    if (queryValue) {
      url += '/q-' + this.parseURL(queryValue);
    }

    return url + '/';
  }

  // CHECK CDN IMMAGINE
  generatePictureUrl(url: string, width: string, type: string): string {
    if ((!url || url === '') && type === 'agent') {
      url = 'assets/img/placeholders/agent.jpg';
      return url;
    } else if ((!url || url === '') && type === 'agency') {
      url = 'assets/img/noLogo.jpg';
      return url;
    } else if ((!url || url === '') && type === 'prodotto') {
      url = 'assets/img/placeholders/annuncio.jpg';
      return url;
    }
    if (url?.includes('https://cdn.cercacasa.it')) {
      return `${url}?w=${width}`;
    }
    return url;
  }

  generateMultiplePictureUrl(url: string, type: string): string {
    let width = '1000';

    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth >= 768) {
        width = '1000';
      } else if (window.innerWidth >= 450 && window.innerWidth < 768) {
        width = '750';
      } else if (window.innerWidth < 500) {
        width = '450';
      }
    }

    if ((!url || url === '') && type === 'agent') {
      url = 'assets/img/placeholders/agent.jpg';
      return url;
    }
    if (url?.includes('https://cdn.cercacasa.it')) {
      return `${url}?w=${width}`;
    }

    return url;
  }

  checkIfPatinaNeeded(url: string): boolean {
    return !(url?.includes(environment.oldImgUrl) || url?.includes(environment.cdnImgUrl));

  }

  // RICERCA ATECO
  async applyAtecoSearch(searchValue: string): Promise<void> {
    await this.router.navigateByUrl('/', { skipLocationChange: true });
    await this.router.navigate(['ricerca-ateco/' + this.parseURL(searchValue)]);
  }

  getCityRegex(address: string, removeDot: any): string {
    const cityRegex = /(?:,|^)\s*([A-Za-z\s]+)\s+\([A-Z]{2}\)\s*$/;
    const match = address?.match(cityRegex);
    const cityName = match ? match[1] : null;
    if (cityName) {
      return removeDot ? ' ' + cityName : ' • ' + cityName;
    } else {
      return '';
    }
  }

  checkIfOldRoute(fullUrl: string): boolean {
    const url = fullUrl.replace('/bnl', '');
    const searchType = url.split('/')[1];
    switch (searchType) {
      case 'vendita':
      case 'affitto':
        const numberOfSegments = url.split('/').length;
        if (numberOfSegments > 4) {
          return true;
        }
        const check = ['/categoria__', '/asta-immobiliare', '/condizioni_', '/al-piano_', '/garage_', '/fornito-di_', '/giardino_', '/contesto-immobiliare_', '/appartamenti-con-cucina_', '/con-riscaldamento_', '/con-bagno_', '/con-prezzo-min_', '/con-prezzo-max_', '/con-superficie-min_', '/con-superficie-max_', '/con-locali-min_', '/con-locali-max_'];
        // if any of these segments are present return true
        // if (check.some(segment => url.indexOf(segment) !== -1)) {
        //   console.log(`Found segments: ${check.filter(segment => url.indexOf(segment) !== -1)}`);
        // }
        return check.some(segment => url.indexOf(segment) !== -1);
      case 'agenzia':
        // https://cercacasa.it/agenzia/provincia-milano/categoria__astepoint/associate_fiaip?placeholder=Milano%20%E2%80%A2%20(Provincia)&metodo=1
        // http://localhost:4001/agenzia/provincia-milano/astepoint?associate=air
        const checkAgency = ['/categoria__', '/associate_'];
        // if (checkAgency.some(segment => url.indexOf(segment) !== -1)) {
        //   console.log(`Found segments: ${checkAgency.filter(segment => url.indexOf(segment) !== -1)}`);
        // }
        return checkAgency.some(segment => url.indexOf(segment) !== -1);
      case 'agente':
        // https://cercacasa.it/agente/provincia-milano/agenti-in-cerca-di-opportunita/collaborazione-tra-agenti/agenti-con-incarico_in-esclusiva/agente-con-esperienza-minima_1/agente-con-esperienza-massima_2/agenti-che-parlano_arabo/agenti-specializzati-in_alberghiero/agenti-esperti-in_compravendite?placeholder=Milano%20%E2%80%A2%20(Provincia)&metodo=1
        const checkAgent = ['/agenti-in-cerca-di-opportunita', '/collaborazione-tra-agenti', '/agenti-con-incarico_', '/agente-con-esperienza-minima_', '/agente-con-esperienza-massima_', '/agenti-che-parlano_', '/agenti-specializzati-in_', '/agenti-esperti-in_'];
        // console.log(`Some: ${checkAgent.some(segment => url.indexOf(segment) !== -1)}`);
        // if (checkAgent.some(segment => url.indexOf(segment) !== -1)) {
        //   console.log(`Found segments: ${checkAgent.filter(segment => url.indexOf(segment) !== -1)}`);
        // }
        return checkAgent.some(segment => url.indexOf(segment) !== -1);
      case 'professionista':
        const checkProfessionista = ['/professionista-con-esperienza-minima_', '/professionista-con-esperienza-massima_', '/professionisti-che-parlano_', 'professionisti-specializzati-in_'];
        // if (checkProfessionista.some(segment => url.indexOf(segment) !== -1)) {
        //   console.log(`Found segments: ${checkProfessionista.filter(segment => url.indexOf(segment) !== -1)}`);
        // }
        return checkProfessionista.some(segment => url.indexOf(segment) !== -1);
      default:
        return false;
    }
  }

  private generatePathVenditaAffitto(searchType: string, url: string, queryParams: { [key: string]: any; }): string {
    const zona = url.split('/')[2];
    let urlToReturn = `/${searchType}/${zona}`;
    if (url.includes('categoria__')) {
      const category = url.split('categoria__')[1].split('/')[0];
      // console.log(`Category: ${category}`);
      urlToReturn += `/${category}`;
    }

    const params = [];

    // console.log(`Url includes asta-immobiliare: ${url.includes('asta-immobiliare')}`);
    if (url.includes('asta-immobiliare')) {
      params.push({ key: 'asta_immobiliare', value: 'asta' });
    }

    if (url.includes('condizioni_')) {
      let condition = url.split('condizioni_')[1].split('/')[0];
      if (condition.includes('?')) {
        condition = condition.split('?')[0];
      }
      if (condition.includes(',')) {
        const conditions = condition.split(',');
        conditions.forEach(c => {
          params.push({ key: 'condizioni', value: c });
        });
      } else {
        params.push({ key: 'condizioni', value: condition });
      }
    }

    if (url.includes('al-piano_')) {
      let floor = url.split('al-piano_')[1].split('/')[0];
      if (floor.includes('?')) {
        floor = floor.split('?')[0];
      }
      if (floor.includes(',')) {
        const floors = floor.split(',');
        floors.forEach(f => {
          params.push({ key: 'piano', value: f });
        });
      } else {
        params.push({ key: 'piano', value: floor });
      }
    }

    if (url.includes('garage_')) {
      let garage = url.split('garage_')[1].split('/')[0];
      if (garage.includes('?')) {
        garage = garage.split('?')[0];
      }
      if (garage.includes(',')) {
        const garages = garage.split(',');
        garages.forEach(g => {
          params.push({ key: 'garage', value: g });
        });
      } else {
        params.push({ key: 'garage', value: garage });
      }
    }

    if (url.includes('fornito-di_')) {
      let extra = url.split('fornito-di_')[1].split('/')[0];
      if (extra.includes('?')) {
        extra = extra.split('?')[0];
      }
      if (extra.includes(',')) {
        const extras = extra.split(',');
        extras.forEach(e => {
          params.push({ key: 'extra', value: e });
        });
      } else {
        params.push({ key: 'extra', value: extra });
      }
    }

    if (url.includes('giardino_')) {
      let garden = url.split('giardino_')[1].split('/')[0];
      if (garden.includes('?')) {
        garden = garden.split('?')[0];
      }
      if (garden.includes(',')) {
        const gardens = garden.split(',');
        gardens.forEach(g => {
          params.push({ key: 'giardino', value: g });
        });
      } else {
        params.push({ key: 'giardino', value: garden });
      }
    }

    if (url.includes('contesto-immobiliare_')) {
      let context = url.split('contesto-immobiliare_')[1].split('/')[0];
      // also check if after constesto-immobiliare_ there is a ?
      if (context.includes('?')) {
        context = context.split('?')[0];
      }
      if (context.includes(',')) {
        const contexts = context.split(',');
        contexts.forEach(c => {
          params.push({ key: 'contesto', value: c });
        });
      } else {
        params.push({ key: 'contesto', value: context });
      }
    }

    if (url.includes('appartamenti-con-cucina_')) {
      let kitchen = url.split('appartamenti-con-cucina_')[1].split('/')[0];
      if (kitchen.includes('?')) {
        kitchen = kitchen.split('?')[0];
      }
      if (kitchen.includes(',')) {
        const kitchens = kitchen.split(',');
        kitchens.forEach(k => {
          params.push({ key: 'con_cucina', value: k });
        });
      } else {
        params.push({ key: 'con_cucina', value: kitchen });
      }
    }

    if (url.includes('con-riscaldamento_')) {
      let heating = url.split('con-riscaldamento_')[1].split('/')[0];
      if (heating.includes('?')) {
        heating = heating.split('?')[0];
      }
      if (heating.includes(',')) {
        const heatings = heating.split(',');
        heatings.forEach(h => {
          params.push({ key: 'riscaldamento', value: h });
        });
      } else {
        params.push({ key: 'riscaldamento', value: heating });
      }
    }

    if (url.includes('con-bagno_')) {
      let bathrooms = url.split('con-bagno_')[1].split('/')[0];
      if (bathrooms.includes('?')) {
        bathrooms = bathrooms.split('?')[0];
      }
      params.push({ key: 'con_bagni', value: bathrooms });
    }

    if (url.includes('con-prezzo-min_')) {
      let minPrice = url.split('con-prezzo-min_')[1].split('/')[0];
      if (minPrice.includes('?')) {
        minPrice = minPrice.split('?')[0];
      }
      params.push({ key: 'con_prezzo_min', value: minPrice });
    }

    if (url.includes('con-prezzo-max_')) {
      let maxPrice = url.split('con-prezzo-max_')[1].split('/')[0];
      if (maxPrice.includes('?')) {
        maxPrice = maxPrice.split('?')[0];
      }
      params.push({ key: 'con_prezzo_max', value: maxPrice });
    }

    if (url.includes('con-superficie-min_')) {
      let minMQ = url.split('con-superficie-min_')[1].split('/')[0];
      if (minMQ.includes('?')) {
        minMQ = minMQ.split('?')[0];
      }
      params.push({ key: 'con_superficie_min', value: minMQ });
    }

    if (url.includes('con-superficie-max_')) {
      let maxMQ = url.split('con-superficie-max_')[1].split('/')[0];
      if (maxMQ.includes('?')) {
        maxMQ = maxMQ.split('?')[0];
      }
      params.push({ key: 'con_superficie_max', value: maxMQ });
    }

    if (url.includes('con-locali-min_')) {
      let minLocali = url.split('con-locali-min_')[1].split('/')[0];
      if (minLocali.includes('?')) {
        minLocali = minLocali.split('?')[0];
      }
      params.push({ key: 'con_locali_min', value: minLocali });
    }

    if (url.includes('con-locali-max_')) {
      let maxLocali = url.split('con-locali-max_')[1].split('/')[0];
      if (maxLocali.includes('?')) {
        maxLocali = maxLocali.split('?')[0];
      }
      params.push({ key: 'con_locali_max', value: maxLocali });
    }

    if (queryParams.finanziamentoMin) {
      params.push({ key: 'finanziamentoMin', value: queryParams.finanziamentoMin });
    }

    if (queryParams.finanziamentoMax) {
      params.push({ key: 'finanziamentoMax', value: queryParams.finanziamentoMax });
    }

    if (queryParams.inEsclusiva) {
      params.push({ key: 'in_esclusiva', value: 'true' });
    }

    if (queryParams.ordine) {
      params.push({ key: 'ordine', value: queryParams.ordine });
    }

    // params should be unique
    const uniqueParams = params.filter((p, index, self) => self.findIndex(t => t.key === p.key && t.value === p.value) === index);

    // add params to url as query params
    urlToReturn += '?' + uniqueParams.map(p => `${p.key}=${p.value}`).join('&');

    // console.log(`URL to return: ${urlToReturn}`);

    return urlToReturn;
  }

  private generatePathAgente(searchType: string, url: string, queryParams: { [p: string]: any }): string {

    const zona = url.split('/')[2];
    let urlToReturn = `/${searchType}/${zona}`;

    if (url.includes('agenti-esperti-in_')) {
      const category = url.split('agenti-esperti-in_')[1].split('/')[0];
      // console.log(`Category: ${category}`);
      urlToReturn += `/esperti_in_${category}`;
    }

    // console.log(`Url to return: ${urlToReturn}`);
    // console.log(`Url: ${url}`);

    const params = [];

    if (url.includes('agente-con-esperienza-minima_')) {
      let minExperience = url.split('agente-con-esperienza-minima_')[1].split('/')[0];
      if (minExperience.includes('?')) {
        minExperience = minExperience.split('?')[0];
      }
      if (minExperience.includes(',')) {
        const conditions = minExperience.split(',');
        conditions.forEach(c => {
          params.push({ key: 'agenti_con_esperienza_minima', value: c });
        });
      } else {
        params.push({ key: 'agenti_con_esperienza_minima', value: minExperience });
      }
    }

    if (url.includes('agente-con-esperienza-massima_')) {
      let maxExperience = url.split('agente-con-esperienza-massima_')[1].split('/')[0];
      if (maxExperience.includes('?')) {
        maxExperience = maxExperience.split('?')[0];
      }
      if (maxExperience.includes(',')) {
        const conditions = maxExperience.split(',');
        conditions.forEach(c => {
          params.push({ key: 'agenti_con_esperienza_massima', value: c });
        });
      } else {
        params.push({ key: 'agenti_con_esperienza_massima', value: maxExperience });
      }
    }

    if (url.includes('agenti-che-parlano_')) {
      let languages = url.split('agenti-che-parlano_')[1].split('/')[0];
      if (languages.includes('?')) {
        languages = languages.split('?')[0];
      }
      if (languages.includes(',')) {
        const conditions = languages.split(',');
        conditions.forEach(c => {
          params.push({ key: 'agenti_che_parlano', value: c });
        });
      } else {
        params.push({ key: 'agenti_che_parlano', value: languages });
      }
    }

    if (url.includes('agenti-specializzati-in_')) {
      let specializations = url.split('agenti-specializzati-in_')[1].split('/')[0];
      if (specializations.includes('?')) {
        specializations = specializations.split('?')[0];
      }
      if (specializations.includes(',')) {
        const conditions = specializations.split(',');
        conditions.forEach(c => {
          params.push({ key: 'agenti_specializzati_in', value: c });
        });
      } else {
        params.push({ key: 'agenti_specializzati_in', value: specializations });
      }
    }

    if (url.includes('agenti-in-cerca-di-opportunita')) {
      params.push({ key: 'agenti_in_cerca_di_opportunita', value: 'true' });
    }

    if (url.includes('collaborazione-tra-agenti')) {
      params.push({ key: 'collaborazione_tra_agenti', value: 'true' });
    }

    if (url.includes('agenti-con-incarico_')) {
      let mandate = url.split('agenti-con-incarico_')[1].split('/')[0];
      if (mandate.includes('?')) {
        mandate = mandate.split('?')[0];
      }
      if (mandate.includes(',')) {
        const mandates = mandate.split(',');
        mandates.forEach(m => {
          params.push({ key: 'agenti_con_incarico', value: m });
        });
      } else {
        params.push({ key: 'agenti_con_incarico', value: mandate });
      }
    }

    if (queryParams.ordine) {
      params.push({ key: 'ordine', value: queryParams.ordine });
    }


    const uniqueParams = params.filter((p, index, self) => self.findIndex(t => t.key === p.key && t.value === p.value) === index);

    // add params to url as query params
    urlToReturn += '?' + uniqueParams.map(p => `${p.key}=${p.value}`).join('&');

    // console.log(`URL to return: ${urlToReturn}`);

    return urlToReturn;
  }

  private generatePathProfessionista(searchType: string, url: string, queryParams: { [p: string]: any }): string {
    const zona = url.split('/')[2];
    let urlToReturn = `/${searchType}/${zona}`;

    // https://cercacasa.it/professionista/provincia-milano/professionista-con-esperienza-minima_1/professionista-con-esperienza-massima_2/professionisti-che-parlano_arabo,cinese/professionisti-specializzati-in_mutui,prestiti-personali?placeholder=Milano%20%E2%80%A2%20(Provincia)&metodo=1
    //
    //   http://localhost:4001/professionista/provincia-milano?professionisti_con_esperienza_minima=1&professionisti_con_esperienza_massima=2&professionisti_che_parlano=arabo&professionisti_che_parlano=cinese&professionisti_specializzati_in=mutui&professionisti_specializzati_in=prestiti-personali
    const params = [];

    if (url.includes('professionista-con-esperienza-minima_')) {
      params.push({
        key: 'professionisti_con_esperienza_minima',
        value: url.split('professionista-con-esperienza-minima_')[1].split('/')[0]
      });
    }

    if (url.includes('professionista-con-esperienza-massima_')) {
      params.push({
        key: 'professionisti_con_esperienza_massima',
        value: url.split('professionista-con-esperienza-massima_')[1].split('/')[0]
      });
    }

    if (url.includes('professionisti-che-parlano_')) {
      const languages = url.split('professionisti-che-parlano_')[1].split('/')[0];
      if (languages.includes(',')) {
        languages.split(',').forEach(l => {
          params.push({ key: 'professionisti_che_parlano', value: l });
        });
      } else {
        params.push({ key: 'professionisti_che_parlano', value: languages });
      }
    }

    if (url.includes('professionisti-specializzati-in_')) {
      const specializations = url.split('professionisti-specializzati-in_')[1].split('/')[0];
      if (specializations.includes(',')) {
        specializations.split(',').forEach(s => {
          params.push({ key: 'professionisti_specializzati_in', value: s });
        });
      } else {
        params.push({ key: 'professionisti_specializzati_in', value: specializations });
      }
    }

    const uniqueParams = params.filter((p, index, self) => self.findIndex(t => t.key === p.key && t.value === p.value) === index);

    // add params to url as query params
    urlToReturn += '?' + uniqueParams.map(p => `${p.key}=${p.value}`).join('&');

    // console.log(`URL to return: ${urlToReturn}`);

    return urlToReturn;
  }

  private generatePathAgenzia(searchType: string, url: string, queryParams: { [p: string]: any }): string {
    // https://cercacasa.it/agenzia/provincia-milano/categoria__astepoint/associate_fiaip?placeholder=Milano%20%E2%80%A2%20(Provincia)&metodo=1
    // http://localhost:4001/agenzia/provincia-milano/astepoint?associate=air
    const zona = url.split('/')[2];
    let urlToReturn = `/${searchType}/${zona}`;
    if (url.includes('categoria__')) {
      const category = url.split('categoria__')[1].split('/')[0];
      console.log(`Category: ${category}`);
      urlToReturn += `/${category}`;
    }


    const params = [];

    if (url.includes('associate_')) {
      let associate = url.split('associate_')[1].split('/')[0];
      if (associate.includes('?')) {
        associate = associate.split('?')[0];
      }
      if (associate.includes(',')) {
        const associates = associate.split(',');
        associates.forEach(a => {
          params.push({ key: 'associate', value: a });
        });
      } else {
        params.push({ key: 'associate', value: associate });
      }
    }
    const uniqueParams = params.filter((p, index, self) => self.findIndex(t => t.key === p.key && t.value === p.value) === index);

    // add params to url as query params
    urlToReturn += '?' + uniqueParams.map(p => `${p.key}=${p.value}`).join('&');

    // console.log(`URL to return: ${urlToReturn}`);

    return urlToReturn;
  }
}

/**
 * cercacasa_api
 * cercacasa_api
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AccessToken } from '../model/accessToken';
import { Agency } from '../model/agency';
import { Agent } from '../model/agent';
import { Analytics } from '../model/analytics';
import { Article } from '../model/article';
import { Brand } from '../model/brand';
import { BugReport } from '../model/bugReport';
import { Client } from '../model/client';
import { ContactRequest } from '../model/contactRequest';
import { Device } from '../model/device';
import { FavouriteAgency } from '../model/favouriteAgency';
import { FavouriteAgent } from '../model/favouriteAgent';
import { FavouriteArticle } from '../model/favouriteArticle';
import { FavouriteProduct } from '../model/favouriteProduct';
import { FavouriteProfessionist } from '../model/favouriteProfessionist';
import { GenericRequest } from '../model/genericRequest';
import { HelpfulReview } from '../model/helpfulReview';
import { InlineResponse200 } from '../model/inlineResponse200';
import { InlineResponse2001 } from '../model/inlineResponse2001';
import { InlineResponse2002 } from '../model/inlineResponse2002';
import { LikedAgency } from '../model/likedAgency';
import { LikedAgent } from '../model/likedAgent';
import { LikedArticle } from '../model/likedArticle';
import { LikedBrand } from '../model/likedBrand';
import { LikedListing } from '../model/likedListing';
import { LikedProduct } from '../model/likedProduct';
import { LikedProfessionist } from '../model/likedProfessionist';
import { Listing } from '../model/listing';
import { Member } from '../model/member';
import { Note } from '../model/note';
import { Partner } from '../model/partner';
import { Product } from '../model/product';
import { Professionist } from '../model/professionist';
import { Report } from '../model/report';
import { Review } from '../model/review';
import { Role } from '../model/role';
import { RoleMapping } from '../model/roleMapping';
import { SavedListing } from '../model/savedListing';
import { UserSearch } from '../model/userSearch';
import { VisitedAgency } from '../model/visitedAgency';
import { VisitedAgent } from '../model/visitedAgent';
import { VisitedListing } from '../model/visitedListing';
import { VisitedProfessionist } from '../model/visitedProfessionist';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MemberService {

    protected basePath = 'https://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Change a user&#39;s password.
     * 
     * @param oldPassword 
     * @param newPassword 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberChangePassword(oldPassword: string, newPassword: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberChangePassword(oldPassword: string, newPassword: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberChangePassword(oldPassword: string, newPassword: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberChangePassword(oldPassword: string, newPassword: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (oldPassword === null || oldPassword === undefined) {
            throw new Error('Required parameter oldPassword was null or undefined when calling memberChangePassword.');
        }

        if (newPassword === null || newPassword === undefined) {
            throw new Error('Required parameter newPassword was null or undefined when calling memberChangePassword.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (oldPassword !== undefined) {
            formParams.append('oldPassword', <any>oldPassword);
        }
        if (newPassword !== undefined) {
            formParams.append('newPassword', <any>newPassword);
        }

        return this.httpClient.post<any>(`${this.basePath}/members/change-password`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberCheckAccessTokenValidity(observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public memberCheckAccessTokenValidity(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public memberCheckAccessTokenValidity(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public memberCheckAccessTokenValidity(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.post<boolean>(`${this.basePath}/members/checkAccessTokenValidity`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param name 
     * @param surname 
     * @param gender 
     * @param day 
     * @param month 
     * @param year 
     * @param birthplace 
     * @param birthplaceProvincia 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberCodiceFiscale(name: string, surname: string, gender: string, day: number, month: number, year: number, birthplace: string, birthplaceProvincia?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberCodiceFiscale(name: string, surname: string, gender: string, day: number, month: number, year: number, birthplace: string, birthplaceProvincia?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberCodiceFiscale(name: string, surname: string, gender: string, day: number, month: number, year: number, birthplace: string, birthplaceProvincia?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberCodiceFiscale(name: string, surname: string, gender: string, day: number, month: number, year: number, birthplace: string, birthplaceProvincia?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling memberCodiceFiscale.');
        }

        if (surname === null || surname === undefined) {
            throw new Error('Required parameter surname was null or undefined when calling memberCodiceFiscale.');
        }

        if (gender === null || gender === undefined) {
            throw new Error('Required parameter gender was null or undefined when calling memberCodiceFiscale.');
        }

        if (day === null || day === undefined) {
            throw new Error('Required parameter day was null or undefined when calling memberCodiceFiscale.');
        }

        if (month === null || month === undefined) {
            throw new Error('Required parameter month was null or undefined when calling memberCodiceFiscale.');
        }

        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling memberCodiceFiscale.');
        }

        if (birthplace === null || birthplace === undefined) {
            throw new Error('Required parameter birthplace was null or undefined when calling memberCodiceFiscale.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (surname !== undefined && surname !== null) {
            queryParameters = queryParameters.set('surname', <any>surname);
        }
        if (gender !== undefined && gender !== null) {
            queryParameters = queryParameters.set('gender', <any>gender);
        }
        if (day !== undefined && day !== null) {
            queryParameters = queryParameters.set('day', <any>day);
        }
        if (month !== undefined && month !== null) {
            queryParameters = queryParameters.set('month', <any>month);
        }
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', <any>year);
        }
        if (birthplace !== undefined && birthplace !== null) {
            queryParameters = queryParameters.set('birthplace', <any>birthplace);
        }
        if (birthplaceProvincia !== undefined && birthplaceProvincia !== null) {
            queryParameters = queryParameters.set('birthplaceProvincia', <any>birthplaceProvincia);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<any>(`${this.basePath}/members/codiceFiscale`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Confirm a user registration with identity verification token.
     * 
     * @param uid 
     * @param token 
     * @param redirect 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberConfirm(uid: string, token: string, redirect?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberConfirm(uid: string, token: string, redirect?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberConfirm(uid: string, token: string, redirect?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberConfirm(uid: string, token: string, redirect?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling memberConfirm.');
        }

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling memberConfirm.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (uid !== undefined && uid !== null) {
            queryParameters = queryParameters.set('uid', <any>uid);
        }
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }
        if (redirect !== undefined && redirect !== null) {
            queryParameters = queryParameters.set('redirect', <any>redirect);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<any>(`${this.basePath}/members/confirm`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Count instances of the model matched by where from the data source.
     * 
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberCount(where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberCount(where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberCount(where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberCount(where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new instance of the model and persist it into the data source.
     * 
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberCreate(data?: Member, observe?: 'body', reportProgress?: boolean): Observable<Member>;
    public memberCreate(data?: Member, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Member>>;
    public memberCreate(data?: Member, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Member>>;
    public memberCreate(data?: Member, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Member>(`${this.basePath}/members`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a change stream.
     * 
     * @param options 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberCreateChangeStreamGetMembersChangeStream(options?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public memberCreateChangeStreamGetMembersChangeStream(options?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public memberCreateChangeStreamGetMembersChangeStream(options?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public memberCreateChangeStreamGetMembersChangeStream(options?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (options !== undefined && options !== null) {
            queryParameters = queryParameters.set('options', <any>options);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get(`${this.basePath}/members/change-stream`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a change stream.
     * 
     * @param options 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberCreateChangeStreamPostMembersChangeStream(options?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public memberCreateChangeStreamPostMembersChangeStream(options?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public memberCreateChangeStreamPostMembersChangeStream(options?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public memberCreateChangeStreamPostMembersChangeStream(options?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (options !== undefined) {
            formParams.append('options', <any>options);
        }

        return this.httpClient.post(`${this.basePath}/members/change-stream`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a model instance by {{id}} from the data source.
     * 
     * @param id Model id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberDeleteById(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberDeleteById(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberDeleteById(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberDeleteById(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberDeleteById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check whether a model instance exists in the data source.
     * 
     * @param id Model id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberExistsGetMembersidExists(id: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2001>;
    public memberExistsGetMembersidExists(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2001>>;
    public memberExistsGetMembersidExists(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2001>>;
    public memberExistsGetMembersidExists(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberExistsGetMembersidExists.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse2001>(`${this.basePath}/members/${encodeURIComponent(String(id))}/exists`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check whether a model instance exists in the data source.
     * 
     * @param id Model id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberExistsHeadMembersid(id: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2001>;
    public memberExistsHeadMembersid(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2001>>;
    public memberExistsHeadMembersid(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2001>>;
    public memberExistsHeadMembersid(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberExistsHeadMembersid.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<InlineResponse2001>(`${this.basePath}/members/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find all instances of the model matched by filter from the data source.
     * 
     * @param filter Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (&#x60;{\&quot;where\&quot;:{\&quot;something\&quot;:\&quot;value\&quot;}}&#x60;).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberFind(filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Member>>;
    public memberFind(filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Member>>>;
    public memberFind(filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Member>>>;
    public memberFind(filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Member>>(`${this.basePath}/members`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a model instance by {{id}} from the data source.
     * 
     * @param id Model id
     * @param filter Filter defining fields and include - must be a JSON-encoded string ({\&quot;something\&quot;:\&quot;value\&quot;})
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberFindById(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Member>;
    public memberFindById(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Member>>;
    public memberFindById(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Member>>;
    public memberFindById(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberFindById.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Member>(`${this.basePath}/members/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find first instance of the model matched by filter from the data source.
     * 
     * @param filter Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (&#x60;{\&quot;where\&quot;:{\&quot;something\&quot;:\&quot;value\&quot;}}&#x60;).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberFindOne(filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Member>;
    public memberFindOne(filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Member>>;
    public memberFindOne(filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Member>>;
    public memberFindOne(filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Member>(`${this.basePath}/members/findOne`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberGenerateAdminToken(id: string, observe?: 'body', reportProgress?: boolean): Observable<Member>;
    public memberGenerateAdminToken(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Member>>;
    public memberGenerateAdminToken(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Member>>;
    public memberGenerateAdminToken(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberGenerateAdminToken.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.post<Member>(`${this.basePath}/members/${encodeURIComponent(String(id))}/generateAdminToken`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberGenerateEternalToken(id: string, observe?: 'body', reportProgress?: boolean): Observable<AccessToken>;
    public memberGenerateEternalToken(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AccessToken>>;
    public memberGenerateEternalToken(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AccessToken>>;
    public memberGenerateEternalToken(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberGenerateEternalToken.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.post<AccessToken>(`${this.basePath}/members/${encodeURIComponent(String(id))}/generateEternalToken`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Login a user with username/email and password.
     * 
     * @param credentials 
     * @param include Related objects to include in the response. See the description of return value for more details.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberLogin(credentials: any, include?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberLogin(credentials: any, include?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberLogin(credentials: any, include?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberLogin(credentials: any, include?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (credentials === null || credentials === undefined) {
            throw new Error('Required parameter credentials was null or undefined when calling memberLogin.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (include !== undefined && include !== null) {
            queryParameters = queryParameters.set('include', <any>include);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/members/login`,
            credentials,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Logout a user with access token.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberLogout(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberLogout(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberLogout(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberLogout(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.post<any>(`${this.basePath}/members/logout`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patch an existing model instance or insert a new one into the data source.
     * 
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPatchOrCreate(data?: Member, observe?: 'body', reportProgress?: boolean): Observable<Member>;
    public memberPatchOrCreate(data?: Member, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Member>>;
    public memberPatchOrCreate(data?: Member, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Member>>;
    public memberPatchOrCreate(data?: Member, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<Member>(`${this.basePath}/members`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts accessTokens of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountAccessTokens(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountAccessTokens(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountAccessTokens(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountAccessTokens(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountAccessTokens.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/accessTokens/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts access_tokens of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountAccessTokens_1(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountAccessTokens_1(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountAccessTokens_1(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountAccessTokens_1(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountAccessTokens_1.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/access_tokens/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts analytics of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountAnalytics(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountAnalytics(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountAnalytics(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountAnalytics(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountAnalytics.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/analytics/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts articles of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountArticles(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountArticles(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountArticles(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountArticles(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountArticles.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/articles/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts bugReports of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountBugReports(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountBugReports(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountBugReports(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountBugReports(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountBugReports.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/bugReports/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts clients of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountClients(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountClients(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountClients(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountClients(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountClients.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/clients/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts contactRequests of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountContactRequests(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountContactRequests(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountContactRequests(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountContactRequests(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountContactRequests.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/contactRequests/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts devices of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountDevices(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountDevices(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountDevices(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountDevices(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountDevices.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/devices/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts favouriteAgencies of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountFavouriteAgencies(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountFavouriteAgencies(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountFavouriteAgencies(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountFavouriteAgencies(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountFavouriteAgencies.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteAgencies/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts favouriteAgents of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountFavouriteAgents(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountFavouriteAgents(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountFavouriteAgents(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountFavouriteAgents(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountFavouriteAgents.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteAgents/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts favouriteArticles of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountFavouriteArticles(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountFavouriteArticles(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountFavouriteArticles(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountFavouriteArticles(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountFavouriteArticles.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteArticles/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts favouriteProducts of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountFavouriteProducts(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountFavouriteProducts(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountFavouriteProducts(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountFavouriteProducts(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountFavouriteProducts.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteProducts/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts favouriteProfessionists of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountFavouriteProfessionists(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountFavouriteProfessionists(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountFavouriteProfessionists(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountFavouriteProfessionists(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountFavouriteProfessionists.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteProfessionists/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts generic_requests of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountGenericRequests(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountGenericRequests(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountGenericRequests(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountGenericRequests(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountGenericRequests.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/generic_requests/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts helpfulReview of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountHelpfulReview(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountHelpfulReview(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountHelpfulReview(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountHelpfulReview(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountHelpfulReview.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/helpfulReview/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts likedAgencies of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountLikedAgencies(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountLikedAgencies(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountLikedAgencies(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountLikedAgencies(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountLikedAgencies.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedAgencies/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts likedAgents of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountLikedAgents(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountLikedAgents(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountLikedAgents(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountLikedAgents(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountLikedAgents.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedAgents/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts likedArticle of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountLikedArticle(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountLikedArticle(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountLikedArticle(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountLikedArticle(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountLikedArticle.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedArticle/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts likedBrand of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountLikedBrand(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountLikedBrand(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountLikedBrand(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountLikedBrand(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountLikedBrand.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedBrand/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts likedListing of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountLikedListing(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountLikedListing(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountLikedListing(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountLikedListing(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountLikedListing.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedListing/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts likedProducts of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountLikedProducts(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountLikedProducts(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountLikedProducts(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountLikedProducts(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountLikedProducts.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedProducts/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts likedProfessionists of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountLikedProfessionists(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountLikedProfessionists(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountLikedProfessionists(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountLikedProfessionists(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountLikedProfessionists.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedProfessionists/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts notes of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountNotes(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountNotes(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountNotes(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountNotes(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountNotes.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/notes/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts reports of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountReports(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountReports(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountReports(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountReports(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountReports.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/reports/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts reviews of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountReviews(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountReviews(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountReviews(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountReviews(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountReviews.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/reviews/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts roles of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountRoles(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountRoles(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountRoles(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountRoles(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountRoles.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/roles/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts savedListings of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountSavedListings(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountSavedListings(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountSavedListings(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountSavedListings(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountSavedListings.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/savedListings/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts userSearches of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountUserSearches(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountUserSearches(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountUserSearches(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountUserSearches(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountUserSearches.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/userSearches/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts visitedAgencies of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountVisitedAgencies(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountVisitedAgencies(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountVisitedAgencies(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountVisitedAgencies(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountVisitedAgencies.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedAgencies/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts visitedAgents of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountVisitedAgents(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountVisitedAgents(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountVisitedAgents(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountVisitedAgents(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountVisitedAgents.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedAgents/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts visitedListings of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountVisitedListings(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountVisitedListings(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountVisitedListings(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountVisitedListings(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountVisitedListings.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedListings/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts visitedProfessionists of Member.
     * 
     * @param id Member id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCountVisitedProfessionists(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public memberPrototypeCountVisitedProfessionists(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public memberPrototypeCountVisitedProfessionists(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public memberPrototypeCountVisitedProfessionists(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCountVisitedProfessionists.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedProfessionists/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in accessTokens of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateAccessTokens(id: string, data?: AccessToken, observe?: 'body', reportProgress?: boolean): Observable<AccessToken>;
    public memberPrototypeCreateAccessTokens(id: string, data?: AccessToken, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AccessToken>>;
    public memberPrototypeCreateAccessTokens(id: string, data?: AccessToken, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AccessToken>>;
    public memberPrototypeCreateAccessTokens(id: string, data?: AccessToken, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateAccessTokens.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AccessToken>(`${this.basePath}/members/${encodeURIComponent(String(id))}/accessTokens`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in access_tokens of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateAccessTokens_2(id: string, data?: AccessToken, observe?: 'body', reportProgress?: boolean): Observable<AccessToken>;
    public memberPrototypeCreateAccessTokens_2(id: string, data?: AccessToken, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AccessToken>>;
    public memberPrototypeCreateAccessTokens_2(id: string, data?: AccessToken, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AccessToken>>;
    public memberPrototypeCreateAccessTokens_2(id: string, data?: AccessToken, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateAccessTokens_2.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AccessToken>(`${this.basePath}/members/${encodeURIComponent(String(id))}/access_tokens`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in agent of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateAgent(id: string, data?: Agent, observe?: 'body', reportProgress?: boolean): Observable<Agent>;
    public memberPrototypeCreateAgent(id: string, data?: Agent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agent>>;
    public memberPrototypeCreateAgent(id: string, data?: Agent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agent>>;
    public memberPrototypeCreateAgent(id: string, data?: Agent, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateAgent.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Agent>(`${this.basePath}/members/${encodeURIComponent(String(id))}/agent`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in analytics of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateAnalytics(id: string, data?: Analytics, observe?: 'body', reportProgress?: boolean): Observable<Analytics>;
    public memberPrototypeCreateAnalytics(id: string, data?: Analytics, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Analytics>>;
    public memberPrototypeCreateAnalytics(id: string, data?: Analytics, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Analytics>>;
    public memberPrototypeCreateAnalytics(id: string, data?: Analytics, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateAnalytics.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Analytics>(`${this.basePath}/members/${encodeURIComponent(String(id))}/analytics`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in articles of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateArticles(id: string, data?: Article, observe?: 'body', reportProgress?: boolean): Observable<Article>;
    public memberPrototypeCreateArticles(id: string, data?: Article, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Article>>;
    public memberPrototypeCreateArticles(id: string, data?: Article, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Article>>;
    public memberPrototypeCreateArticles(id: string, data?: Article, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateArticles.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Article>(`${this.basePath}/members/${encodeURIComponent(String(id))}/articles`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in bugReports of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateBugReports(id: string, data?: BugReport, observe?: 'body', reportProgress?: boolean): Observable<BugReport>;
    public memberPrototypeCreateBugReports(id: string, data?: BugReport, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BugReport>>;
    public memberPrototypeCreateBugReports(id: string, data?: BugReport, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BugReport>>;
    public memberPrototypeCreateBugReports(id: string, data?: BugReport, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateBugReports.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BugReport>(`${this.basePath}/members/${encodeURIComponent(String(id))}/bugReports`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in clients of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateClients(id: string, data?: Client, observe?: 'body', reportProgress?: boolean): Observable<Client>;
    public memberPrototypeCreateClients(id: string, data?: Client, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Client>>;
    public memberPrototypeCreateClients(id: string, data?: Client, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Client>>;
    public memberPrototypeCreateClients(id: string, data?: Client, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateClients.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Client>(`${this.basePath}/members/${encodeURIComponent(String(id))}/clients`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in contactRequests of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateContactRequests(id: string, data?: ContactRequest, observe?: 'body', reportProgress?: boolean): Observable<ContactRequest>;
    public memberPrototypeCreateContactRequests(id: string, data?: ContactRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContactRequest>>;
    public memberPrototypeCreateContactRequests(id: string, data?: ContactRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContactRequest>>;
    public memberPrototypeCreateContactRequests(id: string, data?: ContactRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateContactRequests.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ContactRequest>(`${this.basePath}/members/${encodeURIComponent(String(id))}/contactRequests`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in devices of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateDevices(id: string, data?: Device, observe?: 'body', reportProgress?: boolean): Observable<Device>;
    public memberPrototypeCreateDevices(id: string, data?: Device, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Device>>;
    public memberPrototypeCreateDevices(id: string, data?: Device, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Device>>;
    public memberPrototypeCreateDevices(id: string, data?: Device, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateDevices.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Device>(`${this.basePath}/members/${encodeURIComponent(String(id))}/devices`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in favouriteAgencies of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateFavouriteAgencies(id: string, data?: Agency, observe?: 'body', reportProgress?: boolean): Observable<Agency>;
    public memberPrototypeCreateFavouriteAgencies(id: string, data?: Agency, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agency>>;
    public memberPrototypeCreateFavouriteAgencies(id: string, data?: Agency, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agency>>;
    public memberPrototypeCreateFavouriteAgencies(id: string, data?: Agency, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateFavouriteAgencies.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Agency>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteAgencies`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in favouriteAgents of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateFavouriteAgents(id: string, data?: Agent, observe?: 'body', reportProgress?: boolean): Observable<Agent>;
    public memberPrototypeCreateFavouriteAgents(id: string, data?: Agent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agent>>;
    public memberPrototypeCreateFavouriteAgents(id: string, data?: Agent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agent>>;
    public memberPrototypeCreateFavouriteAgents(id: string, data?: Agent, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateFavouriteAgents.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Agent>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteAgents`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in favouriteArticles of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateFavouriteArticles(id: string, data?: Article, observe?: 'body', reportProgress?: boolean): Observable<Article>;
    public memberPrototypeCreateFavouriteArticles(id: string, data?: Article, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Article>>;
    public memberPrototypeCreateFavouriteArticles(id: string, data?: Article, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Article>>;
    public memberPrototypeCreateFavouriteArticles(id: string, data?: Article, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateFavouriteArticles.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Article>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteArticles`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in favouriteProducts of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateFavouriteProducts(id: string, data?: Product, observe?: 'body', reportProgress?: boolean): Observable<Product>;
    public memberPrototypeCreateFavouriteProducts(id: string, data?: Product, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Product>>;
    public memberPrototypeCreateFavouriteProducts(id: string, data?: Product, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Product>>;
    public memberPrototypeCreateFavouriteProducts(id: string, data?: Product, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateFavouriteProducts.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Product>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteProducts`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in favouriteProfessionists of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateFavouriteProfessionists(id: string, data?: Professionist, observe?: 'body', reportProgress?: boolean): Observable<Professionist>;
    public memberPrototypeCreateFavouriteProfessionists(id: string, data?: Professionist, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Professionist>>;
    public memberPrototypeCreateFavouriteProfessionists(id: string, data?: Professionist, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Professionist>>;
    public memberPrototypeCreateFavouriteProfessionists(id: string, data?: Professionist, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateFavouriteProfessionists.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Professionist>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteProfessionists`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in generic_requests of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateGenericRequests(id: string, data?: GenericRequest, observe?: 'body', reportProgress?: boolean): Observable<GenericRequest>;
    public memberPrototypeCreateGenericRequests(id: string, data?: GenericRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericRequest>>;
    public memberPrototypeCreateGenericRequests(id: string, data?: GenericRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericRequest>>;
    public memberPrototypeCreateGenericRequests(id: string, data?: GenericRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateGenericRequests.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericRequest>(`${this.basePath}/members/${encodeURIComponent(String(id))}/generic_requests`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in helpfulReview of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateHelpfulReview(id: string, data?: Review, observe?: 'body', reportProgress?: boolean): Observable<Review>;
    public memberPrototypeCreateHelpfulReview(id: string, data?: Review, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Review>>;
    public memberPrototypeCreateHelpfulReview(id: string, data?: Review, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Review>>;
    public memberPrototypeCreateHelpfulReview(id: string, data?: Review, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateHelpfulReview.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Review>(`${this.basePath}/members/${encodeURIComponent(String(id))}/helpfulReview`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in likedAgencies of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateLikedAgencies(id: string, data?: Agency, observe?: 'body', reportProgress?: boolean): Observable<Agency>;
    public memberPrototypeCreateLikedAgencies(id: string, data?: Agency, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agency>>;
    public memberPrototypeCreateLikedAgencies(id: string, data?: Agency, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agency>>;
    public memberPrototypeCreateLikedAgencies(id: string, data?: Agency, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateLikedAgencies.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Agency>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedAgencies`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in likedAgents of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateLikedAgents(id: string, data?: Agent, observe?: 'body', reportProgress?: boolean): Observable<Agent>;
    public memberPrototypeCreateLikedAgents(id: string, data?: Agent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agent>>;
    public memberPrototypeCreateLikedAgents(id: string, data?: Agent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agent>>;
    public memberPrototypeCreateLikedAgents(id: string, data?: Agent, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateLikedAgents.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Agent>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedAgents`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in likedArticle of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateLikedArticle(id: string, data?: Article, observe?: 'body', reportProgress?: boolean): Observable<Article>;
    public memberPrototypeCreateLikedArticle(id: string, data?: Article, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Article>>;
    public memberPrototypeCreateLikedArticle(id: string, data?: Article, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Article>>;
    public memberPrototypeCreateLikedArticle(id: string, data?: Article, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateLikedArticle.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Article>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedArticle`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in likedBrand of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateLikedBrand(id: string, data?: Brand, observe?: 'body', reportProgress?: boolean): Observable<Brand>;
    public memberPrototypeCreateLikedBrand(id: string, data?: Brand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Brand>>;
    public memberPrototypeCreateLikedBrand(id: string, data?: Brand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Brand>>;
    public memberPrototypeCreateLikedBrand(id: string, data?: Brand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateLikedBrand.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Brand>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedBrand`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in likedListing of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateLikedListing(id: string, data?: Listing, observe?: 'body', reportProgress?: boolean): Observable<Listing>;
    public memberPrototypeCreateLikedListing(id: string, data?: Listing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Listing>>;
    public memberPrototypeCreateLikedListing(id: string, data?: Listing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Listing>>;
    public memberPrototypeCreateLikedListing(id: string, data?: Listing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateLikedListing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Listing>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedListing`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in likedProducts of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateLikedProducts(id: string, data?: Product, observe?: 'body', reportProgress?: boolean): Observable<Product>;
    public memberPrototypeCreateLikedProducts(id: string, data?: Product, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Product>>;
    public memberPrototypeCreateLikedProducts(id: string, data?: Product, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Product>>;
    public memberPrototypeCreateLikedProducts(id: string, data?: Product, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateLikedProducts.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Product>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedProducts`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in likedProfessionists of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateLikedProfessionists(id: string, data?: Professionist, observe?: 'body', reportProgress?: boolean): Observable<Professionist>;
    public memberPrototypeCreateLikedProfessionists(id: string, data?: Professionist, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Professionist>>;
    public memberPrototypeCreateLikedProfessionists(id: string, data?: Professionist, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Professionist>>;
    public memberPrototypeCreateLikedProfessionists(id: string, data?: Professionist, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateLikedProfessionists.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Professionist>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedProfessionists`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in notes of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateNotes(id: string, data?: Note, observe?: 'body', reportProgress?: boolean): Observable<Note>;
    public memberPrototypeCreateNotes(id: string, data?: Note, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Note>>;
    public memberPrototypeCreateNotes(id: string, data?: Note, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Note>>;
    public memberPrototypeCreateNotes(id: string, data?: Note, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateNotes.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Note>(`${this.basePath}/members/${encodeURIComponent(String(id))}/notes`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in partner of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreatePartner(id: string, data?: Partner, observe?: 'body', reportProgress?: boolean): Observable<Partner>;
    public memberPrototypeCreatePartner(id: string, data?: Partner, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Partner>>;
    public memberPrototypeCreatePartner(id: string, data?: Partner, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Partner>>;
    public memberPrototypeCreatePartner(id: string, data?: Partner, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreatePartner.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Partner>(`${this.basePath}/members/${encodeURIComponent(String(id))}/partner`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in professionist of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateProfessionist(id: string, data?: Professionist, observe?: 'body', reportProgress?: boolean): Observable<Professionist>;
    public memberPrototypeCreateProfessionist(id: string, data?: Professionist, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Professionist>>;
    public memberPrototypeCreateProfessionist(id: string, data?: Professionist, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Professionist>>;
    public memberPrototypeCreateProfessionist(id: string, data?: Professionist, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateProfessionist.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Professionist>(`${this.basePath}/members/${encodeURIComponent(String(id))}/professionist`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in reports of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateReports(id: string, data?: Report, observe?: 'body', reportProgress?: boolean): Observable<Report>;
    public memberPrototypeCreateReports(id: string, data?: Report, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Report>>;
    public memberPrototypeCreateReports(id: string, data?: Report, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Report>>;
    public memberPrototypeCreateReports(id: string, data?: Report, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateReports.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Report>(`${this.basePath}/members/${encodeURIComponent(String(id))}/reports`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in reviews of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateReviews(id: string, data?: Review, observe?: 'body', reportProgress?: boolean): Observable<Review>;
    public memberPrototypeCreateReviews(id: string, data?: Review, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Review>>;
    public memberPrototypeCreateReviews(id: string, data?: Review, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Review>>;
    public memberPrototypeCreateReviews(id: string, data?: Review, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateReviews.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Review>(`${this.basePath}/members/${encodeURIComponent(String(id))}/reviews`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in roles of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateRoles(id: string, data?: Role, observe?: 'body', reportProgress?: boolean): Observable<Role>;
    public memberPrototypeCreateRoles(id: string, data?: Role, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Role>>;
    public memberPrototypeCreateRoles(id: string, data?: Role, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Role>>;
    public memberPrototypeCreateRoles(id: string, data?: Role, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateRoles.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Role>(`${this.basePath}/members/${encodeURIComponent(String(id))}/roles`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in savedListings of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateSavedListings(id: string, data?: Listing, observe?: 'body', reportProgress?: boolean): Observable<Listing>;
    public memberPrototypeCreateSavedListings(id: string, data?: Listing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Listing>>;
    public memberPrototypeCreateSavedListings(id: string, data?: Listing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Listing>>;
    public memberPrototypeCreateSavedListings(id: string, data?: Listing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateSavedListings.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Listing>(`${this.basePath}/members/${encodeURIComponent(String(id))}/savedListings`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in userSearches of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateUserSearches(id: string, data?: UserSearch, observe?: 'body', reportProgress?: boolean): Observable<UserSearch>;
    public memberPrototypeCreateUserSearches(id: string, data?: UserSearch, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserSearch>>;
    public memberPrototypeCreateUserSearches(id: string, data?: UserSearch, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserSearch>>;
    public memberPrototypeCreateUserSearches(id: string, data?: UserSearch, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateUserSearches.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserSearch>(`${this.basePath}/members/${encodeURIComponent(String(id))}/userSearches`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in visitedAgencies of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateVisitedAgencies(id: string, data?: Agency, observe?: 'body', reportProgress?: boolean): Observable<Agency>;
    public memberPrototypeCreateVisitedAgencies(id: string, data?: Agency, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agency>>;
    public memberPrototypeCreateVisitedAgencies(id: string, data?: Agency, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agency>>;
    public memberPrototypeCreateVisitedAgencies(id: string, data?: Agency, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateVisitedAgencies.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Agency>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedAgencies`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in visitedAgents of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateVisitedAgents(id: string, data?: Agent, observe?: 'body', reportProgress?: boolean): Observable<Agent>;
    public memberPrototypeCreateVisitedAgents(id: string, data?: Agent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agent>>;
    public memberPrototypeCreateVisitedAgents(id: string, data?: Agent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agent>>;
    public memberPrototypeCreateVisitedAgents(id: string, data?: Agent, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateVisitedAgents.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Agent>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedAgents`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in visitedListings of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateVisitedListings(id: string, data?: Listing, observe?: 'body', reportProgress?: boolean): Observable<Listing>;
    public memberPrototypeCreateVisitedListings(id: string, data?: Listing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Listing>>;
    public memberPrototypeCreateVisitedListings(id: string, data?: Listing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Listing>>;
    public memberPrototypeCreateVisitedListings(id: string, data?: Listing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateVisitedListings.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Listing>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedListings`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in visitedProfessionists of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeCreateVisitedProfessionists(id: string, data?: Professionist, observe?: 'body', reportProgress?: boolean): Observable<Professionist>;
    public memberPrototypeCreateVisitedProfessionists(id: string, data?: Professionist, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Professionist>>;
    public memberPrototypeCreateVisitedProfessionists(id: string, data?: Professionist, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Professionist>>;
    public memberPrototypeCreateVisitedProfessionists(id: string, data?: Professionist, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeCreateVisitedProfessionists.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Professionist>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedProfessionists`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all accessTokens of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteAccessTokens(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteAccessTokens(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteAccessTokens(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteAccessTokens(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteAccessTokens.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/accessTokens`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all access_tokens of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteAccessTokens_3(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteAccessTokens_3(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteAccessTokens_3(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteAccessTokens_3(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteAccessTokens_3.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/access_tokens`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all analytics of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteAnalytics(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteAnalytics(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteAnalytics(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteAnalytics(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteAnalytics.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/analytics`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all articles of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteArticles(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteArticles(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteArticles(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteArticles(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteArticles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/articles`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all bugReports of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteBugReports(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteBugReports(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteBugReports(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteBugReports(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteBugReports.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/bugReports`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all clients of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteClients(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteClients(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteClients(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteClients(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteClients.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/clients`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all contactRequests of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteContactRequests(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteContactRequests(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteContactRequests(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteContactRequests(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteContactRequests.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/contactRequests`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all devices of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteDevices(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteDevices(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteDevices(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteDevices(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteDevices.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/devices`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all favouriteAgencies of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteFavouriteAgencies(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteFavouriteAgencies(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteFavouriteAgencies(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteFavouriteAgencies(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteFavouriteAgencies.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteAgencies`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all favouriteAgents of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteFavouriteAgents(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteFavouriteAgents(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteFavouriteAgents(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteFavouriteAgents(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteFavouriteAgents.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteAgents`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all favouriteArticles of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteFavouriteArticles(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteFavouriteArticles(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteFavouriteArticles(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteFavouriteArticles(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteFavouriteArticles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteArticles`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all favouriteProducts of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteFavouriteProducts(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteFavouriteProducts(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteFavouriteProducts(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteFavouriteProducts(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteFavouriteProducts.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteProducts`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all favouriteProfessionists of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteFavouriteProfessionists(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteFavouriteProfessionists(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteFavouriteProfessionists(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteFavouriteProfessionists(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteFavouriteProfessionists.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteProfessionists`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all generic_requests of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteGenericRequests(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteGenericRequests(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteGenericRequests(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteGenericRequests(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteGenericRequests.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/generic_requests`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all helpfulReview of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteHelpfulReview(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteHelpfulReview(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteHelpfulReview(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteHelpfulReview(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteHelpfulReview.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/helpfulReview`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all likedAgencies of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteLikedAgencies(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteLikedAgencies(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteLikedAgencies(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteLikedAgencies(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteLikedAgencies.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedAgencies`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all likedAgents of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteLikedAgents(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteLikedAgents(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteLikedAgents(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteLikedAgents(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteLikedAgents.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedAgents`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all likedArticle of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteLikedArticle(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteLikedArticle(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteLikedArticle(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteLikedArticle(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteLikedArticle.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedArticle`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all likedBrand of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteLikedBrand(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteLikedBrand(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteLikedBrand(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteLikedBrand(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteLikedBrand.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedBrand`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all likedListing of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteLikedListing(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteLikedListing(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteLikedListing(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteLikedListing(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteLikedListing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedListing`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all likedProducts of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteLikedProducts(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteLikedProducts(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteLikedProducts(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteLikedProducts(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteLikedProducts.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedProducts`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all likedProfessionists of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteLikedProfessionists(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteLikedProfessionists(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteLikedProfessionists(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteLikedProfessionists(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteLikedProfessionists.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedProfessionists`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all notes of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteNotes(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteNotes(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteNotes(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteNotes(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteNotes.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/notes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all reports of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteReports(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteReports(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteReports(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteReports(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteReports.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/reports`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all reviews of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteReviews(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteReviews(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteReviews(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteReviews(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteReviews.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/reviews`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all roles of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteRoles(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteRoles(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteRoles(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteRoles(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteRoles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/roles`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all savedListings of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteSavedListings(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteSavedListings(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteSavedListings(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteSavedListings(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteSavedListings.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/savedListings`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all userSearches of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteUserSearches(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteUserSearches(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteUserSearches(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteUserSearches(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteUserSearches.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/userSearches`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all visitedAgencies of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteVisitedAgencies(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteVisitedAgencies(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteVisitedAgencies(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteVisitedAgencies(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteVisitedAgencies.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedAgencies`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all visitedAgents of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteVisitedAgents(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteVisitedAgents(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteVisitedAgents(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteVisitedAgents(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteVisitedAgents.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedAgents`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all visitedListings of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteVisitedListings(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteVisitedListings(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteVisitedListings(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteVisitedListings(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteVisitedListings.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedListings`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all visitedProfessionists of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDeleteVisitedProfessionists(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDeleteVisitedProfessionists(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDeleteVisitedProfessionists(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDeleteVisitedProfessionists(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDeleteVisitedProfessionists.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedProfessionists`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes agent of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyAgent(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyAgent(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyAgent(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyAgent(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyAgent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/agent`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for accessTokens.
     * 
     * @param id Member id
     * @param fk Foreign key for accessTokens
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdAccessTokens(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdAccessTokens(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdAccessTokens(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdAccessTokens(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdAccessTokens.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdAccessTokens.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/accessTokens/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for access_tokens.
     * 
     * @param id Member id
     * @param fk Foreign key for access_tokens
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdAccessTokens_4(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdAccessTokens_4(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdAccessTokens_4(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdAccessTokens_4(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdAccessTokens_4.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdAccessTokens_4.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/access_tokens/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for analytics.
     * 
     * @param id Member id
     * @param fk Foreign key for analytics
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdAnalytics(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdAnalytics(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdAnalytics(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdAnalytics(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdAnalytics.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdAnalytics.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/analytics/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for articles.
     * 
     * @param id Member id
     * @param fk Foreign key for articles
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdArticles(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdArticles(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdArticles(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdArticles(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdArticles.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdArticles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/articles/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for bugReports.
     * 
     * @param id Member id
     * @param fk Foreign key for bugReports
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdBugReports(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdBugReports(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdBugReports(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdBugReports(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdBugReports.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdBugReports.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/bugReports/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for clients.
     * 
     * @param id Member id
     * @param fk Foreign key for clients
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdClients(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdClients(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdClients(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdClients(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdClients.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdClients.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/clients/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for contactRequests.
     * 
     * @param id Member id
     * @param fk Foreign key for contactRequests
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdContactRequests(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdContactRequests(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdContactRequests(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdContactRequests(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdContactRequests.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdContactRequests.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/contactRequests/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for devices.
     * 
     * @param id Member id
     * @param fk Foreign key for devices
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdDevices(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdDevices(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdDevices(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdDevices(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdDevices.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdDevices.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/devices/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for favouriteAgencies.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteAgencies
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdFavouriteAgencies(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdFavouriteAgencies(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdFavouriteAgencies(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdFavouriteAgencies(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdFavouriteAgencies.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdFavouriteAgencies.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteAgencies/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for favouriteAgents.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteAgents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdFavouriteAgents(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdFavouriteAgents(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdFavouriteAgents(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdFavouriteAgents(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdFavouriteAgents.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdFavouriteAgents.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteAgents/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for favouriteArticles.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteArticles
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdFavouriteArticles(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdFavouriteArticles(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdFavouriteArticles(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdFavouriteArticles(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdFavouriteArticles.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdFavouriteArticles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteArticles/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for favouriteProducts.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteProducts
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdFavouriteProducts(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdFavouriteProducts(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdFavouriteProducts(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdFavouriteProducts(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdFavouriteProducts.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdFavouriteProducts.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteProducts/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for favouriteProfessionists.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteProfessionists
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdFavouriteProfessionists(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdFavouriteProfessionists(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdFavouriteProfessionists(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdFavouriteProfessionists(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdFavouriteProfessionists.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdFavouriteProfessionists.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteProfessionists/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for generic_requests.
     * 
     * @param id Member id
     * @param fk Foreign key for generic_requests
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdGenericRequests(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdGenericRequests(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdGenericRequests(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdGenericRequests(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdGenericRequests.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdGenericRequests.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/generic_requests/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for helpfulReview.
     * 
     * @param id Member id
     * @param fk Foreign key for helpfulReview
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdHelpfulReview(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdHelpfulReview(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdHelpfulReview(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdHelpfulReview(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdHelpfulReview.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdHelpfulReview.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/helpfulReview/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for likedAgencies.
     * 
     * @param id Member id
     * @param fk Foreign key for likedAgencies
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdLikedAgencies(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdLikedAgencies(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdLikedAgencies(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdLikedAgencies(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdLikedAgencies.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdLikedAgencies.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedAgencies/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for likedAgents.
     * 
     * @param id Member id
     * @param fk Foreign key for likedAgents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdLikedAgents(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdLikedAgents(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdLikedAgents(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdLikedAgents(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdLikedAgents.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdLikedAgents.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedAgents/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for likedArticle.
     * 
     * @param id Member id
     * @param fk Foreign key for likedArticle
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdLikedArticle(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdLikedArticle(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdLikedArticle(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdLikedArticle(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdLikedArticle.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdLikedArticle.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedArticle/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for likedBrand.
     * 
     * @param id Member id
     * @param fk Foreign key for likedBrand
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdLikedBrand(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdLikedBrand(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdLikedBrand(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdLikedBrand(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdLikedBrand.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdLikedBrand.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedBrand/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for likedListing.
     * 
     * @param id Member id
     * @param fk Foreign key for likedListing
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdLikedListing(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdLikedListing(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdLikedListing(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdLikedListing(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdLikedListing.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdLikedListing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedListing/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for likedProducts.
     * 
     * @param id Member id
     * @param fk Foreign key for likedProducts
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdLikedProducts(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdLikedProducts(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdLikedProducts(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdLikedProducts(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdLikedProducts.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdLikedProducts.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedProducts/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for likedProfessionists.
     * 
     * @param id Member id
     * @param fk Foreign key for likedProfessionists
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdLikedProfessionists(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdLikedProfessionists(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdLikedProfessionists(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdLikedProfessionists(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdLikedProfessionists.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdLikedProfessionists.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedProfessionists/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for notes.
     * 
     * @param id Member id
     * @param fk Foreign key for notes
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdNotes(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdNotes(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdNotes(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdNotes(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdNotes.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdNotes.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/notes/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for reports.
     * 
     * @param id Member id
     * @param fk Foreign key for reports
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdReports(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdReports(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdReports(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdReports(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdReports.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdReports.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/reports/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for reviews.
     * 
     * @param id Member id
     * @param fk Foreign key for reviews
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdReviews(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdReviews(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdReviews(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdReviews(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdReviews.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdReviews.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/reviews/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for roles.
     * 
     * @param id Member id
     * @param fk Foreign key for roles
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdRoles(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdRoles(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdRoles(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdRoles(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdRoles.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdRoles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/roles/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for savedListings.
     * 
     * @param id Member id
     * @param fk Foreign key for savedListings
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdSavedListings(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdSavedListings(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdSavedListings(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdSavedListings(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdSavedListings.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdSavedListings.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/savedListings/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for userSearches.
     * 
     * @param id Member id
     * @param fk Foreign key for userSearches
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdUserSearches(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdUserSearches(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdUserSearches(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdUserSearches(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdUserSearches.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdUserSearches.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/userSearches/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for visitedAgencies.
     * 
     * @param id Member id
     * @param fk Foreign key for visitedAgencies
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdVisitedAgencies(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdVisitedAgencies(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdVisitedAgencies(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdVisitedAgencies(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdVisitedAgencies.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdVisitedAgencies.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedAgencies/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for visitedAgents.
     * 
     * @param id Member id
     * @param fk Foreign key for visitedAgents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdVisitedAgents(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdVisitedAgents(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdVisitedAgents(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdVisitedAgents(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdVisitedAgents.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdVisitedAgents.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedAgents/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for visitedListings.
     * 
     * @param id Member id
     * @param fk Foreign key for visitedListings
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdVisitedListings(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdVisitedListings(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdVisitedListings(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdVisitedListings(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdVisitedListings.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdVisitedListings.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedListings/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for visitedProfessionists.
     * 
     * @param id Member id
     * @param fk Foreign key for visitedProfessionists
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyByIdVisitedProfessionists(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyByIdVisitedProfessionists(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyByIdVisitedProfessionists(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyByIdVisitedProfessionists(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyByIdVisitedProfessionists.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeDestroyByIdVisitedProfessionists.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedProfessionists/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes partner of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyPartner(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyPartner(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyPartner(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyPartner(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyPartner.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/partner`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes professionist of this model.
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeDestroyProfessionist(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeDestroyProfessionist(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeDestroyProfessionist(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeDestroyProfessionist(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeDestroyProfessionist.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/professionist`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of favouriteAgencies relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteAgencies
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeExistsFavouriteAgencies(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public memberPrototypeExistsFavouriteAgencies(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public memberPrototypeExistsFavouriteAgencies(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public memberPrototypeExistsFavouriteAgencies(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeExistsFavouriteAgencies.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeExistsFavouriteAgencies.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteAgencies/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of favouriteAgents relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteAgents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeExistsFavouriteAgents(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public memberPrototypeExistsFavouriteAgents(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public memberPrototypeExistsFavouriteAgents(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public memberPrototypeExistsFavouriteAgents(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeExistsFavouriteAgents.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeExistsFavouriteAgents.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteAgents/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of favouriteArticles relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteArticles
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeExistsFavouriteArticles(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public memberPrototypeExistsFavouriteArticles(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public memberPrototypeExistsFavouriteArticles(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public memberPrototypeExistsFavouriteArticles(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeExistsFavouriteArticles.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeExistsFavouriteArticles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteArticles/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of favouriteProducts relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteProducts
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeExistsFavouriteProducts(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public memberPrototypeExistsFavouriteProducts(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public memberPrototypeExistsFavouriteProducts(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public memberPrototypeExistsFavouriteProducts(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeExistsFavouriteProducts.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeExistsFavouriteProducts.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteProducts/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of favouriteProfessionists relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteProfessionists
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeExistsFavouriteProfessionists(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public memberPrototypeExistsFavouriteProfessionists(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public memberPrototypeExistsFavouriteProfessionists(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public memberPrototypeExistsFavouriteProfessionists(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeExistsFavouriteProfessionists.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeExistsFavouriteProfessionists.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteProfessionists/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of helpfulReview relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for helpfulReview
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeExistsHelpfulReview(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public memberPrototypeExistsHelpfulReview(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public memberPrototypeExistsHelpfulReview(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public memberPrototypeExistsHelpfulReview(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeExistsHelpfulReview.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeExistsHelpfulReview.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/members/${encodeURIComponent(String(id))}/helpfulReview/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of likedAgencies relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for likedAgencies
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeExistsLikedAgencies(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public memberPrototypeExistsLikedAgencies(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public memberPrototypeExistsLikedAgencies(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public memberPrototypeExistsLikedAgencies(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeExistsLikedAgencies.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeExistsLikedAgencies.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedAgencies/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of likedAgents relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for likedAgents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeExistsLikedAgents(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public memberPrototypeExistsLikedAgents(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public memberPrototypeExistsLikedAgents(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public memberPrototypeExistsLikedAgents(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeExistsLikedAgents.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeExistsLikedAgents.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedAgents/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of likedArticle relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for likedArticle
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeExistsLikedArticle(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public memberPrototypeExistsLikedArticle(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public memberPrototypeExistsLikedArticle(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public memberPrototypeExistsLikedArticle(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeExistsLikedArticle.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeExistsLikedArticle.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedArticle/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of likedBrand relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for likedBrand
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeExistsLikedBrand(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public memberPrototypeExistsLikedBrand(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public memberPrototypeExistsLikedBrand(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public memberPrototypeExistsLikedBrand(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeExistsLikedBrand.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeExistsLikedBrand.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedBrand/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of likedListing relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for likedListing
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeExistsLikedListing(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public memberPrototypeExistsLikedListing(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public memberPrototypeExistsLikedListing(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public memberPrototypeExistsLikedListing(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeExistsLikedListing.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeExistsLikedListing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedListing/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of likedProducts relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for likedProducts
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeExistsLikedProducts(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public memberPrototypeExistsLikedProducts(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public memberPrototypeExistsLikedProducts(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public memberPrototypeExistsLikedProducts(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeExistsLikedProducts.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeExistsLikedProducts.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedProducts/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of likedProfessionists relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for likedProfessionists
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeExistsLikedProfessionists(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public memberPrototypeExistsLikedProfessionists(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public memberPrototypeExistsLikedProfessionists(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public memberPrototypeExistsLikedProfessionists(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeExistsLikedProfessionists.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeExistsLikedProfessionists.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedProfessionists/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of roles relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for roles
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeExistsRoles(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public memberPrototypeExistsRoles(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public memberPrototypeExistsRoles(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public memberPrototypeExistsRoles(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeExistsRoles.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeExistsRoles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/members/${encodeURIComponent(String(id))}/roles/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of savedListings relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for savedListings
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeExistsSavedListings(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public memberPrototypeExistsSavedListings(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public memberPrototypeExistsSavedListings(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public memberPrototypeExistsSavedListings(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeExistsSavedListings.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeExistsSavedListings.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/members/${encodeURIComponent(String(id))}/savedListings/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of visitedAgencies relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for visitedAgencies
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeExistsVisitedAgencies(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public memberPrototypeExistsVisitedAgencies(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public memberPrototypeExistsVisitedAgencies(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public memberPrototypeExistsVisitedAgencies(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeExistsVisitedAgencies.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeExistsVisitedAgencies.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedAgencies/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of visitedAgents relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for visitedAgents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeExistsVisitedAgents(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public memberPrototypeExistsVisitedAgents(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public memberPrototypeExistsVisitedAgents(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public memberPrototypeExistsVisitedAgents(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeExistsVisitedAgents.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeExistsVisitedAgents.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedAgents/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of visitedListings relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for visitedListings
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeExistsVisitedListings(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public memberPrototypeExistsVisitedListings(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public memberPrototypeExistsVisitedListings(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public memberPrototypeExistsVisitedListings(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeExistsVisitedListings.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeExistsVisitedListings.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedListings/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of visitedProfessionists relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for visitedProfessionists
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeExistsVisitedProfessionists(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public memberPrototypeExistsVisitedProfessionists(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public memberPrototypeExistsVisitedProfessionists(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public memberPrototypeExistsVisitedProfessionists(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeExistsVisitedProfessionists.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeExistsVisitedProfessionists.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedProfessionists/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for accessTokens.
     * 
     * @param id Member id
     * @param fk Foreign key for accessTokens
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdAccessTokens(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<AccessToken>;
    public memberPrototypeFindByIdAccessTokens(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AccessToken>>;
    public memberPrototypeFindByIdAccessTokens(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AccessToken>>;
    public memberPrototypeFindByIdAccessTokens(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdAccessTokens.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdAccessTokens.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<AccessToken>(`${this.basePath}/members/${encodeURIComponent(String(id))}/accessTokens/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for access_tokens.
     * 
     * @param id Member id
     * @param fk Foreign key for access_tokens
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdAccessTokens_5(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<AccessToken>;
    public memberPrototypeFindByIdAccessTokens_5(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AccessToken>>;
    public memberPrototypeFindByIdAccessTokens_5(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AccessToken>>;
    public memberPrototypeFindByIdAccessTokens_5(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdAccessTokens_5.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdAccessTokens_5.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<AccessToken>(`${this.basePath}/members/${encodeURIComponent(String(id))}/access_tokens/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for analytics.
     * 
     * @param id Member id
     * @param fk Foreign key for analytics
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdAnalytics(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Analytics>;
    public memberPrototypeFindByIdAnalytics(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Analytics>>;
    public memberPrototypeFindByIdAnalytics(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Analytics>>;
    public memberPrototypeFindByIdAnalytics(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdAnalytics.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdAnalytics.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Analytics>(`${this.basePath}/members/${encodeURIComponent(String(id))}/analytics/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for articles.
     * 
     * @param id Member id
     * @param fk Foreign key for articles
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdArticles(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Article>;
    public memberPrototypeFindByIdArticles(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Article>>;
    public memberPrototypeFindByIdArticles(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Article>>;
    public memberPrototypeFindByIdArticles(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdArticles.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdArticles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Article>(`${this.basePath}/members/${encodeURIComponent(String(id))}/articles/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for bugReports.
     * 
     * @param id Member id
     * @param fk Foreign key for bugReports
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdBugReports(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<BugReport>;
    public memberPrototypeFindByIdBugReports(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BugReport>>;
    public memberPrototypeFindByIdBugReports(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BugReport>>;
    public memberPrototypeFindByIdBugReports(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdBugReports.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdBugReports.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<BugReport>(`${this.basePath}/members/${encodeURIComponent(String(id))}/bugReports/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for clients.
     * 
     * @param id Member id
     * @param fk Foreign key for clients
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdClients(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Client>;
    public memberPrototypeFindByIdClients(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Client>>;
    public memberPrototypeFindByIdClients(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Client>>;
    public memberPrototypeFindByIdClients(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdClients.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdClients.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Client>(`${this.basePath}/members/${encodeURIComponent(String(id))}/clients/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for contactRequests.
     * 
     * @param id Member id
     * @param fk Foreign key for contactRequests
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdContactRequests(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<ContactRequest>;
    public memberPrototypeFindByIdContactRequests(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContactRequest>>;
    public memberPrototypeFindByIdContactRequests(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContactRequest>>;
    public memberPrototypeFindByIdContactRequests(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdContactRequests.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdContactRequests.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<ContactRequest>(`${this.basePath}/members/${encodeURIComponent(String(id))}/contactRequests/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for devices.
     * 
     * @param id Member id
     * @param fk Foreign key for devices
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdDevices(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Device>;
    public memberPrototypeFindByIdDevices(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Device>>;
    public memberPrototypeFindByIdDevices(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Device>>;
    public memberPrototypeFindByIdDevices(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdDevices.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdDevices.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Device>(`${this.basePath}/members/${encodeURIComponent(String(id))}/devices/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for favouriteAgencies.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteAgencies
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdFavouriteAgencies(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Agency>;
    public memberPrototypeFindByIdFavouriteAgencies(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agency>>;
    public memberPrototypeFindByIdFavouriteAgencies(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agency>>;
    public memberPrototypeFindByIdFavouriteAgencies(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdFavouriteAgencies.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdFavouriteAgencies.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Agency>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteAgencies/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for favouriteAgents.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteAgents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdFavouriteAgents(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Agent>;
    public memberPrototypeFindByIdFavouriteAgents(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agent>>;
    public memberPrototypeFindByIdFavouriteAgents(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agent>>;
    public memberPrototypeFindByIdFavouriteAgents(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdFavouriteAgents.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdFavouriteAgents.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Agent>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteAgents/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for favouriteArticles.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteArticles
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdFavouriteArticles(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Article>;
    public memberPrototypeFindByIdFavouriteArticles(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Article>>;
    public memberPrototypeFindByIdFavouriteArticles(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Article>>;
    public memberPrototypeFindByIdFavouriteArticles(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdFavouriteArticles.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdFavouriteArticles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Article>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteArticles/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for favouriteProducts.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteProducts
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdFavouriteProducts(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Product>;
    public memberPrototypeFindByIdFavouriteProducts(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Product>>;
    public memberPrototypeFindByIdFavouriteProducts(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Product>>;
    public memberPrototypeFindByIdFavouriteProducts(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdFavouriteProducts.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdFavouriteProducts.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Product>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteProducts/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for favouriteProfessionists.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteProfessionists
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdFavouriteProfessionists(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Professionist>;
    public memberPrototypeFindByIdFavouriteProfessionists(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Professionist>>;
    public memberPrototypeFindByIdFavouriteProfessionists(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Professionist>>;
    public memberPrototypeFindByIdFavouriteProfessionists(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdFavouriteProfessionists.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdFavouriteProfessionists.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Professionist>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteProfessionists/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for generic_requests.
     * 
     * @param id Member id
     * @param fk Foreign key for generic_requests
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdGenericRequests(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<GenericRequest>;
    public memberPrototypeFindByIdGenericRequests(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericRequest>>;
    public memberPrototypeFindByIdGenericRequests(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericRequest>>;
    public memberPrototypeFindByIdGenericRequests(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdGenericRequests.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdGenericRequests.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<GenericRequest>(`${this.basePath}/members/${encodeURIComponent(String(id))}/generic_requests/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for helpfulReview.
     * 
     * @param id Member id
     * @param fk Foreign key for helpfulReview
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdHelpfulReview(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Review>;
    public memberPrototypeFindByIdHelpfulReview(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Review>>;
    public memberPrototypeFindByIdHelpfulReview(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Review>>;
    public memberPrototypeFindByIdHelpfulReview(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdHelpfulReview.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdHelpfulReview.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Review>(`${this.basePath}/members/${encodeURIComponent(String(id))}/helpfulReview/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for likedAgencies.
     * 
     * @param id Member id
     * @param fk Foreign key for likedAgencies
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdLikedAgencies(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Agency>;
    public memberPrototypeFindByIdLikedAgencies(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agency>>;
    public memberPrototypeFindByIdLikedAgencies(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agency>>;
    public memberPrototypeFindByIdLikedAgencies(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdLikedAgencies.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdLikedAgencies.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Agency>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedAgencies/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for likedAgents.
     * 
     * @param id Member id
     * @param fk Foreign key for likedAgents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdLikedAgents(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Agent>;
    public memberPrototypeFindByIdLikedAgents(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agent>>;
    public memberPrototypeFindByIdLikedAgents(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agent>>;
    public memberPrototypeFindByIdLikedAgents(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdLikedAgents.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdLikedAgents.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Agent>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedAgents/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for likedArticle.
     * 
     * @param id Member id
     * @param fk Foreign key for likedArticle
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdLikedArticle(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Article>;
    public memberPrototypeFindByIdLikedArticle(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Article>>;
    public memberPrototypeFindByIdLikedArticle(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Article>>;
    public memberPrototypeFindByIdLikedArticle(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdLikedArticle.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdLikedArticle.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Article>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedArticle/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for likedBrand.
     * 
     * @param id Member id
     * @param fk Foreign key for likedBrand
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdLikedBrand(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Brand>;
    public memberPrototypeFindByIdLikedBrand(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Brand>>;
    public memberPrototypeFindByIdLikedBrand(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Brand>>;
    public memberPrototypeFindByIdLikedBrand(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdLikedBrand.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdLikedBrand.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Brand>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedBrand/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for likedListing.
     * 
     * @param id Member id
     * @param fk Foreign key for likedListing
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdLikedListing(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Listing>;
    public memberPrototypeFindByIdLikedListing(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Listing>>;
    public memberPrototypeFindByIdLikedListing(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Listing>>;
    public memberPrototypeFindByIdLikedListing(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdLikedListing.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdLikedListing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Listing>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedListing/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for likedProducts.
     * 
     * @param id Member id
     * @param fk Foreign key for likedProducts
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdLikedProducts(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Product>;
    public memberPrototypeFindByIdLikedProducts(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Product>>;
    public memberPrototypeFindByIdLikedProducts(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Product>>;
    public memberPrototypeFindByIdLikedProducts(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdLikedProducts.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdLikedProducts.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Product>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedProducts/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for likedProfessionists.
     * 
     * @param id Member id
     * @param fk Foreign key for likedProfessionists
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdLikedProfessionists(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Professionist>;
    public memberPrototypeFindByIdLikedProfessionists(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Professionist>>;
    public memberPrototypeFindByIdLikedProfessionists(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Professionist>>;
    public memberPrototypeFindByIdLikedProfessionists(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdLikedProfessionists.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdLikedProfessionists.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Professionist>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedProfessionists/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for notes.
     * 
     * @param id Member id
     * @param fk Foreign key for notes
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdNotes(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Note>;
    public memberPrototypeFindByIdNotes(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Note>>;
    public memberPrototypeFindByIdNotes(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Note>>;
    public memberPrototypeFindByIdNotes(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdNotes.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdNotes.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Note>(`${this.basePath}/members/${encodeURIComponent(String(id))}/notes/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for reports.
     * 
     * @param id Member id
     * @param fk Foreign key for reports
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdReports(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Report>;
    public memberPrototypeFindByIdReports(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Report>>;
    public memberPrototypeFindByIdReports(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Report>>;
    public memberPrototypeFindByIdReports(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdReports.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdReports.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Report>(`${this.basePath}/members/${encodeURIComponent(String(id))}/reports/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for reviews.
     * 
     * @param id Member id
     * @param fk Foreign key for reviews
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdReviews(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Review>;
    public memberPrototypeFindByIdReviews(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Review>>;
    public memberPrototypeFindByIdReviews(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Review>>;
    public memberPrototypeFindByIdReviews(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdReviews.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdReviews.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Review>(`${this.basePath}/members/${encodeURIComponent(String(id))}/reviews/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for roles.
     * 
     * @param id Member id
     * @param fk Foreign key for roles
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdRoles(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Role>;
    public memberPrototypeFindByIdRoles(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Role>>;
    public memberPrototypeFindByIdRoles(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Role>>;
    public memberPrototypeFindByIdRoles(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdRoles.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdRoles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Role>(`${this.basePath}/members/${encodeURIComponent(String(id))}/roles/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for savedListings.
     * 
     * @param id Member id
     * @param fk Foreign key for savedListings
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdSavedListings(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Listing>;
    public memberPrototypeFindByIdSavedListings(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Listing>>;
    public memberPrototypeFindByIdSavedListings(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Listing>>;
    public memberPrototypeFindByIdSavedListings(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdSavedListings.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdSavedListings.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Listing>(`${this.basePath}/members/${encodeURIComponent(String(id))}/savedListings/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for userSearches.
     * 
     * @param id Member id
     * @param fk Foreign key for userSearches
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdUserSearches(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<UserSearch>;
    public memberPrototypeFindByIdUserSearches(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserSearch>>;
    public memberPrototypeFindByIdUserSearches(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserSearch>>;
    public memberPrototypeFindByIdUserSearches(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdUserSearches.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdUserSearches.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<UserSearch>(`${this.basePath}/members/${encodeURIComponent(String(id))}/userSearches/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for visitedAgencies.
     * 
     * @param id Member id
     * @param fk Foreign key for visitedAgencies
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdVisitedAgencies(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Agency>;
    public memberPrototypeFindByIdVisitedAgencies(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agency>>;
    public memberPrototypeFindByIdVisitedAgencies(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agency>>;
    public memberPrototypeFindByIdVisitedAgencies(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdVisitedAgencies.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdVisitedAgencies.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Agency>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedAgencies/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for visitedAgents.
     * 
     * @param id Member id
     * @param fk Foreign key for visitedAgents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdVisitedAgents(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Agent>;
    public memberPrototypeFindByIdVisitedAgents(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agent>>;
    public memberPrototypeFindByIdVisitedAgents(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agent>>;
    public memberPrototypeFindByIdVisitedAgents(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdVisitedAgents.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdVisitedAgents.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Agent>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedAgents/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for visitedListings.
     * 
     * @param id Member id
     * @param fk Foreign key for visitedListings
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdVisitedListings(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Listing>;
    public memberPrototypeFindByIdVisitedListings(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Listing>>;
    public memberPrototypeFindByIdVisitedListings(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Listing>>;
    public memberPrototypeFindByIdVisitedListings(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdVisitedListings.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdVisitedListings.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Listing>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedListings/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for visitedProfessionists.
     * 
     * @param id Member id
     * @param fk Foreign key for visitedProfessionists
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeFindByIdVisitedProfessionists(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Professionist>;
    public memberPrototypeFindByIdVisitedProfessionists(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Professionist>>;
    public memberPrototypeFindByIdVisitedProfessionists(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Professionist>>;
    public memberPrototypeFindByIdVisitedProfessionists(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeFindByIdVisitedProfessionists.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeFindByIdVisitedProfessionists.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Professionist>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedProfessionists/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries accessTokens of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetAccessTokens(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AccessToken>>;
    public memberPrototypeGetAccessTokens(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AccessToken>>>;
    public memberPrototypeGetAccessTokens(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AccessToken>>>;
    public memberPrototypeGetAccessTokens(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetAccessTokens.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<AccessToken>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/accessTokens`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries access_tokens of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetAccessTokens_6(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AccessToken>>;
    public memberPrototypeGetAccessTokens_6(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AccessToken>>>;
    public memberPrototypeGetAccessTokens_6(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AccessToken>>>;
    public memberPrototypeGetAccessTokens_6(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetAccessTokens_6.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<AccessToken>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/access_tokens`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetches hasOne relation agent.
     * 
     * @param id Member id
     * @param refresh 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetAgent(id: string, refresh?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Agent>;
    public memberPrototypeGetAgent(id: string, refresh?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agent>>;
    public memberPrototypeGetAgent(id: string, refresh?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agent>>;
    public memberPrototypeGetAgent(id: string, refresh?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetAgent.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (refresh !== undefined && refresh !== null) {
            queryParameters = queryParameters.set('refresh', <any>refresh);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Agent>(`${this.basePath}/members/${encodeURIComponent(String(id))}/agent`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries analytics of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetAnalytics(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Analytics>>;
    public memberPrototypeGetAnalytics(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Analytics>>>;
    public memberPrototypeGetAnalytics(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Analytics>>>;
    public memberPrototypeGetAnalytics(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetAnalytics.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Analytics>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/analytics`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries articles of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetArticles(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Article>>;
    public memberPrototypeGetArticles(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Article>>>;
    public memberPrototypeGetArticles(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Article>>>;
    public memberPrototypeGetArticles(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetArticles.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Article>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/articles`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries bugReports of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetBugReports(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<BugReport>>;
    public memberPrototypeGetBugReports(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BugReport>>>;
    public memberPrototypeGetBugReports(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BugReport>>>;
    public memberPrototypeGetBugReports(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetBugReports.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<BugReport>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/bugReports`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries clients of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetClients(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Client>>;
    public memberPrototypeGetClients(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Client>>>;
    public memberPrototypeGetClients(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Client>>>;
    public memberPrototypeGetClients(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetClients.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Client>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/clients`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries contactRequests of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetContactRequests(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ContactRequest>>;
    public memberPrototypeGetContactRequests(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ContactRequest>>>;
    public memberPrototypeGetContactRequests(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ContactRequest>>>;
    public memberPrototypeGetContactRequests(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetContactRequests.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<ContactRequest>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/contactRequests`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries devices of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetDevices(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Device>>;
    public memberPrototypeGetDevices(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Device>>>;
    public memberPrototypeGetDevices(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Device>>>;
    public memberPrototypeGetDevices(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetDevices.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Device>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/devices`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries favouriteAgencies of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetFavouriteAgencies(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Agency>>;
    public memberPrototypeGetFavouriteAgencies(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Agency>>>;
    public memberPrototypeGetFavouriteAgencies(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Agency>>>;
    public memberPrototypeGetFavouriteAgencies(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetFavouriteAgencies.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Agency>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteAgencies`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries favouriteAgents of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetFavouriteAgents(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Agent>>;
    public memberPrototypeGetFavouriteAgents(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Agent>>>;
    public memberPrototypeGetFavouriteAgents(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Agent>>>;
    public memberPrototypeGetFavouriteAgents(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetFavouriteAgents.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Agent>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteAgents`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries favouriteArticles of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetFavouriteArticles(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Article>>;
    public memberPrototypeGetFavouriteArticles(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Article>>>;
    public memberPrototypeGetFavouriteArticles(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Article>>>;
    public memberPrototypeGetFavouriteArticles(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetFavouriteArticles.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Article>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteArticles`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries favouriteProducts of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetFavouriteProducts(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Product>>;
    public memberPrototypeGetFavouriteProducts(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Product>>>;
    public memberPrototypeGetFavouriteProducts(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Product>>>;
    public memberPrototypeGetFavouriteProducts(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetFavouriteProducts.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Product>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteProducts`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries favouriteProfessionists of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetFavouriteProfessionists(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Professionist>>;
    public memberPrototypeGetFavouriteProfessionists(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Professionist>>>;
    public memberPrototypeGetFavouriteProfessionists(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Professionist>>>;
    public memberPrototypeGetFavouriteProfessionists(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetFavouriteProfessionists.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Professionist>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteProfessionists`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries generic_requests of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetGenericRequests(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<GenericRequest>>;
    public memberPrototypeGetGenericRequests(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GenericRequest>>>;
    public memberPrototypeGetGenericRequests(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GenericRequest>>>;
    public memberPrototypeGetGenericRequests(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetGenericRequests.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<GenericRequest>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/generic_requests`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries helpfulReview of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetHelpfulReview(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Review>>;
    public memberPrototypeGetHelpfulReview(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Review>>>;
    public memberPrototypeGetHelpfulReview(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Review>>>;
    public memberPrototypeGetHelpfulReview(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetHelpfulReview.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Review>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/helpfulReview`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries likedAgencies of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetLikedAgencies(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Agency>>;
    public memberPrototypeGetLikedAgencies(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Agency>>>;
    public memberPrototypeGetLikedAgencies(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Agency>>>;
    public memberPrototypeGetLikedAgencies(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetLikedAgencies.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Agency>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedAgencies`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries likedAgents of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetLikedAgents(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Agent>>;
    public memberPrototypeGetLikedAgents(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Agent>>>;
    public memberPrototypeGetLikedAgents(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Agent>>>;
    public memberPrototypeGetLikedAgents(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetLikedAgents.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Agent>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedAgents`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries likedArticle of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetLikedArticle(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Article>>;
    public memberPrototypeGetLikedArticle(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Article>>>;
    public memberPrototypeGetLikedArticle(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Article>>>;
    public memberPrototypeGetLikedArticle(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetLikedArticle.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Article>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedArticle`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries likedBrand of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetLikedBrand(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Brand>>;
    public memberPrototypeGetLikedBrand(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Brand>>>;
    public memberPrototypeGetLikedBrand(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Brand>>>;
    public memberPrototypeGetLikedBrand(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetLikedBrand.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Brand>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedBrand`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries likedListing of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetLikedListing(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Listing>>;
    public memberPrototypeGetLikedListing(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Listing>>>;
    public memberPrototypeGetLikedListing(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Listing>>>;
    public memberPrototypeGetLikedListing(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetLikedListing.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Listing>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedListing`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries likedProducts of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetLikedProducts(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Product>>;
    public memberPrototypeGetLikedProducts(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Product>>>;
    public memberPrototypeGetLikedProducts(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Product>>>;
    public memberPrototypeGetLikedProducts(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetLikedProducts.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Product>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedProducts`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries likedProfessionists of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetLikedProfessionists(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Professionist>>;
    public memberPrototypeGetLikedProfessionists(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Professionist>>>;
    public memberPrototypeGetLikedProfessionists(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Professionist>>>;
    public memberPrototypeGetLikedProfessionists(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetLikedProfessionists.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Professionist>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedProfessionists`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries notes of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetNotes(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Note>>;
    public memberPrototypeGetNotes(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Note>>>;
    public memberPrototypeGetNotes(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Note>>>;
    public memberPrototypeGetNotes(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetNotes.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Note>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/notes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetches hasOne relation partner.
     * 
     * @param id Member id
     * @param refresh 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetPartner(id: string, refresh?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Partner>;
    public memberPrototypeGetPartner(id: string, refresh?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Partner>>;
    public memberPrototypeGetPartner(id: string, refresh?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Partner>>;
    public memberPrototypeGetPartner(id: string, refresh?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetPartner.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (refresh !== undefined && refresh !== null) {
            queryParameters = queryParameters.set('refresh', <any>refresh);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Partner>(`${this.basePath}/members/${encodeURIComponent(String(id))}/partner`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetches hasOne relation professionist.
     * 
     * @param id Member id
     * @param refresh 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetProfessionist(id: string, refresh?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Professionist>;
    public memberPrototypeGetProfessionist(id: string, refresh?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Professionist>>;
    public memberPrototypeGetProfessionist(id: string, refresh?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Professionist>>;
    public memberPrototypeGetProfessionist(id: string, refresh?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetProfessionist.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (refresh !== undefined && refresh !== null) {
            queryParameters = queryParameters.set('refresh', <any>refresh);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Professionist>(`${this.basePath}/members/${encodeURIComponent(String(id))}/professionist`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries reports of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetReports(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Report>>;
    public memberPrototypeGetReports(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Report>>>;
    public memberPrototypeGetReports(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Report>>>;
    public memberPrototypeGetReports(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetReports.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Report>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/reports`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries reviews of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetReviews(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Review>>;
    public memberPrototypeGetReviews(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Review>>>;
    public memberPrototypeGetReviews(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Review>>>;
    public memberPrototypeGetReviews(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetReviews.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Review>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/reviews`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries roles of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetRoles(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Role>>;
    public memberPrototypeGetRoles(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Role>>>;
    public memberPrototypeGetRoles(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Role>>>;
    public memberPrototypeGetRoles(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetRoles.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Role>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/roles`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries savedListings of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetSavedListings(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Listing>>;
    public memberPrototypeGetSavedListings(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Listing>>>;
    public memberPrototypeGetSavedListings(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Listing>>>;
    public memberPrototypeGetSavedListings(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetSavedListings.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Listing>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/savedListings`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries userSearches of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetUserSearches(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<UserSearch>>;
    public memberPrototypeGetUserSearches(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserSearch>>>;
    public memberPrototypeGetUserSearches(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserSearch>>>;
    public memberPrototypeGetUserSearches(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetUserSearches.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<UserSearch>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/userSearches`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries visitedAgencies of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetVisitedAgencies(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Agency>>;
    public memberPrototypeGetVisitedAgencies(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Agency>>>;
    public memberPrototypeGetVisitedAgencies(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Agency>>>;
    public memberPrototypeGetVisitedAgencies(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetVisitedAgencies.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Agency>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedAgencies`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries visitedAgents of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetVisitedAgents(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Agent>>;
    public memberPrototypeGetVisitedAgents(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Agent>>>;
    public memberPrototypeGetVisitedAgents(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Agent>>>;
    public memberPrototypeGetVisitedAgents(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetVisitedAgents.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Agent>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedAgents`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries visitedListings of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetVisitedListings(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Listing>>;
    public memberPrototypeGetVisitedListings(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Listing>>>;
    public memberPrototypeGetVisitedListings(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Listing>>>;
    public memberPrototypeGetVisitedListings(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetVisitedListings.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Listing>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedListings`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries visitedProfessionists of Member.
     * 
     * @param id Member id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeGetVisitedProfessionists(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Professionist>>;
    public memberPrototypeGetVisitedProfessionists(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Professionist>>>;
    public memberPrototypeGetVisitedProfessionists(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Professionist>>>;
    public memberPrototypeGetVisitedProfessionists(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeGetVisitedProfessionists.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Professionist>>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedProfessionists`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for favouriteAgencies.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteAgencies
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeLinkFavouriteAgencies(id: string, fk: string, data?: FavouriteAgency, observe?: 'body', reportProgress?: boolean): Observable<FavouriteAgency>;
    public memberPrototypeLinkFavouriteAgencies(id: string, fk: string, data?: FavouriteAgency, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FavouriteAgency>>;
    public memberPrototypeLinkFavouriteAgencies(id: string, fk: string, data?: FavouriteAgency, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FavouriteAgency>>;
    public memberPrototypeLinkFavouriteAgencies(id: string, fk: string, data?: FavouriteAgency, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeLinkFavouriteAgencies.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeLinkFavouriteAgencies.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<FavouriteAgency>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteAgencies/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for favouriteAgents.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteAgents
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeLinkFavouriteAgents(id: string, fk: string, data?: FavouriteAgent, observe?: 'body', reportProgress?: boolean): Observable<FavouriteAgent>;
    public memberPrototypeLinkFavouriteAgents(id: string, fk: string, data?: FavouriteAgent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FavouriteAgent>>;
    public memberPrototypeLinkFavouriteAgents(id: string, fk: string, data?: FavouriteAgent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FavouriteAgent>>;
    public memberPrototypeLinkFavouriteAgents(id: string, fk: string, data?: FavouriteAgent, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeLinkFavouriteAgents.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeLinkFavouriteAgents.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<FavouriteAgent>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteAgents/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for favouriteArticles.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteArticles
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeLinkFavouriteArticles(id: string, fk: string, data?: FavouriteArticle, observe?: 'body', reportProgress?: boolean): Observable<FavouriteArticle>;
    public memberPrototypeLinkFavouriteArticles(id: string, fk: string, data?: FavouriteArticle, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FavouriteArticle>>;
    public memberPrototypeLinkFavouriteArticles(id: string, fk: string, data?: FavouriteArticle, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FavouriteArticle>>;
    public memberPrototypeLinkFavouriteArticles(id: string, fk: string, data?: FavouriteArticle, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeLinkFavouriteArticles.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeLinkFavouriteArticles.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<FavouriteArticle>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteArticles/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for favouriteProducts.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteProducts
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeLinkFavouriteProducts(id: string, fk: string, data?: FavouriteProduct, observe?: 'body', reportProgress?: boolean): Observable<FavouriteProduct>;
    public memberPrototypeLinkFavouriteProducts(id: string, fk: string, data?: FavouriteProduct, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FavouriteProduct>>;
    public memberPrototypeLinkFavouriteProducts(id: string, fk: string, data?: FavouriteProduct, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FavouriteProduct>>;
    public memberPrototypeLinkFavouriteProducts(id: string, fk: string, data?: FavouriteProduct, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeLinkFavouriteProducts.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeLinkFavouriteProducts.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<FavouriteProduct>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteProducts/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for favouriteProfessionists.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteProfessionists
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeLinkFavouriteProfessionists(id: string, fk: string, data?: FavouriteProfessionist, observe?: 'body', reportProgress?: boolean): Observable<FavouriteProfessionist>;
    public memberPrototypeLinkFavouriteProfessionists(id: string, fk: string, data?: FavouriteProfessionist, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FavouriteProfessionist>>;
    public memberPrototypeLinkFavouriteProfessionists(id: string, fk: string, data?: FavouriteProfessionist, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FavouriteProfessionist>>;
    public memberPrototypeLinkFavouriteProfessionists(id: string, fk: string, data?: FavouriteProfessionist, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeLinkFavouriteProfessionists.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeLinkFavouriteProfessionists.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<FavouriteProfessionist>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteProfessionists/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for helpfulReview.
     * 
     * @param id Member id
     * @param fk Foreign key for helpfulReview
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeLinkHelpfulReview(id: string, fk: string, data?: HelpfulReview, observe?: 'body', reportProgress?: boolean): Observable<HelpfulReview>;
    public memberPrototypeLinkHelpfulReview(id: string, fk: string, data?: HelpfulReview, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HelpfulReview>>;
    public memberPrototypeLinkHelpfulReview(id: string, fk: string, data?: HelpfulReview, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HelpfulReview>>;
    public memberPrototypeLinkHelpfulReview(id: string, fk: string, data?: HelpfulReview, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeLinkHelpfulReview.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeLinkHelpfulReview.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<HelpfulReview>(`${this.basePath}/members/${encodeURIComponent(String(id))}/helpfulReview/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for likedAgencies.
     * 
     * @param id Member id
     * @param fk Foreign key for likedAgencies
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeLinkLikedAgencies(id: string, fk: string, data?: LikedAgency, observe?: 'body', reportProgress?: boolean): Observable<LikedAgency>;
    public memberPrototypeLinkLikedAgencies(id: string, fk: string, data?: LikedAgency, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LikedAgency>>;
    public memberPrototypeLinkLikedAgencies(id: string, fk: string, data?: LikedAgency, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LikedAgency>>;
    public memberPrototypeLinkLikedAgencies(id: string, fk: string, data?: LikedAgency, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeLinkLikedAgencies.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeLinkLikedAgencies.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<LikedAgency>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedAgencies/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for likedAgents.
     * 
     * @param id Member id
     * @param fk Foreign key for likedAgents
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeLinkLikedAgents(id: string, fk: string, data?: LikedAgent, observe?: 'body', reportProgress?: boolean): Observable<LikedAgent>;
    public memberPrototypeLinkLikedAgents(id: string, fk: string, data?: LikedAgent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LikedAgent>>;
    public memberPrototypeLinkLikedAgents(id: string, fk: string, data?: LikedAgent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LikedAgent>>;
    public memberPrototypeLinkLikedAgents(id: string, fk: string, data?: LikedAgent, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeLinkLikedAgents.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeLinkLikedAgents.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<LikedAgent>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedAgents/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for likedArticle.
     * 
     * @param id Member id
     * @param fk Foreign key for likedArticle
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeLinkLikedArticle(id: string, fk: string, data?: LikedArticle, observe?: 'body', reportProgress?: boolean): Observable<LikedArticle>;
    public memberPrototypeLinkLikedArticle(id: string, fk: string, data?: LikedArticle, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LikedArticle>>;
    public memberPrototypeLinkLikedArticle(id: string, fk: string, data?: LikedArticle, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LikedArticle>>;
    public memberPrototypeLinkLikedArticle(id: string, fk: string, data?: LikedArticle, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeLinkLikedArticle.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeLinkLikedArticle.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<LikedArticle>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedArticle/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for likedBrand.
     * 
     * @param id Member id
     * @param fk Foreign key for likedBrand
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeLinkLikedBrand(id: string, fk: string, data?: LikedBrand, observe?: 'body', reportProgress?: boolean): Observable<LikedBrand>;
    public memberPrototypeLinkLikedBrand(id: string, fk: string, data?: LikedBrand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LikedBrand>>;
    public memberPrototypeLinkLikedBrand(id: string, fk: string, data?: LikedBrand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LikedBrand>>;
    public memberPrototypeLinkLikedBrand(id: string, fk: string, data?: LikedBrand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeLinkLikedBrand.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeLinkLikedBrand.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<LikedBrand>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedBrand/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for likedListing.
     * 
     * @param id Member id
     * @param fk Foreign key for likedListing
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeLinkLikedListing(id: string, fk: string, data?: LikedListing, observe?: 'body', reportProgress?: boolean): Observable<LikedListing>;
    public memberPrototypeLinkLikedListing(id: string, fk: string, data?: LikedListing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LikedListing>>;
    public memberPrototypeLinkLikedListing(id: string, fk: string, data?: LikedListing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LikedListing>>;
    public memberPrototypeLinkLikedListing(id: string, fk: string, data?: LikedListing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeLinkLikedListing.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeLinkLikedListing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<LikedListing>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedListing/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for likedProducts.
     * 
     * @param id Member id
     * @param fk Foreign key for likedProducts
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeLinkLikedProducts(id: string, fk: string, data?: LikedProduct, observe?: 'body', reportProgress?: boolean): Observable<LikedProduct>;
    public memberPrototypeLinkLikedProducts(id: string, fk: string, data?: LikedProduct, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LikedProduct>>;
    public memberPrototypeLinkLikedProducts(id: string, fk: string, data?: LikedProduct, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LikedProduct>>;
    public memberPrototypeLinkLikedProducts(id: string, fk: string, data?: LikedProduct, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeLinkLikedProducts.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeLinkLikedProducts.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<LikedProduct>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedProducts/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for likedProfessionists.
     * 
     * @param id Member id
     * @param fk Foreign key for likedProfessionists
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeLinkLikedProfessionists(id: string, fk: string, data?: LikedProfessionist, observe?: 'body', reportProgress?: boolean): Observable<LikedProfessionist>;
    public memberPrototypeLinkLikedProfessionists(id: string, fk: string, data?: LikedProfessionist, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LikedProfessionist>>;
    public memberPrototypeLinkLikedProfessionists(id: string, fk: string, data?: LikedProfessionist, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LikedProfessionist>>;
    public memberPrototypeLinkLikedProfessionists(id: string, fk: string, data?: LikedProfessionist, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeLinkLikedProfessionists.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeLinkLikedProfessionists.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<LikedProfessionist>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedProfessionists/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for roles.
     * 
     * @param id Member id
     * @param fk Foreign key for roles
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeLinkRoles(id: string, fk: string, data?: RoleMapping, observe?: 'body', reportProgress?: boolean): Observable<RoleMapping>;
    public memberPrototypeLinkRoles(id: string, fk: string, data?: RoleMapping, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RoleMapping>>;
    public memberPrototypeLinkRoles(id: string, fk: string, data?: RoleMapping, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RoleMapping>>;
    public memberPrototypeLinkRoles(id: string, fk: string, data?: RoleMapping, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeLinkRoles.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeLinkRoles.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<RoleMapping>(`${this.basePath}/members/${encodeURIComponent(String(id))}/roles/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for savedListings.
     * 
     * @param id Member id
     * @param fk Foreign key for savedListings
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeLinkSavedListings(id: string, fk: string, data?: SavedListing, observe?: 'body', reportProgress?: boolean): Observable<SavedListing>;
    public memberPrototypeLinkSavedListings(id: string, fk: string, data?: SavedListing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SavedListing>>;
    public memberPrototypeLinkSavedListings(id: string, fk: string, data?: SavedListing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SavedListing>>;
    public memberPrototypeLinkSavedListings(id: string, fk: string, data?: SavedListing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeLinkSavedListings.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeLinkSavedListings.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<SavedListing>(`${this.basePath}/members/${encodeURIComponent(String(id))}/savedListings/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for visitedAgencies.
     * 
     * @param id Member id
     * @param fk Foreign key for visitedAgencies
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeLinkVisitedAgencies(id: string, fk: string, data?: VisitedAgency, observe?: 'body', reportProgress?: boolean): Observable<VisitedAgency>;
    public memberPrototypeLinkVisitedAgencies(id: string, fk: string, data?: VisitedAgency, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VisitedAgency>>;
    public memberPrototypeLinkVisitedAgencies(id: string, fk: string, data?: VisitedAgency, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VisitedAgency>>;
    public memberPrototypeLinkVisitedAgencies(id: string, fk: string, data?: VisitedAgency, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeLinkVisitedAgencies.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeLinkVisitedAgencies.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<VisitedAgency>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedAgencies/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for visitedAgents.
     * 
     * @param id Member id
     * @param fk Foreign key for visitedAgents
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeLinkVisitedAgents(id: string, fk: string, data?: VisitedAgent, observe?: 'body', reportProgress?: boolean): Observable<VisitedAgent>;
    public memberPrototypeLinkVisitedAgents(id: string, fk: string, data?: VisitedAgent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VisitedAgent>>;
    public memberPrototypeLinkVisitedAgents(id: string, fk: string, data?: VisitedAgent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VisitedAgent>>;
    public memberPrototypeLinkVisitedAgents(id: string, fk: string, data?: VisitedAgent, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeLinkVisitedAgents.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeLinkVisitedAgents.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<VisitedAgent>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedAgents/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for visitedListings.
     * 
     * @param id Member id
     * @param fk Foreign key for visitedListings
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeLinkVisitedListings(id: string, fk: string, data?: VisitedListing, observe?: 'body', reportProgress?: boolean): Observable<VisitedListing>;
    public memberPrototypeLinkVisitedListings(id: string, fk: string, data?: VisitedListing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VisitedListing>>;
    public memberPrototypeLinkVisitedListings(id: string, fk: string, data?: VisitedListing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VisitedListing>>;
    public memberPrototypeLinkVisitedListings(id: string, fk: string, data?: VisitedListing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeLinkVisitedListings.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeLinkVisitedListings.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<VisitedListing>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedListings/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for visitedProfessionists.
     * 
     * @param id Member id
     * @param fk Foreign key for visitedProfessionists
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeLinkVisitedProfessionists(id: string, fk: string, data?: VisitedProfessionist, observe?: 'body', reportProgress?: boolean): Observable<VisitedProfessionist>;
    public memberPrototypeLinkVisitedProfessionists(id: string, fk: string, data?: VisitedProfessionist, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VisitedProfessionist>>;
    public memberPrototypeLinkVisitedProfessionists(id: string, fk: string, data?: VisitedProfessionist, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VisitedProfessionist>>;
    public memberPrototypeLinkVisitedProfessionists(id: string, fk: string, data?: VisitedProfessionist, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeLinkVisitedProfessionists.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeLinkVisitedProfessionists.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<VisitedProfessionist>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedProfessionists/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patch attributes for a model instance and persist it into the data source.
     * 
     * @param id Member id
     * @param data An object of model property name/value pairs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypePatchAttributes(id: string, data?: Member, observe?: 'body', reportProgress?: boolean): Observable<Member>;
    public memberPrototypePatchAttributes(id: string, data?: Member, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Member>>;
    public memberPrototypePatchAttributes(id: string, data?: Member, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Member>>;
    public memberPrototypePatchAttributes(id: string, data?: Member, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypePatchAttributes.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<Member>(`${this.basePath}/members/${encodeURIComponent(String(id))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the favouriteAgencies relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteAgencies
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUnlinkFavouriteAgencies(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeUnlinkFavouriteAgencies(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeUnlinkFavouriteAgencies(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeUnlinkFavouriteAgencies(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUnlinkFavouriteAgencies.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUnlinkFavouriteAgencies.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteAgencies/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the favouriteAgents relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteAgents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUnlinkFavouriteAgents(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeUnlinkFavouriteAgents(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeUnlinkFavouriteAgents(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeUnlinkFavouriteAgents(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUnlinkFavouriteAgents.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUnlinkFavouriteAgents.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteAgents/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the favouriteArticles relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteArticles
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUnlinkFavouriteArticles(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeUnlinkFavouriteArticles(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeUnlinkFavouriteArticles(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeUnlinkFavouriteArticles(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUnlinkFavouriteArticles.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUnlinkFavouriteArticles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteArticles/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the favouriteProducts relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteProducts
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUnlinkFavouriteProducts(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeUnlinkFavouriteProducts(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeUnlinkFavouriteProducts(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeUnlinkFavouriteProducts(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUnlinkFavouriteProducts.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUnlinkFavouriteProducts.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteProducts/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the favouriteProfessionists relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteProfessionists
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUnlinkFavouriteProfessionists(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeUnlinkFavouriteProfessionists(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeUnlinkFavouriteProfessionists(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeUnlinkFavouriteProfessionists(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUnlinkFavouriteProfessionists.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUnlinkFavouriteProfessionists.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteProfessionists/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the helpfulReview relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for helpfulReview
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUnlinkHelpfulReview(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeUnlinkHelpfulReview(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeUnlinkHelpfulReview(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeUnlinkHelpfulReview(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUnlinkHelpfulReview.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUnlinkHelpfulReview.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/helpfulReview/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the likedAgencies relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for likedAgencies
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUnlinkLikedAgencies(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeUnlinkLikedAgencies(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeUnlinkLikedAgencies(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeUnlinkLikedAgencies(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUnlinkLikedAgencies.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUnlinkLikedAgencies.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedAgencies/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the likedAgents relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for likedAgents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUnlinkLikedAgents(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeUnlinkLikedAgents(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeUnlinkLikedAgents(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeUnlinkLikedAgents(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUnlinkLikedAgents.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUnlinkLikedAgents.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedAgents/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the likedArticle relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for likedArticle
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUnlinkLikedArticle(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeUnlinkLikedArticle(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeUnlinkLikedArticle(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeUnlinkLikedArticle(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUnlinkLikedArticle.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUnlinkLikedArticle.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedArticle/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the likedBrand relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for likedBrand
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUnlinkLikedBrand(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeUnlinkLikedBrand(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeUnlinkLikedBrand(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeUnlinkLikedBrand(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUnlinkLikedBrand.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUnlinkLikedBrand.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedBrand/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the likedListing relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for likedListing
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUnlinkLikedListing(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeUnlinkLikedListing(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeUnlinkLikedListing(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeUnlinkLikedListing(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUnlinkLikedListing.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUnlinkLikedListing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedListing/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the likedProducts relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for likedProducts
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUnlinkLikedProducts(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeUnlinkLikedProducts(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeUnlinkLikedProducts(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeUnlinkLikedProducts(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUnlinkLikedProducts.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUnlinkLikedProducts.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedProducts/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the likedProfessionists relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for likedProfessionists
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUnlinkLikedProfessionists(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeUnlinkLikedProfessionists(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeUnlinkLikedProfessionists(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeUnlinkLikedProfessionists(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUnlinkLikedProfessionists.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUnlinkLikedProfessionists.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedProfessionists/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the roles relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for roles
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUnlinkRoles(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeUnlinkRoles(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeUnlinkRoles(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeUnlinkRoles(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUnlinkRoles.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUnlinkRoles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/roles/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the savedListings relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for savedListings
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUnlinkSavedListings(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeUnlinkSavedListings(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeUnlinkSavedListings(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeUnlinkSavedListings(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUnlinkSavedListings.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUnlinkSavedListings.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/savedListings/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the visitedAgencies relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for visitedAgencies
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUnlinkVisitedAgencies(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeUnlinkVisitedAgencies(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeUnlinkVisitedAgencies(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeUnlinkVisitedAgencies(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUnlinkVisitedAgencies.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUnlinkVisitedAgencies.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedAgencies/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the visitedAgents relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for visitedAgents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUnlinkVisitedAgents(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeUnlinkVisitedAgents(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeUnlinkVisitedAgents(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeUnlinkVisitedAgents(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUnlinkVisitedAgents.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUnlinkVisitedAgents.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedAgents/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the visitedListings relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for visitedListings
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUnlinkVisitedListings(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeUnlinkVisitedListings(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeUnlinkVisitedListings(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeUnlinkVisitedListings(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUnlinkVisitedListings.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUnlinkVisitedListings.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedListings/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the visitedProfessionists relation to an item by id.
     * 
     * @param id Member id
     * @param fk Foreign key for visitedProfessionists
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUnlinkVisitedProfessionists(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeUnlinkVisitedProfessionists(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeUnlinkVisitedProfessionists(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeUnlinkVisitedProfessionists(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUnlinkVisitedProfessionists.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUnlinkVisitedProfessionists.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedProfessionists/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update agent of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateAgent(id: string, data?: Agent, observe?: 'body', reportProgress?: boolean): Observable<Agent>;
    public memberPrototypeUpdateAgent(id: string, data?: Agent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agent>>;
    public memberPrototypeUpdateAgent(id: string, data?: Agent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agent>>;
    public memberPrototypeUpdateAgent(id: string, data?: Agent, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateAgent.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Agent>(`${this.basePath}/members/${encodeURIComponent(String(id))}/agent`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for accessTokens.
     * 
     * @param id Member id
     * @param fk Foreign key for accessTokens
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdAccessTokens(id: string, fk: string, data?: AccessToken, observe?: 'body', reportProgress?: boolean): Observable<AccessToken>;
    public memberPrototypeUpdateByIdAccessTokens(id: string, fk: string, data?: AccessToken, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AccessToken>>;
    public memberPrototypeUpdateByIdAccessTokens(id: string, fk: string, data?: AccessToken, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AccessToken>>;
    public memberPrototypeUpdateByIdAccessTokens(id: string, fk: string, data?: AccessToken, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdAccessTokens.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdAccessTokens.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<AccessToken>(`${this.basePath}/members/${encodeURIComponent(String(id))}/accessTokens/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for access_tokens.
     * 
     * @param id Member id
     * @param fk Foreign key for access_tokens
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdAccessTokens_7(id: string, fk: string, data?: AccessToken, observe?: 'body', reportProgress?: boolean): Observable<AccessToken>;
    public memberPrototypeUpdateByIdAccessTokens_7(id: string, fk: string, data?: AccessToken, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AccessToken>>;
    public memberPrototypeUpdateByIdAccessTokens_7(id: string, fk: string, data?: AccessToken, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AccessToken>>;
    public memberPrototypeUpdateByIdAccessTokens_7(id: string, fk: string, data?: AccessToken, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdAccessTokens_7.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdAccessTokens_7.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<AccessToken>(`${this.basePath}/members/${encodeURIComponent(String(id))}/access_tokens/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for analytics.
     * 
     * @param id Member id
     * @param fk Foreign key for analytics
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdAnalytics(id: string, fk: string, data?: Analytics, observe?: 'body', reportProgress?: boolean): Observable<Analytics>;
    public memberPrototypeUpdateByIdAnalytics(id: string, fk: string, data?: Analytics, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Analytics>>;
    public memberPrototypeUpdateByIdAnalytics(id: string, fk: string, data?: Analytics, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Analytics>>;
    public memberPrototypeUpdateByIdAnalytics(id: string, fk: string, data?: Analytics, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdAnalytics.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdAnalytics.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Analytics>(`${this.basePath}/members/${encodeURIComponent(String(id))}/analytics/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for articles.
     * 
     * @param id Member id
     * @param fk Foreign key for articles
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdArticles(id: string, fk: string, data?: Article, observe?: 'body', reportProgress?: boolean): Observable<Article>;
    public memberPrototypeUpdateByIdArticles(id: string, fk: string, data?: Article, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Article>>;
    public memberPrototypeUpdateByIdArticles(id: string, fk: string, data?: Article, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Article>>;
    public memberPrototypeUpdateByIdArticles(id: string, fk: string, data?: Article, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdArticles.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdArticles.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Article>(`${this.basePath}/members/${encodeURIComponent(String(id))}/articles/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for bugReports.
     * 
     * @param id Member id
     * @param fk Foreign key for bugReports
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdBugReports(id: string, fk: string, data?: BugReport, observe?: 'body', reportProgress?: boolean): Observable<BugReport>;
    public memberPrototypeUpdateByIdBugReports(id: string, fk: string, data?: BugReport, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BugReport>>;
    public memberPrototypeUpdateByIdBugReports(id: string, fk: string, data?: BugReport, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BugReport>>;
    public memberPrototypeUpdateByIdBugReports(id: string, fk: string, data?: BugReport, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdBugReports.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdBugReports.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<BugReport>(`${this.basePath}/members/${encodeURIComponent(String(id))}/bugReports/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for clients.
     * 
     * @param id Member id
     * @param fk Foreign key for clients
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdClients(id: string, fk: string, data?: Client, observe?: 'body', reportProgress?: boolean): Observable<Client>;
    public memberPrototypeUpdateByIdClients(id: string, fk: string, data?: Client, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Client>>;
    public memberPrototypeUpdateByIdClients(id: string, fk: string, data?: Client, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Client>>;
    public memberPrototypeUpdateByIdClients(id: string, fk: string, data?: Client, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdClients.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdClients.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Client>(`${this.basePath}/members/${encodeURIComponent(String(id))}/clients/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for contactRequests.
     * 
     * @param id Member id
     * @param fk Foreign key for contactRequests
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdContactRequests(id: string, fk: string, data?: ContactRequest, observe?: 'body', reportProgress?: boolean): Observable<ContactRequest>;
    public memberPrototypeUpdateByIdContactRequests(id: string, fk: string, data?: ContactRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContactRequest>>;
    public memberPrototypeUpdateByIdContactRequests(id: string, fk: string, data?: ContactRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContactRequest>>;
    public memberPrototypeUpdateByIdContactRequests(id: string, fk: string, data?: ContactRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdContactRequests.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdContactRequests.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ContactRequest>(`${this.basePath}/members/${encodeURIComponent(String(id))}/contactRequests/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for devices.
     * 
     * @param id Member id
     * @param fk Foreign key for devices
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdDevices(id: string, fk: string, data?: Device, observe?: 'body', reportProgress?: boolean): Observable<Device>;
    public memberPrototypeUpdateByIdDevices(id: string, fk: string, data?: Device, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Device>>;
    public memberPrototypeUpdateByIdDevices(id: string, fk: string, data?: Device, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Device>>;
    public memberPrototypeUpdateByIdDevices(id: string, fk: string, data?: Device, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdDevices.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdDevices.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Device>(`${this.basePath}/members/${encodeURIComponent(String(id))}/devices/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for favouriteAgencies.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteAgencies
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdFavouriteAgencies(id: string, fk: string, data?: Agency, observe?: 'body', reportProgress?: boolean): Observable<Agency>;
    public memberPrototypeUpdateByIdFavouriteAgencies(id: string, fk: string, data?: Agency, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agency>>;
    public memberPrototypeUpdateByIdFavouriteAgencies(id: string, fk: string, data?: Agency, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agency>>;
    public memberPrototypeUpdateByIdFavouriteAgencies(id: string, fk: string, data?: Agency, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdFavouriteAgencies.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdFavouriteAgencies.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Agency>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteAgencies/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for favouriteAgents.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteAgents
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdFavouriteAgents(id: string, fk: string, data?: Agent, observe?: 'body', reportProgress?: boolean): Observable<Agent>;
    public memberPrototypeUpdateByIdFavouriteAgents(id: string, fk: string, data?: Agent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agent>>;
    public memberPrototypeUpdateByIdFavouriteAgents(id: string, fk: string, data?: Agent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agent>>;
    public memberPrototypeUpdateByIdFavouriteAgents(id: string, fk: string, data?: Agent, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdFavouriteAgents.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdFavouriteAgents.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Agent>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteAgents/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for favouriteArticles.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteArticles
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdFavouriteArticles(id: string, fk: string, data?: Article, observe?: 'body', reportProgress?: boolean): Observable<Article>;
    public memberPrototypeUpdateByIdFavouriteArticles(id: string, fk: string, data?: Article, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Article>>;
    public memberPrototypeUpdateByIdFavouriteArticles(id: string, fk: string, data?: Article, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Article>>;
    public memberPrototypeUpdateByIdFavouriteArticles(id: string, fk: string, data?: Article, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdFavouriteArticles.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdFavouriteArticles.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Article>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteArticles/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for favouriteProducts.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteProducts
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdFavouriteProducts(id: string, fk: string, data?: Product, observe?: 'body', reportProgress?: boolean): Observable<Product>;
    public memberPrototypeUpdateByIdFavouriteProducts(id: string, fk: string, data?: Product, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Product>>;
    public memberPrototypeUpdateByIdFavouriteProducts(id: string, fk: string, data?: Product, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Product>>;
    public memberPrototypeUpdateByIdFavouriteProducts(id: string, fk: string, data?: Product, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdFavouriteProducts.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdFavouriteProducts.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Product>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteProducts/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for favouriteProfessionists.
     * 
     * @param id Member id
     * @param fk Foreign key for favouriteProfessionists
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdFavouriteProfessionists(id: string, fk: string, data?: Professionist, observe?: 'body', reportProgress?: boolean): Observable<Professionist>;
    public memberPrototypeUpdateByIdFavouriteProfessionists(id: string, fk: string, data?: Professionist, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Professionist>>;
    public memberPrototypeUpdateByIdFavouriteProfessionists(id: string, fk: string, data?: Professionist, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Professionist>>;
    public memberPrototypeUpdateByIdFavouriteProfessionists(id: string, fk: string, data?: Professionist, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdFavouriteProfessionists.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdFavouriteProfessionists.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Professionist>(`${this.basePath}/members/${encodeURIComponent(String(id))}/favouriteProfessionists/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for generic_requests.
     * 
     * @param id Member id
     * @param fk Foreign key for generic_requests
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdGenericRequests(id: string, fk: string, data?: GenericRequest, observe?: 'body', reportProgress?: boolean): Observable<GenericRequest>;
    public memberPrototypeUpdateByIdGenericRequests(id: string, fk: string, data?: GenericRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericRequest>>;
    public memberPrototypeUpdateByIdGenericRequests(id: string, fk: string, data?: GenericRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericRequest>>;
    public memberPrototypeUpdateByIdGenericRequests(id: string, fk: string, data?: GenericRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdGenericRequests.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdGenericRequests.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<GenericRequest>(`${this.basePath}/members/${encodeURIComponent(String(id))}/generic_requests/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for helpfulReview.
     * 
     * @param id Member id
     * @param fk Foreign key for helpfulReview
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdHelpfulReview(id: string, fk: string, data?: Review, observe?: 'body', reportProgress?: boolean): Observable<Review>;
    public memberPrototypeUpdateByIdHelpfulReview(id: string, fk: string, data?: Review, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Review>>;
    public memberPrototypeUpdateByIdHelpfulReview(id: string, fk: string, data?: Review, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Review>>;
    public memberPrototypeUpdateByIdHelpfulReview(id: string, fk: string, data?: Review, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdHelpfulReview.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdHelpfulReview.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Review>(`${this.basePath}/members/${encodeURIComponent(String(id))}/helpfulReview/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for likedAgencies.
     * 
     * @param id Member id
     * @param fk Foreign key for likedAgencies
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdLikedAgencies(id: string, fk: string, data?: Agency, observe?: 'body', reportProgress?: boolean): Observable<Agency>;
    public memberPrototypeUpdateByIdLikedAgencies(id: string, fk: string, data?: Agency, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agency>>;
    public memberPrototypeUpdateByIdLikedAgencies(id: string, fk: string, data?: Agency, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agency>>;
    public memberPrototypeUpdateByIdLikedAgencies(id: string, fk: string, data?: Agency, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdLikedAgencies.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdLikedAgencies.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Agency>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedAgencies/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for likedAgents.
     * 
     * @param id Member id
     * @param fk Foreign key for likedAgents
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdLikedAgents(id: string, fk: string, data?: Agent, observe?: 'body', reportProgress?: boolean): Observable<Agent>;
    public memberPrototypeUpdateByIdLikedAgents(id: string, fk: string, data?: Agent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agent>>;
    public memberPrototypeUpdateByIdLikedAgents(id: string, fk: string, data?: Agent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agent>>;
    public memberPrototypeUpdateByIdLikedAgents(id: string, fk: string, data?: Agent, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdLikedAgents.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdLikedAgents.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Agent>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedAgents/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for likedArticle.
     * 
     * @param id Member id
     * @param fk Foreign key for likedArticle
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdLikedArticle(id: string, fk: string, data?: Article, observe?: 'body', reportProgress?: boolean): Observable<Article>;
    public memberPrototypeUpdateByIdLikedArticle(id: string, fk: string, data?: Article, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Article>>;
    public memberPrototypeUpdateByIdLikedArticle(id: string, fk: string, data?: Article, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Article>>;
    public memberPrototypeUpdateByIdLikedArticle(id: string, fk: string, data?: Article, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdLikedArticle.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdLikedArticle.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Article>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedArticle/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for likedBrand.
     * 
     * @param id Member id
     * @param fk Foreign key for likedBrand
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdLikedBrand(id: string, fk: string, data?: Brand, observe?: 'body', reportProgress?: boolean): Observable<Brand>;
    public memberPrototypeUpdateByIdLikedBrand(id: string, fk: string, data?: Brand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Brand>>;
    public memberPrototypeUpdateByIdLikedBrand(id: string, fk: string, data?: Brand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Brand>>;
    public memberPrototypeUpdateByIdLikedBrand(id: string, fk: string, data?: Brand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdLikedBrand.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdLikedBrand.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Brand>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedBrand/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for likedListing.
     * 
     * @param id Member id
     * @param fk Foreign key for likedListing
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdLikedListing(id: string, fk: string, data?: Listing, observe?: 'body', reportProgress?: boolean): Observable<Listing>;
    public memberPrototypeUpdateByIdLikedListing(id: string, fk: string, data?: Listing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Listing>>;
    public memberPrototypeUpdateByIdLikedListing(id: string, fk: string, data?: Listing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Listing>>;
    public memberPrototypeUpdateByIdLikedListing(id: string, fk: string, data?: Listing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdLikedListing.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdLikedListing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Listing>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedListing/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for likedProducts.
     * 
     * @param id Member id
     * @param fk Foreign key for likedProducts
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdLikedProducts(id: string, fk: string, data?: Product, observe?: 'body', reportProgress?: boolean): Observable<Product>;
    public memberPrototypeUpdateByIdLikedProducts(id: string, fk: string, data?: Product, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Product>>;
    public memberPrototypeUpdateByIdLikedProducts(id: string, fk: string, data?: Product, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Product>>;
    public memberPrototypeUpdateByIdLikedProducts(id: string, fk: string, data?: Product, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdLikedProducts.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdLikedProducts.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Product>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedProducts/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for likedProfessionists.
     * 
     * @param id Member id
     * @param fk Foreign key for likedProfessionists
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdLikedProfessionists(id: string, fk: string, data?: Professionist, observe?: 'body', reportProgress?: boolean): Observable<Professionist>;
    public memberPrototypeUpdateByIdLikedProfessionists(id: string, fk: string, data?: Professionist, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Professionist>>;
    public memberPrototypeUpdateByIdLikedProfessionists(id: string, fk: string, data?: Professionist, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Professionist>>;
    public memberPrototypeUpdateByIdLikedProfessionists(id: string, fk: string, data?: Professionist, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdLikedProfessionists.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdLikedProfessionists.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Professionist>(`${this.basePath}/members/${encodeURIComponent(String(id))}/likedProfessionists/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for notes.
     * 
     * @param id Member id
     * @param fk Foreign key for notes
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdNotes(id: string, fk: string, data?: Note, observe?: 'body', reportProgress?: boolean): Observable<Note>;
    public memberPrototypeUpdateByIdNotes(id: string, fk: string, data?: Note, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Note>>;
    public memberPrototypeUpdateByIdNotes(id: string, fk: string, data?: Note, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Note>>;
    public memberPrototypeUpdateByIdNotes(id: string, fk: string, data?: Note, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdNotes.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdNotes.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Note>(`${this.basePath}/members/${encodeURIComponent(String(id))}/notes/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for reports.
     * 
     * @param id Member id
     * @param fk Foreign key for reports
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdReports(id: string, fk: string, data?: Report, observe?: 'body', reportProgress?: boolean): Observable<Report>;
    public memberPrototypeUpdateByIdReports(id: string, fk: string, data?: Report, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Report>>;
    public memberPrototypeUpdateByIdReports(id: string, fk: string, data?: Report, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Report>>;
    public memberPrototypeUpdateByIdReports(id: string, fk: string, data?: Report, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdReports.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdReports.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Report>(`${this.basePath}/members/${encodeURIComponent(String(id))}/reports/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for reviews.
     * 
     * @param id Member id
     * @param fk Foreign key for reviews
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdReviews(id: string, fk: string, data?: Review, observe?: 'body', reportProgress?: boolean): Observable<Review>;
    public memberPrototypeUpdateByIdReviews(id: string, fk: string, data?: Review, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Review>>;
    public memberPrototypeUpdateByIdReviews(id: string, fk: string, data?: Review, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Review>>;
    public memberPrototypeUpdateByIdReviews(id: string, fk: string, data?: Review, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdReviews.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdReviews.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Review>(`${this.basePath}/members/${encodeURIComponent(String(id))}/reviews/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for roles.
     * 
     * @param id Member id
     * @param fk Foreign key for roles
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdRoles(id: string, fk: string, data?: Role, observe?: 'body', reportProgress?: boolean): Observable<Role>;
    public memberPrototypeUpdateByIdRoles(id: string, fk: string, data?: Role, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Role>>;
    public memberPrototypeUpdateByIdRoles(id: string, fk: string, data?: Role, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Role>>;
    public memberPrototypeUpdateByIdRoles(id: string, fk: string, data?: Role, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdRoles.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdRoles.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Role>(`${this.basePath}/members/${encodeURIComponent(String(id))}/roles/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for savedListings.
     * 
     * @param id Member id
     * @param fk Foreign key for savedListings
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdSavedListings(id: string, fk: string, data?: Listing, observe?: 'body', reportProgress?: boolean): Observable<Listing>;
    public memberPrototypeUpdateByIdSavedListings(id: string, fk: string, data?: Listing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Listing>>;
    public memberPrototypeUpdateByIdSavedListings(id: string, fk: string, data?: Listing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Listing>>;
    public memberPrototypeUpdateByIdSavedListings(id: string, fk: string, data?: Listing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdSavedListings.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdSavedListings.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Listing>(`${this.basePath}/members/${encodeURIComponent(String(id))}/savedListings/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for userSearches.
     * 
     * @param id Member id
     * @param fk Foreign key for userSearches
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdUserSearches(id: string, fk: string, data?: UserSearch, observe?: 'body', reportProgress?: boolean): Observable<UserSearch>;
    public memberPrototypeUpdateByIdUserSearches(id: string, fk: string, data?: UserSearch, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserSearch>>;
    public memberPrototypeUpdateByIdUserSearches(id: string, fk: string, data?: UserSearch, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserSearch>>;
    public memberPrototypeUpdateByIdUserSearches(id: string, fk: string, data?: UserSearch, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdUserSearches.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdUserSearches.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<UserSearch>(`${this.basePath}/members/${encodeURIComponent(String(id))}/userSearches/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for visitedAgencies.
     * 
     * @param id Member id
     * @param fk Foreign key for visitedAgencies
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdVisitedAgencies(id: string, fk: string, data?: Agency, observe?: 'body', reportProgress?: boolean): Observable<Agency>;
    public memberPrototypeUpdateByIdVisitedAgencies(id: string, fk: string, data?: Agency, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agency>>;
    public memberPrototypeUpdateByIdVisitedAgencies(id: string, fk: string, data?: Agency, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agency>>;
    public memberPrototypeUpdateByIdVisitedAgencies(id: string, fk: string, data?: Agency, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdVisitedAgencies.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdVisitedAgencies.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Agency>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedAgencies/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for visitedAgents.
     * 
     * @param id Member id
     * @param fk Foreign key for visitedAgents
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdVisitedAgents(id: string, fk: string, data?: Agent, observe?: 'body', reportProgress?: boolean): Observable<Agent>;
    public memberPrototypeUpdateByIdVisitedAgents(id: string, fk: string, data?: Agent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agent>>;
    public memberPrototypeUpdateByIdVisitedAgents(id: string, fk: string, data?: Agent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agent>>;
    public memberPrototypeUpdateByIdVisitedAgents(id: string, fk: string, data?: Agent, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdVisitedAgents.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdVisitedAgents.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Agent>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedAgents/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for visitedListings.
     * 
     * @param id Member id
     * @param fk Foreign key for visitedListings
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdVisitedListings(id: string, fk: string, data?: Listing, observe?: 'body', reportProgress?: boolean): Observable<Listing>;
    public memberPrototypeUpdateByIdVisitedListings(id: string, fk: string, data?: Listing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Listing>>;
    public memberPrototypeUpdateByIdVisitedListings(id: string, fk: string, data?: Listing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Listing>>;
    public memberPrototypeUpdateByIdVisitedListings(id: string, fk: string, data?: Listing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdVisitedListings.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdVisitedListings.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Listing>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedListings/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for visitedProfessionists.
     * 
     * @param id Member id
     * @param fk Foreign key for visitedProfessionists
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateByIdVisitedProfessionists(id: string, fk: string, data?: Professionist, observe?: 'body', reportProgress?: boolean): Observable<Professionist>;
    public memberPrototypeUpdateByIdVisitedProfessionists(id: string, fk: string, data?: Professionist, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Professionist>>;
    public memberPrototypeUpdateByIdVisitedProfessionists(id: string, fk: string, data?: Professionist, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Professionist>>;
    public memberPrototypeUpdateByIdVisitedProfessionists(id: string, fk: string, data?: Professionist, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateByIdVisitedProfessionists.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling memberPrototypeUpdateByIdVisitedProfessionists.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Professionist>(`${this.basePath}/members/${encodeURIComponent(String(id))}/visitedProfessionists/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update partner of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdatePartner(id: string, data?: Partner, observe?: 'body', reportProgress?: boolean): Observable<Partner>;
    public memberPrototypeUpdatePartner(id: string, data?: Partner, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Partner>>;
    public memberPrototypeUpdatePartner(id: string, data?: Partner, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Partner>>;
    public memberPrototypeUpdatePartner(id: string, data?: Partner, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdatePartner.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Partner>(`${this.basePath}/members/${encodeURIComponent(String(id))}/partner`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update professionist of this model.
     * 
     * @param id Member id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeUpdateProfessionist(id: string, data?: Professionist, observe?: 'body', reportProgress?: boolean): Observable<Professionist>;
    public memberPrototypeUpdateProfessionist(id: string, data?: Professionist, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Professionist>>;
    public memberPrototypeUpdateProfessionist(id: string, data?: Professionist, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Professionist>>;
    public memberPrototypeUpdateProfessionist(id: string, data?: Professionist, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeUpdateProfessionist.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Professionist>(`${this.basePath}/members/${encodeURIComponent(String(id))}/professionist`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Trigger user&#39;s identity verification with configured verifyOptions
     * 
     * @param id Member id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberPrototypeVerify(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberPrototypeVerify(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberPrototypeVerify(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberPrototypeVerify(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberPrototypeVerify.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.post<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/verify`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace attributes for a model instance and persist it into the data source.
     * 
     * @param id Model id
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberReplaceByIdPostMembersidReplace(id: string, data?: Member, observe?: 'body', reportProgress?: boolean): Observable<Member>;
    public memberReplaceByIdPostMembersidReplace(id: string, data?: Member, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Member>>;
    public memberReplaceByIdPostMembersidReplace(id: string, data?: Member, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Member>>;
    public memberReplaceByIdPostMembersidReplace(id: string, data?: Member, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberReplaceByIdPostMembersidReplace.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Member>(`${this.basePath}/members/${encodeURIComponent(String(id))}/replace`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace attributes for a model instance and persist it into the data source.
     * 
     * @param id Model id
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberReplaceByIdPutMembersid(id: string, data?: Member, observe?: 'body', reportProgress?: boolean): Observable<Member>;
    public memberReplaceByIdPutMembersid(id: string, data?: Member, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Member>>;
    public memberReplaceByIdPutMembersid(id: string, data?: Member, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Member>>;
    public memberReplaceByIdPutMembersid(id: string, data?: Member, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberReplaceByIdPutMembersid.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Member>(`${this.basePath}/members/${encodeURIComponent(String(id))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace an existing model instance or insert a new one into the data source.
     * 
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberReplaceOrCreatePostMembersReplaceOrCreate(data?: Member, observe?: 'body', reportProgress?: boolean): Observable<Member>;
    public memberReplaceOrCreatePostMembersReplaceOrCreate(data?: Member, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Member>>;
    public memberReplaceOrCreatePostMembersReplaceOrCreate(data?: Member, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Member>>;
    public memberReplaceOrCreatePostMembersReplaceOrCreate(data?: Member, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Member>(`${this.basePath}/members/replaceOrCreate`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace an existing model instance or insert a new one into the data source.
     * 
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberReplaceOrCreatePutMembers(data?: Member, observe?: 'body', reportProgress?: boolean): Observable<Member>;
    public memberReplaceOrCreatePutMembers(data?: Member, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Member>>;
    public memberReplaceOrCreatePutMembers(data?: Member, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Member>>;
    public memberReplaceOrCreatePutMembers(data?: Member, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Member>(`${this.basePath}/members`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberRequestDeletionAccount(id: string, observe?: 'body', reportProgress?: boolean): Observable<Member>;
    public memberRequestDeletionAccount(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Member>>;
    public memberRequestDeletionAccount(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Member>>;
    public memberRequestDeletionAccount(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberRequestDeletionAccount.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.post<Member>(`${this.basePath}/members/${encodeURIComponent(String(id))}/requestDeletionAccount`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reset password for a user with email.
     * 
     * @param options 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberResetPassword(options: any, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberResetPassword(options: any, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberResetPassword(options: any, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberResetPassword(options: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (options === null || options === undefined) {
            throw new Error('Required parameter options was null or undefined when calling memberResetPassword.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/members/reset`,
            options,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param codiceFiscale 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberReverseCodiceFiscale(codiceFiscale: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberReverseCodiceFiscale(codiceFiscale: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberReverseCodiceFiscale(codiceFiscale: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberReverseCodiceFiscale(codiceFiscale: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (codiceFiscale === null || codiceFiscale === undefined) {
            throw new Error('Required parameter codiceFiscale was null or undefined when calling memberReverseCodiceFiscale.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (codiceFiscale !== undefined && codiceFiscale !== null) {
            queryParameters = queryParameters.set('codiceFiscale', <any>codiceFiscale);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<any>(`${this.basePath}/members/reverseCodiceFiscale`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberSendEmailInvite(id: string, observe?: 'body', reportProgress?: boolean): Observable<Member>;
    public memberSendEmailInvite(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Member>>;
    public memberSendEmailInvite(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Member>>;
    public memberSendEmailInvite(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberSendEmailInvite.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.post<Member>(`${this.basePath}/members/${encodeURIComponent(String(id))}/sendEmailInvite`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param title 
     * @param body 
     * @param deepLink 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberSendNotification(id: string, title: string, body: string, deepLink?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberSendNotification(id: string, title: string, body: string, deepLink?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberSendNotification(id: string, title: string, body: string, deepLink?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberSendNotification(id: string, title: string, body: string, deepLink?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberSendNotification.');
        }

        if (title === null || title === undefined) {
            throw new Error('Required parameter title was null or undefined when calling memberSendNotification.');
        }

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling memberSendNotification.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (title !== undefined && title !== null) {
            queryParameters = queryParameters.set('title', <any>title);
        }
        if (body !== undefined && body !== null) {
            queryParameters = queryParameters.set('body', <any>body);
        }
        if (deepLink !== undefined && deepLink !== null) {
            queryParameters = queryParameters.set('deepLink', <any>deepLink);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<any>(`${this.basePath}/members/${encodeURIComponent(String(id))}/sendNotification`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberSetCommonPassword(id: string, observe?: 'body', reportProgress?: boolean): Observable<Member>;
    public memberSetCommonPassword(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Member>>;
    public memberSetCommonPassword(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Member>>;
    public memberSetCommonPassword(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberSetCommonPassword.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.post<Member>(`${this.basePath}/members/${encodeURIComponent(String(id))}/setCommonPassword`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reset user&#39;s password via a password-reset token.
     * 
     * @param newPassword 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberSetPassword(newPassword: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public memberSetPassword(newPassword: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public memberSetPassword(newPassword: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public memberSetPassword(newPassword: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (newPassword === null || newPassword === undefined) {
            throw new Error('Required parameter newPassword was null or undefined when calling memberSetPassword.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (newPassword !== undefined) {
            formParams.append('newPassword', <any>newPassword);
        }

        return this.httpClient.post<any>(`${this.basePath}/members/reset-password`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update instances of the model matched by {{where}} from the data source.
     * 
     * @param where Criteria to match model instances
     * @param data An object of model property name/value pairs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberUpdateAll(where?: string, data?: Member, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2002>;
    public memberUpdateAll(where?: string, data?: Member, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2002>>;
    public memberUpdateAll(where?: string, data?: Member, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2002>>;
    public memberUpdateAll(where?: string, data?: Member, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse2002>(`${this.basePath}/members/update`,
            data,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberUploadProfileImage(id: string, observe?: 'body', reportProgress?: boolean): Observable<Member>;
    public memberUploadProfileImage(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Member>>;
    public memberUploadProfileImage(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Member>>;
    public memberUploadProfileImage(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling memberUploadProfileImage.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.post<Member>(`${this.basePath}/members/${encodeURIComponent(String(id))}/uploadProfileImage`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an existing model instance or insert a new one into the data source based on the where criteria.
     * 
     * @param where Criteria to match model instances
     * @param data An object of model property name/value pairs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public memberUpsertWithWhere(where?: string, data?: Member, observe?: 'body', reportProgress?: boolean): Observable<Member>;
    public memberUpsertWithWhere(where?: string, data?: Member, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Member>>;
    public memberUpsertWithWhere(where?: string, data?: Member, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Member>>;
    public memberUpsertWithWhere(where?: string, data?: Member, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Member>(`${this.basePath}/members/upsertWithWhere`,
            data,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

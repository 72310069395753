/**
 * cercacasa_api
 * cercacasa_api
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Agency } from '../model/agency';
import { InlineResponse200 } from '../model/inlineResponse200';
import { InlineResponse2001 } from '../model/inlineResponse2001';
import { InlineResponse2002 } from '../model/inlineResponse2002';
import { Listing } from '../model/listing';
import { MlsGroup } from '../model/mlsGroup';
import { MlsGroupInvitation } from '../model/mlsGroupInvitation';
import { MlsGroupMessage } from '../model/mlsGroupMessage';
import { MlsGroupSendMessage } from '../model/mlsGroupSendMessage';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MlsGroupService {

    protected basePath = 'https://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Count instances of the model matched by where from the data source.
     * 
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupCount(where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public mlsGroupCount(where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public mlsGroupCount(where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public mlsGroupCount(where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/mls-models/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new instance of the model and persist it into the data source.
     * 
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupCreate(data?: MlsGroup, observe?: 'body', reportProgress?: boolean): Observable<MlsGroup>;
    public mlsGroupCreate(data?: MlsGroup, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MlsGroup>>;
    public mlsGroupCreate(data?: MlsGroup, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MlsGroup>>;
    public mlsGroupCreate(data?: MlsGroup, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MlsGroup>(`${this.basePath}/mls-models`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a change stream.
     * 
     * @param options 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupCreateChangeStreamGetMlsModelsChangeStream(options?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public mlsGroupCreateChangeStreamGetMlsModelsChangeStream(options?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public mlsGroupCreateChangeStreamGetMlsModelsChangeStream(options?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public mlsGroupCreateChangeStreamGetMlsModelsChangeStream(options?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (options !== undefined && options !== null) {
            queryParameters = queryParameters.set('options', <any>options);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get(`${this.basePath}/mls-models/change-stream`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a change stream.
     * 
     * @param options 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupCreateChangeStreamPostMlsModelsChangeStream(options?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public mlsGroupCreateChangeStreamPostMlsModelsChangeStream(options?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public mlsGroupCreateChangeStreamPostMlsModelsChangeStream(options?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public mlsGroupCreateChangeStreamPostMlsModelsChangeStream(options?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (options !== undefined) {
            formParams.append('options', <any>options);
        }

        return this.httpClient.post(`${this.basePath}/mls-models/change-stream`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a model instance by {{id}} from the data source.
     * 
     * @param id Model id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupDeleteById(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public mlsGroupDeleteById(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public mlsGroupDeleteById(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public mlsGroupDeleteById(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupDeleteById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check whether a model instance exists in the data source.
     * 
     * @param id Model id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupExistsGetMlsModelsidExists(id: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2001>;
    public mlsGroupExistsGetMlsModelsidExists(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2001>>;
    public mlsGroupExistsGetMlsModelsidExists(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2001>>;
    public mlsGroupExistsGetMlsModelsidExists(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupExistsGetMlsModelsidExists.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse2001>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/exists`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check whether a model instance exists in the data source.
     * 
     * @param id Model id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupExistsHeadMlsModelsid(id: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2001>;
    public mlsGroupExistsHeadMlsModelsid(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2001>>;
    public mlsGroupExistsHeadMlsModelsid(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2001>>;
    public mlsGroupExistsHeadMlsModelsid(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupExistsHeadMlsModelsid.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<InlineResponse2001>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find all instances of the model matched by filter from the data source.
     * 
     * @param filter Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (&#x60;{\&quot;where\&quot;:{\&quot;something\&quot;:\&quot;value\&quot;}}&#x60;).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupFind(filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<MlsGroup>>;
    public mlsGroupFind(filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MlsGroup>>>;
    public mlsGroupFind(filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MlsGroup>>>;
    public mlsGroupFind(filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<MlsGroup>>(`${this.basePath}/mls-models`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a model instance by {{id}} from the data source.
     * 
     * @param id Model id
     * @param filter Filter defining fields and include - must be a JSON-encoded string ({\&quot;something\&quot;:\&quot;value\&quot;})
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupFindById(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<MlsGroup>;
    public mlsGroupFindById(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MlsGroup>>;
    public mlsGroupFindById(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MlsGroup>>;
    public mlsGroupFindById(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupFindById.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<MlsGroup>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find first instance of the model matched by filter from the data source.
     * 
     * @param filter Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string (&#x60;{\&quot;where\&quot;:{\&quot;something\&quot;:\&quot;value\&quot;}}&#x60;).  See https://loopback.io/doc/en/lb3/Querying-data.html#using-stringified-json-in-rest-queries for more details.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupFindOne(filter?: string, observe?: 'body', reportProgress?: boolean): Observable<MlsGroup>;
    public mlsGroupFindOne(filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MlsGroup>>;
    public mlsGroupFindOne(filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MlsGroup>>;
    public mlsGroupFindOne(filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<MlsGroup>(`${this.basePath}/mls-models/findOne`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param agencyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupGetListings(id: string, agencyId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Listing>>;
    public mlsGroupGetListings(id: string, agencyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Listing>>>;
    public mlsGroupGetListings(id: string, agencyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Listing>>>;
    public mlsGroupGetListings(id: string, agencyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupGetListings.');
        }

        if (agencyId === null || agencyId === undefined) {
            throw new Error('Required parameter agencyId was null or undefined when calling mlsGroupGetListings.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agencyId !== undefined && agencyId !== null) {
            queryParameters = queryParameters.set('agencyId', <any>agencyId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Listing>>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/getListings`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patch an existing model instance or insert a new one into the data source.
     * 
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupPatchOrCreate(data?: MlsGroup, observe?: 'body', reportProgress?: boolean): Observable<MlsGroup>;
    public mlsGroupPatchOrCreate(data?: MlsGroup, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MlsGroup>>;
    public mlsGroupPatchOrCreate(data?: MlsGroup, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MlsGroup>>;
    public mlsGroupPatchOrCreate(data?: MlsGroup, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<MlsGroup>(`${this.basePath}/mls-models`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts agencies of MlsGroup.
     * 
     * @param id MlsGroup id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupPrototypeCountAgencies(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public mlsGroupPrototypeCountAgencies(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public mlsGroupPrototypeCountAgencies(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public mlsGroupPrototypeCountAgencies(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupPrototypeCountAgencies.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/agencies/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts mlsGroupInvitations of MlsGroup.
     * 
     * @param id MlsGroup id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupPrototypeCountMlsGroupInvitations(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public mlsGroupPrototypeCountMlsGroupInvitations(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public mlsGroupPrototypeCountMlsGroupInvitations(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public mlsGroupPrototypeCountMlsGroupInvitations(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupPrototypeCountMlsGroupInvitations.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/mlsGroupInvitations/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Counts mlsGroupMessages of MlsGroup.
     * 
     * @param id MlsGroup id
     * @param where Criteria to match model instances
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupPrototypeCountMlsGroupMessages(id: string, where?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public mlsGroupPrototypeCountMlsGroupMessages(id: string, where?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public mlsGroupPrototypeCountMlsGroupMessages(id: string, where?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public mlsGroupPrototypeCountMlsGroupMessages(id: string, where?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupPrototypeCountMlsGroupMessages.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/mlsGroupMessages/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in agencies of this model.
     * 
     * @param id MlsGroup id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupPrototypeCreateAgencies(id: string, data?: Agency, observe?: 'body', reportProgress?: boolean): Observable<Agency>;
    public mlsGroupPrototypeCreateAgencies(id: string, data?: Agency, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agency>>;
    public mlsGroupPrototypeCreateAgencies(id: string, data?: Agency, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agency>>;
    public mlsGroupPrototypeCreateAgencies(id: string, data?: Agency, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupPrototypeCreateAgencies.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Agency>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/agencies`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in mlsGroupInvitations of this model.
     * 
     * @param id MlsGroup id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupPrototypeCreateMlsGroupInvitations(id: string, data?: MlsGroupInvitation, observe?: 'body', reportProgress?: boolean): Observable<MlsGroupInvitation>;
    public mlsGroupPrototypeCreateMlsGroupInvitations(id: string, data?: MlsGroupInvitation, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MlsGroupInvitation>>;
    public mlsGroupPrototypeCreateMlsGroupInvitations(id: string, data?: MlsGroupInvitation, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MlsGroupInvitation>>;
    public mlsGroupPrototypeCreateMlsGroupInvitations(id: string, data?: MlsGroupInvitation, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupPrototypeCreateMlsGroupInvitations.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MlsGroupInvitation>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/mlsGroupInvitations`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new instance in mlsGroupMessages of this model.
     * 
     * @param id MlsGroup id
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupPrototypeCreateMlsGroupMessages(id: string, data?: MlsGroupMessage, observe?: 'body', reportProgress?: boolean): Observable<MlsGroupMessage>;
    public mlsGroupPrototypeCreateMlsGroupMessages(id: string, data?: MlsGroupMessage, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MlsGroupMessage>>;
    public mlsGroupPrototypeCreateMlsGroupMessages(id: string, data?: MlsGroupMessage, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MlsGroupMessage>>;
    public mlsGroupPrototypeCreateMlsGroupMessages(id: string, data?: MlsGroupMessage, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupPrototypeCreateMlsGroupMessages.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MlsGroupMessage>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/mlsGroupMessages`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all agencies of this model.
     * 
     * @param id MlsGroup id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupPrototypeDeleteAgencies(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public mlsGroupPrototypeDeleteAgencies(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public mlsGroupPrototypeDeleteAgencies(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public mlsGroupPrototypeDeleteAgencies(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupPrototypeDeleteAgencies.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/agencies`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all mlsGroupInvitations of this model.
     * 
     * @param id MlsGroup id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupPrototypeDeleteMlsGroupInvitations(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public mlsGroupPrototypeDeleteMlsGroupInvitations(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public mlsGroupPrototypeDeleteMlsGroupInvitations(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public mlsGroupPrototypeDeleteMlsGroupInvitations(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupPrototypeDeleteMlsGroupInvitations.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/mlsGroupInvitations`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes all mlsGroupMessages of this model.
     * 
     * @param id MlsGroup id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupPrototypeDeleteMlsGroupMessages(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public mlsGroupPrototypeDeleteMlsGroupMessages(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public mlsGroupPrototypeDeleteMlsGroupMessages(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public mlsGroupPrototypeDeleteMlsGroupMessages(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupPrototypeDeleteMlsGroupMessages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/mlsGroupMessages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for agencies.
     * 
     * @param id MlsGroup id
     * @param fk Foreign key for agencies
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupPrototypeDestroyByIdAgencies(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public mlsGroupPrototypeDestroyByIdAgencies(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public mlsGroupPrototypeDestroyByIdAgencies(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public mlsGroupPrototypeDestroyByIdAgencies(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupPrototypeDestroyByIdAgencies.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling mlsGroupPrototypeDestroyByIdAgencies.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/agencies/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for mlsGroupInvitations.
     * 
     * @param id MlsGroup id
     * @param fk Foreign key for mlsGroupInvitations
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupPrototypeDestroyByIdMlsGroupInvitations(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public mlsGroupPrototypeDestroyByIdMlsGroupInvitations(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public mlsGroupPrototypeDestroyByIdMlsGroupInvitations(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public mlsGroupPrototypeDestroyByIdMlsGroupInvitations(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupPrototypeDestroyByIdMlsGroupInvitations.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling mlsGroupPrototypeDestroyByIdMlsGroupInvitations.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/mlsGroupInvitations/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a related item by id for mlsGroupMessages.
     * 
     * @param id MlsGroup id
     * @param fk Foreign key for mlsGroupMessages
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupPrototypeDestroyByIdMlsGroupMessages(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public mlsGroupPrototypeDestroyByIdMlsGroupMessages(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public mlsGroupPrototypeDestroyByIdMlsGroupMessages(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public mlsGroupPrototypeDestroyByIdMlsGroupMessages(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupPrototypeDestroyByIdMlsGroupMessages.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling mlsGroupPrototypeDestroyByIdMlsGroupMessages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/mlsGroupMessages/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check the existence of agencies relation to an item by id.
     * 
     * @param id MlsGroup id
     * @param fk Foreign key for agencies
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupPrototypeExistsAgencies(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public mlsGroupPrototypeExistsAgencies(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public mlsGroupPrototypeExistsAgencies(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public mlsGroupPrototypeExistsAgencies(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupPrototypeExistsAgencies.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling mlsGroupPrototypeExistsAgencies.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.head<boolean>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/agencies/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for agencies.
     * 
     * @param id MlsGroup id
     * @param fk Foreign key for agencies
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupPrototypeFindByIdAgencies(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<Agency>;
    public mlsGroupPrototypeFindByIdAgencies(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agency>>;
    public mlsGroupPrototypeFindByIdAgencies(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agency>>;
    public mlsGroupPrototypeFindByIdAgencies(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupPrototypeFindByIdAgencies.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling mlsGroupPrototypeFindByIdAgencies.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Agency>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/agencies/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for mlsGroupInvitations.
     * 
     * @param id MlsGroup id
     * @param fk Foreign key for mlsGroupInvitations
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupPrototypeFindByIdMlsGroupInvitations(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<MlsGroupInvitation>;
    public mlsGroupPrototypeFindByIdMlsGroupInvitations(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MlsGroupInvitation>>;
    public mlsGroupPrototypeFindByIdMlsGroupInvitations(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MlsGroupInvitation>>;
    public mlsGroupPrototypeFindByIdMlsGroupInvitations(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupPrototypeFindByIdMlsGroupInvitations.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling mlsGroupPrototypeFindByIdMlsGroupInvitations.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<MlsGroupInvitation>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/mlsGroupInvitations/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find a related item by id for mlsGroupMessages.
     * 
     * @param id MlsGroup id
     * @param fk Foreign key for mlsGroupMessages
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupPrototypeFindByIdMlsGroupMessages(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<MlsGroupMessage>;
    public mlsGroupPrototypeFindByIdMlsGroupMessages(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MlsGroupMessage>>;
    public mlsGroupPrototypeFindByIdMlsGroupMessages(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MlsGroupMessage>>;
    public mlsGroupPrototypeFindByIdMlsGroupMessages(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupPrototypeFindByIdMlsGroupMessages.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling mlsGroupPrototypeFindByIdMlsGroupMessages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<MlsGroupMessage>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/mlsGroupMessages/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries agencies of MlsGroup.
     * 
     * @param id MlsGroup id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupPrototypeGetAgencies(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Agency>>;
    public mlsGroupPrototypeGetAgencies(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Agency>>>;
    public mlsGroupPrototypeGetAgencies(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Agency>>>;
    public mlsGroupPrototypeGetAgencies(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupPrototypeGetAgencies.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<Agency>>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/agencies`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries mlsGroupInvitations of MlsGroup.
     * 
     * @param id MlsGroup id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupPrototypeGetMlsGroupInvitations(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<MlsGroupInvitation>>;
    public mlsGroupPrototypeGetMlsGroupInvitations(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MlsGroupInvitation>>>;
    public mlsGroupPrototypeGetMlsGroupInvitations(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MlsGroupInvitation>>>;
    public mlsGroupPrototypeGetMlsGroupInvitations(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupPrototypeGetMlsGroupInvitations.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<MlsGroupInvitation>>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/mlsGroupInvitations`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Queries mlsGroupMessages of MlsGroup.
     * 
     * @param id MlsGroup id
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupPrototypeGetMlsGroupMessages(id: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<MlsGroupMessage>>;
    public mlsGroupPrototypeGetMlsGroupMessages(id: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MlsGroupMessage>>>;
    public mlsGroupPrototypeGetMlsGroupMessages(id: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MlsGroupMessage>>>;
    public mlsGroupPrototypeGetMlsGroupMessages(id: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupPrototypeGetMlsGroupMessages.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<MlsGroupMessage>>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/mlsGroupMessages`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a related item by id for agencies.
     * 
     * @param id MlsGroup id
     * @param fk Foreign key for agencies
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupPrototypeLinkAgencies(id: string, fk: string, data?: MlsGroupInvitation, observe?: 'body', reportProgress?: boolean): Observable<MlsGroupInvitation>;
    public mlsGroupPrototypeLinkAgencies(id: string, fk: string, data?: MlsGroupInvitation, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MlsGroupInvitation>>;
    public mlsGroupPrototypeLinkAgencies(id: string, fk: string, data?: MlsGroupInvitation, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MlsGroupInvitation>>;
    public mlsGroupPrototypeLinkAgencies(id: string, fk: string, data?: MlsGroupInvitation, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupPrototypeLinkAgencies.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling mlsGroupPrototypeLinkAgencies.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<MlsGroupInvitation>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/agencies/rel/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patch attributes for a model instance and persist it into the data source.
     * 
     * @param id MlsGroup id
     * @param data An object of model property name/value pairs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupPrototypePatchAttributes(id: string, data?: MlsGroup, observe?: 'body', reportProgress?: boolean): Observable<MlsGroup>;
    public mlsGroupPrototypePatchAttributes(id: string, data?: MlsGroup, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MlsGroup>>;
    public mlsGroupPrototypePatchAttributes(id: string, data?: MlsGroup, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MlsGroup>>;
    public mlsGroupPrototypePatchAttributes(id: string, data?: MlsGroup, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupPrototypePatchAttributes.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<MlsGroup>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the agencies relation to an item by id.
     * 
     * @param id MlsGroup id
     * @param fk Foreign key for agencies
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupPrototypeUnlinkAgencies(id: string, fk: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public mlsGroupPrototypeUnlinkAgencies(id: string, fk: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public mlsGroupPrototypeUnlinkAgencies(id: string, fk: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public mlsGroupPrototypeUnlinkAgencies(id: string, fk: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupPrototypeUnlinkAgencies.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling mlsGroupPrototypeUnlinkAgencies.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/agencies/rel/${encodeURIComponent(String(fk))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for agencies.
     * 
     * @param id MlsGroup id
     * @param fk Foreign key for agencies
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupPrototypeUpdateByIdAgencies(id: string, fk: string, data?: Agency, observe?: 'body', reportProgress?: boolean): Observable<Agency>;
    public mlsGroupPrototypeUpdateByIdAgencies(id: string, fk: string, data?: Agency, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Agency>>;
    public mlsGroupPrototypeUpdateByIdAgencies(id: string, fk: string, data?: Agency, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Agency>>;
    public mlsGroupPrototypeUpdateByIdAgencies(id: string, fk: string, data?: Agency, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupPrototypeUpdateByIdAgencies.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling mlsGroupPrototypeUpdateByIdAgencies.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Agency>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/agencies/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for mlsGroupInvitations.
     * 
     * @param id MlsGroup id
     * @param fk Foreign key for mlsGroupInvitations
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupPrototypeUpdateByIdMlsGroupInvitations(id: string, fk: string, data?: MlsGroupInvitation, observe?: 'body', reportProgress?: boolean): Observable<MlsGroupInvitation>;
    public mlsGroupPrototypeUpdateByIdMlsGroupInvitations(id: string, fk: string, data?: MlsGroupInvitation, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MlsGroupInvitation>>;
    public mlsGroupPrototypeUpdateByIdMlsGroupInvitations(id: string, fk: string, data?: MlsGroupInvitation, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MlsGroupInvitation>>;
    public mlsGroupPrototypeUpdateByIdMlsGroupInvitations(id: string, fk: string, data?: MlsGroupInvitation, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupPrototypeUpdateByIdMlsGroupInvitations.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling mlsGroupPrototypeUpdateByIdMlsGroupInvitations.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<MlsGroupInvitation>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/mlsGroupInvitations/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a related item by id for mlsGroupMessages.
     * 
     * @param id MlsGroup id
     * @param fk Foreign key for mlsGroupMessages
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupPrototypeUpdateByIdMlsGroupMessages(id: string, fk: string, data?: MlsGroupMessage, observe?: 'body', reportProgress?: boolean): Observable<MlsGroupMessage>;
    public mlsGroupPrototypeUpdateByIdMlsGroupMessages(id: string, fk: string, data?: MlsGroupMessage, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MlsGroupMessage>>;
    public mlsGroupPrototypeUpdateByIdMlsGroupMessages(id: string, fk: string, data?: MlsGroupMessage, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MlsGroupMessage>>;
    public mlsGroupPrototypeUpdateByIdMlsGroupMessages(id: string, fk: string, data?: MlsGroupMessage, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupPrototypeUpdateByIdMlsGroupMessages.');
        }

        if (fk === null || fk === undefined) {
            throw new Error('Required parameter fk was null or undefined when calling mlsGroupPrototypeUpdateByIdMlsGroupMessages.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<MlsGroupMessage>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/mlsGroupMessages/${encodeURIComponent(String(fk))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param agencyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupRemoveAgency(id: string, agencyId: string, observe?: 'body', reportProgress?: boolean): Observable<MlsGroup>;
    public mlsGroupRemoveAgency(id: string, agencyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MlsGroup>>;
    public mlsGroupRemoveAgency(id: string, agencyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MlsGroup>>;
    public mlsGroupRemoveAgency(id: string, agencyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupRemoveAgency.');
        }

        if (agencyId === null || agencyId === undefined) {
            throw new Error('Required parameter agencyId was null or undefined when calling mlsGroupRemoveAgency.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.delete<MlsGroup>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/removeAgency/${encodeURIComponent(String(agencyId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace attributes for a model instance and persist it into the data source.
     * 
     * @param id Model id
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupReplaceByIdPostMlsModelsidReplace(id: string, data?: MlsGroup, observe?: 'body', reportProgress?: boolean): Observable<MlsGroup>;
    public mlsGroupReplaceByIdPostMlsModelsidReplace(id: string, data?: MlsGroup, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MlsGroup>>;
    public mlsGroupReplaceByIdPostMlsModelsidReplace(id: string, data?: MlsGroup, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MlsGroup>>;
    public mlsGroupReplaceByIdPostMlsModelsidReplace(id: string, data?: MlsGroup, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupReplaceByIdPostMlsModelsidReplace.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MlsGroup>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/replace`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace attributes for a model instance and persist it into the data source.
     * 
     * @param id Model id
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupReplaceByIdPutMlsModelsid(id: string, data?: MlsGroup, observe?: 'body', reportProgress?: boolean): Observable<MlsGroup>;
    public mlsGroupReplaceByIdPutMlsModelsid(id: string, data?: MlsGroup, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MlsGroup>>;
    public mlsGroupReplaceByIdPutMlsModelsid(id: string, data?: MlsGroup, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MlsGroup>>;
    public mlsGroupReplaceByIdPutMlsModelsid(id: string, data?: MlsGroup, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupReplaceByIdPutMlsModelsid.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<MlsGroup>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace an existing model instance or insert a new one into the data source.
     * 
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupReplaceOrCreatePostMlsModelsReplaceOrCreate(data?: MlsGroup, observe?: 'body', reportProgress?: boolean): Observable<MlsGroup>;
    public mlsGroupReplaceOrCreatePostMlsModelsReplaceOrCreate(data?: MlsGroup, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MlsGroup>>;
    public mlsGroupReplaceOrCreatePostMlsModelsReplaceOrCreate(data?: MlsGroup, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MlsGroup>>;
    public mlsGroupReplaceOrCreatePostMlsModelsReplaceOrCreate(data?: MlsGroup, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MlsGroup>(`${this.basePath}/mls-models/replaceOrCreate`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Replace an existing model instance or insert a new one into the data source.
     * 
     * @param data Model instance data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupReplaceOrCreatePutMlsModels(data?: MlsGroup, observe?: 'body', reportProgress?: boolean): Observable<MlsGroup>;
    public mlsGroupReplaceOrCreatePutMlsModels(data?: MlsGroup, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MlsGroup>>;
    public mlsGroupReplaceOrCreatePutMlsModels(data?: MlsGroup, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MlsGroup>>;
    public mlsGroupReplaceOrCreatePutMlsModels(data?: MlsGroup, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<MlsGroup>(`${this.basePath}/mls-models`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param agencyId 
     * @param message 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupRequestAccess(id: string, agencyId: string, message: string, observe?: 'body', reportProgress?: boolean): Observable<MlsGroupInvitation>;
    public mlsGroupRequestAccess(id: string, agencyId: string, message: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MlsGroupInvitation>>;
    public mlsGroupRequestAccess(id: string, agencyId: string, message: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MlsGroupInvitation>>;
    public mlsGroupRequestAccess(id: string, agencyId: string, message: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupRequestAccess.');
        }

        if (agencyId === null || agencyId === undefined) {
            throw new Error('Required parameter agencyId was null or undefined when calling mlsGroupRequestAccess.');
        }

        if (message === null || message === undefined) {
            throw new Error('Required parameter message was null or undefined when calling mlsGroupRequestAccess.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.post<MlsGroupInvitation>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/requestAccess/${encodeURIComponent(String(agencyId))}/${encodeURIComponent(String(message))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param query 
     * @param zoneId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupSearchByName(query?: string, zoneId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<MlsGroup>>;
    public mlsGroupSearchByName(query?: string, zoneId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MlsGroup>>>;
    public mlsGroupSearchByName(query?: string, zoneId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MlsGroup>>>;
    public mlsGroupSearchByName(query?: string, zoneId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (zoneId !== undefined && zoneId !== null) {
            queryParameters = queryParameters.set('zoneId', <any>zoneId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.get<Array<MlsGroup>>(`${this.basePath}/mls-models/searchByName`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupSendMessageToAdmin(id: string, data: MlsGroupSendMessage, observe?: 'body', reportProgress?: boolean): Observable<MlsGroupMessage>;
    public mlsGroupSendMessageToAdmin(id: string, data: MlsGroupSendMessage, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MlsGroupMessage>>;
    public mlsGroupSendMessageToAdmin(id: string, data: MlsGroupSendMessage, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MlsGroupMessage>>;
    public mlsGroupSendMessageToAdmin(id: string, data: MlsGroupSendMessage, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling mlsGroupSendMessageToAdmin.');
        }

        if (data === null || data === undefined) {
            throw new Error('Required parameter data was null or undefined when calling mlsGroupSendMessageToAdmin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MlsGroupMessage>(`${this.basePath}/mls-models/${encodeURIComponent(String(id))}/sendMessageToAdmin`,
            data,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update instances of the model matched by {{where}} from the data source.
     * 
     * @param where Criteria to match model instances
     * @param data An object of model property name/value pairs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupUpdateAll(where?: string, data?: MlsGroup, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2002>;
    public mlsGroupUpdateAll(where?: string, data?: MlsGroup, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2002>>;
    public mlsGroupUpdateAll(where?: string, data?: MlsGroup, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2002>>;
    public mlsGroupUpdateAll(where?: string, data?: MlsGroup, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<InlineResponse2002>(`${this.basePath}/mls-models/update`,
            data,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupUploadLogo(observe?: 'body', reportProgress?: boolean): Observable<string>;
    public mlsGroupUploadLogo(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public mlsGroupUploadLogo(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public mlsGroupUploadLogo(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];

        return this.httpClient.post<string>(`${this.basePath}/mls-models/uploadLogo`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an existing model instance or insert a new one into the data source based on the where criteria.
     * 
     * @param where Criteria to match model instances
     * @param data An object of model property name/value pairs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mlsGroupUpsertWithWhere(where?: string, data?: MlsGroup, observe?: 'body', reportProgress?: boolean): Observable<MlsGroup>;
    public mlsGroupUpsertWithWhere(where?: string, data?: MlsGroup, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MlsGroup>>;
    public mlsGroupUpsertWithWhere(where?: string, data?: MlsGroup, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MlsGroup>>;
    public mlsGroupUpsertWithWhere(where?: string, data?: MlsGroup, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'application/xml',
            'text/xml',
            'application/javascript',
            'text/javascript'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'application/xml',
            'text/xml'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MlsGroup>(`${this.basePath}/mls-models/upsertWithWhere`,
            data,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AgencyService } from './api/agency.service';
import { AgencyZoneGenericRequestService } from './api/agencyZoneGenericRequest.service';
import { AgentService } from './api/agent.service';
import { AgentManagingAgencyService } from './api/agentManagingAgency.service';
import { AnalyticsService } from './api/analytics.service';
import { ArticleService } from './api/article.service';
import { ArticleZoneService } from './api/articleZone.service';
import { AtecoCompanyService } from './api/atecoCompany.service';
import { BillingInfoService } from './api/billingInfo.service';
import { BrandService } from './api/brand.service';
import { BugReportService } from './api/bugReport.service';
import { ClientService } from './api/client.service';
import { ContactRequestService } from './api/contactRequest.service';
import { ContainerService } from './api/container.service';
import { DefaultProductProfessionistService } from './api/defaultProductProfessionist.service';
import { DeviceService } from './api/device.service';
import { FavouriteAgencyService } from './api/favouriteAgency.service';
import { FavouriteAgentService } from './api/favouriteAgent.service';
import { FavouriteArticleService } from './api/favouriteArticle.service';
import { FavouriteProductService } from './api/favouriteProduct.service';
import { FavouriteProfessionistService } from './api/favouriteProfessionist.service';
import { FooterStatisticService } from './api/footerStatistic.service';
import { GenericRequestService } from './api/genericRequest.service';
import { HelpfulReviewService } from './api/helpfulReview.service';
import { HistoryPaymentService } from './api/historyPayment.service';
import { IframeService } from './api/iframe.service';
import { LikedAgencyService } from './api/likedAgency.service';
import { LikedAgentService } from './api/likedAgent.service';
import { LikedArticleService } from './api/likedArticle.service';
import { LikedBrandService } from './api/likedBrand.service';
import { LikedListingService } from './api/likedListing.service';
import { LikedProductService } from './api/likedProduct.service';
import { LikedProfessionistService } from './api/likedProfessionist.service';
import { ListingService } from './api/listing.service';
import { ListingClientService } from './api/listingClient.service';
import { MemberService } from './api/member.service';
import { MlsGroupService } from './api/mlsGroup.service';
import { MlsGroupInvitationService } from './api/mlsGroupInvitation.service';
import { MlsGroupMessageService } from './api/mlsGroupMessage.service';
import { MlsQueryService } from './api/mlsQuery.service';
import { MlsRequestService } from './api/mlsRequest.service';
import { MutuiConfigService } from './api/mutuiConfig.service';
import { NoteService } from './api/note.service';
import { PartnerService } from './api/partner.service';
import { PlanService } from './api/plan.service';
import { ProductService } from './api/product.service';
import { ProfessionistService } from './api/professionist.service';
import { ProfessionistManagingBrandService } from './api/professionistManagingBrand.service';
import { ProfessionistZoneService } from './api/professionistZone.service';
import { ReportService } from './api/report.service';
import { ReportImportFtpService } from './api/reportImportFtp.service';
import { ReviewService } from './api/review.service';
import { SavedListingService } from './api/savedListing.service';
import { SubscriptionService } from './api/subscription.service';
import { UserSearchService } from './api/userSearch.service';
import { VisibilityScheduleService } from './api/visibilitySchedule.service';
import { VisitedAgencyService } from './api/visitedAgency.service';
import { VisitedAgentService } from './api/visitedAgent.service';
import { VisitedListingService } from './api/visitedListing.service';
import { VisitedProfessionistService } from './api/visitedProfessionist.service';
import { ZoneService } from './api/zone.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AgencyService,
    AgencyZoneGenericRequestService,
    AgentService,
    AgentManagingAgencyService,
    AnalyticsService,
    ArticleService,
    ArticleZoneService,
    AtecoCompanyService,
    BillingInfoService,
    BrandService,
    BugReportService,
    ClientService,
    ContactRequestService,
    ContainerService,
    DefaultProductProfessionistService,
    DeviceService,
    FavouriteAgencyService,
    FavouriteAgentService,
    FavouriteArticleService,
    FavouriteProductService,
    FavouriteProfessionistService,
    FooterStatisticService,
    GenericRequestService,
    HelpfulReviewService,
    HistoryPaymentService,
    IframeService,
    LikedAgencyService,
    LikedAgentService,
    LikedArticleService,
    LikedBrandService,
    LikedListingService,
    LikedProductService,
    LikedProfessionistService,
    ListingService,
    ListingClientService,
    MemberService,
    MlsGroupService,
    MlsGroupInvitationService,
    MlsGroupMessageService,
    MlsQueryService,
    MlsRequestService,
    MutuiConfigService,
    NoteService,
    PartnerService,
    PlanService,
    ProductService,
    ProfessionistService,
    ProfessionistManagingBrandService,
    ProfessionistZoneService,
    ReportService,
    ReportImportFtpService,
    ReviewService,
    SavedListingService,
    SubscriptionService,
    UserSearchService,
    VisibilityScheduleService,
    VisitedAgencyService,
    VisitedAgentService,
    VisitedListingService,
    VisitedProfessionistService,
    ZoneService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<any> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}

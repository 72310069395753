import { Injectable } from '@angular/core';
import {BehaviorSubject, lastValueFrom, Observable} from 'rxjs';

import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { MemberService } from '../api';
import { ConsoleLoggerService } from './console-logger.service';
import { ToastrService } from 'ngx-toastr';
import {SsrCookieService} from 'ngx-cookie-service-ssr';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  public get token(): string {
    if (this.cookieService.get('token')){
      return this.cookieService.get('token');
    } else {
      return null;
    }
  }

  public set token(val) {
    if (val) {
      this.cookieService.set('token', val, {expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)), sameSite: 'Lax', secure: true});
    } else {
      this.cookieService.delete('token');
    }
  }

  public set setCurrentUser(val) {
    if (val) {
      this.cookieService.set('currentUser', JSON.stringify(val), {expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)), sameSite: 'Lax', secure: true});
      this.currentUserSubject.next(val);
    }
  }

  constructor(
    private http: HttpClient,
    private member: MemberService,
    private console: ConsoleLoggerService,
    private cookieService: SsrCookieService,
    public toastr: ToastrService,
    ) {
    if (this.cookieService.get('currentUser')) {
      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(this.cookieService.get('currentUser')));
    } else {
      this.currentUserSubject = new BehaviorSubject<User>(null);
    }
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get getCurrentUser(): User {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string, rememberMe: boolean): any {
    const credentials = {
      email, password, ttl: rememberMe ? -1 : 1209600,
    };
    return this.member.memberLogin(credentials, 'user').pipe(map(response => {
      if (response.user.role === 'agent' || response.user.role === 'professionist'){
        // Prevent login to dashboard users
        throw new Error('dashboard');
      }
      else {
        this.token = response.id;
        this.cookieService.set('currentUser', JSON.stringify(response.user), {expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)), sameSite: 'Lax', secure: true});
        this.currentUserSubject.next(response.user);
        return response.id;
      }
    }));
  }

  async logout(): Promise<void> {
    try {
      await lastValueFrom( this.member.memberLogout());
      this.token = null;
      this.cookieService.delete('currentUser');
      this.currentUserSubject.next(null);
    } catch {
      this.token = null;
      this.cookieService.delete('currentUser');
      this.currentUserSubject.next(null);
    }
  }

  async signup(user: User): Promise<void> {
    try {
      await lastValueFrom( this.member.memberCreate(user));
      return await lastValueFrom( this.login(user.email, user.password, true));
    } catch (error) {
      throw new Error(error);
    }
  }

  async updateUser(user: User): Promise<boolean | string> {
    try {
      const response = await lastValueFrom( this.member.memberPrototypePatchAttributes('me', user));
      this.currentUserSubject.next(response);
      return true;
    } catch (error) {
      return error;
    }
  }

}



import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MetaTag } from '../models/meta-tag';

@Injectable({
  providedIn: 'root'
})
export class ShareService {

  private urlMeta = 'og:url';
  private titleMeta = 'og:title';
  private descriptionMeta = 'og:description';
  private imageMeta = 'og:image';
  private secureImageMeta = 'og:image:secure_url';
  private type = 'og:type';

  constructor(private metaService: Meta, private route: Router) { }

  public setFacebookTags(url: string, title: string, description: string, image: string): void {
    const tags = [
      new MetaTag(this.urlMeta, url, null),
      new MetaTag(this.titleMeta, title, null),
      new MetaTag(this.descriptionMeta, description, null),
      new MetaTag(this.imageMeta, image, 'image'),
      new MetaTag(this.secureImageMeta, image, 'image'),
      new MetaTag(this.type, 'website', null),
    ];
    this.setTags(tags);
  }

  private setTags(tags: MetaTag[]): void {
    tags.forEach(siteTag => {
      if (siteTag.itemProp) {
        this.metaService.updateTag({ property: siteTag.name, content: siteTag.value, itemprop: siteTag.itemProp });
      } else {
        this.metaService.updateTag({ property: siteTag.name, content: siteTag.value });
      }
    });
  }

  public getActualRoute(): string {
    return environment.sitePath + this.route.url;
  }
}
